import React from 'react';

const PurchaseListingCardSkeleton = ({ isPurchaseDetails }: { isPurchaseDetails?: boolean }) => {
  if (isPurchaseDetails) {
    return (
      <div className="lg:px-4 max-w-[906px]">
        <div className="bg-white lg:pt-0 lg:px-4 pt-2 rounded">
          {/* Items Count */}
          <div className="flex justify-end py-3 px-4 border-b border-gray-200">
            <div className="h-6 w-20 bg-gray-200 rounded animate-pulse"></div>
          </div>

          {/* Item Card */}
          <div className="border-b border-gray-200 flex  flex-col lg:flex-row p-4 gap-4">
            {/* Image Section */}
            <div className="lg:w-[218px] w-full flex justify-center items-center py-7">
              <div className="h-[150px] w-[100px] bg-gray-200 rounded animate-pulse"></div>
            </div>

            {/* Product Details */}
            <div className="flex flex-col flex-1 gap-4 lg:py-7">
              <div className="h-6 w-3/4 bg-gray-200 rounded animate-pulse"></div>
              <div className="flex justify-between mt-4">
                <div className="h-6 w-24 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-6 w-24 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div className="flex items-end justify-between mt-4">
                <div className="h-6 w-20 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="border border-gray-200 p-4 rounded-lg flex flex-row lg:flex-col gap-3 lg:h-80">
        <div className="w-32 h-32 bg-gray-200 rounded animate-pulse"></div>
        <div className="flex-1 space-y-4">
          <div className="h-6 w-full bg-gray-200 rounded animate-pulse"></div>
          <div className="flex justify-between items-center">
            <div className="h-6 w-20 bg-gray-200 rounded animate-pulse"></div>
          </div>
          <div className="hidden lg:block h-10 w-full bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    );
  }
};

export default PurchaseListingCardSkeleton;
