import { Field, GetStaticComponentProps, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { SiteNameUppercase } from 'src/helpers/Constants';
import { useEffect, useState } from 'react';
import { useOcSelector } from 'src/redux/ocStore';
import { useCookies } from 'react-cookie';
import LoyaltyPointsProgressBar from '../CrowdTwistLoyaltyProgressBar/PSPCrowdTwistLoyaltyProgressBar';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import getProfileImages from './query.graphql';
import UserGroupProfileImage from '../UserGroupProfileImage/UserGroupProfileImage';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { pSPCrowdTwistLoyaltyTailwindVariant } from 'tailwindVariants/components/pSPCrowdTwistLoyaltyTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import Loader from 'components/Loader/Loader';

type UserProfileType = {
  data: {
    first_name: string;
    redeemable_points: number | undefined;
  };
  memberSinceYear: string;
} | null;

type PSPCrowdTwistLoyaltyStaticProps = {
  item: {
    children: {
      results: {
        Specie: string;
        SpecieData: {
          jsonValue: {
            value: {
              src: string;
              alt: string;
            };
          };
        };
      }[];
    };
  };
};

type PSPCrowdTwistLoyaltyProps = {
  fields: {
    heading: Field<string>;
    helperText: Field<string>;
    isCrowdTwistEnabled: Field<string>;
    jumpToTextMobile: Field<string>;
    memberSinceText: Field<string>;
    notSignedIntoCrowdTwistMessage: Field<string>;
    pointsRedemptionLabel: Field<string>;
    pointsText: Field<string>;
    redeemNowLink: LinkField;
    redeemNowText: Field<string>;
    selectWidgetTextMobile: Field<string>;
    tabNavItems: {
      displayName: string;
    };
    welcomeText: Field<string>;
  };
  staticProps: PSPCrowdTwistLoyaltyStaticProps;
};

const {
  heading,
  content,
  details,
  top,
  myRewardsProfile,
  myRewardsProfileImage,
  myRewardsProfileName,
  myRewardsProfileMemberSince,
  myRewardsProfilePoints,
} = pSPCrowdTwistLoyaltyTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const PSPCrowdTwistLoyalty = ({ fields, staticProps }: PSPCrowdTwistLoyaltyProps) => {
  const userXP = useOcSelector((state) => state?.ocUser?.user?.xp);
  const userFirstName = useOcSelector((state) => state?.ocUser?.user?.FirstName);
  const unityId = useOcSelector((state) => state?.ocUser?.user?.xp?.UnityId);
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<UserProfileType>(null);
  const [profileName, setProfileName] = useState<string | undefined>('');
  const [points, setPoints] = useState<number | undefined>(0);
  const [memberSince, setMemberSince] = useState<number | undefined>(0);

  const [cookies] = useCookies(['ct-auth']);
  const ctAuthCookie = cookies['ct-auth'];
  const isExperienceEditor = useExperienceEditor();

  // todo: remove the "any" type when "pets" is added to the types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userPets = useOcSelector((s: any) => s.ocUser?.user?.xp?.Pets);
  const isUserPetsAvailable = userPets && Object.keys(userPets).length > 0;
  const profileImages = staticProps?.item?.children?.results;

  useEffect(() => {
    if (userXP && Object.keys(userXP)?.length > 0 && isLoyaltyAccepted) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [isLoyaltyAccepted, userXP]);

  useEffect(() => {
    if (!isLoading && unityId && ctAuthCookie && !isExperienceEditor) {
      fetch('/api/crowdtwist/crowd-twist-get-user-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const dateCreated = new Date(data.date_created * 1000);
          const memberSince = dateCreated.getFullYear();
          const memberSinceYear = memberSince.toString();
          setUserData({ data, ...{ memberSinceYear: memberSinceYear } });
        })
        .catch((error) => {
          console.error('Error creating getting User Response: ', error);
        });
    }
  }, [ctAuthCookie, isExperienceEditor, isLoading, unityId]);

  function extractYear(dateString: string): number | null {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date format');
    }
    return date.getFullYear();
  }

  useEffect(() => {
    if (isExperienceEditor) {
      setProfileName('Experience editor user');
      setPoints(2000);
      setMemberSince(2022);
    } else {
      // Per our previous discussion instead of checking Model.IsSignedIntoCrowdTwist will check the ctAuthCookie
      if (ctAuthCookie && userXP?.LoyaltyAcceptedOn) {
        setProfileName(userFirstName);
        setPoints(Number(userData?.data?.redeemable_points));
        setMemberSince(extractYear(userXP.LoyaltyAcceptedOn) ?? 0);
      } else {
        setProfileName(userFirstName);
      }
    }
  }, [
    ctAuthCookie,
    fields,
    isExperienceEditor,
    userData?.data?.redeemable_points,
    userFirstName,
    userXP?.LoyaltyAcceptedOn,
  ]);

  if (!fields) {
    return;
  }

  if (isLoyaltyAccepted === true) {
    return (
      <>
        <div className={content()}>
          <RichTextHelper className={heading()} field={fields?.heading} tag="h1" />
          <div className={details()}>
            <div className={top()}>
              <>
                <div className={myRewardsProfile()}>
                  {fields.heading && (
                    <>
                      {profileImages && profileImages?.length > 0 && (
                        <div className={myRewardsProfileImage()}>
                          {profileImages?.map((item, index) => (
                            <div key={index}>
                              {isUserPetsAvailable ? (
                                <>
                                  {item?.Specie === Object.keys(userPets)[0] && (
                                    <UserGroupProfileImage
                                      profileImages={profileImages}
                                      userPets={userPets}
                                      isUserPetsAvailable={isUserPetsAvailable}
                                      width={64}
                                      height={64}
                                      addClass="max-w-full"
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* Default image 'Paw' if no userGroups available in Redux */}
                                  {item?.Specie === 'Paw' && (
                                    <UserGroupProfileImage
                                      profileImages={profileImages}
                                      userPets={userPets}
                                      isUserPetsAvailable={isUserPetsAvailable}
                                      width={64}
                                      height={64}
                                      addClass="max-w-full"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  <div>
                    <h4 className={myRewardsProfileName()}>
                      {fields?.welcomeText?.value} {profileName}!
                    </h4>
                    {ctAuthCookie && (
                      <>
                        <p className={myRewardsProfileMemberSince()}>
                          {fields?.memberSinceText?.value} {memberSince}
                        </p>
                        <p className={myRewardsProfilePoints()}>
                          {points !== null && points !== undefined && !isNaN(points) ? (
                            `${points} ${fields?.pointsText?.value || ''}`
                          ) : (
                            <Loader />
                          )}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                {ctAuthCookie && points !== undefined && points !== null ? (
                  <LoyaltyPointsProgressBar
                    userPoints={points}
                    pointRedemptionLabel={fields?.pointsRedemptionLabel}
                    redeemNowLink={fields?.redeemNowLink}
                    redeemNowText={fields?.redeemNowText}
                  />
                ) : (
                  <div>Please check again later</div>
                )}
              </>
            </div>
            {fields?.isCrowdTwistEnabled?.value &&
              !ctAuthCookie &&
              fields.notSignedIntoCrowdTwistMessage?.value && (
                <RichTextHelper field={fields?.notSignedIntoCrowdTwistMessage} />
              )}
          </div>
        </div>
      </>
    );
  }

  return;
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData, context) => {
  if (rendering.dataSource && layoutData?.sitecore?.context?.language) {
    const sitecore = layoutData?.sitecore;
    const sitecoreContext = sitecore?.context;
    const siteName = sitecoreContext?.site?.name as string;
    const siteNameUpper = siteName?.toUpperCase() as SiteNameUppercase;

    const graphQLClient = graphqlClientFactory({});

    let datasourceItem;

    if (siteNameUpper === 'PSP') {
      datasourceItem = '{27A2AD57-966E-4EC6-8EE9-F25945F4C151}';
    } else {
      datasourceItem = '{DC436F5A-3AFC-4110-8220-8B493379D6B6}';
    }
    const data = await graphQLClient.request<PSPCrowdTwistLoyaltyStaticProps>(
      getProfileImages(datasourceItem),
      {
        datasource: rendering?.dataSource,
        language: layoutData?.sitecore?.context?.language,
      }
    );

    return {
      staticProps: data,
    };
  }
  throw new Error(
    `No datasource set for rendering: ${rendering.componentName}, Guid: ${
      layoutData.sitecore.route?.itemId
    }, Path: ${
      context.params?.path instanceof Array ? context.params.path.join('/') : context.params?.path
    } `
  );
};

export default PSPCrowdTwistLoyalty;
