import React from 'react';
import { BazaarVoiceScript } from 'src/helpers/BazaarVoice/BazaarVoiceScript';
// Define the type for BV object
interface BVObject {
  reviews: {
    on: (event: string, callback: () => void) => void;
  };
}
const bvCallback = function (BV: BVObject) {
  if (BV && BV.reviews) {
    BV.reviews.on('show', function () {
      const el = document.getElementById('el-pReviews');
      if (el) {
        el.click();
        window.scrollTo({
          top: el.offsetTop,
          behavior: 'smooth',
        });
      } else {
        console.error('Element with id "el-pReviews" not found.');
      }
    });
  } else {
    console.error('BV or BV.reviews is not defined.');
  }
};
const BazarVoiceReviews = ({
  bvProductId,
  showLeftAlign,
  bvRedirectUrl,
  onClick,
}: {
  bvProductId: string;
  showLeftAlign?: boolean;
  bvRedirectUrl?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <>
      <BazaarVoiceScript bvCallback={bvCallback} />
      <div
        onClick={onClick}
        data-bv-show={bvRedirectUrl ? 'inline_rating' : 'rating_summary'}
        data-bv-product-id={bvProductId}
        id="ratingSummary"
        data-bv-redirect-url={bvRedirectUrl}
        data-bind="attr:{'data-bv-product-id:SKU(), 'data-bv-redirect-url':ProductUrl()}"
        className={'ratingSummary ' + showLeftAlign ? 'justify-start' : 'justify-center'}
      />
    </>
  );
};
export default BazarVoiceReviews;
