import React, { ChangeEvent, useEffect, useState } from 'react';
import ButtonHelper from '../commonComponents/Button';
import useDictionary from 'src/hooks/useDictionary';
import { productDetailAddToCartTailwindVariant } from 'tailwindVariants/components/ProductDetails/productDetailAddToCartTailwindVariant';
import { FulfillmentType, GTM_EVENT, GTMLabels } from '../Constants';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcSelector } from 'src/redux/ocStore';
import useOcCart from 'src/hooks/useOcCart';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from '../search/SearchResults/types';
import { trackAddEvent } from '@sitecore-search/react';
import { inventoryStateProps } from 'components/ProductDetailR2/ProductDetailR2';

const {
  base,
  bottomError,
  buttonBase,
  qtyText,
  qtyInput,
  qtyButton,
  addToCartButton,
  inputWrapper,
  qtyWrapper,
} = productDetailAddToCartTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

interface ProductDetailAddToCartProps {
  productStockAvailability?: inventoryStateProps;
  fullwidth?: boolean;
}
const ProductDetailAddToCart = (props: ProductDetailAddToCartProps) => {
  const { sitecoreContext } = useSitecoreContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productData: any = sitecoreContext?.productData;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { getDictionaryValue } = useDictionary();
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const { pageItem } = getGTMSessionStorage();
  const [isError, setIsError] = useState<boolean>();
  const { addToCart } = useOcCart();
  const [qty, setQty] = useState<number>(1);

  const productStockAvailability = props?.productStockAvailability;

  useEffect(() => {
    if (productStockAvailability?.availability) {
      if (productStockAvailability?.availability == 'OutOfStock') {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
  }, [productStockAvailability]);

  const updateQuantity = (newQty: number) => {
    const updatedQty = isNaN(newQty) ? 1 : newQty;
    const isValidQuantity =
      productStockAvailability?.productInventory &&
      updatedQty <= productStockAvailability.productInventory;
    setQty(newQty > 0 ? newQty : 1);
    setIsError(!isValidQuantity);
  };
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = parseInt(e.target?.value);
    updateQuantity(value);
    if (!Number.isNaN(value)) {
      updateQuantity(value);
    } else {
      setQty(0);
    }
  };

  const onInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value === 0) {
      setQty(1);
    }
  };

  return (
    <div className={base({ className: props?.fullwidth ? 'w-full' : '' })}>
      <div className={buttonBase()}>
        <div className={qtyWrapper()}>
          <div className={inputWrapper()}>
            <p className={qtyText()}>{getDictionaryValue('QuantityPDP')}</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateQuantity(qty);
              }}
            >
              <input
                onChange={onInputChange}
                type="text"
                min="0"
                max="100"
                value={qty}
                onBlur={onInputBlur}
                className={qtyInput()}
              />
            </form>
          </div>
          <div className="rounded-r-2">
            <button
              aria-label="add to cart"
              onClick={(e) => {
                e.preventDefault();
                if (qty >= 0) {
                  updateQuantity(qty + 1);
                }
              }}
              className={qtyButton()}
            >
              +
            </button>
            <button
              aria-label="remove from cart"
              onClick={(e) => {
                e.preventDefault();
                if (qty >= 0) {
                  updateQuantity(qty - 1);
                }
              }}
              className={qtyButton()}
            >
              -
            </button>
          </div>
        </div>
        {!myStoreData?.ecommerceDisabled ? (
          <>
            <ButtonHelper
              size={'default'}
              isLinkField={true}
              variant={'primary'}
              className={addToCartButton({ isError: isError })}
              field={{
                value: {
                  href: '/',
                  text: getDictionaryValue('AddToCartCTAPDP'),
                  target: '_blank',
                },
              }}
              onClickHandler={async (e) => {
                e?.preventDefault();
                if (!isError && productData?.ID) {
                  try {
                    await addToCart({
                      productId: productData?.ID,
                      quantity: +qty,
                      storeId: myStoreData?.storeId,
                      inventoryItemId: productStockAvailability?.inventoryRecordId,
                      ID: productData?.ID,
                    });
                  } catch (_error) {
                    setIsError(true);
                  }
                }
                const newMethod =
                  localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
                sendProductsPromotion({
                  eventName: GTM_EVENT?.addToCart,
                  data: productData as ProductSearchResultModelWithVariants,
                  currentPage: GTMLabels.PDP,
                  pageItem: pageItem,
                  position: 0,
                  click_from: GTMLabels?.PDP,
                  quantity: qty as number,
                  isRatingShow: true,
                  fulfillment_option:
                    newMethod === FulfillmentType.DFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                });
                //Sending Add Cart event to Search
                trackAddEvent('product', 'cart', {
                  items: [{ entityType: 'product', id: productData?.ID as string }],
                  actionSubtype: 'conversion',
                });
              }}
            />
          </>
        ) : null}
      </div>

      {isError && (
        <>
          <div className={bottomError()}>
            {productStockAvailability?.availability === 'OutOfStock'
              ? getDictionaryValue('NoProductUnitsAvailablePDP')
              : productStockAvailability?.productInventory === 1
              ? getDictionaryValue('OneProductUnitsAvailablePDP')
              : getDictionaryValue('ProductUnitsAvailablePDP')?.replace(
                  '{productUnit}',
                  productStockAvailability?.remainingInventory &&
                    productStockAvailability?.remainingInventory > 0
                    ? productStockAvailability?.remainingInventory?.toString()
                    : parseInt(productStockAvailability?.productInventory?.toString() ?? '') < 0
                    ? '0'
                    : productStockAvailability?.productInventory?.toString() ?? '0'
                )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetailAddToCart;
