import {
  FilterGeo,
  PageController,
  SearchResultsInitialState,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { BuyerAddress, Me, Order } from 'ordercloud-javascript-sdk';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { convertMilesToKM } from 'src/utils/milestokm';
import { changeStoreVariants } from 'tailwindVariants/components/changeStoreTailwindVariant';
import clsx from 'clsx';
import { ImageField, NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import useDictionary from 'src/hooks/useDictionary';
import { OcRootState, useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import ComponentContext, { ComponentContextData } from 'lib/context/ComponentContext';
import { StoreObjectData } from 'src/pages/api/store/get-store-details';
import { closedText, FulfillmentType, GTM_EVENT } from 'src/helpers/Constants';
import {
  clearCurrentOrder,
  clearCurrentOrderWithoutInitialized,
  patchOrder,
  retrieveOrder,
  saveShippingAddress,
} from 'src/redux/ocCurrentOrder';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';
import Loader from 'components/Loader/Loader';
import { DateTime, WeekdayNumbers } from 'luxon';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { storeApi } from 'src/utils/nextApiConfig';
import { useSiteNameForApi } from 'src/hooks/useSiteName';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { apiRequest, ApiRequestOptions } from 'src/utils/apiWrapper';
import sendStoreUpdate from 'src/utils/homeStoreHelper';

export type GenericStoreLocatorProps =
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeStoreWidget.Fields.ChangeStore & {
    coordinates: { lat: number; lng: number };
    productId: string;
    setFindStorePopupOpen?: (a: boolean) => void;
    handleModalClose?: () => void;
    showMainLoader?: (a: boolean) => void;
    formShippingAddress?: Partial<BuyerAddress>;
    setLoading?: (a: boolean) => void;
    isFromPDP?: boolean;
    setShowHardError?: (a: boolean) => void;
    setShowNoDeliveryError?: (a: boolean) => void;
    setRenderStoreWidget?: (a: boolean) => void;
  };
export type StoreDetailsProps =
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeStoreWidget.Fields.ChangeStore & {
    store: StoreProps | null;
    handleModalClose: (event?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
    showModal: boolean | undefined;
    myStoreData: StoreObjectData;
    getDictionaryValue: (key: string, fallbackValue?: string | undefined) => string;
  };
//Tailwind Variants Styles
const {
  dialogueTitle,
  storeListRow,
  storeDetailsContainer,
  storeDetail,
  storeName,
  storeInventoryContainer,
  inventoryText,
  myStoreWrapper,
  myStoreTxt,
  selectStoreCTA,
  openUnitilText,
  moreDetailsLink,
  popupSpacing,
  storeDetailsPopupContainer,
  storeDetailsPopupRow,
  storeDetailsPopupRight,
  storeDetailsAddress,
  popupDetailsTitle,
  storeDetailsPopupRightPadding,
  storeDetailsimage,
} = changeStoreVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const NoStoreFound = (props: { noStoreTxt: string | undefined }) => {
  return (
    <div className={clsx(storeListRow(), 'lg:flex-col border-b-0 lg:pl-0 lg:pr-0')}>
      <p
        className={clsx(
          'text-color-text-dark',
          'text-heading-desk-small-regular',
          'font-heading-desk-small-regular',
          'leading-heading-desk-small-regular'
        )}
      >
        {props?.noStoreTxt}
      </p>
    </div>
  );
};

interface ShopThisStoreCTAProps {
  ctaText: string;
  setcomponentContextData: Dispatch<SetStateAction<ComponentContextData>>;
  componentContextData: ComponentContextData;
  store: StoreProps;
  dispatch: ThunkDispatch<OcRootState, null, AnyAction>;
  setFindStorePopupOpen?: (a: boolean) => void;
  handleModalClose?: () => void;
  formShippingAddress?: Partial<BuyerAddress>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  currentOrder?: any;
  myStoreID?: string;
  showMainLoader?: (a: boolean) => void;
}

const ShopThisStoreCTA = ({
  ctaText,
  setcomponentContextData,
  componentContextData,
  store,
  dispatch,
  setFindStorePopupOpen,
  handleModalClose,
  myStoreID,
  formShippingAddress,
  currentOrder,
  showMainLoader,
}: ShopThisStoreCTAProps): JSX.Element | null => {
  const handleChangeStoreModalCloseShopThisStore = () => {
    setcomponentContextData({ ...componentContextData, showChangeStorePopup: false });
  };
  const handleChangeStoreModalClose = async () => {
    await setcomponentContextData({
      ...componentContextData,
      showChangeStorePopup: false,
      showDeliveryAvailabilityPopup: false,
      openDeliveryAvailabilityPopupFromPDP: false,
    });
    if (handleModalClose) {
      handleModalClose();
    }
    if (setFindStorePopupOpen) {
      setFindStorePopupOpen(false);
    }
  };
  const onShopThisStoreClick = () => {
    //Show Warning Popup while switch to another store.
    setcomponentContextData({
      ...componentContextData,
      isAutoshipMethod: false,
      storeId: store?.storeid,
      callBackFunctionAfterStoreChange: async () => {
        handleChangeStoreModalCloseShopThisStore();
        localStorage.removeItem('Is_Autoship_Method');
        const request: Order = { xp: { Fulfillment: FulfillmentType.BOPIS } };
        const retrieveStoreOrder = await dispatch(retrieveOrder());
        const responsesStoreOrder = unwrapResult(retrieveStoreOrder);
        if (responsesStoreOrder === undefined) {
          await dispatch(clearCurrentOrder());
        } else {
          await dispatch(retrieveOrder());
        }
        await dispatch(patchOrder({ request }));
      },
    });
  };
  const onSelectStoreClick = async () => {
    if (store?.storeid === myStoreID) {
      sendStoreUpdate(store?.storeid);
      sendStoreGTMData({
        eventName: GTM_EVENT?.selectStore,
        storeId: store?.storeid,
        //TODO: KRushna
        // storesShown: storesList?.length?.toString(),
      });

      const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
      showMainLoader && showMainLoader(true);
      //Save shipping Address
      if (formShippingAddress?.Street1) {
        if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
          await dispatch(saveShippingAddress(formShippingAddress));
        } else {
          localStorage.setItem('selected_delivery_address', JSON.stringify(formShippingAddress));
        }
        if (currentOrder?.order && currentOrder?.order?.ID) {
          await dispatch(patchOrder({ request }));
          showMainLoader && showMainLoader(false);
        } else {
          localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
          showMainLoader && showMainLoader(false);
        }
      }
      handleChangeStoreModalClose();
    } else {
      //Show Warning Popup while switch to another store.
      setcomponentContextData({
        ...componentContextData,
        storeId: store?.storeid,
        fromFulfillment: true,
        callBackFunctionAfterStoreChange: async () => {
          showMainLoader && showMainLoader(true);
          setcomponentContextData({
            ...componentContextData,
            showDeliveryAvailabilityPopup: false,
          });
          if (setFindStorePopupOpen) {
            setFindStorePopupOpen(false);
          }
          // const redirectUrl = '/'; // TODO: will this url configure from CMS?
          const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
          const retrieveStoreOrder = await dispatch(retrieveOrder());
          const responsesStoreOrder = unwrapResult(retrieveStoreOrder);
          if (responsesStoreOrder === undefined) {
            await dispatch(clearCurrentOrderWithoutInitialized());
            if (formShippingAddress?.Street1) {
              localStorage.setItem(
                'selected_delivery_address',
                JSON.stringify(formShippingAddress)
              );
              if (componentContextData?.autoshipProductIdFromPDP) {
                localStorage.setItem('Is_Autoship_Method', 'true');
                setcomponentContextData({
                  ...componentContextData,
                  isAutoshipMethod: true,
                });
              } else {
                localStorage.removeItem('Is_Autoship_Method');
                setcomponentContextData({
                  ...componentContextData,
                  isAutoshipMethod: false,
                });
              }
              localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
            }
          } else {
            await dispatch(retrieveOrder());
            if (responsesStoreOrder?.LineItems && responsesStoreOrder?.LineItems?.length > 0) {
              if (componentContextData?.autoshipProductIdFromPDP) {
                localStorage.setItem('Is_Autoship_Method', 'true');
                setcomponentContextData({
                  ...componentContextData,
                  isAutoshipMethod: true,
                });
              } else {
                localStorage.removeItem('Is_Autoship_Method');
                setcomponentContextData({
                  ...componentContextData,
                  isAutoshipMethod: false,
                });
              }
              if (formShippingAddress?.Street1) {
                await dispatch(await saveShippingAddress(formShippingAddress));
              }
              localStorage.removeItem('selected_delivery_address');
              localStorage.removeItem('selected_fulfillment_Method');
            } else {
              localStorage.setItem(
                'selected_delivery_address',
                JSON.stringify(formShippingAddress)
              );
            }
            sendStoreUpdate(store?.storeid);
          }
          if (responsesStoreOrder?.Order && responsesStoreOrder?.Order?.ID) {
            await dispatch(patchOrder({ request }));
            showMainLoader && showMainLoader(false);
          } else {
            // localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
            showMainLoader && showMainLoader(false);
          }
          handleChangeStoreModalClose();
          // router?.push(redirectUrl);
        },
      });
    }
  };
  return (
    <>
      <LinkHelper
        className={selectStoreCTA()}
        onClick={async (e) => {
          e.preventDefault();
          setFindStorePopupOpen ? onSelectStoreClick() : onShopThisStoreClick();
        }}
        field={{
          value: {
            href: '/',
            text: ctaText,
          },
        }}
      />
    </>
  );
};

const MyStoreText = (props: {
  myStoreText: string;
  field: ImageField | undefined;
}): JSX.Element | null => {
  return (
    <div className={myStoreWrapper()}>
      <div className="storeImage">
        <NextImage field={props.field} />
      </div>
      <span className={myStoreTxt()}>{props.myStoreText}</span>
    </div>
  );
};

export const StoreDetailModal = ({
  store,
  showModal,
  handleModalClose,
  fields,
  myStoreData,
  getDictionaryValue,
}: StoreDetailsProps): JSX.Element | null => {
  if (!store) return null;

  const storeTimings = store?.xp_storehours?.find(
    (dayData) => dayData?.Day === new Date()?.getDay() + 1
  );
  const openingHours = store?.xp_storehours.map((hours) => {
    if (hours.Day) {
      return { ...hours, Day: hours?.Day === 1 ? 7 : hours?.Day - 1 };
    }
    return { ...hours };
  });
  // Get today's day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const today: number = DateTime.now().weekday;

  // Get tomorrow's day of the week
  const tomorrow: number = (today + 1) % 7;

  // Add missing days to the openingHours array if necessary
  for (let day = 1; day <= 7; day++) {
    // Check if the day is missing in the array
    if (!openingHours.some((entry) => entry.Day === day)) {
      // Add the missing day with "00:00:00" for open and close times
      openingHours.push({ CloseTime: '00:00:00', Day: day, OpenTime: '00:00:00' });
    }
  }
  // Sort the opening hours by day
  openingHours.sort((a, b) => {
    if (a.Day !== undefined && b.Day !== undefined) {
      if (a.Day === today) return -1; // Today comes first
      if (b.Day === today) return 1; // Today comes before any other day
      if (a.Day < today && b.Day > today) return 1; // Days before today come after today
      if (a.Day > today && b.Day < today) return -1; // Days after today come before today
      return a.Day - b.Day; // Sort other days normally
    }
    // Handle the case when either a.Day or b.Day is undefined
    // For example, you can return 0 to indicate no preference in sorting
    return 0;
  });
  // Format the opening hours
  const FormattedHours: JSX.Element[] = openingHours.map((hour) => {
    // Get the day of the week
    const day: string =
      hour.Day !== undefined
        ? DateTime.fromObject({ weekday: hour.Day as WeekdayNumbers }).toFormat('cccc')
        : '';
    console.log('hour.OpenTime', hour.OpenTime);
    if (hour.OpenTime === hour.CloseTime) {
      return (
        <div
          key={day}
          className={clsx(
            'flex justify-between items-start self-stretch',
            'text-heading-mob-large-regular',
            'font-heading-mob-large-regular',
            'leading-heading-mob-large-regular'
          )}
        >
          <div>
            {day}{' '}
            {hour.Day === today
              ? fields?.todayText?.value // (Today Text condition)
              : hour.Day === tomorrow
              ? fields?.tomorrowText?.value // (Tomorrow Text condition)
              : ''}
          </div>
          <div>{closedText}</div>
        </div>
      );
    }
    // Parse and format opening and closing times
    const openTime: string = DateTime.fromFormat(hour.OpenTime || '', 'HH:mm:ss').toFormat(
      'h:mm a'
    );
    const closeTime: string = DateTime.fromFormat(hour.CloseTime || '', 'HH:mm:ss').toFormat(
      'h:mm a'
    );

    return (
      <div
        key={day}
        className={clsx(
          'flex justify-between items-start self-stretch',
          'text-heading-mob-large-regular',
          'font-heading-mob-large-regular',
          'leading-heading-mob-large-regular'
        )}
      >
        <div>
          {day}{' '}
          {hour.Day === today
            ? fields?.todayText?.value // (Today Text condition)
            : hour.Day === tomorrow
            ? fields?.tomorrowText?.value // (Tomorrow Text condition)
            : ''}
        </div>
        <div className="min-w-[150px]">
          {openTime} - {closeTime}
        </div>
      </div>
    );
  });

  return (
    <>
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={showModal}
        customPopup={true}
        popupSpacing={popupSpacing()}
        popupWidth={'max-w-[1060px]'}
      >
        <div className={storeDetailsPopupContainer()}>
          <Text field={fields?.title} tag="h4" className={dialogueTitle()} />

          <div className={clsx(storeDetailsPopupRow())}>
            <div className={storeDetailsimage()}>
              {store?.xp_storeimage && (
                <NextImage
                  className="w-[100%]"
                  field={{
                    value: {
                      src: `${store?.xp_storeimage[0]}`,
                      alt: '',
                      width: '124',
                      height: '124',
                    },
                  }}
                />
              )}
            </div>
            <div className={storeDetailsPopupRight()}>
              <div className={storeDetailsContainer()}>
                <div className={storeDetail()}>
                  <div
                    className={clsx(
                      'text-color-text-dark',
                      'text-heading-desk-large-bold',
                      'font-heading-desk-large-bold',
                      'leading-heading-desk-large-bold'
                    )}
                  >
                    {store.firstname}
                  </div>
                  {storeTimings?.OpenTime && storeTimings?.CloseTime && (
                    <div className={openUnitilText()}>
                      {`${fields?.openTodayFromText?.value} ${
                        DateTime.fromFormat(storeTimings?.OpenTime, 'HH:mm:ss').toFormat('h a') ||
                        ''
                      } - ${
                        DateTime.fromFormat(storeTimings?.CloseTime, 'HH:mm:ss').toFormat('h a') ||
                        ''
                      }`}
                    </div>
                  )}
                </div>

                <div className={storeInventoryContainer()}>
                  <div className={inventoryText()}>
                    {store.QuantityAvailable === 0
                      ? getDictionaryValue('OutOfStock')
                      : getDictionaryValue('InStock')}
                  </div>
                  <div className="flex">
                    {myStoreData?.storeId === store?.storeid && (
                      <MyStoreText
                        myStoreText={getDictionaryValue('MyStore')}
                        field={fields?.storeImageIcon}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={clsx(storeDetailsPopupRight(), storeDetailsPopupRightPadding())}>
                <div className={storeDetailsAddress()}>
                  <p className={popupDetailsTitle()}>{fields?.addressText?.value}</p>
                  <div
                    className={clsx(
                      'text-heading-desk-small-regular',
                      'font-heading-desk-small-regular',
                      'leading-heading-desk-small-regular'
                    )}
                  >
                    <p>{store.street1},</p>
                    <p>
                      {store.city}, {store.state}
                    </p>
                  </div>
                  <p
                    className={clsx(
                      'text-heading-desk-medium-bold',
                      'font-heading-desk-medium-bold',
                      'leading-heading-desk-medium-bold'
                    )}
                  >
                    {fields?.callText?.value}
                    <LinkHelper
                      className={moreDetailsLink()}
                      field={{
                        value: {
                          href: `tel:${store.phone}`,
                          text: ` ${store.phone}`,
                        },
                      }}
                    />
                  </p>
                </div>

                <div className="storeHoursTitle">
                  <p className={popupDetailsTitle()}>{fields?.storeHoursText?.value}</p>
                </div>
                <div className="storeHoursDetails flex gap-[12px] flex-col self-stretch items-start">
                  {FormattedHours}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

const GenericStoreLocator = ({
  fields,
  coordinates,
  productId,
  setFindStorePopupOpen,
  ...props
}: GenericStoreLocatorProps) => {
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  // added km statically as circleRadius used other places as well
  const milestokm = convertMilesToKM(props?.isFromPDP ? '60' : myStoreData?.globalRadiusInMiles);
  const [renderStores, setRenderStores] = useState<StoreProps[]>([]);
  const [selectedStore, setSelectedStore] = useState<StoreProps | null>(null);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [noStoreError, setNoStoreError] = useState(false);
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const dispatch = useOcDispatch();
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const site = useSiteNameForApi();
  const token = useAuthToken();

  type InitialState = SearchResultsInitialState<'itemsPerPage' | 'sortType'>;
  const {
    queryResult: {
      isFetching,
      isLoading,
      data: { content: sdStoresList = [], errors: sdErrorList = [] } = {},
    },
    query,
  } = useSearchResults<StoreProps, InitialState>({
    state: {
      itemsPerPage: 20,
      sortType: 'near_by_distance_asc',
    },
  });
  const geoFilter = new FilterGeo('location', `${milestokm}km`);
  useEffect(() => {
    query.getRequest().resetSearchFilter().setSearchFilter(geoFilter);
  }, []);

  useEffect(() => {
    PageController.getContext().setGeo({
      location: { lat: coordinates?.lat, lon: coordinates?.lng },
    });
  }, [coordinates]);

  interface ApiResponseItem {
    StoreId: string; // Store ID
    DrivingDistance: number; // distance value (e.g., distance)
  }
  const sendToAPIM = async (storesList: StoreProps[]) => {
    const userLocation: { lat: number; lng: number } = {
      lat: coordinates?.lat,
      lng: coordinates?.lng,
    };

    const requestPayload = {
      stores: storesList.map((store: { id: string; location: string }) => ({
        Id: store.id,
        location: store.location,
      })),
      userLocation,
      searchLimit: Number(fields?.numberOfRecords?.value),
    };

    try {
      const options: ApiRequestOptions = {
        method: 'POST',
        data: requestPayload,
        headers: {
          Authorization: token,
          site: site,
        },
      };
      const response = await apiRequest<ApiResponseItem[]>(
        // 'https://run.mocky.io/v3/4fe33e0d-a549-4608-806f-15eb30fbea0c',
        storeApi?.getDeliveryStoresByAddressCoordinates,
        options
      );
      return filterStores(response, storesList);
    } catch (error) {
      console.error('Error fetching filtered stores:', error);
      return null;
    }
  };

  const filterStores = (apiResponse: ApiResponseItem[], storesList: StoreProps[]) => {
    // Extract the store IDs from the API response
    const storeKeys = apiResponse.map((item) => item.StoreId);
    // Filter the stores based on the keys from the API response
    const filtered = storesList.filter((store) => storeKeys.includes(store.id));
    return filtered;
  };

  const getSellers = async () => {
    try {
      let storeList: StoreProps[] = sdStoresList || [];
      // Get the first 6 nearest store IDs from sdStoreList
      // const productId = '3015441';
      if (props?.isFromPDP) {
        const apimData = await sendToAPIM(storeList);
        storeList = apimData || [];
      }
      if (storeList.length <= 0 && props?.isFromPDP) {
        props?.setShowHardError && props?.setShowHardError(false);
        props?.setShowNoDeliveryError && props?.setShowNoDeliveryError(true);
        props?.setRenderStoreWidget && props?.setRenderStoreWidget(false);
        props?.setLoading && props?.setLoading(false);
        setFindStorePopupOpen && setFindStorePopupOpen(false);
        return;
      }
      const storeIds = storeList
        .slice(0, Number(fields?.numberOfRecords?.value || 6))
        .map((store) => store.storeid)
        .join('|');

      // Fetch seller list and inventory records concurrently
      const [getListProductSellersResponse, getInventoryRecordsResponse] = await Promise.all([
        Me.ListProductSellers(productId, {
          filters: {
            ID: storeIds,
          },
          pageSize: 100,
        }),
        Me.ListProductInventoryRecords(productId, {
          filters: {
            OwnerId: storeIds,
          },
          pageSize: 100,
        }),
      ]);

      // Extract items from the responses
      const getListProductSellers = getListProductSellersResponse.Items;
      const getInventoryRecords = getInventoryRecordsResponse.Items;

      // Filter out nearest 6 stores from sdStoresList that do not have corresponding records in getListProductSellers
      const filteredStores = storeList
        .slice(0, Number(fields?.numberOfRecords?.value || 6))
        .filter((store) => getListProductSellers.some((seller) => seller.ID === store.storeid));

      // Match inventory records with corresponding stores and update setRenderStore state
      const updatedStores = filteredStores.map((store) => {
        const matchingInventoryRecord = getInventoryRecords.find(
          (record) => record.OwnerID === store.storeid
        );
        return {
          ...store,
          QuantityAvailable: matchingInventoryRecord
            ? matchingInventoryRecord.QuantityAvailable || 0
            : 0,
        };
      });
      // show NoStore message if there is no data filtered.
      if (updatedStores?.length <= 0) {
        if (props?.isFromPDP) {
          props?.setShowHardError && props?.setShowHardError(false);
          props?.setShowNoDeliveryError && props?.setShowNoDeliveryError(true);
          props?.setRenderStoreWidget && props?.setRenderStoreWidget(false);
          props?.setLoading && props?.setLoading(false);
          setFindStorePopupOpen && setFindStorePopupOpen(false);
          return;
        } else {
          setNoStoreError(true);
        }
      }
      // Update setRenderStore state with the updated store list
      setRenderStores(updatedStores);
      setFetchLoader(false);
      if (props?.isFromPDP && updatedStores?.length > 0) {
        if (props?.setLoading) props.setLoading(false);
        setFindStorePopupOpen && setFindStorePopupOpen(true);
      }
    } catch (error) {
      setFetchLoader(false);
      console.error('Error occurred:', error);
      setNoStoreError(true);
    }
  };

  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, showChangeStorePopupDetails: false });
    // Reset selectedStore state
    setSelectedStore(null);
  };

  useEffect(() => {
    setFetchLoader(true);

    if (!(isLoading || isFetching)) {
      if (
        !(sdStoresList?.length <= 0) &&
        (process.env.NEXT_PUBLIC_SHOW_STORE_LIST === 'true' || sdErrorList?.length <= 0) // TODO: We will have to remove env. variable once Sitecore fix page uri issue.
      ) {
        setNoStoreError(false);
        getSellers();
      } else {
        setNoStoreError(true);
        setFetchLoader(false);
      }
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (productId && !(isLoading || isFetching)) {
      if (sdStoresList?.length <= 0) {
        props?.setShowHardError && props?.setShowHardError(false);
        props?.setShowNoDeliveryError && props?.setShowNoDeliveryError(true);
        props?.setRenderStoreWidget && props?.setRenderStoreWidget(false);
        props?.setLoading && props?.setLoading(false);
        return;
      }
    }
  }, [isLoading, isFetching]);

  const getStockStatus = (store: StoreProps) => {
    if (store?.QuantityAvailable === 0) {
      return getDictionaryValue('OutOfStock');
    } else if (
      store?.QuantityAvailable &&
      myStoreData?.deliveryFee?.lowStock &&
      store?.QuantityAvailable < myStoreData?.deliveryFee?.lowStock
    ) {
      return getDictionaryValue('LowStock');
    } else {
      return getDictionaryValue('InStock');
    }
  };

  return (
    <>
      {!setFindStorePopupOpen && (fetchLoader || isLoading || isFetching) && (
        <div className={clsx(storeListRow(), 'items-center lg:flex-col border-b-0')}>
          <Loader />
        </div>
      )}
      {noStoreError && !(isLoading || isFetching) && (
        <NoStoreFound noStoreTxt={fields?.noStoreFoundMessage?.value} />
      )}
      {!(isLoading || isFetching) && renderStores?.length > 0 && (
        <div data-component="/src/components/ProductDetail/ChangeStorePopup/GetStoresWidget">
          {renderStores.map((store, index) => {
            const storeTimings = store?.xp_storehours?.find(
              (dayData) => dayData?.Day === new Date()?.getDay() + 1
            );
            return (
              <div data-storeId={store.id} key={index} className={storeListRow()}>
                <div className={clsx('storeimage', 'lg:w-3/12', 'flex')}>
                  {store?.xp_storeimage && (
                    <NextImage
                      className="max-h-[124px] w-auto"
                      field={{
                        value: {
                          src: `${store?.xp_storeimage[0]}`,
                          alt:
                            myStoreData?.storeId === store?.storeid
                              ? myStoreData?.storeName
                              : store?.state,
                          width: '124',
                          height: '124',
                        },
                      }}
                    />
                  )}
                </div>
                <div className={storeDetailsContainer()}>
                  <div className={storeDetail()}>
                    <div className={storeName()}>{store?.firstname}</div>
                    <div className={openUnitilText()}>
                      {`${fields?.openTodayFromText?.value} ${
                        storeTimings?.OpenTime
                          ? DateTime.fromFormat(storeTimings.OpenTime, 'HH:mm:ss').toFormat('h a')
                          : ''
                      } - ${
                        storeTimings?.CloseTime
                          ? DateTime.fromFormat(storeTimings.CloseTime, 'HH:mm:ss').toFormat('h a')
                          : ''
                      }`}
                    </div>
                    <div className="moreDetails">
                      <LinkHelper
                        className={moreDetailsLink()}
                        onClick={async (e) => {
                          e.preventDefault();
                          setSelectedStore(store);
                          setcomponentContextData({
                            ...componentContextData,
                            showChangeStorePopupDetails: true,
                          });
                        }}
                        field={{
                          value: {
                            href: '/',
                            text: fields?.moreStoreDetailsText?.value,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className={storeInventoryContainer()}>
                    <div className={inventoryText()}>{getStockStatus(store)}</div>
                    <div
                      className={
                        myStoreData?.storeId === store?.storeid && setFindStorePopupOpen
                          ? 'contents'
                          : 'flex'
                      }
                    >
                      {myStoreData?.storeId === store?.storeid ? (
                        <>
                          <MyStoreText
                            myStoreText={getDictionaryValue('MyStore')}
                            field={fields?.storeImageIcon}
                          />
                          {setFindStorePopupOpen && (
                            <ShopThisStoreCTA
                              showMainLoader={props?.showMainLoader}
                              formShippingAddress={props?.formShippingAddress}
                              currentOrder={currentOrder}
                              myStoreID={myStoreData?.storeId}
                              handleModalClose={props?.handleModalClose}
                              ctaText={getDictionaryValue('SelectStore')}
                              setcomponentContextData={setcomponentContextData}
                              componentContextData={componentContextData}
                              store={store}
                              dispatch={dispatch}
                              setFindStorePopupOpen={setFindStorePopupOpen}
                            />
                          )}
                        </>
                      ) : (
                        <ShopThisStoreCTA
                          myStoreID={myStoreData?.storeId}
                          formShippingAddress={props?.formShippingAddress}
                          showMainLoader={props?.showMainLoader}
                          currentOrder={currentOrder}
                          handleModalClose={props?.handleModalClose}
                          ctaText={
                            setFindStorePopupOpen
                              ? getDictionaryValue('SelectStore')
                              : getDictionaryValue('ShopThisStore')
                          }
                          setcomponentContextData={setcomponentContextData}
                          componentContextData={componentContextData}
                          store={store}
                          dispatch={dispatch}
                          setFindStorePopupOpen={setFindStorePopupOpen}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* Render modal */}
          <StoreDetailModal
            store={selectedStore}
            showModal={componentContextData?.showChangeStorePopupDetails}
            handleModalClose={handleModalClose}
            fields={fields}
            myStoreData={myStoreData}
            getDictionaryValue={getDictionaryValue}
          />
        </div>
      )}
    </>
  );
};
const GetStoresWidget = widget(GenericStoreLocator, WidgetDataType.SEARCH_RESULTS, 'store');

export default GetStoresWidget;
