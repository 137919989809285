import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { PSP } from 'models/PetSuppliesPlus.Model';
import React from 'react';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import FrequentlyBroughtProductWidget from 'src/helpers/FrequentlyBrought';
import { RerenderOnRouteChange } from 'src/helpers/utility/RerenderOnRouteChange';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

export type FrequentlyBroughtProductProps =
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.ProductRecommendation.Fields.FrequentlyBroughtTogetherData;

const FrequentlyBrought: React.FC<FrequentlyBroughtProductProps> = ({ fields }) => {
  const isEE = useExperienceEditor();
  if (isEE) {
    return (
      <div className="w-full flex flex-col gap-4 m-8">
        <div>
          {' '}
          Heading: <RichTextHelper field={fields?.heading} />
        </div>
        <div>
          Min Record: <Text field={fields?.minRecords} />
        </div>
        <div>
          Add to cart text: <Text field={fields?.addtoCartText} />
        </div>
        <div>
          Add all to cart text: <Text field={fields?.addtoCartAllText} />
        </div>
        <div>
          Total price text: <Text field={fields?.totalPrice} />
        </div>
        <div>
          RFKID: <TextHelper field={fields?.rfkID} />
        </div>
      </div>
    );
  }

  // if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  if (!fields?.rfkID?.value && (fields?.rfkID?.value?.length as number) <= 0) return <></>;
  if (!fields?.showComponent?.value) return <></>;

  return (
    <RerenderOnRouteChange>
      <FrequentlyBroughtProductWidget
        rfkId={fields?.rfkID?.value || 'rfk_recommendation_TopOrdered'}
        fields={fields}
      />
    </RerenderOnRouteChange>
  );
};

export default FrequentlyBrought;

// export const Default = (props: ProductRecommendationProps): JSX.Element => {
//   return <ProductRecommendation {...props} />;
// });
