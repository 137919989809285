import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { ProductSearchResultModel } from '../types';
import searchResultsTailwind from '../SearchResultsTailwind';
import { BuyerProductWithXp } from 'src/redux/xp';
// import { PDPCarouselVariant } from 'components/ProductDetailSliderComponent/ProductDetailSliderComponent';
// const { flagIcon } = PDPCarouselVariant({
//   size: { initial: 'mobile', lg: 'desktop' },
// });

export function ProductMadeInUsa({
  product,
}: {
  product: ProductSearchResultModel | BuyerProductWithXp;
}) {
  const { flagIcon } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  if (
    product?.xp?.Features?.includes('Made in USA') ??
    (product as ProductSearchResultModel)?.xp_features?.includes('Made in USA')
  ) {
    // return <div>[US Flag]</div>;
    return (
      <NextImage
        field={{
          value: {
            src: '/images/badge-flag-1.png',
            alt: 'US Made',
            width: '27',
            height: '18',
          },
        }}
        className={flagIcon({ className: '!cursor-normal' })}
        onClick={(e) => {
          e?.preventDefault();
          e.stopPropagation();
        }}
      />
    );
  } else {
    return <div></div>;
  }
  return null;
}
