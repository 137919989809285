import { tv } from 'tailwind-variants';

export const productDetailImageCarouselTailwindVariant = tv(
  {
    slots: {
      base: 'flex justify-center',
      usImage: 'h-[29px] w-[40px] mb-2',
      imageCarouselWrapper:
        'w-full overflow-hidden relative lg-for-pdp:w-[368px] xl-for-pdp:w-[428px]',
      splideSlide: 'flex !w-full justify-center align-middle rounded-sm',
      mainSlideWrapper: 'h-auto w-auto mx-auto mt-[40px]',
      thumbnailImage: 'h-auto w-auto mx-auto',
      thumbnailWrapper: 'flex justify-center items-center mt-[40px]',
      thumbnailSlideWrapper: 'flex justify-center align-middle rounded',
      tagWrapper: 'absolute top-0 left-0 z-[1]',
      mainVideoPlayer: 'object-cover relative overflow-hidden w-full h-full mt-[40px]',
      thumbnailContainer: 'w-[368px] [&_.is-active.is-visible]:!border-color-brand-primary-1-base',
      thumbnailVideo: 'w-auto object-cover h-full',
      thumbnailVideoWrapper: 'object-cover relative overflow-hidden w-full h-full',
      heartIcon:
        'absolute top-0 right-0 z-[1] h-[29px] w-[40px] cursor-pointer inline-flex [&>svg>*>*]:w-[40px] [&>svg>*>*]:h-[40px] [&_svg]:w-[40px] [&_svg]:h-[40px] [&_svg]:min-w-[40px] [&_svg]:min-h-[40px] [&>svg>*>*]:fill-color-brand-primary-1-base',
      saleTag:
        'bg-system-red text-color-text-white text-body-small-bold font-body-small-bold leading-body-small-bold',
      prevPDPArrow:
        'nirmal splide__arrow--prev !bg-[transparent] [&_svg]:!h-[42px] [&_svg]:!w-[19.5px] disabled:hidden [&_svg]:!h-16',
      nextPDPArrow:
        'patel splide__arrow--next !right-0 !bg-[transparent] [&_svg]:!h-[42px] [&_svg]:!w-[19.5px] disabled:hidden [&_svg]:!h-16',
    },
    compoundSlots: [
      {
        slots: ['prevPDPArrow', 'nextPDPArrow'],
        class: [
          '[&_svg]:!fill-color-background-brand-1',
          '[&_svg]:!bg-background-transparent',
          '[&_svg]:!border-color-background-white',
        ],
      },
    ],
    variants: {
      size: {
        mobile: {
          mainSlideWrapper: 'max-h-[415px]',
          saleTag: 'px-mob-global-spacing-spacing-2 py-mob-global-spacing-spacing-1',
        },
        desktop: {
          imageCarouselWrapper: 'w-[428px]',
          mainSlideWrapper: 'max-h-[428px]',
          saleTag: 'px-desk-global-spacing-spacing-2 py-desk-global-spacing-spacing-1',
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
