import { tv } from 'tailwind-variants';

export const miniCartTailwindVariant = tv({
  slots: {
    miniCartWrapper: ['minicart-wrapper', 'md:min-h-[396px]'],
    miniCartTitle: [
      'minicart-title',
      'text-heading-desk-xLarge-bold',
      'font-heading-desk-xLarge-bold',
      'text-color-text-dark',
      'leading-heading-desk-xLarge-bold',
      'my-[12px]',
    ],
    miniCartItemsInCart: [
      'text-body-large-regular',
      'font-body-large-regular',
      'leading-body-large-regular',
      'text-color-text-dark',
      'my-[12px]',
    ],
    merchandiseTotal: [
      'merchandise-total',
      'font-body-large-bold',
      'text-body-large-bold',
      'leading-body-large-bold',
      'text-color-text-dark',
      'mb-desk-margin-tight-bottom',
      'my-[12px]',
    ],
    miniCartLineItemDescription: [
      'font-body-large-regular',
      'text-body-large-regular',
      'leading-body-large-regular',
      'text-color-text-dark',
      'mb-desk-margin-base-bottom',
    ],
    miniCartItemImage: ['min-w-[69px]', 'min-h-[88px]', 'max-h-[88px]', 'object-contain'],
    buttonStyles: [
      'text-heading-desk-large-bold',
      'font-heading-desk-large-bold',
      'leading-heading-desk-large-bold',
      'p-4',
      'w-full',
      'hover:no-underline',
      'flex',
      'justify-center',
      'items-center',
      'text-center',
      'duration-300',
      'rounded',
      'mb-desk-margin-base-bottom',
      'my-[12px]',
    ],
    buttonPrimaryStyle: [
      'bg-color-brand-primary-1-base',
      'hover:bg-color-brand-primary-1-dark',
      'border-color-brand-primary-1-base',
      'text-white',
    ],
    miniCartLineItemsWrapper: [
      'minicart-lineitems-wrapper',
      'h-[175px]',
      'overflow-y-scroll',
      'mb-[12px]',
    ],
    miniCartLineItem: [
      'flex',
      'flex-row',
      'gap-desk-margin-tight-right',
      'mb-[12px]',
      'pb-[12px]',
      'border-b-2',
      'border-color-border-light',
      'last:border-0',
    ],
    miniCartLoaderWrapper: ['minicart-loader', 'flex', 'flex-row', 'items-start', 'my-[12px]'],
    miniCartLoaderText: [
      'text-heading-desk-large-bold',
      'font-heading-desk-large-bold',
      'text-color-text-dark',
      'leading-heading-desk-large-bold',
      'pl-[10px]',
    ],
    miniCartQuantityWrapper: [
      'quantity-wrapper',
      'qnt-box',
      'border',
      'border-1',
      'border-color-border-mid',
      'rounded-radius-base',
      'py-[17px]',
      'inline-block',
      'flex',
      'flex-row',
      'w-[40%]',
      'items-center',
    ],
    miniCartBottomWrapper: [
      'minicart-bottom-wrapper',
      'border-t-2',
      'border-color-border-light',
      'rounded-radius-button',
      'mt-margin-tight-right',
      'flex',
      'justify-center',
    ],
    miniCartBottomWrapperLink: [
      'pt-padding-micro-x',
      'w-fit group no-underline flex items-center hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base gap-mob-space-between-micro-horizontal lg:gap-desk-space-between-micro-horizontal text-color-text-brand-1',
      'pt-desk-padding-micro-x',
      'font-body-large-bold',
      'leading-body-large-bold',
      'text-body-large-bold',
    ],

    viewMoreIcon: [
      '[&_svg_*]:fill-color-text-brand-1 group-hover:[&_svg_*]:fill-color-brand-tertiary-3-base group-focus:[&_svg_*]:fill-color-brand-tertiary-3-base [&_svg]:w-4 [&_svg]:h-4',
    ],

    quantityBox: [
      'text-lg',
      'border-none',
      'flex',
      'flex-row',
      'border-[2px]',
      'pl-[10px]',
      'w-[20%]',
      'focus-visible:none',
    ],
    flexEnd: ['justify-end'],
    utlitiCloseIcon: ['top-4 right-4 w-6'],
    closeIcon: ['cursor-pointer [&>svg>*>*]:fill-color-scale-8-dark'],
    productQtyWrapper:
      'relative w-[130px] border-[1px] border-color-border-mid overflow-hidden rounded-[4px]',
    productQtyWrapperOuter: [
      'flex',
      'flex-row',
      'justify-between',
      'mb-desk-margin-tight-bottom',
      'lg:gap-[15px]',
      'gap-[16px]',
    ],
    productQtyWrapperLeftInner: ['flex', 'items-center', 'gap-1'],
    productStrikedRate: ['line-through'],
    productListRate: [
      'font-heading-mob-large-bold',
      'text-heading-mob-large-bold',
      'leading-heading-mob-large-bold',
      'text-color-text-dark',
    ],
    productFinalRate: [
      'font-heading-mob-large-bold',
      'text-heading-mob-large-bold',
      'leading-heading-mob-large-bold',
      'text-color-accent-red',
    ],
    productPriceWrapper: ['productPrices flex flex-col gap-[8px]'],
    prodcutQtyWrapperRightInner: ['product-qty-right-inner', 'pr-[25px]', 'items-center flex'],
    removeLineItems: [''],
    removeLineItemText: [
      'font-body-large-regular_underline',
      'text-body-large-regular_underline',
      'leading-body-large-regular_underline',
      'text-color-text-dark',
      'underline',
      'cursor-pointer',
    ],
    errorMessage:
      'w-full text-body-large-bold font-primary font-body-large-bold leading-body-large-bold text-color-accent-red',
    lineItemLoader: ['absolute', 'left-[5px]'],
    productQtyWrapperQtyLabel: [
      'pl-[8px] text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
    ],
    productQtyInput:
      'w-10 focus:outline-none font-primary text-color-text-black placeholder:text-color-text-black h-[62px] text-heading-desk-large-regular font-heading-desk-large-regular leading-heading-desk-large-regular ',
    productQtyButtonWrapper:
      'absolute bottom-[1px] w-[31px] h-[60px] bg-color-background-light right-[0px] flex flex-col',
    productQtyButton: 'w-full h-1/2 text-[17px] font-[700]',
    availabilityWrapper:
      'flex font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
  },
  variants: {
    isDisabled: {
      true: {
        miniCartBottomWrapperLink: ['cursor-not-allowed'],
        buttonPrimaryStyle: [
          'cursor-not-allowed disabled:bg-color-brand-primary-1-disabled hover:disabled:bg-color-brand-primary-1-disabled',
        ],
      },
      false: {
        miniCartBottomWrapperLink: ['pointer'],
        buttonPrimaryStyle: ['pointer'],
      },
    },
  },
});
