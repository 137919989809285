//global
import clsx from 'clsx';
import {
  ComponentRendering,
  Field,
  Link as JssLink,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { navigationTailwindVariant } from 'tailwindVariants/components/navigationTailwindVariant';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
//lib
import { Result } from 'lib/types/header';
import BackText from 'src/helpers/HeaderR2/BackText';
import React from 'react';

export type isOpen = {
  [key: string]: boolean;
};

type MobilesubNavProps = {
  subNav: Result;
  height?: number;
  isOpen: isOpen;
  backText?: Field<string>;
  toggleItem: (itemName: string | null) => void;
  setIsHamburger: (val: boolean) => void;
  isShop?: boolean;
  firstLevel?: boolean;
  parentIndex?: number;
  rendering?: ComponentRendering;
};
export default function MobileSubNav({
  setIsHamburger,
  subNav,
  isOpen,
  toggleItem,
  backText,
  height,
  parentIndex,
  rendering,
}: MobilesubNavProps): JSX.Element {
  const {
    mobileNavOpen,
    flexGrid,
    ulWrapper,
    iconWrapper,
    navigationLink,
    fillBlack,
    iconXSmall,
    linkWrapper,
    justifyBetween,
  } = navigationTailwindVariant();

  return (
    <React.Fragment>
      {subNav?.childrens &&
        subNav?.childrens?.results?.map((sub, key) => {
          const isHide = sub?.hideInMobile?.jsonValue?.value;
          const hasChildren = sub?.childrens?.results && sub?.childrens?.results?.length > 0;
          return !isHide &&
            sub?.link?.jsonValue?.value?.href &&
            sub?.link?.jsonValue?.value?.href?.length > 0 ? (
            <ul key={sub?.link?.jsonValue?.value?.text} className={clsx(ulWrapper())}>
              {hasChildren ? (
                <div
                  className={flexGrid()}
                  onClick={() =>
                    hasChildren ? toggleItem(sub?.link?.jsonValue?.value?.text as string) : ''
                  }
                >
                  {sub?.childrens?.results && sub?.link?.jsonValue?.value?.text && (
                    <button aria-label={'sub menu link' + key} className={navigationLink()}>
                      {sub?.link?.jsonValue?.value?.text}
                    </button>
                  )}

                  {hasChildren && (
                    <IconHelper
                      icon={'chevron-right'}
                      className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
                      onClick={() => toggleItem(sub?.link?.jsonValue?.value?.text as string)}
                    />
                  )}
                </div>
              ) : (
                sub?.link?.jsonValue?.value?.href &&
                sub?.link?.jsonValue?.value?.href?.length > 0 && (
                  <JssLink
                    editable={false}
                    field={sub?.link?.jsonValue}
                    onClick={() => {
                      toggleItem(''), setIsHamburger(false);
                    }}
                    className={clsx(justifyBetween(), linkWrapper(), flexGrid())}
                  />
                )
              )}

              <li
                style={{ height: `calc(100vh - ${height}px)` }}
                className={clsx(
                  mobileNavOpen({ className: 'pb-[50px]' }),
                  `${isOpen[sub?.link?.jsonValue?.value?.text || ''] ? '!translate-x-0 ' : ''}  `
                )}
              >
                {sub?.childrens?.results && (
                  <BackText
                    backText={backText as Field<string>}
                    setIsHamburger={() => setIsHamburger(false)}
                    toggleItem={() => toggleItem(sub?.link?.jsonValue?.value?.text as string)}
                    link={sub?.link}
                    shopAll={sub?.shopall}
                  />
                )}

                {/**Last Children */}
                {sub?.childrens?.results?.map((last) => {
                  const href =
                    last?.link?.jsonValue?.value?.href || last?.mobileLink?.jsonValue?.value?.href;

                  const linkField = last?.mobileLink?.jsonValue?.value?.href
                    ? last?.mobileLink?.jsonValue
                    : last?.link?.jsonValue;
                  if (href && href.length > 0 && linkField) {
                    return (
                      <JssLink
                        key={last?.mobileLink?.jsonValue?.value?.text}
                        editable={false}
                        field={linkField}
                        onClick={() => {
                          toggleItem(''), setIsHamburger(false);
                        }}
                        className={clsx(
                          ulWrapper(),
                          linkWrapper(),
                          `${isOpen[subNav?.name || ''] ? '[&>*div]:!translate-x-0' : ''}`
                        )}
                      />
                    );
                  }
                  return null;
                })}
              </li>
            </ul>
          ) : null;
        })}
      {rendering && (
        <div className="popular-categories-wrapper">
          <Placeholder name={`popular-categories-${parentIndex}`} rendering={rendering} />
        </div>
      )}
    </React.Fragment>
  );
}
