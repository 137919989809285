import { ProductLink } from './ProductLink';
import { PspProductRootProps } from '../PspProductRoot';
import useDictionary from 'src/hooks/useDictionary';
import searchResultsTailwind from '../SearchResultsTailwind';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import { ProductSearchResultModelWithVariants } from '../types';

export function ProductMoreOptions({
  product,
  index,
  actions,
  className,
  linkClassName,
}: PspProductRootProps) {
  const dictionary = useDictionary();

  const { optionAvailable } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    outlineCta: true,
  });
  // getting current page from GTM local Storage
  const { currentPage, pageItem } = getGTMSessionStorage();

  if (product.showVariants && product.variants && product.variants.length > 1) {
    return (
      <div
        className={`more_options w-full flex text-center h-[44px] specific-range-cta-h ${className}`}
        onClick={() => {
          window && window?.localStorage?.setItem(GTMLabels?.position, index?.toString());
          sendProductsPromotion({
            eventName: GTM_EVENT?.selectItem,
            data: product as ProductSearchResultModelWithVariants,
            currentPage: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
            pageItem: pageItem,
            position: index,
            isRatingShow: true,
          });
        }}
      >
        <ProductLink
          {...{ actions, product, index }}
          styles={optionAvailable({ className: 'h-fit' })}
          linkClassName={linkClassName}
        >
          <span className="group-active:text-color-brand-primary-1-base">
            {dictionary.getDictionaryValue('MoreOptionsAvailablePLP', 'More Options Available')}
          </span>
        </ProductLink>
      </div>
    );
  } else {
    return (
      <div className={`more_options_blank min-h-[40px] specific-range-cta-h ${className}`}></div>
    );
  }
}
