const headerQuery = `
query HeaderQuery($datasource: String!, $language: String!) {
  datasource: item(path: $datasource, language: $language) {
    ... on Header {
      title {
        value
      }
      desktopLogo {
        jsonValue
      }
      logoLink {
        jsonValue
      }
      moreLogo {
        jsonValue
      }
      moreText {
        value
      }
      locationLogo {
        jsonValue
      }
      locationText {
        value
      }
      closeText {
        value
      }
      backText {
        value
      }
      promotionId {
        value
      }
      componentName {
        value
      }
      promotionName {
        value
      }
      promotionCopy {
        value
      }
      promotionDateFROM {
        value
      }
      promotionDateTO {
        value
      }
      cTAButtonCopy {
        value
      }
      promotionURL {
        jsonValue
      }
      creativeSlotNumber {
        value
      }
      desktopPrimaryNav {
        ...PrimaryNav
      }
      desktopLeftUtilityNav {
        ...DesktopUtilityNav
      }
      desktopRightUtilityNav {
        ...DesktopUtilityNav
      }
    }
  }
}

fragment PrimaryNav on MultilistField {
  targetItems {
    name
    rfkID: field(name: "rfkID") {
      value
    }
    link: field(name: "link") {
      jsonValue
    }
    isShop: field(name: "isShop") {
      jsonValue
    }
    isBrand: field(name: "isBrand") {
      jsonValue
    }
    childrens: children(first: 30) {
      total
      results {
        name
        hideTitle: field(name: "hideTitle") {
          jsonValue
        }
        shopAll: field(name: "shopAllLink") {
          jsonValue
        }
        link: field(name: "link") {
          jsonValue
        }
        childrens: children(first: 30) {
          total
          results {
            name
            hideInMobile: field(name: "hideInMobile") {
              jsonValue
            }
            iconTitle: field(name: "iconTitle") {
              value
            }
            icon: field(name: "icon") {
              jsonValue
            }
            link: field(name: "link") {
              jsonValue
            }
            shopAll: field(name: "shopAllLink") {
              jsonValue
            }
            brandImage: field(name: "brandImage") {
              jsonValue
            }
            childrens: children(first: 30) {
              total
              results {
                link: field(name: "link") {
                  jsonValue
                }
              shopAll: field(name: "shopAllLink") {
                jsonValue
              }
                childrens: children(first: 30) {
                  total
                  results {
                    name
                    hideInMobile: field(name: "hideInMobile") {
                      jsonValue
                    }
                    iconTitle: field(name: "iconTitle") {
                      value
                    }
                    icon: field(name: "icon") {
                      jsonValue
                    }
                    link: field(name: "link") {
                      jsonValue
                    }
                    shopAll: field(name: "shopAllLink") {
                      jsonValue
                    }
                    childrens: children(first: 30) {
                      total
                      results {
                        link: field(name: "link") {
                          jsonValue
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
fragment DesktopUtilityNav on MultilistField {
  targetItems {
    name
    rfkID: field(name: "rfkID") {
      value
    }
    desktopImage: field(name: "desktopImage") {
      jsonValue
    }
    desktopLink: field(name: "link") {
      jsonValue
    }
    desktopText: field(name: "desktopText") {
      value
    }
    isStoreMenu: field(name: "isStoreMenu") {
      jsonValue
    }
    storeFacility: field(name: "storeFacility") {
      value
    }
    storeLocation: field(name: "storeLocation") {
      value
    }
    childrens: children(first: 30) {
      total
      results {
        link: field(name: "link") {
          jsonValue
        }
      }
    }
  }
}

`;

export default headerQuery;
