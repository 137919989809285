import React from 'react';

const PurchaseOrderSkeleton = () => {
  return (
    <div className="border border-gray-200 rounded-lg mb-6">
      {/* Header Section */}
      <div className="bg-gray-50 p-4 rounded-t-lg">
        <div className="flex justify-between pb-4 border-b border-gray-200">
          <div className="h-6 w-32 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
        </div>

        <div className="flex justify-between p-4">
          <div className="space-y-4">
            <div className="h-6 w-48 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-6 w-56 bg-gray-200 rounded animate-pulse"></div>
            <div className="space-y-2">
              <div className="h-6 w-44 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-6 w-36 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-6 w-32 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>

          <div className="hidden lg:block space-y-4">
            <div className="h-6 w-36 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-6 w-32 bg-gray-200 rounded animate-pulse"></div>
            <div className="flex gap-4">
              <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Items Section */}
      <div className="p-4">
        <div className="text-right pb-4">
          <div className="h-6 w-24 bg-gray-200 rounded animate-pulse ml-auto"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {[1, 2, 3, 4].map((item) => (
            <div
              key={item}
              className="border border-gray-200 p-4 rounded-lg flex flex-row lg:flex-col gap-3 lg:h-80"
            >
              <div className="w-32 h-32 bg-gray-200 rounded animate-pulse"></div>
              <div className="flex-1 space-y-4">
                <div className="h-6 w-full bg-gray-200 rounded animate-pulse"></div>
                <div className="flex justify-between items-center">
                  <div className="h-6 w-20 bg-gray-200 rounded animate-pulse"></div>
                </div>
                <div className="hidden lg:block h-10 w-full bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          ))}
        </div>

        {/* Mobile Buttons */}
        <div className="flex flex-col gap-2 mt-6 lg:hidden">
          <div className="h-12 w-full bg-gray-200 rounded animate-pulse"></div>
          <div className="h-12 w-full bg-gray-200 rounded animate-pulse"></div>
          <div className="h-12 w-full bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderSkeleton;
