import React from 'react';

const PurchaseDetailsSkeleton = () => {
  return (
    <div className="purchaseDetails container bg-gray-50 rounded-lg flex py-5 my-5">
      {/* Main Content */}
      <div className="flex flex-col flex-1">
        {/* Order Details Section */}
        <div className="lg:p-8 p-4">
          <div className="flex flex-col px-2">
            {/* Header */}
            <div className="flex justify-between pb-4 border-b border-gray-200 mb-4">
              <div className="h-7 w-32 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-7 w-40 bg-gray-200 rounded animate-pulse"></div>
            </div>

            {/* Details Grid */}
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-6">
              {/* Order Info */}
              <div className="flex flex-col gap-4 pt-2">
                <div className="h-6 w-48 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-6 w-36 bg-gray-200 rounded animate-pulse"></div>
              </div>

              {/* Store Location */}
              <div className="flex flex-col gap-4">
                <div className="h-6 w-52 bg-gray-200 rounded animate-pulse"></div>
                <div className="space-y-2">
                  <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
                  <div className="h-6 w-48 bg-gray-200 rounded animate-pulse"></div>
                  <div className="h-6 w-32 bg-gray-200 rounded animate-pulse"></div>
                </div>
              </div>

              {/* Action Button */}
              <div className="flex flex-col gap-2 pt-2">
                <div className="h-10 w-full bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Line Items Section */}
        <div className="lg:px-4 max-w-[906px]">
          <div className="bg-white lg:pt-0 lg:px-4 pt-2 rounded">
            {/* Items Count */}
            <div className="flex justify-end py-3 px-4 border-b border-gray-200">
              <div className="h-6 w-20 bg-gray-200 rounded animate-pulse"></div>
            </div>

            {/* Item Card */}
            <div className="border-b border-gray-200 flex  flex-col lg:flex-row p-4 gap-4">
              {/* Image Section */}
              <div className="lg:w-[218px] w-full flex justify-center items-center py-7">
                <div className="h-[150px] w-[100px] bg-gray-200 rounded animate-pulse"></div>
              </div>

              {/* Product Details */}
              <div className="flex flex-col flex-1 gap-4 lg:py-7">
                <div className="h-6 w-3/4 bg-gray-200 rounded animate-pulse"></div>
                <div className="flex justify-between mt-4">
                  <div className="h-6 w-24 bg-gray-200 rounded animate-pulse"></div>
                  <div className="h-6 w-24 bg-gray-200 rounded animate-pulse"></div>
                </div>
                <div className="flex items-end justify-between mt-4">
                  <div className="h-6 w-20 bg-gray-200 rounded animate-pulse"></div>
                  <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Order Summary Sidebar */}
      <div className="relative w-[342px] hidden lg:block">
        <div className="bg-white p-4 rounded-lg sticky top-0">
          <div className="h-7 w-40 bg-gray-200 rounded animate-pulse mb-4"></div>
          {/* Summary Items */}
          {[1, 2, 3, 4].map((item) => (
            <div key={item} className="py-2 border-b border-gray-200 last:border-0">
              <div className="flex justify-between mb-2">
                <div className="h-5 w-24 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div className="flex justify-between">
                <div className="h-5 w-32 bg-gray-200 rounded animate-pulse"></div>
                <div className="h-5 w-16 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PurchaseDetailsSkeleton;
