/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { useEffect, useRef, useState } from 'react';
import {
  LinkField,
  Placeholder,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide, SplideSlide } from '@splidejs/react-splide';

//helpers
// import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

//lib
import { ComponentProps } from 'lib/component-props';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { useOcSelector } from 'src/redux/ocStore';
import { PSP } from 'models/PetSuppliesPlus.Model';

//tailwindVariants
import { servicePanelVariants } from 'tailwindVariants/components/servicePanelTailwindVariants';
import { EventInfoGql } from 'src/pages/api/store/get-store-details';
import { useBreakpoints } from 'src/utils/breakpoints';
import { Items, trackObjectForPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import clsx from 'clsx';

//type
export type ServicePanelProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.ServicePanel.Fields.ServicePanel;

//main component
const ServicePanel: React.FC<ServicePanelProps> = (props) => {
  const isEE = useExperienceEditor();
  const { base, eventWrapper } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  //if no data then returned empty fragment
  if (props?.fields === undefined || props?.fields === null) return <></>;

  return (
    <React.Fragment>
      <ServiceCarousel {...props} />
      {!isEE ? (
        <Events {...props} />
      ) : (
        <section className={base()} component-data="components/ServicePanel/ServicePanel">
          <div className={eventWrapper()}>
            <MarketingTile
              marketingTilesToShow={3}
              fields={props.fields}
              rendering={props?.rendering}
              params={props?.params}
            />
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

//ServicePanel Carousel
const ServiceCarousel: React.FC<ServicePanelProps> = ({ fields }) => {
  const {
    base,
    panelWrapper,
    carouselWrapper,
    panelDetails,
    panelHeading,
    panelDescription,
    panelContent,
    singleSlide,
  } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const singleSliderRef = useRef<Splide | null>(null);
  const secondarySliderRef = useRef<Splide | null>(null);
  const { isMobile, isDesktop, screenWidth } = useBreakpoints();

  //current store data
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const otherSettingsData = useOcSelector((state) => state?.otherSettingSlice?.data);
  const storeServices = useGraphQlDataContext();
  const currentStoreServices = storeData?.services;
  const servicesToExclude = storeData?.servicesToExculdeFromServicePanel;
  const servicesToExcludeArray = servicesToExclude ? servicesToExclude?.split('|') : [];
  const currentStoreServicesArray = currentStoreServices ? currentStoreServices.split('|') : [];

  const selectedService = currentStoreServicesArray
    ?.filter((x) => !servicesToExcludeArray.includes(x))
    ?.map(
      (storeServiceId) =>
        storeServices &&
        storeServices?.storeServices?.find(
          (service) => service?.serviceId?.value?.toString() === storeServiceId
        )
    );

  //getting role field to filter employees from current store based on globalEmployeeRoles
  const roleTitlesToFilter = otherSettingsData?.storeLeadRoles?.jsonValue.map(
    (role) => role?.fields?.title?.value
  );

  // Filter employees based on role titles
  const filteredEmployees = storeData?.storeEmployees?.filter((employee) => {
    const employeeShouldVisible = employee?.showInServicePanel?.jsonValue?.value;
    return employeeShouldVisible;
  });

  const storeDetailURL =
    `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
      ' ',
      '-'
    );

  const replaceStoreUrl = (linkData: LinkField | undefined) => {
    if (linkData && linkData.value.href && linkData.value.href.includes('{store-detail-url}')) {
      return {
        ...linkData,
        value: {
          ...linkData.value,
          href: linkData.value.href
            .replace('{store-detail-url}', storeDetailURL)
            .replace(/^https?:\/\//, ''),
        },
      };
    }

    return linkData;
  };

  const defaultEmployeesData = [
    {
      title: otherSettingsData?.defaultEmpTitle,
      subHeading: otherSettingsData?.storeLeadRoles?.jsonValue?.[0]?.fields?.title,
      description: otherSettingsData?.defaultEmpBio,
      image: otherSettingsData?.defaultStoreManagerImage,
      cta: replaceStoreUrl(otherSettingsData?.defaultEmpLink?.jsonValue),
    },
  ];

  //helper functions to transform data for carousel Items.
  const transformData = (data: typeof selectedService | typeof filteredEmployees) => {
    return data?.map((item) => {
      if (!item) return null;
      return {
        title: 'name' in item ? item?.name : 'employeeName' in item ? item.employeeName : '',
        subHeading:
          'subHead' in item
            ? item?.subHead?.jsonValue?.fields?.title?.value &&
              item?.subHead?.jsonValue?.fields?.title?.value?.length > 0
              ? item?.subHead?.jsonValue?.fields?.title
              : otherSettingsData?.storeLeadRoles?.jsonValue?.[0]?.fields?.title
            : null, // Default value since it's not in the input
        description:
          'description' in item
            ? item?.description
            : 'empDescription' in item
            ? item?.empDescription?.jsonValue?.value &&
              item?.empDescription?.jsonValue?.value?.length > 0
              ? item?.empDescription?.jsonValue
              : otherSettingsData?.defaultEmpBio
            : null,
        image:
          'servicePanelImage' in item
            ? item?.servicePanelImage
            : 'photo' in item
            ? item?.photo?.jsonValue?.value?.src || item?.photo?.jsonValue?.value
              ? {
                  jsonValue: {
                    value: {
                      src: item?.photo?.jsonValue?.value,
                    },
                  },
                }
              : otherSettingsData?.defaultStoreManagerImage
            : null,
        cta:
          'link' in item
            ? item?.link
            : 'learnMore' in item
            ? item?.learnMore?.jsonValue?.value?.href &&
              item?.learnMore?.jsonValue?.value?.href?.length > 0
              ? replaceStoreUrl(item?.learnMore?.jsonValue)
              : replaceStoreUrl(otherSettingsData?.defaultEmpLink?.jsonValue)
            : null,
      };
    });
  };

  const hasEmployeesIncludesRole = filteredEmployees?.filter((employee) =>
    // Only include employees whose roles are in the employeeRoles array
    roleTitlesToFilter?.includes(employee?.subHead?.jsonValue?.fields?.title?.value)
  );

  const sortedEmployees = hasEmployeesIncludesRole?.sort((a, b) => {
    const roleAIndex = roleTitlesToFilter?.indexOf(a?.subHead?.jsonValue?.fields?.title?.value);
    const roleBIndex = roleTitlesToFilter?.indexOf(b?.subHead?.jsonValue?.fields?.title?.value);
    return (roleAIndex as number) - (roleBIndex as number);
  });

  //Employees final data after filtering
  const employeesArray =
    fields?.showStoreManager?.value &&
    (sortedEmployees && sortedEmployees?.length > 0
      ? transformData(sortedEmployees)
      : filteredEmployees?.length > 0
      ? transformData(filteredEmployees)
      : defaultEmployeesData);

  //transforming data for services tile
  const cards = transformData(
    selectedService?.sort((a, b) => {
      if (!a?.servicePanelOrder?.value && !b?.servicePanelOrder?.value) return 0;
      if (!a?.servicePanelOrder?.value) return 1;
      if (!b?.servicePanelOrder?.value) return -1;

      // If both values exist, sort normally
      return a.servicePanelOrder.value - b.servicePanelOrder.value;
    })
  );

  //combining employee data and service data for carousel, Manager will be first item.
  const serviceCarouselTiles = [
    employeesArray && employeesArray?.length > 0 ? employeesArray[0] : [],
    ...Object.values(cards),
  ]?.filter((item) => item && (Array.isArray(item) ? item.length > 0 : true));

  const showArrows =
    (screenWidth && screenWidth < 1312 && serviceCarouselTiles?.length > 3) ||
    (screenWidth && screenWidth > 1312 && serviceCarouselTiles?.length > 4) ||
    false;
  const shouldSlice = !showArrows && screenWidth && screenWidth > 1023 ? 1 : 0;

  //syncing both slider
  useEffect(() => {
    isDesktop &&
      secondarySliderRef?.current?.splide?.Components?.Controller?.setIndex(activeSlideIndex + 1);
    if (
      activeSlideIndex ===
      serviceCarouselTiles?.length - (screenWidth && screenWidth < 1312 ? 3 : 4)
    ) {
      singleSliderRef?.current?.splide?.Components?.Arrows?.arrows?.next?.setAttribute(
        'disabled',
        'true'
      );
    }
  }, [activeSlideIndex]);

  const transformGTMData = (data: any) => {
    return data.map((item: any) => ({
      title: item?.title?.value,
      description: item?.description?.value,
      image: item?.image?.jsonValue?.value?.src,
      ctaLink: item?.cta?.value?.url || '',
    }));
  };

  const gtmData = transformGTMData(serviceCarouselTiles);
  const sendGtmData = (index: number) => {
    const obj = [];
    obj?.push(gtmData && gtmData[index]);
    gtmData &&
      trackObjectForPromotion(GTM_EVENT?.selectPromotion, storeData?.storeId, obj as Items[]);
  };

  //sending view_promotion data on page load
  useEffect(() => {
    if (storeData?.storeId && serviceCarouselTiles) {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, storeData?.storeId, gtmData as Items[]);
    }
  }, [storeData?.storeId]);

  return (
    <section className={base()} component-data="components/ServicePanel/ServicePanel">
      <div className={panelContent()}>
        {/**Single Slide  hiding on mobile devices*/}
        {!isMobile && isDesktop && (
          <div className={singleSlide()}>
            <Splide
              ref={singleSliderRef}
              options={{
                pagination: false,
                drag: false,
                perMove: 1,
                perPage: 1,
                speed: 0,
                gap: '24px',
                width: '404px',
                fixedWidth: '404px',
                arrows: showArrows,
                classes: {
                  arrows: 'inset-0 ',
                  prev: 'arrow-prev translate-x-0 splide-button splide__arrow--prev',
                  next: 'arrow-next splide-button splide__arrow--next',
                },
              }}
              onMoved={(_currentIndex, newIndex) => {
                secondarySliderRef?.current?.splide?.Components?.Move?.move(
                  newIndex,
                  newIndex + 1,
                  0
                );
                setActiveSlideIndex(newIndex);
              }}
              className={'!static h-full '}
            >
              {serviceCarouselTiles?.map((card: any, index: number) => {
                return (
                  <SplideSlide key={index}>
                    <ServiceTile
                      onClick={() => {
                        sendGtmData(index);
                      }}
                      card={card}
                      key={index}
                      index={index}
                      tileLength={cards?.length}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        )}

        {/** Three slide carousel */}
        <div className={panelWrapper()}>
          <div className={panelDetails()}>
            <RichTextHelper
              updatedField={{
                value:
                  fields?.title?.value?.replace('${store-name}', storeData?.storeName ?? '') || '',
              }}
              field={fields?.title}
              className={panelHeading()}
            />
            <RichText tag={'p'} field={fields?.description} className={panelDescription()} />
          </div>
          <div className={carouselWrapper()}>
            {screenWidth && (
              <Splide
                ref={secondarySliderRef}
                options={{
                  pagination: false,
                  drag: false,
                  perMove: 1,
                  gap: isMobile ? '16px' : '24px',
                  arrows: false,
                  speed: 0,
                  start: screenWidth && screenWidth > 1023 ? 2 : 1,
                  breakpoints: {
                    1044: {
                      perPage: 3,
                    },
                    1023: {
                      perPage: 2,
                    },
                  },
                }}
                className="md:max-w-[600px] lg:max-w-[816px] [&>div]:!overflow-scroll [&>div]:lg:!overflow-hidden"
              >
                {serviceCarouselTiles?.slice(shouldSlice)?.map((card: any, index: number) => {
                  return (
                    <SplideSlide key={index}>
                      <ServiceTile
                        onClick={() => {
                          sendGtmData(index);
                        }}
                        card={card}
                        key={index}
                        index={index}
                        tileLength={serviceCarouselTiles?.length}
                      />
                    </SplideSlide>
                  );
                })}
              </Splide>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//UpcomingEvents
const Events: React.FC<ServicePanelProps & ComponentProps> = ({ fields, rendering, params }) => {
  const { base, eventHeading, eventWrapper, eventsTiles, eventBorder, eventAndTileWrapper } =
    servicePanelVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });

  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const eventsData = storeData?.storeEvents;
  const isEE = useExperienceEditor();

  const getMarketingGridCols = (eventCount: number) => {
    if (eventCount === 1) return 'lg:col-span-2';
    if (eventCount === 2) return 'lg:col-span-1';
    return 'lg:col-span-3';
  };

  const getEventGridCols = (eventCount: number) => {
    if (eventCount === 1) return 'lg:col-span-1';
    if (eventCount === 2) return 'lg:col-span-2';
    return 'lg:col-span-3';
  };

  const parseDateTime = (dateTimeObj: EventInfoGql) => {
    const [datePart, timePart] = dateTimeObj?.fromDateTime?.value.split(' ');
    const [month, day, year] = datePart.split('/');

    // Ensure zero-padding for single-digit months and days
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    const formattedTime = timePart.includes(':') ? timePart : '00:00';

    // Create date string in ISO format
    const dateString = `${year}-${formattedMonth}-${formattedDay}T${formattedTime}:00`;
    const parsedDate = new Date(dateString);

    // Validate the date
    if (isNaN(parsedDate.getTime())) {
      console.error('Invalid date:', dateTimeObj.fromDateTime.value);
      return new Date(0); // Return epoch start as a fallback
    }

    return parsedDate;
  };

  const sortEventsByDateTime = (events: EventInfoGql[]) => {
    return [...events]?.sort((a, b) => {
      const dateA = parseDateTime(a);
      const dateB = parseDateTime(b);

      // If dates are the same, comparing time
      if (dateA.getTime() === dateB.getTime()) {
        const timeA = a.fromDateTime.value.split(' ')[1];
        const timeB = b.fromDateTime.value.split(' ')[1];
        return timeA.localeCompare(timeB);
      }

      return dateA?.getTime() - dateB?.getTime();
    });
  };

  const filterPastEvents = (events: EventInfoGql[]) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return events.filter((event) => {
      const toDate = new Date(event.toDateTime.value);
      toDate.setHours(0, 0, 0, 0);

      return toDate >= today; // Keep only events where toDate is in the past
    });
  };

  const upComingEvents = filterPastEvents(eventsData);

  const sortedEventsData = sortEventsByDateTime(upComingEvents);

  const marketingTilesToShow = isEE
    ? 3
    : upComingEvents?.length > 2
    ? 0
    : 3 - upComingEvents?.length;

  if (isEE) {
    return (
      <section className={base()} component-data="components/ServicePanel/ServicePanel">
        <div className={eventWrapper()}>
          <MarketingTile
            marketingTilesToShow={3}
            fields={fields}
            rendering={rendering}
            params={params}
          />
        </div>
      </section>
    );
  }

  if (upComingEvents && upComingEvents?.length > 0) {
    return (
      <section className={base()} component-data="components/ServicePanel/ServicePanel">
        <div className={eventWrapper()}>
          <RichTextHelper tag="h3" field={fields?.eventTitle} className={eventHeading()} />

          <div className={eventAndTileWrapper({ className: `lg:grid-cols-3` })}>
            {/**Events */}
            {!isEE ? (
              upComingEvents?.length > 0 && (
                <div
                  className={eventsTiles({
                    className: `${getEventGridCols(upComingEvents?.length)}`,
                  })}
                >
                  {sortedEventsData?.slice(0, 3)?.map((upcomingEvent, index) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <span className={eventBorder()}></span>}
                      <EventTile event={upcomingEvent} key={index} />
                    </React.Fragment>
                  ))}
                </div>
              )
            ) : (
              <></>
            )}
            <MarketingTile
              rendering={rendering}
              params={params}
              fields={fields}
              eventsDataLength={upComingEvents?.length}
              className={`${getMarketingGridCols(upComingEvents?.length)}`}
              marketingTilesToShow={marketingTilesToShow}
            />
          </div>
        </div>
      </section>
    );
  } else {
    // Below code is added to render marketing tile when there are no upcoming events instead of rendering <></>
    return (
      <section className={base()} component-data="components/ServicePanel/ServicePanel">
        <div className={eventWrapper()}>
          <div className={eventAndTileWrapper({ className: `lg:grid-cols-3` })}>
            <MarketingTile
              rendering={rendering}
              params={params}
              fields={fields}
              eventsDataLength={upComingEvents?.length}
              className={`${getMarketingGridCols(upComingEvents?.length)}`}
              marketingTilesToShow={marketingTilesToShow}
            />
          </div>
        </div>
      </section>
    );
  }
};

//Marketing Tile
const MarketingTile: React.FC<
  Pick<ServicePanelProps, 'fields'> &
    ComponentProps & {
      marketingTilesToShow: number;
      eventsDataLength?: number;
      className?: string;
      sendGtmData?: (val: number) => void;
    }
> = ({
  fields,
  marketingTilesToShow,
  eventsDataLength,
  // sendGtmData,
  rendering,
  // params,
  className = 'lg:col-span-3',
}) => {
  const { eventHeading, marketingTiles } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const isEE = useExperienceEditor();
  const showMarketingTile = isEE ? true : eventsDataLength ? eventsDataLength < 4 : true;

  return (
    <React.Fragment>
      {isEE && <RichTextHelper tag="h3" field={fields?.eventTitle} className={eventHeading()} />}

      {/**Marketing Tile */}

      <div
        className={marketingTiles({
          className: className,
        })}
      >
        {showMarketingTile &&
          [1, 2, 3]?.slice(0, marketingTilesToShow)?.map((_, index) => {
            return (
              <Placeholder
                key={index}
                name={`sp-marketing-tile-${index}`}
                rendering={rendering}
                params={{
                  showLink: 'false',
                  className: 'w-full',
                  imgClassName: 'h-[186px] lg:h-[212px] w-full',
                }}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
};

//Carousel Tile
const ServiceTile = ({
  card,
  hideInMobile,
  className,
  index,
  tileLength,
  onClick,
}: {
  card: any;
  hideInMobile?: boolean;
  className?: string;
  index: number;
  tileLength: number;
  onClick: () => void;
}) => {
  const {
    tileWrapper,
    tileDetailWrapper,
    tileHeading,
    tileSubHeading,
    tileDescription,
    tileCTA,
    tileTextWrapper,
    imgWrapper,
    icon,
    ctaWrapper,
  } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const isEE = useExperienceEditor();
  return (
    <LinkHelper className={'hover:no-underline'} field={card?.cta}>
      <div
        className={tileWrapper({
          className: `${className} ${hideInMobile ? 'hidden' : ''} ${
            index === tileLength - 1 ? 'mr-[14px] lg:mr-0' : 'mr-0'
          }`,
        })}
        onClick={onClick}
      >
        <div className={imgWrapper()}>
          <ImageHelper field={card?.image?.jsonValue} height={400} width={400} />
        </div>
        <div className={tileDetailWrapper()}>
          <div className={tileTextWrapper()}>
            <Text tag={'h3'} field={card?.title} className={tileHeading()} />
            <RichText field={card?.subHeading} className={tileSubHeading()} />
            <RichText field={card?.description} className={tileDescription()} />
          </div>
          <div className={ctaWrapper({ className: '!items-end hover:underline' })}>
            {card?.cta?.value?.href ? (
              <React.Fragment>
                <LinkHelper className={clsx(ctaWrapper(), tileCTA())} field={card?.cta}>
                  {!isEE && <span className={tileCTA()}>{card?.cta?.value?.text}</span>}
                  <IconHelper icon={'chevron-right'} className={icon()} />
                </LinkHelper>
              </React.Fragment>
            ) : (
              <span className={tileCTA()}>{card?.cta?.value?.text}</span>
            )}
          </div>
        </div>
      </div>
    </LinkHelper>
  );
};

//Event Tile
const EventTile = ({ event }: { event: EventInfoGql }) => {
  const { eventTile, eventTitle, eventDate, eventDataContainer } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const storeEventDate = event?.fromDateTime?.value?.split(' ')?.[0];

  return (
    <div key={event?.title?.value} data-attr={event?.title?.value} className={eventTile()}>
      <RichTextHelper tag="span" className={eventTitle()} field={event?.title} />
      <div className={eventDataContainer()}>
        <IconHelper icon="calendar" className="block" />
        <TextHelper tag="span" className={eventDate()} field={{ value: storeEventDate }} />
      </div>
      {/* <div className={eventDataContainer({ className: '!mb-0' })}>
        <IconHelper icon="storeLocation" className="block" />
        <TextHelper tag="span" className={eventLocation()} field={event?.location} />
      </div> */}
    </div>
  );
};

//Default variant to show both the component (Service Panel and Upcoming Events)
export const Default = (props: ServicePanelProps): JSX.Element => {
  return <ServicePanel {...props} />;
};

//show only Service Panel carousel
export const CarouselWithTiles = (props: ServicePanelProps): JSX.Element => {
  return <ServiceCarousel {...props} />;
};

//show only Events
export const UpcomingEvents = (props: ServicePanelProps): JSX.Element => {
  const { base, eventWrapper } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const isEE = useExperienceEditor();
  if (isEE) {
    return (
      <section className={base()} component-data="components/ServicePanel/ServicePanel">
        <div className={eventWrapper()}>
          <MarketingTile
            marketingTilesToShow={3}
            fields={props.fields}
            rendering={props?.rendering}
            params={props?.params}
          />
        </div>
      </section>
    );
  }
  return <Events {...props} />;
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<ServicePanelProps>(ServicePanel);
