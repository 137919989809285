//global
import React, { useEffect, useState } from 'react';
// import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//hooks
import useDictionary from 'src/hooks/useDictionary';
import { useTheme } from 'lib/context/ThemeContext';
import { newStoreDetailTabTailwindVariant } from 'tailwindVariants/components/newStoreDetailTabTailwindVariant';
import ProductDetailsTab from './ProductDetailsTab';
import ProductNutritionTab from './ProductNutritionTab';
import ImageHelper from '../commonComponents/ImageHelper';
import ReactPlayer from 'react-player';
import { MPCMProduct, PdpDatasourceData } from 'components/ProductDetail/ProductDetail';
//type
export interface NewStoreDetailTabProps {
  bullets?: string[];
  ingredientsComposition?: string;
  guaranteedAnalysisNutrientName?: string;
  GuaranteedAnalysisPDP?: string;
  fields: PdpDatasourceData;
}
const { base, imageWrapper, contentWrapper, tabs, tabTitle, description, whiteText } =
  newStoreDetailTabTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

/**
 * Renders a placeholder image component for the product detail page.
 *
 * @returns {React.ReactElement} A React component for rendering a placeholder image.
 */
export const renderImage = (imgSrc: MPCMProduct['MKTImage']) => {
  if (!imgSrc) return null;
  return (
    <ImageHelper
      field={{
        value: {
          src: imgSrc,
          alt: 'Product Image',
          height: 434,
          width: 627,
        },
      }}
      className={imageWrapper()}
    />
  );
};
/**
 * Renders a react-player component with a default video url and dimensions.
 *
 * @returns {ReactElement} A React component with a video player.
 */
export const renderVideo = (vidSrc: MPCMProduct['MKTVideo']) => {
  if (!vidSrc) return null;
  return <ReactPlayer width="100%" height="434px" url={vidSrc} controls />;
};
//main component
const NewStoreDetailTab: React.FC<NewStoreDetailTabProps> = ({ fields }) => {
  const { themeName } = useTheme();
  const { getDictionaryValue } = useDictionary();
  const [currentTab, setCurrentTab] = useState(getDictionaryValue('TabDeailsPDP'));
  const tabArray = [getDictionaryValue('TabDeailsPDP'), getDictionaryValue('TabNutrition')];
  useEffect(() => {
    document.body.classList.add(themeName);
    return function cleanup() {
      document.body.classList.remove(themeName);
    };
  }, []);

  return (
    <div className={base()} data-component={'components/NewStoreDetailTab/NewStoreDetailTab'}>
      <div className={contentWrapper({ className: '' })}>
        <div className={tabs()}>
          {tabArray?.map((tab, index) => {
            return (
              <div
                key={index}
                className={tabTitle({
                  className: tab === currentTab ? whiteText() : '',
                })}
                onClick={() => setCurrentTab(tab)}
              >
                <p>{tab}</p>
              </div>
            );
          })}
        </div>
        <div className={description()}>
          <ProductDetailsTab currentTab={currentTab} fields={fields} />
          <ProductNutritionTab currentTab={currentTab} fields={fields} />
        </div>
      </div>
    </div>
  );
};

export default NewStoreDetailTab;
