import { FulfillmentType } from 'src/helpers/Constants';
import { FormikProps, FormikValues } from 'formik';
import {
  hasValidPickupAddress,
  hasValidDeliveryAddress,
  hasValidBillingAddress,
} from 'lib/utils/checkout/section-validators';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import {
  Dispatch,
  Ref,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useOcCart from 'src/hooks/useOcCart';
import { useOcSelector } from 'src/redux/ocStore';
import { LineItemWithXp, OrderWithXp } from 'src/redux/xp';
import { SubmittingOrder } from 'components/WarningSubmittingCart/WarningSubmittingCart';

export type SingleExpandSteps = 'none' | 'pickup' | 'delivery' | 'billing';

// Payment form can be expanded along side other forms and uses separate logic.
export type AllSteps = SingleExpandSteps | 'payment' | 'frequency' | 'paymentError';

export type PaymentError = 'cvv' | 'avs' | 'unknown';
// Mapping of forms to corresponding html element ids.
const FormIdMap: Record<AllSteps, string> = {
  none: 'content',
  pickup: 'pickupaddress',
  delivery: 'deliveryaddress',
  billing: 'billingaddress',
  payment: 'paymentsection',
  frequency: 'deliveryfrequency',
  paymentError: 'payment-error-label',
};

interface CheckoutFormContextData {
  isPickup?: boolean;
  isDelivery?: boolean;

  setSubmitting?: boolean;
  setDeliverySubmitting?: boolean;

  warningProfileUpdate?: boolean;

  CheckoutError?: PaymentError | false;

  warningDeliveryProfileUpdate?: boolean;
  showAutoshipWarning?: boolean;

  checkoutIsSubmitting?: boolean;
  submittingOrder?: SubmittingOrder;
}

interface CheckoutFormContextProps {
  checkoutContextData: CheckoutFormContextData;
  updateCheckoutContextData: Dispatch<CheckoutFormContextData>;

  expandedStep: SingleExpandSteps;
  /**
   * Scrolls to the corresponding form element, and expands it if it is expandable.
   */
  activateStep: (step: AllSteps) => void;
  /**
   * Finish the current step and proceed to next applicable
   */
  goToNextStep: (step: AllSteps) => void;
  deliveryAddressForm: Ref<FormikProps<FormikValues>> & React.RefObject<FormikProps<unknown>>;
  pickupAddressForm: Ref<FormikProps<FormikValues>> & React.RefObject<FormikProps<unknown>>;

  paymentOpen: boolean;
}

const CheckoutFormContext = createContext<CheckoutFormContextProps>({} as CheckoutFormContextProps);

export default CheckoutFormContext;

export function useCheckoutFormContext() {
  return useContext(CheckoutFormContext);
}

export function CheckoutFormContextProvider({ children }: React.PropsWithChildren) {
  const deliveryAddressForm = useRef<FormikProps<FormikValues>>(null);
  const pickupAddressForm = useRef<FormikProps<FormikValues>>(null);

  const scrollTimeout = useRef<{ timeout?: NodeJS.Timeout }>({});

  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const { getProductLineItems } = useOcCart();

  const productLineItems = getProductLineItems();

  const [expandedStep, setExpandedStep] = useState<SingleExpandSteps>('none');
  const [checkoutContextData, setCheckoutContextData] = useState<CheckoutFormContextData>({});

  const pathName = useRealPathName();

  const initialExpandedForm = getExpandedForm(cart, productLineItems);

  useEffect(() => {
    setExpandedStep(initialExpandedForm);
  }, [initialExpandedForm, pathName]);

  const updateCheckoutContextData = useCallback((updates: CheckoutFormContextData) => {
    setCheckoutContextData((prevState) => ({
      ...prevState,
      ...updates,
    }));
  }, []);

  const activateStep = useCallback((step: AllSteps) => {
    // Expandable forms
    if (step === 'pickup' || step === 'delivery' || step === 'billing') {
      setExpandedStep(step);
    }

    // If we go to payment form, collapse all others
    if (step === 'payment') {
      setExpandedStep('none');
    }

    // Whether or not it's an expandable form, scroll to the corresponding element
    scrollToTarget(FormIdMap[step]);
  }, []);

  const goToNextStep = useCallback(
    (currentStep: AllSteps) => {
      const nextStep = getNextFormStep(currentStep, cart, productLineItems);
      activateStep(nextStep);
    },
    [activateStep, cart, productLineItems]
  );

  function scrollToTarget(elemId: string) {
    // Cancel previously queued scroll
    if (scrollTimeout.current.timeout) {
      clearTimeout(scrollTimeout.current.timeout);
    }
    scrollTimeout.current.timeout = setTimeout(() => {
      const target = document.getElementById(elemId);
      target && target.scrollIntoView({ behavior: 'smooth' });
      scrollTimeout.current.timeout = undefined;
    }, 500);
  }

  const paymentOpen = isPaymentOpen();

  return (
    <CheckoutFormContext.Provider
      value={{
        checkoutContextData,
        updateCheckoutContextData,
        expandedStep,
        activateStep,
        goToNextStep,
        deliveryAddressForm,
        pickupAddressForm,
        paymentOpen,
      }}
    >
      {children}
    </CheckoutFormContext.Provider>
  );

  function isPaymentOpen() {
    const paymentOpen = !!cart?.BillingAddress && hasShippingOrPickupAddress(cart);
    return paymentOpen;
  }

  function hasShippingOrPickupAddress(cart: OrderWithXp) {
    let hasShippingOrPickupAddress = false;

    if (cart?.xp?.Fulfillment === FulfillmentType.BOPIS) {
      const pickupInformation = cart?.xp?.PickupInfo;
      hasShippingOrPickupAddress = !!pickupInformation?.FirstName;
    } else if (cart?.xp?.Fulfillment === FulfillmentType.DFS) {
      const shippingAddress = productLineItems?.[0]?.ShippingAddress;

      hasShippingOrPickupAddress = !!shippingAddress?.FirstName;
    }
    return hasShippingOrPickupAddress;
  }
}

function getExpandedForm(
  cart?: OrderWithXp,
  productLineItems?: LineItemWithXp[]
): SingleExpandSteps {
  if (cart?.xp?.Fulfillment === FulfillmentType.BOPIS) {
    // Go to pickup if missing pickup info
    if (!hasValidPickupAddress(cart)) {
      return 'pickup';
    }
  }
  if (cart?.xp?.Fulfillment === FulfillmentType.DFS) {
    const hasAutoshipEligible = productLineItems?.find((x) => x.Product?.xp?.Autoship);
    // If cart has autoship eligible items, it should go to top of page
    if (hasAutoshipEligible) {
      return 'none';
    }
    // Otherwise go to delivery if that's missing
    if (!hasValidDeliveryAddress(productLineItems)) {
      return 'delivery';
    }
  }

  // Regardless of Fulfillment, go to billing next if it's missing
  if (!hasValidBillingAddress(cart)) {
    return 'billing';
  }

  return 'none';
}

function getNextFormStep(
  currentStep: AllSteps,
  cart?: OrderWithXp,
  productLineItems?: LineItemWithXp[]
): AllSteps {
  switch (currentStep) {
    case 'frequency':
      if (!hasValidDeliveryAddress(productLineItems)) {
        return 'delivery';
      }
      if (!hasValidBillingAddress(cart)) {
        return 'billing';
      }
      return 'payment';
    case 'delivery':
      if (!hasValidBillingAddress(cart)) {
        return 'billing';
      }
      return 'payment';
    case 'pickup':
      if (!hasValidBillingAddress(cart)) {
        return 'billing';
      }
      return 'payment';
    case 'billing':
      return 'payment';
  }
  return 'none';
}
