import { Field, GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcSelector } from 'src/redux/ocStore';
import { jumpMenuQuery } from 'lib/jumpMenu.grapfql';
import { ChangeEvent } from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { jumpMenuTailwindVariant } from 'tailwindVariants/components/jumpMenuTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

type JumpToProgramStaticProps = {
  item: {
    children: {
      results: {
        JumpMenuItemName: {
          name: string;
          jsonValue: {
            value: string;
          };
        };
        JumpMenuTargetSectionId: {
          name: string;
          jsonValue: {
            value: string;
          };
        };
      }[];
    };
  };
};

type JumpToProgram = {
  fields: {
    jumpMenuLabel: Field<string>;
    navigationJumpToItems: {
      title: string;
      anchor: string;
    }[];
    helperText: Field<string>;
  };
  staticProps: JumpToProgramStaticProps;
};

const { navigationMenu, navigationName, navigationDropdown } = jumpMenuTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const JumpToProgram = ({ fields, staticProps }: JumpToProgram) => {
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const unityId = useOcSelector((state) => state?.ocUser?.user?.xp?.UnityId) ?? '';
  const isExperienceEditor = useExperienceEditor();

  function handleJumpToChange(event: ChangeEvent<HTMLSelectElement>) {
    const selectedValue = event.target?.value;
    const sectionId = document.getElementById(selectedValue);
    const jumpId = document.getElementById(`${selectedValue}_section`);

    if (sectionId && jumpId?.hasChildNodes()) {
      sectionId.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Anchors filter if the UnityId is not available (not case sensitive)
  const defaultOptions: string[] = ['Product Rewards', 'Service Rewards', 'Quick Links'];

  const getOptions = (staticProps: JumpToProgramStaticProps, filterOptions: string[] = []) => {
    const lowerCaseOptions = filterOptions.map((option) => option.toLowerCase());
    return (
      staticProps?.item?.children?.results?.filter((item) => {
        const optionName = item?.JumpMenuItemName?.jsonValue?.value?.toLowerCase();
        return lowerCaseOptions.includes(optionName);
      }) || []
    );
  };

  if (isLoyaltyAccepted === true && !isExperienceEditor) {
    return (
      <>
        <div className={navigationMenu()}>
          {fields.jumpMenuLabel?.value && (
            <div>
              <p className={navigationName()}>{fields.jumpMenuLabel?.value}</p>
            </div>
          )}
          <div>
            <select
              aria-label="select"
              className={navigationDropdown()}
              onChange={(e) => handleJumpToChange(e)}
            >
              {unityId ? (
                <>
                  {staticProps &&
                    staticProps?.item?.children?.results?.map((item, index) => (
                      <option key={index} value={item?.JumpMenuTargetSectionId?.jsonValue?.value}>
                        {item?.JumpMenuItemName?.jsonValue?.value}
                      </option>
                    ))}
                </>
              ) : (
                <>
                  {getOptions(staticProps, defaultOptions).map((item, index) => (
                    <option key={index} value={item?.JumpMenuTargetSectionId?.jsonValue?.value}>
                      {item?.JumpMenuItemName?.jsonValue?.value}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </div>
        {fields?.helperText?.value && <RichTextHelper field={fields?.helperText} />}
      </>
    );
  }
  return;
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData, context) => {
  if (rendering.dataSource && layoutData?.sitecore?.context?.language) {
    const graphQLClient = graphqlClientFactory({});

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = await graphQLClient.request<any>(jumpMenuQuery, {
      datasource: rendering?.dataSource,
      language: layoutData?.sitecore?.context?.language,
    });

    return {
      staticProps: data,
    };
  }
  throw new Error(
    `No datasource set for rendering: ${rendering.componentName}, Guid: ${
      layoutData.sitecore.route?.itemId
    }, Path: ${
      context.params?.path instanceof Array ? context.params.path.join('/') : context.params?.path
    } `
  );
};

export default JumpToProgram;
