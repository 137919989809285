import clsx from 'clsx';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useContext } from 'react';
import { OrderWorksheetWithXP } from 'src/redux/xp';
import { formatPurchaseOrderDate } from 'src/utils/formatDate';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import ButtonHelper from '../commonComponents/Button';
import { DiscreteLineItem, FulfillmentType, GTM_EVENT, GTMLabels } from '../Constants';
import useDictionary from 'src/hooks/useDictionary';
import Link from 'next/link';
import { getPurchaseType } from 'src/utils/myPurchase';
import { PurchaseDetailsFields } from 'components/PurchaseDetails/PurchaseDetails';
import { useBarcode } from 'next-barcode';
import { getOrderStatus } from './PurchaseOrderUtils';
import { formatPhoneForDisplay } from '../Form/PhoneField';
import useOcCart, { ProductToAddProps } from 'src/hooks/useOcCart';
import { useOcSelector } from 'src/redux/ocStore';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import ComponentContext from 'lib/context/ComponentContext';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from '../search/SearchResults/types';

export interface PurchaseDetailsProps extends PurchaseDetailsFields {
  orderData: OrderWorksheetWithXP;
  setIsReorderAll?: (val: boolean) => void;
  isReorderAllDisabled?: boolean;
  setIsPartiallyAdded?: (val: boolean) => void;
  lineItemInventory?: { [key: string]: number }[];
  inventoryForAllLineItems?:
    | {
        [key: string]: number;
      }[]
    | undefined;
}

const Details = ({
  orderData,
  fields,
  isReorderAllDisabled,
  setIsReorderAll,
  setIsPartiallyAdded,
  inventoryForAllLineItems,
}: PurchaseDetailsProps) => {
  const {
    detailSection,
    dateWrapper,
    orderDate,
    topDetailsWrapper,
    boldLabels,
    regularLabels,
    colorTextBrand,
    colorRed,
    orderDetailsWrapper,
    detailsWrapper,
    detailsLeft,
    detailsRight,
    detailsButtonWrapper,
    reOrderAllButton,
    autoshipButton,
    listWrapper,
    phoneNumber,
  } = purchaseDetailsVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { getDictionaryValue } = useDictionary();
  const { reorderAll } = useOcCart();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const currOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const pickup: boolean = currOrder?.order?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const { inputRef } = useBarcode({
    value: orderData?.Order?.xp?.OrderId || '',
    options: {
      displayValue: false,
      background: '#fff',
      height: 38,
      width: 1,
      margin: 5,
    },
  });

  //Excluding tip lineItem
  const lineItems = orderData?.LineItems?.filter(
    (product) => product?.ProductID !== DiscreteLineItem.TIP
  );

  const reorderAllLineItems = lineItems?.map((x) => {
    return { ProductId: x.ProductID, Quantity: x?.Quantity };
  });

  const productData = lineItems?.map((lineItem) => {
    return {
      ...lineItem?.Product,
      quantity: lineItem?.Quantity,
      BasePrice: lineItem?.LineTotal,
      listPrice: lineItem?.xp?.ListPrice,
    };
  });

  const handleReorderAll = async () => {
    const res = await reorderAll({
      CurrentCardId: currOrder?.order?.ID as string,
      ProductToAdd: reorderAllLineItems as ProductToAddProps,
      currentCartLineItemLength: currOrder?.lineItems?.filter(
        (product) => product?.ProductID !== DiscreteLineItem.TIP
      ).length as number,
      orderFulFillMentType: orderData?.Order?.xp?.Fulfillment as string,
      currentCartFulFillMentType: currOrder?.order?.xp?.Fulfillment as string,
    });
    if (res?.isDFS) {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
      });
      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
        fromProductListing: true,
      });
    } else if (res?.isFullyAdded) {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
      });
    } else {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: false,
        showMiniCartLoader: false,
      });
      if (res?.isPartiallyAdded) {
        setIsPartiallyAdded && setIsPartiallyAdded(true);
        setIsReorderAll && setIsReorderAll(true);
      }
      if (res?.isAllNotAdded) {
        setIsReorderAll && setIsReorderAll(true);
      }
    }
    const productDataObj = productData?.map((lineItem) => {
      const inventory =
        inventoryForAllLineItems &&
        inventoryForAllLineItems?.find(
          (val) => Object?.keys(val)?.[0] == (lineItem?.ID as string)
        )?.[lineItem?.ID as string];
      return {
        ...lineItem,
        quantity: lineItem?.quantity,
        BasePrice: lineItem?.BasePrice,
        listPrice: lineItem?.listPrice,
        availableInventory: inventory && inventory,
      };
    });
    sendProductsPromotion({
      eventName: GTM_EVENT?.addToCart,
      data: productDataObj as ProductSearchResultModelWithVariants,
      click_from: GTMLabels?.myPurchaseDetailsReorderAll,
      storeId: myStoreData?.storeId,
      fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      shouldPreciousDataLaLayerRemoved: true,
      pageItem: GTMLabels?.myPurchaseDetails,
      currentPage: GTMLabels?.myPurchaseDetails,
    });
  };
  const isAutoshipOrder = orderData?.Order?.xp?.IsSubscription;
  return (
    <div
      className={detailSection()}
      data-component={'/helpers/purchaseDetails/Details.tsx'}
      data-wrapper={'purchase-details'}
    >
      <div className={topDetailsWrapper()}>
        {/**Order Date and Status */}
        <div className={dateWrapper()}>
          <h2 className={orderDate()}>
            {formatPurchaseOrderDate(orderData?.Order?.DateSubmitted)}
          </h2>
          <span className={clsx(boldLabels({ className: 'whitespace-nowrap' }), colorTextBrand())}>
            {/**TODO: need to implement actual logic */}
            {getOrderStatus({
              orderStatus: orderData?.Order?.xp?.OrderStatus as string,
              fulFillmentType: orderData?.Order?.xp?.Fulfillment as string,
              manhattenStatus: orderData?.Order?.xp?.ManhattenStatus as string,
            })}
          </span>
        </div>
        {/**Order Details */}
        <div data-wrapper={'order-details'} className={orderDetailsWrapper()}>
          <div className={detailsWrapper()}>
            <div className={detailsLeft()}>
              <div className={listWrapper({ className: 'lg:order-3' })}>
                <Text
                  tag="span"
                  field={fields?.orderText}
                  className={boldLabels({ className: 'whitespace-nowrap' })}
                />
                <span className={regularLabels()}>{orderData?.Order?.xp?.OrderId}</span>
              </div>
              {/**Order Total*/}
              <div className={listWrapper({ className: 'order-3 lg:order-1' })}>
                <Text tag="span" field={fields?.orderTotalText} className={boldLabels()} />
                <span className={regularLabels()}>
                  {getDictionaryValue('CurrencySymbol')}
                  {orderData?.Order?.Total?.toFixed(2)}
                </span>
              </div>
              {/**Member Saving*/}
              {orderData?.Order?.xp?.EmailContent?.TotalMemberSaving &&
              orderData?.Order?.xp?.EmailContent?.TotalMemberSaving > 0 ? (
                <div className={listWrapper({ className: 'order-3 lg:order-2' })}>
                  <Text
                    tag="span"
                    field={fields?.memberSavingText}
                    className={clsx(boldLabels(), colorRed())}
                  />
                  <span className={regularLabels({ className: colorRed() })}>
                    {getDictionaryValue('CurrencySymbol')}
                    {orderData?.Order?.xp?.EmailContent?.TotalMemberSaving?.toFixed(2)}
                  </span>
                </div>
              ) : null}
              <div className={listWrapper({ className: 'order-2 lg:order-3' })}>
                <svg ref={inputRef} />
              </div>
            </div>
            {/**Purchase Type */}
            <div className={detailsRight()}>
              <div className={listWrapper()}>
                <Text tag="span" field={fields?.purchaseTypeText} className={boldLabels()} />
                <span className={regularLabels()}>
                  {getPurchaseType(
                    orderData?.Order?.xp?.Fulfillment as string,
                    orderData?.Order?.xp?.IsSubscription as boolean
                  )}
                </span>
              </div>

              {/**Delivery TO */}
              {orderData?.Order?.xp?.Fulfillment === FulfillmentType?.DFS && (
                <div className={listWrapper()}>
                  <Text tag="span" field={fields?.deliveryToText} className={boldLabels()} />
                  <div className={regularLabels()}>
                    <p>
                      {orderData?.Order?.xp?.DeliveryAddress?.Street1 ||
                        orderData?.LineItems?.[0]?.ShippingAddress?.Street1}
                    </p>
                    <span>
                      {orderData?.Order?.xp?.DeliveryAddress?.City ||
                        orderData?.LineItems?.[0]?.ShippingAddress?.City}
                      ,{' '}
                      {orderData?.Order?.xp?.DeliveryAddress?.State ||
                        orderData?.LineItems?.[0]?.ShippingAddress?.State}{' '}
                      {orderData?.Order?.xp?.DeliveryAddress?.Zip ||
                        orderData?.LineItems?.[0]?.ShippingAddress?.Zip}
                    </span>
                  </div>
                </div>
              )}

              {/**storeLocation */}
              <div className={listWrapper()}>
                <Text
                  tag="span"
                  field={fields?.storeLocationText}
                  className={boldLabels({ className: 'whitespace-nowrap' })}
                />
                {orderData?.LineItems && (
                  <div className={regularLabels()}>
                    <p>{orderData?.LineItems[0]?.ShipFromAddress?.City}</p>
                    <p>{orderData?.LineItems[0]?.ShipFromAddress?.Street1}</p>
                    <span>
                      {orderData?.LineItems[0]?.ShipFromAddress?.Country},{' '}
                      {orderData?.LineItems[0]?.ShipFromAddress?.State}{' '}
                      {orderData?.LineItems[0]?.ShipFromAddress?.Zip}
                    </span>
                    {orderData?.LineItems[0]?.ShipFromAddress?.Phone && (
                      <Link
                        aria-label="phone number link"
                        href={`tel:${formatPhoneForDisplay(
                          orderData?.LineItems[0]?.ShipFromAddress?.Phone as string
                        )}`}
                        className={phoneNumber()}
                      >
                        {orderData?.LineItems[0]?.ShipFromAddress?.Phone}
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/**Reorder all and Autoship CTAs */}

            <div className={detailsButtonWrapper()}>
              <ButtonHelper
                isLinkField={true}
                size="small"
                isDisabled={isReorderAllDisabled}
                variant={isReorderAllDisabled ? 'disabled' : 'primary'}
                asButton={true}
                field={fields?.reOrderAll}
                onClickHandler={async (e) => {
                  e?.preventDefault();
                  !isReorderAllDisabled && (await handleReorderAll());
                }}
                className={reOrderAllButton()}
              />

              {/**Show Autoship CTA if order placed as Autoship*/}
              {isAutoshipOrder && (
                <ButtonHelper
                  isLinkField={true}
                  size="small"
                  variant="secondary"
                  asButton={true}
                  field={{
                    value: {
                      ...fields?.manageAutoship?.value,
                      href:
                        fields?.manageAutoship?.value?.href +
                        '#' +
                        orderData?.Order?.SubscriptionID,
                    },
                  }}
                  className={autoshipButton()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Details;
