import { tv } from 'tailwind-variants';

export const productDetailAddToCartTailwindVariant = tv(
  {
    slots: {
      qtyWrapper: 'w-[132px] border border-color-border-mid rounded flex',
      addToCartButton: 'w-[204px] !px-0 !text-center',
      buttonBase:
        'flex justify-center lg-for-pdp:gap-[14px] xl-for-pdp:gap-desk-space-between-base-vertical',
      topError:
        'w-full text-center text-body-large-bold mb-3 font-primary font-body-large-bold leading-body-large-bold text-color-accent-red',
      bottomError:
        'w-full text-center text-body-large-bold mt-3 font-primary font-body-large-bold leading-body-large-bold text-color-accent-red',
      base: '',
      qtyButton: 'h-1/2 w-[30px] bg-color-background-light flex justify-center items-center',
      qtyInput: 'w-fit max-w-[40px] outline-none focus:outline-none',
      inputWrapper:
        'w-[100px] flex gap-[17px] font-primary font-heading-desk-medium-regular leading-heading-desk-medium-regular text-heading-desk-medium-regular items-center pl-[15px]',
      qtyText:
        'w-[37px] max-w-[37px] font-heading-desk-medium-regular leading-heading-desk-medium-regular text-heading-desk-medium-regular',
    },
    variants: {
      size: {
        mobile: {
          base: 'py-mob-global-spacing-spacing-2 z-[2] bg-color-background-white sticky left-0 top-0',
          buttonBase: 'gap-mob-space-between-base-vertical ',
        },
        desktop: {
          base: 'py-0',
          buttonBase: 'relative gap-desk-space-between-base-vertical',
        },
      },
      isError: {
        true: {
          addToCartButton: '!bg-color-brand-primary-1-disabled !cursor-not-allowed',
        },
        false: {
          addToCartButton:
            'hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
