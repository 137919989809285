import { tv } from 'tailwind-variants';

export const purchaseListingVariant = tv(
  {
    slots: {
      purchaseListingWrapper: ['purchase-listing-wrapper', 'flex', 'flex-col'],
      purchaseListingTransactionWrapper: ['flex', 'justify-between'],
      purchaseHistory: [
        'flex',
        'gap-desk-global-spacing-spacing-4',
        'items-center',
        'grow',
        'justify-end',
      ],
      historyWrapper: [
        'relative',
        'border-[1px]',
        'border-color-border-mid',
        'rounded-desk-global-radius-small',
        'grow',
        'max-w-[240px]',
        'cursor-pointer',
      ],
      filterBlock: ['flex', 'items-center', 'justify-between'],
      pageTitle: [
        'block',
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
        'grow-[3]',
      ],
      smallBoldLabel: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      largeBoldLabel: ['text-body-large-bold', 'font-body-large-bold', 'leading-body-large-bold'],
      myPurchaseOrderWrapper: [],
      myPurchaseOrderItem: [
        'my-purchase-order border-[1px] border-color-border-mid rounded-desk-global-radius-medium mb-desk-margin-base-bottom',
      ],
      myPurchaseOrderItemTop: [
        'my-purchase-order-top bg-color-background-light py-desk-padding-micro-y px-desk-padding-micro-x rounded-t-desk-global-radius-medium',
      ],
      myPurchaseOrderItemDateandStatus: [
        'flex justify-between pb-desk-padding-micro-bottom border-b-[1px] border-color-border-mid',
      ],
      myPurchaseLabelRegular: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      myPurchaseOrderItemDate: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      myPurchaseOrderItemStatus: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-text-brand-1',
      ],
      myPurchaseOrderDetails: ['order-details', 'flex', 'justify-between'],
      myPurchaseOrderDetailsLeft: [],
      myPurchaseOrderDetailLineItem: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      myPurchaseOrderDetailLineLabel: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      myPurchaseOrderWarningText: ['text-system-red'],
      myPurchaseOrderDetailsRight: [
        'flex flex-row gap-desk-global-spacing-spacing-5 justify-end grow',
      ],
      myPurchaseOrderDetailsRightCTAWrapper: ['flex flex-col gap-[8px]'],
      myPurchaseOrderDetailsRightCTAWrapperMobile: ['flex flex-col gap-[8px]'],
      myPurchaseOrderItemBottom: ['py-desk-padding-micro-y px-desk-padding-micro-x '],
      myPurchaseOrderItemCountLabel: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      myPurchaseOrderItemCount: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'text-right',
      ],
      myPurchaseOrderListItems: [
        'grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
        'flex flex-row flex-wrap',
      ],
      myPurchaseOrderListItemCardImg: [
        'mx-10',
        'my-auto',
        'relative',
        'flex',
        'justify-center',
        'items-center',
        'overflow-hidden',
      ],
      myPurchaseOrderListItemImage: ['min-w-[104px]', 'max-h-[140px]', 'h-auto', 'object-contain'],
      myPurchaseOrderListItemCard: [
        'cardWrapper',
        'relative',
        'border-[1px]',
        'border-color-border-mid',
        'bg-color-background-white',
        'flex',
        '!gap-[12px]',
        'p-desk-global-spacing-spacing-2',
        'flex-row',
        'lg:flex-col',
      ],
      myPurchaseOrderListItemCardShowMore: [
        '!bg-color-background-light !items-center !justify-center',
      ],
      viewMoreWrapper: [
        'justify-center',
        'items-center',
        'gap-desk-global-spacing-spacing-2',
        'border-[1px]',
        'py-desk-global-spacing-spacing-2',
        'text-body-large-bold ',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-brand-primary-1-base',
        'border-color-brand-primary-1-base',
        'rounded-desk-global-radius-small',
        // '[&>span>svg>*]:fill-color-brand-primary-1-base',
      ],
      myPurchaseOrderListItemCardShowMoreNumber: [
        'bg-color-brand-primary-1-base font-heading-mob-xLarge-medium text-heading-mob-xLarge-medium leading-heading-mob-xLarge-medium text-color-scale-1-white cursor-pointer flex items-center justify-center',
      ],
      myPurchaseOrderListItemCardInfo: [
        'sitecoreProductLink',
        'block',
        'text-color-text-dark',
        'text-left',
        'font-body-large-regular',
        'text-body-large-regular',
        'leading-body-large-regular',
        'inline-block whitespace-break-spaces',
        'overflow-hidden overflow-ellipsis',
      ],
      myPurchaseOrderListItemBottomWrapper: [
        'flex',
        'flex-col',
        'gap-[24px]',
        'justify-around',
        // 'min-h-[50px]',
        // 'spefic-range-wrapper-min-h',
        '!gap-[12px]',
      ],
      myPurchaseOrderListItemQTYandPrice: ['flex justify-between'],
      phoneNumberWrapper:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-brand-primary-1-dark hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      viewMoreIcon: [
        '[&_svg_*]:fill-color-scale-1-white group-hover:[&_svg_*]:fill-color-brand-tertiary-3-base group-focus:[&_svg_*]:fill-color-brand-tertiary-3-base [&_svg]:w-4 [&_svg]:h-4',
      ],
      myPurchaseShowLessBar: [
        'show-less-bar bg-color-brand-primary-1-base w-full text-center mt-[12px] cursor-pointer',
        'rounded-desk-global-radius-small',
      ],
      myPurchaseItemListPrice: ['text-color-dark'],
      myPurchaseItemMemberPrice: ['text-system-red'],
      myPurchaseItemAutoshipPrice: ['text-color-brand-autoship'],
      myPurchaseCartIcon: [
        'lg:hidden ',
        '[&>svg>*>*]:fill-color-brand-primary-1-base',
        '[&>svg]:max-w-[32px]',
        '[&>svg]:max-h-[32px]',
      ],
      myPurchaseOrderInfoCTA: [
        'lg:w-auto w-full py-desk-global-spacing-spacing-2 px-desk-global-spacing-spacing-5 whitespace-nowrap !text-body-large-bold !font-body-large-bold !leading-body-large-bold text-center',
      ],
      myPurchasePaginationItems: [
        'py-mob-global-spacing-spacing-1 px-mob-global-spacing-spacing-2 border-[1px] border-color-brand-primary-1-base bg-color-background-white outline-none text-color-brand-primary-1-base hover:no-underline hover:text-color-text-white hover:bg-color-brand-primary-1-base  font-body-large-regular text-body-large-regular leading-body-large-regular',
      ],
      myPurchaseUnavailableProductWrapper: [
        'absolute',
        'top-1/2',
        'left-1/2',
        '-translate-x-[50%]',
        '-translate-y-[50%]',
        'bg-[rgba(35,31,31,0.5)]',
        'py-desk-global-spacing-spacing-3',
        'px-desk-global-spacing-spacing-4',
        'max-w-[140px]',
        'w-full',
        'text-center',
      ],
      myPurchaseUnavailableProductMark: [
        'text-body-large-bold font-body-large-bold leading-body-large-bold text-white',
      ],
    },
    variants: {
      size: {
        mobile: {
          purchaseListingWrapper: ['gap-mob-padding-tight-bottom'],
          purchaseListingTransactionWrapper: ['flex-col', 'gap-mob-padding-tight-bottom'],
          historyWrapper: ['p-mob-padding-micro-y'],
          myPurchaseOrderListItemCardInfo: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-0',
            // 'h-[138px]',
            '!pb-[0px]',
            'flex-grow overflow-hidden text-ellipsis whitespace-nowrap',
          ],
          myPurchaseOrderListItemBottomWrapper: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-0',
          ],
          myPurchaseOrderDetailsRightCTAWrapper: ['hidden'],
          myPurchaseOrderDetailsRightCTAWrapperMobile: [
            'flex',
            'mt-[24px',
            'pt-mob-padding-tight-y',
          ],
          myPurchaseOrderDetails: ['p-mob-global-spacing-spacing-2'],
          myPurchaseOrderListItemCardImg: ['mx-auto', 'h-auto'],
          myPurchaseOrderListItemQTYandPrice: ['items-center', 'gap-mob-global-spacing-spacing-3'],
          myPurchaseOrderListItemCardShowMore: [
            'px-desk-global-spacing-spacing-3',
            'py-desk-global-spacing-spacing-5',
            'mb-[24px]',
            'hidden',
          ],
          myPurchaseOrderListItemCardShowMoreNumber: ['px-0 h-[80px] w-[80px] rounded-full'],
          myPurchaseOrderListItems: ['gap-mob-global-spacing-spacing-2'],
          myPurchaseOrderItemCount: ['pb-mob-padding-micro-bottom'],
          myPurchaseShowLessBar: [
            'py-mob-margin-tight-top',
            'mb-mob-padding-micro-bottom',
            'hidden',
          ],
          viewMoreWrapper: ['flex'],
        },
        desktop: {
          purchaseListingWrapper: ['gap-desk-margin-base-bottom'],
          purchaseListingTransactionWrapper: ['flex-row'],
          historyWrapper: ['py-desk-padding-micro-y'],
          myPurchaseOrderListItemCardInfo: [
            'px-desk-global-spacing-spacing-3',
            'py-desk-global-spacing-spacing-3',
            '!pb-[0px]',
            // 'h-[106px]',
          ],
          myPurchaseOrderListItemBottomWrapper: [
            'px-desk-global-spacing-spacing-3',
            'py-desk-global-spacing-spacing-3',
          ],
          myPurchaseOrderListItemCardShowMoreNumber: ['h-[96px]', 'w-[96px]', ' rounded-full'],
          myPurchaseOrderDetailsRightCTAWrapperMobile: ['hidden'],
          myPurchaseOrderDetailsRightCTAWrapper: ['flex'],
          myPurchaseOrderDetails: ['p-desk-global-spacing-spacing-1'],
          myPurchaseOrderListItemCardImg: ['min-h-[140px]'],
          myPurchaseOrderListItemCard: ['w-[208px]', 'flex'],
          myPurchaseOrderListItems: ['gap-mob-global-spacing-spacing-2'],
          myPurchaseOrderItemCount: ['pb-desk-padding-micro-bottom'],
          myPurchaseShowLessBar: ['py-desk-margin-tight-top', 'mb-0', 'flex'],
          myPurchaseOrderListItemCardShowMore: ['flex'],
          viewMoreWrapper: ['hidden'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
