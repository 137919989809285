// store/otherSettingSlice.ts
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

import { OrderWorksheetWithXP } from '../xp';

interface PastOrdersState {
  data: OrderWorksheetWithXP | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PastOrdersState = {
  data: null,
  status: 'idle',
  error: null,
};

const pastOrdersSlice = createSlice({
  name: 'ocPastOrder',
  initialState,
  reducers: {
    setPastOrders: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { setPastOrders } = pastOrdersSlice.actions;
export default pastOrdersSlice.reducer;
