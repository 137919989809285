import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import {
  DiscreteLineItem,
  FulfillmentType,
  getProductDetailPath,
  GTM_EVENT,
  GTMLabels,
  productAvailability,
} from 'src/helpers/Constants';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import useDictionary from 'src/hooks/useDictionary';
import useOcCart, { ProductToAddProps } from 'src/hooks/useOcCart';
import { BuyerProductWithXp, LineItemWithXp, OrderWorksheetWithXP, ProductXp } from 'src/redux/xp';
import { getPurchaseType } from 'src/utils/myPurchase';
import { purchaseListingVariant } from 'tailwindVariants/components/purchaseListing';
import { PurchaseListingProps } from './PurchaseListing';
import Link from 'next/link';
import { useOcSelector } from 'src/redux/ocStore';
import { ProductInventory } from 'src/helpers/purchaseDetails/LineItemCard';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { LineItemProduct } from 'ordercloud-javascript-sdk';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getOrderStatus } from 'src/helpers/purchaseDetails/PurchaseOrderUtils';
import { formatStorePhoneForDisplay } from 'src/helpers/Form/PhoneField';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import { useTheme } from 'lib/context/ThemeContext';
import ComponentContext from 'lib/context/ComponentContext';
import PurchaseListingCardSkeleton from '../../helpers/purchaseDetails/PurchaseListingCardSkeleton';

interface PurchaseListingOrderItemProps extends Pick<PurchaseListingProps, 'fields'> {
  orderItem: OrderWorksheetWithXP;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsReorderAll: Dispatch<SetStateAction<boolean>>;
  setIsPartiallyAdded: Dispatch<SetStateAction<boolean>>;
  inventoryForAllLineItems:
    | {
        [key: string]: number;
      }[]
    | undefined;
  setInventoryForAllLineItems: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: number;
        }[]
      | undefined
    >
  >;
  productDataLength: number;
}

function PurchaseListingOrderItem({
  orderItem,
  fields,
  setIsModalOpen,
  setIsReorderAll,
  setIsPartiallyAdded,
  setInventoryForAllLineItems,
  productDataLength,
  inventoryForAllLineItems,
}: PurchaseListingOrderItemProps) {
  const {
    myPurchaseOrderItem,
    myPurchaseOrderItemTop,
    myPurchaseOrderItemDateandStatus,
    myPurchaseOrderItemDate,
    myPurchaseOrderItemStatus,
    myPurchaseOrderDetails,
    myPurchaseOrderDetailsLeft,
    myPurchaseOrderDetailsRight,
    myPurchaseOrderDetailLineItem,
    myPurchaseOrderDetailLineLabel,
    myPurchaseOrderWarningText,
    myPurchaseOrderDetailsRightCTAWrapper,
    myPurchaseOrderItemBottom,
    myPurchaseOrderItemCount,
    myPurchaseOrderItemCountLabel,
    myPurchaseOrderListItems,
    phoneNumberWrapper,
    myPurchaseShowLessBar,
    myPurchaseOrderDetailsRightCTAWrapperMobile,
    viewMoreIcon,
    myPurchaseOrderInfoCTA,
    viewMoreWrapper,
    myPurchaseCartIcon,
  } = purchaseListingVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { reorderAll } = useOcCart();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const currOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const pickup: boolean = currOrder?.order?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleView = () => {
    setIsExpanded((prev) => !prev);
  };
  const { getDictionaryValue } = useDictionary();
  // Function to format the date
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const lineItems: LineItemWithXp[] = orderItem?.LineItems?.filter(
    (product: LineItemWithXp) => product?.ProductID !== DiscreteLineItem.TIP
  )?.filter((x) => x?.Product?.xp?.UPC != undefined) as LineItemWithXp[];

  const productData = lineItems?.map((lineItem) => {
    return {
      ...lineItem?.Product,
      quantity: lineItem?.Quantity,
      BasePrice: lineItem?.LineTotal,
      listPrice: lineItem?.xp?.ListPrice,
    };
  });

  const reorderAllLineItems = lineItems?.map((x) => {
    return { ProductId: x.ProductID, Quantity: x?.Quantity };
  });

  const displayedItems = isExpanded ? lineItems : lineItems.slice(0, 4);
  const remainingItemsCount = lineItems.length - 3;
  const isAutoshipOrder = orderItem?.Order?.xp?.IsSubscription;
  const [lineItemInventory, setLineItemInventory] = useState<{ [key: string]: number }[]>();
  const isReorderAllDisabled =
    (lineItemInventory &&
      lineItemInventory.every((obj) => Object.values(obj).every((value) => value <= 0))) ||
    false;

  //Reorder all CTA
  const handleReorderAll = async () => {
    const res = await reorderAll({
      CurrentCardId: currOrder?.order?.ID as string,
      ProductToAdd: reorderAllLineItems as ProductToAddProps,
      currentCartLineItemLength: currOrder?.lineItems?.filter(
        (product) => product?.ProductID !== DiscreteLineItem.TIP
      ).length as number,
      orderFulFillMentType: orderItem?.Order?.xp?.Fulfillment as string,
      currentCartFulFillMentType: currOrder?.order?.xp?.Fulfillment as string,
    });
    if (res?.isDFS) {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
      });
      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
        fromProductListing: true,
      });
    } else if (res?.isFullyAdded) {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
      });
    } else {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: false,
        showMiniCartLoader: false,
      });
      if (res?.isPartiallyAdded) {
        setIsPartiallyAdded && setIsPartiallyAdded(true);
        setIsReorderAll && setIsReorderAll(true);
      }
      if (res?.isAllNotAdded) {
        setIsReorderAll && setIsReorderAll(true);
      }
    }

    const productDataObj = productData?.map((lineItem) => {
      const inventory =
        inventoryForAllLineItems &&
        inventoryForAllLineItems?.find(
          (val) => Object?.keys(val)?.[0] == (lineItem?.ID as string)
        )?.[lineItem?.ID as string];
      return {
        ...lineItem,
        quantity: lineItem?.quantity,
        BasePrice: lineItem?.BasePrice,
        listPrice: lineItem?.listPrice,
        availableInventory: inventory && inventory,
      };
    });
    sendProductsPromotion({
      eventName: GTM_EVENT?.addToCart,
      data: productDataObj as ProductSearchResultModelWithVariants,
      click_from: GTMLabels?.myPurchasesReorderAll,
      storeId: myStoreData?.storeId,
      fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      shouldPreciousDataLaLayerRemoved: true,
      pageItem: GTMLabels?.myPurchases,
      currentPage: GTMLabels?.myPurchases,
    });
  };

  return (
    <>
      <div className={myPurchaseOrderItem()}>
        <div className={myPurchaseOrderItemTop()}>
          <div className={myPurchaseOrderItemDateandStatus()}>
            <p className={myPurchaseOrderItemDate()}>
              {formatDate(orderItem?.Order?.DateSubmitted)}
            </p>
            {/**
             * TODO: need implementation for getting order status
             *  */}
            <p className={myPurchaseOrderItemStatus()}>
              {getOrderStatus({
                orderStatus: orderItem?.Order?.xp?.OrderStatus as string,
                fulFillmentType: orderItem?.Order?.xp?.Fulfillment as string,
                manhattenStatus: orderItem?.Order?.xp?.ManhattenStatus as string,
              })}
            </p>
          </div>
          <div className={myPurchaseOrderDetails()}>
            <div className={myPurchaseOrderDetailsLeft()}>
              <div className={myPurchaseOrderDetailLineItem()}>
                <Text
                  tag="span"
                  className={myPurchaseOrderDetailLineLabel()}
                  field={fields?.orderText}
                />{' '}
                {orderItem?.Order?.xp?.OrderId}
              </div>
              {/* Hide below from desktop */}
              <div className="lg:hidden block">
                <div className={myPurchaseOrderDetailLineItem()}>
                  <Text
                    tag="span"
                    className={myPurchaseOrderDetailLineLabel()}
                    field={fields?.orderTotalText}
                  />{' '}
                  {getDictionaryValue('CurrencySymbol')}
                  {orderItem?.Order?.Total?.toFixed(2)}
                </div>
                {orderItem?.Order?.xp?.EmailContent?.TotalMemberSaving &&
                orderItem?.Order?.xp?.EmailContent?.TotalMemberSaving > 0 ? (
                  <div
                    className={clsx(myPurchaseOrderDetailLineItem(), myPurchaseOrderWarningText())}
                  >
                    <Text
                      tag="span"
                      className={myPurchaseOrderDetailLineLabel()}
                      field={fields?.memberSavingsText}
                    />{' '}
                    {getDictionaryValue('CurrencySymbol')}
                    {orderItem?.Order?.xp?.EmailContent?.TotalMemberSaving?.toFixed(2)}
                  </div>
                ) : null}
              </div>
              {/* Hide above from desktop */}
              <div className={myPurchaseOrderDetailLineItem()}>
                <Text
                  tag="span"
                  className={myPurchaseOrderDetailLineLabel()}
                  field={fields?.purchaseTypeText}
                />{' '}
                {getPurchaseType(
                  orderItem?.Order?.xp?.Fulfillment as string,
                  orderItem?.Order?.xp?.IsSubscription as boolean
                )}
              </div>
              <div className={clsx(myPurchaseOrderDetailLineItem(), 'flex flex-row')}>
                <Text
                  tag="span"
                  className={myPurchaseOrderDetailLineLabel()}
                  field={fields?.storeLocationText}
                />
                <p className="pl-1">
                  {orderItem?.LineItems && orderItem?.LineItems?.length > 0 ? (
                    <>
                      <span className="block">
                        {orderItem.LineItems[0]?.ShipFromAddress?.Street1}
                      </span>
                      <span className="block">
                        {orderItem.LineItems[0]?.ShipFromAddress?.Street2 || ''}
                      </span>
                      <span className="block">
                        {orderItem.LineItems[0]?.ShipFromAddress?.City},
                        {orderItem.LineItems[0]?.ShipFromAddress?.Zip}
                      </span>
                      <span className="block">
                        <LinkHelper
                          className={phoneNumberWrapper()}
                          field={{
                            value: {
                              text: orderItem.LineItems[0]?.ShipFromAddress?.Phone,
                              title: orderItem.LineItems[0]?.ShipFromAddress?.Phone,
                              href: `tel:${formatStorePhoneForDisplay(
                                orderItem.LineItems[0]?.ShipFromAddress?.Phone
                              )}`,
                            },
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    'Address not available'
                  )}
                </p>
              </div>
            </div>
            <div className={myPurchaseOrderDetailsRight()}>
              {/* Hide below from mobile */}
              <div className={clsx('hidden', 'lg:flex', 'lg:flex-col', 'items-end')}>
                <div className={myPurchaseOrderDetailLineItem({ className: 'w-max' })}>
                  <Text
                    tag="span"
                    className={myPurchaseOrderDetailLineLabel()}
                    field={fields?.orderTotalText}
                  />{' '}
                  {getDictionaryValue('CurrencySymbol')}
                  {orderItem?.Order?.Total?.toFixed(2)}
                </div>
                {orderItem?.Order?.xp?.EmailContent?.TotalMemberSaving &&
                orderItem?.Order?.xp?.EmailContent?.TotalMemberSaving > 0 ? (
                  <div
                    className={clsx(
                      myPurchaseOrderDetailLineItem({ className: 'w-max' }),
                      myPurchaseOrderWarningText()
                    )}
                  >
                    <Text
                      tag="span"
                      className={myPurchaseOrderDetailLineLabel()}
                      field={fields?.memberSavingsText}
                    />{' '}
                    {getDictionaryValue('CurrencySymbol')}
                    {orderItem?.Order?.xp?.EmailContent?.TotalMemberSaving?.toFixed(2)}
                  </div>
                ) : null}
              </div>

              {/* Hide above from mobile */}
              <div
                className={myPurchaseOrderDetailsRightCTAWrapper({
                  className: 'w-full max-w-[220px]',
                })}
              >
                <ButtonHelper
                  isDisabled={isReorderAllDisabled}
                  field={fields?.reorderAll}
                  className={myPurchaseOrderInfoCTA()}
                  isLinkField={true}
                  size="medium"
                  variant={isReorderAllDisabled ? 'disabled' : 'primary'}
                  onClickHandler={async (e) => {
                    e?.preventDefault();
                    !isReorderAllDisabled && (await handleReorderAll());
                  }}
                />
                <ButtonHelper
                  field={{
                    value: {
                      ...fields?.viewDetails?.value,
                      href: fields?.viewDetails?.value?.href + '#' + orderItem?.Order?.xp?.OrderId,
                    },
                  }}
                  className={myPurchaseOrderInfoCTA()}
                  isLinkField={true}
                  asButton={true}
                  size="medium"
                  variant={'secondary'}
                />
                {isAutoshipOrder && (
                  <ButtonHelper
                    field={{
                      value: {
                        ...fields?.manageAutoship?.value,
                        href:
                          fields?.manageAutoship?.value?.href +
                          '#' +
                          orderItem?.Order?.SubscriptionID,
                      },
                    }}
                    className={myPurchaseOrderInfoCTA()}
                    isLinkField={true}
                    asButton={true}
                    size="medium"
                    variant={'secondary'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={myPurchaseOrderItemBottom()}>
          <RichText tag="span" field={fields?.historicalPriceNoteText}></RichText>
          {/**Show Total lineItems */}
          <div className={myPurchaseOrderItemCount()}>
            <span className={myPurchaseOrderItemCountLabel()}>
              {lineItems?.reduce((acc, item) => acc + (item?.Quantity || 0), 0)}
            </span>{' '}
            <Text tag="span" field={fields?.itemsText} />
          </div>
          <div className={myPurchaseOrderListItems()}>
            {displayedItems.map((lineItem, index) => (
              <ProductLineItem
                setLineItemInventory={setLineItemInventory}
                key={lineItem?.ID}
                index={index}
                item={lineItem}
                fields={fields}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                remainingItemsCount={remainingItemsCount}
                setIsModalOpen={setIsModalOpen}
                productDataLength={productDataLength}
                setInventoryForAllLineItems={setInventoryForAllLineItems}
              />
            ))}
          </div>
          {/* Show "Show Less" button in expanded view */}
          {isExpanded && (
            <React.Fragment>
              <div className={myPurchaseShowLessBar()} onClick={toggleView}>
                <button className="show-less-button flex mx-auto">
                  {' '}
                  <IconHelper icon={'chevron-up'} className={viewMoreIcon()} />
                </button>
              </div>
              <div
                className={viewMoreWrapper({ className: 'mt-mob-margin-tight-top' })}
                onClick={toggleView}
              >
                <Text tag="span" field={fields?.viewLessText} />
                <IconHelper
                  icon="Caret"
                  className={myPurchaseCartIcon({
                    className: '[&>svg>*]:fill-color-background-brand-1 rotate-180',
                  })}
                />
              </div>
            </React.Fragment>
          )}
          {/* For mobile use only */}
          <div className={myPurchaseOrderDetailsRightCTAWrapperMobile()}>
            <ButtonHelper
              field={fields?.reorderAll}
              isDisabled={isReorderAllDisabled}
              className={myPurchaseOrderInfoCTA()}
              isLinkField={true}
              size="medium"
              variant={isReorderAllDisabled ? 'disabled' : 'primary'}
              onClickHandler={async (e) => {
                e?.preventDefault();
                !isReorderAllDisabled && (await handleReorderAll());
              }}
            />
            <ButtonHelper
              field={{
                value: {
                  ...fields?.viewDetails?.value,
                  href: fields?.viewDetails?.value?.href + '#' + orderItem?.Order?.xp?.OrderId,
                },
              }}
              className={myPurchaseOrderInfoCTA()}
              isLinkField={true}
              asButton={true}
              size="medium"
              variant={'secondary'}
            />
            <ButtonHelper
              field={{
                value: {
                  ...fields?.manageAutoship?.value,
                  href:
                    fields?.manageAutoship?.value?.href + '#' + orderItem?.Order?.SubscriptionID,
                },
              }}
              className={myPurchaseOrderInfoCTA()}
              isLinkField={true}
              asButton={true}
              size="medium"
              variant={'secondary'}
            />
          </div>
        </div>
      </div>
    </>
  );
}

//LineItems
const ProductLineItem = ({
  item,
  index,
  setIsExpanded,
  remainingItemsCount,
  isExpanded,
  fields,
  setIsModalOpen,
  setLineItemInventory,
  setInventoryForAllLineItems,
  productDataLength,
}: {
  item: LineItemWithXp;
  index: number;
  remainingItemsCount: number;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setLineItemInventory: Dispatch<SetStateAction<{ [key: string]: number }[] | undefined>>;
  setInventoryForAllLineItems: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: number;
        }[]
      | undefined
    >
  >;
  productDataLength: number;
} & Pick<PurchaseListingProps, 'fields'>) => {
  const {
    myPurchaseOrderItemDate,
    myPurchaseOrderListItemImage,
    myPurchaseOrderListItemCard,
    myPurchaseOrderListItemCardImg,
    myPurchaseOrderListItemCardInfo,
    myPurchaseOrderListItemBottomWrapper,
    myPurchaseOrderListItemQTYandPrice,
    myPurchaseLabelRegular,
    myPurchaseItemAutoshipPrice,
    myPurchaseItemMemberPrice,
    myPurchaseItemListPrice,
    myPurchaseCartIcon,
    myPurchaseUnavailableProductWrapper,
    myPurchaseUnavailableProductMark,
    myPurchaseOrderListItemCardShowMore,
    myPurchaseOrderListItemCardShowMoreNumber,
    viewMoreWrapper,
  } = purchaseListingVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { themeName } = useTheme();
  const { boldLabels, colorTextBrand, colorLowStock, colorOutOfStock } = purchaseDetailsVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    theme: themeName,
  });

  const { purchaseOrderAddToCart, getProductAvailability } = useOcCart();
  const { getDictionaryValue } = useDictionary();
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const currOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const pickup: boolean = currOrder?.order?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const [productInventory, setProductInventory] = useState<ProductInventory>({});
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const eCommerceDisabled = myStoreData?.ecommerceDisabled;
  const image = getFirstProductImage(item?.Product?.xp?.Images);
  const productURL = getProductDetailPath(item?.Product as BuyerProductWithXp);

  //if product is website excluded
  const isUnavailable = item?.Product?.xp?.WE === true;

  //is product Out Of Stock or not
  const isOutOfStock =
    (productInventory?.productInventory as number) < 1 ||
    isNaN(productInventory?.productInventory as number);

  //disabling add to cart if eCommerce disabled or quantity less than 1 or product is website excluded
  const isAddToCartDisable = isOutOfStock || eCommerceDisabled === 1 || isUnavailable;

  //GTM event for selection an item and Add to cart
  const sendSelectionGTMEvent = ({
    lineItem,
    eventName,
  }: {
    lineItem: LineItemProduct<ProductXp>;
    eventName: string;
  }) => {
    sendProductsPromotion({
      eventName: eventName,
      data: lineItem as ProductSearchResultModelWithVariants,
      listPrice: Number(item?.xp?.ListPrice?.toFixed(2)),
      quantity: item?.Quantity,
      ...(eventName === GTM_EVENT?.addToCart && { click_from: GTMLabels?.myPurchases }),
      ...(eventName === GTM_EVENT?.addToCart && { storeId: myStoreData?.storeId }),
      ...(eventName === GTM_EVENT?.addToCart && {
        fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      }),
      pageItem: GTMLabels?.myPurchases,
      currentPage: GTMLabels?.myPurchases,
      shouldPreciousDataLaLayerRemoved: true,
      availableInventory: productInventory?.productInventory,
    });
  };

  const handleLineItemInventory = (productId: string, inventory: number) => {
    setLineItemInventory((prev) => {
      const newInventoryValue = Number(inventory);
      if (!productId) {
        return prev;
      }
      if (isNaN(newInventoryValue) || !productId) {
        return [{ [productId]: 0 }];
      }
      if (!prev) {
        return [{ [productId]: newInventoryValue }];
      }
      const exists = prev.some((obj) => productId in obj);
      if (exists) {
        return prev.map((obj) => {
          if (productId in obj) {
            return { [productId]: newInventoryValue };
          }
          return obj;
        });
      }

      return [...prev, { [productId]: newInventoryValue }];
    });

    setInventoryForAllLineItems((prev) => {
      const newInventoryValue = inventory;
      if (!productId) {
        return prev;
      }

      if (prev?.length && prev?.length >= productDataLength) {
        return prev;
      }
      if (!prev) {
        return [{ [productId]: newInventoryValue }];
      }

      return [...prev, { [productId]: newInventoryValue }];
    });
  };
  //set initial inventory for all the lineItems
  useEffect(() => {
    const getProductInventory = async () => {
      const data = await getProductAvailability(
        item?.Product?.ID as string,
        myStoreData?.storeId,
        myStoreData?.deliveryFee?.lowStock
      );
      data && setProductInventory(data);
      data && handleLineItemInventory(item?.Product?.ID as string, data?.productInventory);

      if (item?.Product?.xp?.WE) {
        handleLineItemInventory(item?.Product?.ID as string, 0);
      }
    };
    getProductInventory();
  }, [currOrder?.lineItems]);

  //expanding cards
  const toggleView = () => {
    setIsExpanded((prev) => !prev);
  };

  if (!isExpanded && index === 3 && remainingItemsCount > 0) {
    // Render the card with the remaining items count
    return (
      <React.Fragment>
        <div
          key={`remaining-${index}`}
          className={clsx(
            myPurchaseOrderListItemCard({ className: 'lg:!mb-0' }),
            myPurchaseOrderListItemCardShowMore()
          )}
          onClick={toggleView}
        >
          <div
            className={clsx(
              myPurchaseOrderListItemCardInfo(),
              '!flex !items-center !justify-center !py-0'
            )}
          >
            <p
              className={myPurchaseOrderListItemCardShowMoreNumber()}
            >{`${remainingItemsCount}+`}</p>
          </div>
        </div>

        <div className={viewMoreWrapper()} onClick={toggleView}>
          <Text tag="span" field={fields?.viewMoreText} />
          <IconHelper
            icon="Caret"
            className={myPurchaseCartIcon({ className: '[&>svg>*]:fill-color-background-brand-1' })}
          />
        </div>
      </React.Fragment>
    );
  }

  //set inventory color based on availability e.g. low stock - yellow
  const stockColor =
    productInventory?.availability === productAvailability.OUT ||
    isNaN(productInventory?.productInventory as number)
      ? colorOutOfStock()
      : productInventory?.availability === productAvailability.LOW
      ? colorLowStock()
      : colorTextBrand();

  const isMemberPrice =
    item?.xp?.ListPrice && item?.xp?.MemberPrice && item?.xp?.MemberPrice < item?.xp?.ListPrice;

  const handleAddToCart = async () => {
    try {
      setIsAddingToCart(true);
      const res = await purchaseOrderAddToCart(
        item?.Product?.ID as string,
        item?.Quantity as number,
        myStoreData?.storeId,
        myStoreData?.deliveryFee?.lowStock as number
      );
      item?.Product &&
        sendSelectionGTMEvent({
          lineItem: item?.Product,
          eventName: GTM_EVENT?.addToCart,
        });
      if (res?.inventoryStatus == productAvailability?.LOW) {
        setHeaderContextData({
          ...headerContextData,
          openMiniCart: false,
          showMiniCartLoader: false,
        });
        setIsModalOpen(true);
      }
    } catch (e) {
      console.error('@@error add to cart: ', e);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const isAutoShipPrice =
    item?.xp?.Autoship &&
    item?.xp?.MemberPrice &&
    item?.UnitPrice &&
    item?.UnitPrice < item?.xp?.MemberPrice;

  const finalPrice = isMemberPrice
    ? item?.xp?.MemberPrice
    : isAutoShipPrice
    ? item?.UnitPrice
    : item?.xp?.ListPrice;

  const priceToRender =
    item?.LineTotal && finalPrice && finalPrice > item?.LineTotal ? item?.LineTotal : finalPrice;

  if (productInventory && productInventory?.productInventory === undefined) {
    return <PurchaseListingCardSkeleton key={item?.ProductID} />;
  }

  return (
    <div className={myPurchaseOrderListItemCard()}>
      <div className="flex items-center">
        <div className={myPurchaseOrderListItemCardImg()}>
          <LinkHelper
            className={`relative ${isUnavailable && '!cursor-not-allowed'}`}
            field={{ value: { href: productURL } }}
            onClick={(e) => {
              isUnavailable && e.preventDefault();
              sendSelectionGTMEvent({
                lineItem: item.Product as LineItemProduct<ProductXp>,
                eventName: GTM_EVENT?.selectItem,
              });
            }}
          >
            <ImageHelper
              className={myPurchaseOrderListItemImage()}
              field={{
                value: {
                  src: image?.Url,
                  alt: item?.Product?.Name,
                  width: 140,
                  height: 140,
                },
              }}
            />
            {isUnavailable && (
              <div className={myPurchaseUnavailableProductWrapper()}>
                <Text
                  tag="span"
                  className={myPurchaseUnavailableProductMark()}
                  field={fields?.unAvailableText}
                />
              </div>
            )}
          </LinkHelper>
        </div>
      </div>
      <div className="w-full h-full flex flex-col justify-between">
        <div className={myPurchaseOrderListItemCardInfo()}>
          <Link
            href={productURL}
            className={`hover:underline ${
              isUnavailable && 'hover:!no-underline !cursor-not-allowed'
            }`}
            onClick={(e) => {
              isUnavailable && e.preventDefault();
              sendSelectionGTMEvent({
                lineItem: item.Product as LineItemProduct<ProductXp>,
                eventName: GTM_EVENT?.selectItem,
              });
            }}
          >
            <p>{item?.Product?.Name}</p>
          </Link>
        </div>
        <div className="">
          <div className={myPurchaseOrderListItemBottomWrapper()}>
            <div className={myPurchaseOrderListItemQTYandPrice()}>
              <Text className={myPurchaseLabelRegular()} field={fields?.quantityText}></Text>
              {item?.Quantity}
              <p
                className={clsx(
                  myPurchaseOrderItemDate(),
                  item?.xp?.Autoship
                    ? myPurchaseItemAutoshipPrice()
                    : !isMemberPrice
                    ? myPurchaseItemListPrice()
                    : myPurchaseItemMemberPrice()
                )}
              >
                {getDictionaryValue('CurrencySymbol')}
                {priceToRender?.toFixed(2)}
              </p>
              <IconHelper
                className={myPurchaseCartIcon({
                  className: `${
                    isAddingToCart || isAddToCartDisable
                      ? 'pointer-event-none [&>svg>*>*]:fill-color-brand-primary-1-disabled'
                      : ''
                  }`,
                })}
                icon={'shopping-cart'}
                onClick={async () => {
                  if (!isAddToCartDisable) {
                    setIsAddingToCart(true);
                    const res = await purchaseOrderAddToCart(
                      item?.Product?.ID as string,
                      item?.Quantity as number,
                      myStoreData?.storeId,
                      myStoreData?.deliveryFee?.lowStock as number
                    );
                    item?.Product &&
                      sendSelectionGTMEvent({
                        lineItem: item?.Product,
                        eventName: GTM_EVENT?.addToCart,
                      });
                    if (res?.inventoryStatus == productAvailability?.LOW) {
                      setHeaderContextData({
                        ...headerContextData,
                        openMiniCart: false,
                        showMiniCartLoader: false,
                      });
                      setIsModalOpen(true);
                    }
                    setIsAddingToCart(false);
                  }
                }}
              />
            </div>
          </div>
          <div className={'flex flex-col items-start lg:items-center gap-2'}>
            {!isUnavailable ? (
              <span className={boldLabels({ className: stockColor })}>
                {getDictionaryValue(
                  isOutOfStock ? productAvailability.OUT : productInventory?.availability || ''
                )}
              </span>
            ) : (
              <Text tag="span" field={fields?.unAvailableText} className={boldLabels()} />
            )}
            <ButtonHelper
              field={{
                value: `${
                  isAddingToCart
                    ? getDictionaryValue('AddingItemToCart')
                    : fields?.addToCartCTAText?.value
                }`,
              }}
              className={clsx(
                'w-full text-center lg:block hidden whitespace-nowrap lg:px-mob-global-spacing-spacing-3 xl:g:px-desk-global-spacing-spacing-5',
                isAddToCartDisable || isAddingToCart
                  ? 'bg-color-brand-primary-1-disabled hover:bg-color-brand-primary-1-disabled pointer-not-allowed'
                  : ''
              )}
              isLinkField={false}
              asButton={true}
              size="medium"
              variant={'primary'}
              isDisabled={isAddToCartDisable}
              onClickHandler={async () => {
                await handleAddToCart();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseListingOrderItem;
