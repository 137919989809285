import { tv } from 'tailwind-variants';

export const purchaseDetailsVariant = tv(
  {
    slots: {
      componentWrapper: ['purchaseDetails', 'container', 'flex-col', 'gap-desk-margin-base-bottom'],
      sectionWrapper: [
        'sectionWrapper',
        'bg-color-background-light',
        'rounded-desk-global-radius-medium',
      ],
      detailsAndLineItemsWrapper: ['detailsAndLineItemsWrapper', 'flex-col'],
      purchaseLineItemsWrapper: ['purchaseLineItemsWrapper'],
      topWrapper: ['topWrapper'],
      pageTitle: [''],
      printButton: ['printReceipt', 'w-fit', 'rounded-mob-global-radius-small'],
      detailSection: ['detailSection'],
      topDetailsWrapper: ['topDetailsWrapper', 'flex-col'],
      orderDate: [
        'text-heading-mob-large-bold',
        'font-heading-mob-large-bold',
        'leading-heading-mob-large-bold',
      ],
      orderSummary: [
        'orderSummary',
        'lg:px-desk-global-spacing-spacing-4',
        '[&>*]:!rounded-0',
        'mb-desk-global-spacing-spacing-4',
      ],
      orderSummaryWrapper: [
        'orderSummaryWrapper',
        'bg-color-background-white',
        'px-desk-padding-micro-x',
        'xl:rounded-desk-global-radius-medium',
        'h-fit',
        'w-full',
      ],
      printOrderSummary: [
        'printOrderSummary',
        'hidden',
        'pr-desk-global-spacing-spacing-7',
        'ml-auto',
        'max-w-[320px]',
        'mt-desk-global-spacing-spacing-7',
      ],
      summaryTitle: [''],
      orderSummaryDetails: [''],
      orderRaw: ['py-[8px]', 'flex-col', 'gap-[8px]', 'border-b-[1px]', 'border-color-border-mid'],
      orderInnerRow: ['justify-between'],
      orderStatus: [''],
      boldLabels: [''],
      smallRegularLabel: [
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      smallBoldLabel: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      autoShipLabel: [
        'bg-color-brand-autoship',
        'rounded-mob-global-radius-small',
        'px-desk-global-spacing-spacing-2',
        'text-color-text-white',
        'w-fit',
      ],
      colorBgBrand: ['bg-color-brand-primary-1-base'],
      colorBgLight: ['bg-color-background-light'],
      colorTextBrand: ['text-color-brand-primary-1-base'],
      colorLowStock: ['text-color-global-ratings'],
      colorOutOfStock: ['text-color-global-outof-stock'],
      colorRed: ['text-system-red'],
      strike: ['line-through'],
      colorBgAutoShip: ['bg-color-brand-autoship'],
      regularLabels: [
        'text-body-regular-bold',
        'font-body-regular-bold',
        'leading-body-regular-bold',
      ],
      productTitle: [
        'productTitle',
        'text-heading-mob-medium-bold',
        'font-heading-mob-medium-bold',
        'leading-heading-mob-medium-bold',
      ],
      dateWrapper: ['justify-between', 'border-b-[1px]', 'border-color-border-mid'],
      orderDetailsWrapper: [''],
      detailsWrapper: ['grid', 'w-full'],
      detailsLeft: ['flex-col'],
      detailsRight: ['flex-col'],
      listWrapper: ['gap-[6px]'],
      detailsButtonWrapper: [
        'flex-col',
        'gap-desk-global-spacing-spacing-2',
        'pt-desk-global-spacing-spacing-2',
      ],
      reOrderAllButton: ['text-center'],
      autoshipButton: ['text-center'],
      PurchaseLineItemsWrapper: ['rounded-mob-global-radius-small', 'bg-color-scale-1-white'],
      totalItems: [
        'totalItems',
        'w-full',
        'justify-end',
        'border-b-[1px]',
        'border-color-border-mid',
        'gap-mob-global-spacing-spacing-1',
      ],
      OrderLineItemsWrapper: [''],
      LineItemCardWrapper: [
        'LineItemCardWrapper',
        'relative',
        'border-b-[1px]',
        'border-color-border-mid',
      ],
      ImageWrapper: [
        'ImageWrapper',
        'w-full',
        'flex-col',
        'gap-desk-global-spacing-spacing-2',
        'justify-center',
        'items-center',
        'relative',
      ],
      ImageAndPriceIconWrapper: [
        'ImageAndPriceIconWrapper',
        'w-full',
        'justify-center',
        'items-center ',
        'relative',
      ],
      imageTagWrapper: [
        'imageTagWrapper',
        'absolute',
        'left-0',
        ' top-0',
        'flex-col',
        'gap-[6px]',
        'p-[inherit]',
      ],
      saleLabel: [
        'saleLabel',
        'font-primary',
        'z-[2]',
        'text-body-small-bold',
        'leading-body-small-bold',
        'font-body-small-bold ',
        'uppercase',
        'text-color-text-white',
        'px-desk-global-spacing-spacing-2',
        'py-desk-global-spacing-spacing-1 ',
        'bg-pdp-red',
      ],
      retailTagWrapper: ['retailTagWrapper', 'absolute', 'p-[inherit]'],
      retailTag: [
        'retailTag',
        'bg-color-brand-primary-1-base px-desk-global-spacing-spacing-1 text-body-small-bold font-body-small-bold leading-body-small-bold text-white',
      ],
      retailTagSVG: ['retailTagSVG', '[&>svg>*]:fill-color-brand-primary-1-base'],
      imageAndPriceWrapper: [
        'grow',
        'imageAndPriceWrapper',
        'gap-desk-space-between-base-horizontal',
        'flex-col',
        'lg:flex-row',
        'items-start',
      ],
      itemPromo: [
        'itemPromo',
        'px-desk-global-spacing-spacing-4',
        'text-color-text-white',
        'bg-[#D1362E]',
        'rounded-desk-global-radius-x-small',
      ],
      itemUnavailable: [
        // 'bg-[rgba(35,31,31,0.5)]',
        'itemUnavailable',
        'absolute',
        'text-color-text-white',
        'px-desk-global-spacing-spacing-4',
        'py-desk-global-spacing-spacing-3',
        'top-1/2',
        'left-1/2',
        'translate-x-[-50%]',
        'translate-y-[-50%]',
        'text-center',
        'w-full',
        'max-w-[140px]',
      ],
      favoriteIcon: ['favoriteIcon', 'absolute', 'p-[inherit]'],
      heartIcon: [
        'heartIcon',
        '[&>svg>*>*]:fill-color-brand-primary-1-base',
        '[&>svg]:max-w-[20px] ',
      ],
      phoneNumber: [
        'block',
        // 'mt-desk-global-spacing-spacing-3',
        'focus:outline-0',
        'underline',
        'text-color-brand-primary-1-base',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      productNameAndPrice: ['productNameAndPrice', 'flex-col', 'w-full', 'h-full'],
      printOrderTopSection: ['printOrderTopSection', 'hidden'],
      printPriceWrapper: ['printPriceWrapper', 'hidden', 'flex-col'],
      printQtyPriceWrapper: ['printQtyPriceWrapper', 'justify-between', '[&>div]:!w-fit'],
      printOrderLabel: [
        'printOrderLabel',
        'w-full',
        'text-color-text-white',
        'my-desk-global-spacing-spacing-5',
        'py-desk-global-spacing-spacing-3',
        'text-uppercase',
        'text-center',
      ],
      printOrderDetails: ['printOrderDetails', 'flex-col', 'gap-desk-global-spacing-spacing-5'],
      printOrderInfo: ['px-desk-global-spacing-spacing-9', 'py-desk-global-spacing-spacing-5'],
      printAddressAndCodeWrapper: [''],
      printStoreDetail: ['w-full', 'justify-between', 'items-center'],
      printStoreDetailLabel: [''],
      priceAndReviewWrapper: ['priceAndReviewWrapper', 'w-full'],
      priceWrapper: ['priceWrapper', 'flex-col', 'w-fit', 'grow'],
      price: ['w-full'],
      bvRatingWrapper: ['bvRatingWrapper', 'mt-auto', 'w-fit'],
      reviewWrapper: [
        'flex',
        'flex-col',
        'mt-2',
        'lg:mt-0',
        'font-primary',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      addToCartWrapper: ['addToCartWrapper', 'items-center', 'flex-col'],
      addToCartBtn: [
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        'bg-color-brand-primary-1-base',
        'text-color-text-white',
        'text-center',
        'whitespace-nowrap',
        'font-heading-desk-medium-bold',
        'text-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
    },
    compoundSlots: [
      {
        slots: [
          'componentWrapper',
          'topWrapper',
          'detailsAndLineItemsWrapper',
          'dateWrapper',
          'topDetailsWrapper',
          'sectionWrapper',
          'orderDetailsWrapper',
          'detailsLeft',
          'detailsRight',
          'listWrapper',
          'detailsButtonWrapper',
          'totalItems',
          'ImageWrapper',
          'imageTagWrapper',
          'imageAndPriceWrapper',
          'addToCartWrapper',
          'priceAndReviewWrapper',
          'retailTagWrapper',
          'productNameAndPrice',
          'priceWrapper',
          'price',
          'LineItemCardWrapper',
          'ImageAndPriceIconWrapper',
          'printQtyPriceWrapper',
          'printOrderDetails',
          'printStoreDetail',
          'printOrderInfo',
          'orderRaw',
          'orderInnerRow',
        ],
        className: ['flex'],
      },
      {
        slots: ['boldLabels', 'orderStatus'],
        className: ['text-body-large-bold', 'font-body-large-bold', 'leading-body-large-bold'],
      },
    ],
    variants: {
      size: {
        mobile: {
          componentWrapper: ['pt-mob-margin-loose-right', 'pb-mob-padding-loose-bottom'],
          topWrapper: ['flex-col', 'justify-normal', 'gap-mob-global-spacing-spacing-4'],
          sectionWrapper: ['px-mob-global-spacing-spacing-4', 'py-mob-global-spacing-spacing-4'],
          pageTitle: [
            'text-heading-mob-xLarge-bold ',
            'font-heading-mob-xLarge-bold ',
            'leading-heading-mob-xLarge-bold',
          ],
          printButton: [
            'text-color-text-white',
            'text-body-large-bold',
            'font-body-large-bold',
            'leading-body-large-bold',
            'px-mob-component-button-condensed-padding-x',
            'py-mob-component-button-condensed-padding-y',
          ],
          detailSection: ['pb-mob-padding-micro-y', 'px-0'],
          topDetailsWrapper: ['px-mob-global-spacing-spacing-2'],
          orderDetailsWrapper: ['flex-col'],
          detailsWrapper: ['grid-cols-1'],
          autoshipButton: ['!py-mob-component-button-condensed-padding-y'],
          reOrderAllButton: ['!py-mob-component-button-condensed-padding-y'],
          dateWrapper: ['pb-mob-padding-micro-bottom'],
          detailsLeft: ['pt-mob-global-spacing-spacing-2'],
          detailsRight: [''],
          ImageAndPriceWrapper: ['flex-col'],
          productNameAndPrice: ['gap-desk-space-between-base-vertical'],
          PurchaseLineItemsWrapper: ['pt-mob-global-spacing-spacing-2'],
          addToCartWrapper: ['gap-mob-global-spacing-spacing-3'],
          totalItems: ['py-mob-global-spacing-spacing-3', 'px-mob-global-spacing-spacing-4'],
          LineItemCardWrapper: [
            'flex-col',
            'py-mob-padding-tight-y',
            'px-mob-padding-micro-x',
            'gap-mob-margin-base-bottom',
          ],
          priceWrapper: ['px-mob-global-spacing-spacing-7', 'gap-mob-global-spacing-spacing-2'],
          price: ['justify-between'],
          favoriteIcon: ['top-[16px]', 'right-[24px]'],
          retailTagWrapper: ['right-[-15px]', 'top-[95px]'],
          addToCartBtn: [
            'w-full',
            'py-mob-component-button-full-padding-y',
            'px-mob-component-button-full-padding-x',
          ],
          orderSummary: ['-mx-mob-global-spacing-spacing-4'],
          orderSummaryWrapper: ['py-mob-padding-micro-y'],
          summaryTitle: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          printStoreDetail: ['px-desk-global-spacing-spacing-5'],
          printQtyPriceWrapper: ['gap-mob-global-spacing-spacing-4'],
          printStoreDetailLabel: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leafing-heading-mob-xLarge-bold',
          ],
          reviewWrapper: ['gap-mob-space-between-tight-horizontal'],
        },
        desktop: {
          componentWrapper: ['py-desk-padding-tight-y'],
          topWrapper: ['flex-row', 'justify-between'],
          pageTitle: [
            'text-heading-desk-xLarge-bold ',
            'font-heading-desk-xLarge-bold ',
            'leading-heading-desk-xLarge-bold',
          ],
          printButton: [
            'py-desk-component-button-condensed-padding-y',
            'px-desk-component-button-condensed-padding-x',
          ],
          detailSection: ['py-desk-global-spacing-spacing-4', 'px-desk-global-spacing-spacing-6'],
          purchaseLineItemsWrapper: ['px-desk-global-spacing-spacing-4', 'max-w-[906px]'],
          topDetailsWrapper: [''],
          detailsWrapper: ['grid-cols-3', 'gap-desk-global-spacing-spacing-5'],
          dateWrapper: ['pb-desk-padding-micro-bottom'],
          orderDetailsWrapper: ['flex-row'],
          detailsLeft: ['grow', 'w-full', 'order-1', 'items-end'],
          detailsButtonWrapper: ['order-3'],
          detailsRight: ['grow', 'w-full'],
          ImageAndPriceWrapper: ['flex-row'],
          autoshipButton: ['!py-desk-component-button-condensed-padding-y'],
          reOrderAllButton: ['!py-desk-component-button-condensed-padding-y'],
          PurchaseLineItemsWrapper: ['pt-0', 'px-desk-global-spacing-spacing-4'],
          totalItems: ['py-desk-global-spacing-spacing-3', 'px-0'],
          LineItemCardWrapper: ['flex-row', 'p-0', 'gap-0'],
          favoriteIcon: ['top-0', 'right-0'],
          retailTagWrapper: ['right-[0px]', 'top-[110px]'],
          productTitle: ['pr-[53px]'],
          ImageAndPriceIconWrapper: ['py-desk-global-spacing-spacing-7', 'max-w-[218px]'],
          productNameAndPrice: [
            'relative',
            'py-desk-global-spacing-spacing-7',
            'pr-desk-space-between-base-horizontal',
            'gap-desk-global-spacing-spacing-2',
            'border-r-[1px]',
            'border-color-border-mid',
          ],
          priceWrapper: ['flex-col', 'px-0', 'gap-desk-global-spacing-spacing-1'],
          price: ['flex-col'],
          addToCartWrapper: [
            'gap-desk-space-between-tight-vertical',
            'pl-desk-global-spacing-spacing-5',
            'py-desk-global-spacing-spacing-7',
          ],
          addToCartBtn: [
            'max-w-[185px]',
            'w-full',
            'overflow-hidden',
            'truncate',
            'text-center',
            'w-fit',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
          ],
          orderSummary: ['-mx-0'],
          orderSummaryWrapper: ['py-desk-padding-micro-y', 'max-w-[342px]'],
          summaryTitle: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          printStoreDetail: ['px-desk-global-spacing-spacing-7'],
          printStoreDetailLabel: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          reviewWrapper: ['gap-desk-space-between-tight-horizontal', 'items-end'],
        },
      },
      theme: {
        psp: {},
        wnw: {
          addToCartBtn: [
            'font-heading-desk-small-bold',
            'text-heading-desk-small-bold',
            'leading-heading-desk-small-bold',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg', 'xl'],
  }
);
