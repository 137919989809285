import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { BuyerProductWithXp, LineItemWithXp, ProductXp } from 'src/redux/xp';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import ImageHelper from '../commonComponents/ImageHelper';
import { getFirstProductImage } from '../productDetailHelper';
import {
  FulfillmentType,
  getProductDetailPath,
  getProductPromoTag,
  GTM_EVENT,
  GTMLabels,
  productAvailability,
} from '../Constants';
import LinkHelper from '../commonComponents/LinkHelper';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import useDictionary from 'src/hooks/useDictionary';
import IconHelper from '../commonComponents/IconHelper';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import ComponentContext from 'lib/context/ComponentContext';
import { LineItemProduct } from 'ordercloud-javascript-sdk';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import Link from 'next/link';
import clsx from 'clsx';
import useOcCart from 'src/hooks/useOcCart';
import BazarVoiceReviews from 'components/BazarVoice/BazarVoiceReviews';
import { useBreakpoints } from 'src/utils/breakpoints';
import { PurchaseLineItemsProps } from './PurchaseLineItems';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from '../search/SearchResults/types';
import { useTheme } from 'lib/context/ThemeContext';
import PurchaseListingCardSkeleton from 'src/helpers/purchaseDetails/PurchaseListingCardSkeleton';
import { useRouter } from 'next/router';
export interface LineItemCardProps extends Pick<PurchaseLineItemsProps, 'fields'> {
  item: LineItemWithXp;
  index: number;
  lineItemsLength: number;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  lineItemInventory?:
    | {
        [key: string]: number;
      }[]
    | undefined;
  setInventoryForAllLineItems: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: number;
        }[]
      | undefined
    >
  >;
  setLineItemInventory: Dispatch<SetStateAction<{ [key: string]: number }[] | undefined>>;
}
export interface ProductInventory {
  productInventory?: number;
  availability?: productAvailability;
  inventoryRecordId?: string;
}

const LineItemCard: React.FC<LineItemCardProps> = ({
  item,
  index,
  lineItemsLength,
  fields,
  setIsModalOpen,
  setLineItemInventory,
  setInventoryForAllLineItems,
}) => {
  const { themeName } = useTheme();
  const {
    LineItemCardWrapper,
    ImageWrapper,
    imageAndPriceWrapper,
    itemPromo,
    itemUnavailable,
    imageTagWrapper,
    retailTagWrapper,
    retailTag,
    retailTagSVG,
    favoriteIcon,
    heartIcon,
    productTitle,
    ImageAndPriceIconWrapper,
    productNameAndPrice,
    printPriceWrapper,
    printQtyPriceWrapper,
    priceAndReviewWrapper,
    bvRatingWrapper,
    reviewWrapper,
    priceWrapper,
    price,
    boldLabels,
    autoShipLabel,
    colorRed,
    colorTextBrand,
    colorLowStock,
    colorOutOfStock,
    strike,
    addToCartWrapper,
    addToCartBtn,
    regularLabels,
  } = purchaseDetailsVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    theme: themeName,
  });

  const dispatch = useOcDispatch();
  const { getProductAvailability, purchaseOrderAddToCart } = useOcCart();
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const currOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const pickup: boolean = currOrder?.order?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const isAnonymous = useOcSelector((state) => state.ocAuth.isAnonymous);
  const wishlistData = useOcSelector((state) => state?.wishlist);
  const productURL = getProductDetailPath(item?.Product as BuyerProductWithXp);
  const { isDesktop } = useBreakpoints();
  const [productInventory, setProductInventory] = useState<ProductInventory>({});
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const eCommerceDisabled = myStoreData?.ecommerceDisabled;
  const router = useRouter();

  const handleLineItemInventory = (productId: string, inventory: number) => {
    setLineItemInventory((prev) => {
      const newInventoryValue = Number(inventory);
      if (!productId) {
        return prev;
      }
      if (isNaN(newInventoryValue) || !productId) {
        return [{ [productId]: 0 }];
      }
      if (!prev) {
        return [{ [productId]: newInventoryValue }];
      }
      const exists = prev.some((obj) => productId in obj);
      if (exists) {
        return prev.map((obj) => {
          if (productId in obj) {
            return { [productId]: newInventoryValue };
          }
          return obj;
        });
      }

      return [...prev, { [productId]: newInventoryValue }];
    });

    setInventoryForAllLineItems((prev) => {
      const newInventoryValue = inventory;
      if (!productId) {
        return prev;
      }

      if (prev?.length && prev?.length >= lineItemsLength) {
        return prev;
      }
      if (!prev) {
        return [{ [productId]: newInventoryValue }];
      }

      return [...prev, { [productId]: newInventoryValue }];
    });
  };
  //set initial inventory for all the lineItems
  useEffect(() => {
    const getProductInventory = async () => {
      const data = await getProductAvailability(
        item?.Product?.ID as string,
        myStoreData?.storeId,
        myStoreData?.deliveryFee?.lowStock
      );
      data && setProductInventory(data);
      data && handleLineItemInventory(item?.Product?.ID as string, data?.productInventory);
      if (item?.Product?.xp?.WE) {
        handleLineItemInventory(item?.Product?.ID as string, 0);
      }
    };
    getProductInventory();
  }, [currOrder?.lineItems]);

  //set inventory color based on availability e.g. low stock - yellow
  const stockColor =
    productInventory?.availability === productAvailability.OUT ||
    isNaN(productInventory?.productInventory as number)
      ? colorOutOfStock()
      : productInventory?.availability === productAvailability.LOW
      ? colorLowStock()
      : colorTextBrand();

  //if product is website excluded
  const isUnavailable = item?.Product?.xp?.WE === true;

  //is product Out Of Stock or not
  const isOutOfStock =
    (productInventory?.productInventory as number) < 1 ||
    isNaN(productInventory?.productInventory as number);

  //disabling add to cart if eCommerce disabled or quantity less than 1
  const isAddToCartDisable = isOutOfStock || eCommerceDisabled === 1 || isUnavailable;
  //heart Icon
  const favoriteHeartIcon =
    !isAnonymous &&
    item?.Product?.ID &&
    isProductInWishlist(wishlistData?.wishlist || [], item?.Product?.ID || '')
      ? 'heart'
      : 'heart-stroke';

  //check is memberPrice is less then ListPrice then show other wise not
  const isMemberPrice =
    item?.xp?.ListPrice && item?.xp?.MemberPrice && item?.xp?.MemberPrice < item?.xp?.ListPrice;

  //check is AutoShipPrice is less then memberPrice then show other wise not
  const isAutoShipPrice =
    item?.xp?.Autoship &&
    item?.xp?.MemberPrice &&
    item?.LineTotal &&
    item?.LineTotal < item?.xp?.MemberPrice;

  const priceToRender =
    item?.xp?.ListPrice && item?.LineTotal && item?.xp?.ListPrice > item?.LineTotal
      ? item?.LineTotal
      : item?.xp?.ListPrice;

  const promoTagData = item?.Product?.xp?.PromoTag;
  const promoTag =
    promoTagData &&
    getProductPromoTag(promoTagData, myStoreData?.dataareaid, myStoreData.priceGroupArray);
  //handling addToFavorite
  const addToFavoriteClick = (product: LineItemProduct) => {
    const productId = product?.ID;
    if (productId) {
      if (isAnonymous) {
        setcomponentContextData({
          ...componentContextData,
          ToggleLoginOverlayForm: true,
          productToAddInWishlistAfterLogin: product,
          productIdToAddInWishlistAfterLogin: productId,
          clickFrom: GTMLabels?.myPurchaseDetails,
        });
        // router.push(`${urlConfig?.loginWithReturnUrl}${router.asPath}&favoritesku=${productId}`);
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            //TODO: need to add Index of the product for GTM
            storeId: myStoreData?.storeId,
            clickFrom: GTMLabels?.myPurchaseDetails,
            wishlistData: wishlistData,
          })
        );
      }
    }
  };

  //GTM event for selection an item and Add to cart
  const sendSelectionGTMEvent = ({
    lineItem,
    eventName,
  }: {
    lineItem: LineItemProduct<ProductXp>;
    eventName: string;
  }) => {
    sendProductsPromotion({
      eventName: eventName,
      data: lineItem as ProductSearchResultModelWithVariants,
      listPrice: item?.xp?.ListPrice,
      pageItem: GTMLabels?.myPurchaseDetails,
      currentPage: GTMLabels?.myPurchaseDetails,
      quantity: item?.Quantity,
      ...(eventName === GTM_EVENT?.addToCart && {
        click_from: GTMLabels?.myPurchaseDetails,
      }),
      ...(eventName === GTM_EVENT?.addToCart && { storeId: myStoreData?.storeId }),
      ...(eventName === GTM_EVENT?.addToCart && {
        fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      }),
      shouldPreciousDataLaLayerRemoved: true,
      availableInventory: productInventory?.productInventory,
    });
  };

  const handleAddToCart = async () => {
    try {
      setIsAddingToCart(true);
      const res = await purchaseOrderAddToCart(
        item?.Product?.ID as string,
        item?.Quantity as number,
        myStoreData?.storeId,
        myStoreData?.deliveryFee?.lowStock as number
      );
      item?.Product &&
        sendSelectionGTMEvent({
          lineItem: item?.Product,
          eventName: GTM_EVENT?.addToCart,
        });
      if (res?.inventoryStatus == productAvailability?.LOW) {
        setHeaderContextData({
          ...headerContextData,
          openMiniCart: false,
          showMiniCartLoader: false,
        });
        setIsModalOpen(true);
      }
    } catch (e) {
      console.error('@@error add to cart: ', e);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const onSaleImage = useOcSelector((state) => state?.otherSettingSlice?.data?.onSaleImage);

  if (productInventory && productInventory?.productInventory === undefined) {
    return <PurchaseListingCardSkeleton key={item?.ProductID} isPurchaseDetails={true} />;
  }

  return (
    <div
      className={LineItemCardWrapper({ className: index === lineItemsLength - 1 && '!border-b-0' })}
      data-component={'/helpers/purchaseDetails/LineItemCard.tsx'}
    >
      {/**Product Image */}
      <div className={imageAndPriceWrapper()}>
        <div className={ImageAndPriceIconWrapper()}>
          <div className={imageTagWrapper()}>
            {/**US made flag */}
            {item?.Product?.xp?.USMade && (
              <NextImage
                field={{
                  value: {
                    src: '/images/badge-flag-1.png',
                    alt: 'US Made',
                    width: '27',
                    height: '18',
                  },
                }}
                className="z-[2]"
              />
            )}
            {/**Sale flag */}
            {item?.Product?.xp?.OnSale && (
              <ImageHelper field={onSaleImage?.jsonValue} className="top-[150%] !w-[50px] !z-[2]" />
            )}
          </div>
          {/**Product Image */}
          <div className={ImageWrapper()}>
            <LinkHelper
              field={{ value: { href: productURL } }}
              onClick={(e) => {
                isUnavailable && e.preventDefault();
                sendSelectionGTMEvent({
                  lineItem: item.Product as LineItemProduct<ProductXp>,
                  eventName: GTM_EVENT?.selectItem,
                });
              }}
              className={`${isUnavailable && '!cursor-not-allowed'}`}
            >
              <ImageHelper
                className={'h-[150px] object-cover max-w-[100px] w-auto'}
                field={{
                  value: {
                    alt: item.Product?.Name,
                    src: getFirstProductImage(item.Product?.xp?.Images)?.Url,
                    width: 100,
                    height: 150,
                  },
                }}
              />
            </LinkHelper>
            {promoTag && <span className={itemPromo()}>{promoTag}</span>}
            {isUnavailable && (
              <Text
                tag="span"
                className={itemUnavailable({
                  className: clsx(boldLabels(), 'bg-[rgba(35,31,31,0.5)]'),
                })}
                field={fields?.unAvailableText}
              />
            )}
          </div>
          {/**Retail flag */}
          <div className={retailTagWrapper()}>
            <span className={retailTag()}>
              {item?.Product?.xp?.RetailUnit}&nbsp;{item?.Product?.xp?.RetailMeasure}
            </span>
            <IconHelper icon="vector" className={retailTagSVG()} />
          </div>
        </div>

        {/**Product Details */}
        <div className={productNameAndPrice()}>
          <Link
            aria-label={item?.Product?.Name}
            href={productURL}
            prefetch={false}
            onClick={(e) => {
              isUnavailable && e.preventDefault();
              sendSelectionGTMEvent({
                lineItem: item.Product as LineItemProduct<ProductXp>,
                eventName: GTM_EVENT?.selectItem,
              });
            }}
            className={`${isUnavailable && 'hover:!no-underline !cursor-not-allowed'}`}
          >
            <h3 className={productTitle()}>{item?.Product?.Name}</h3>
          </Link>
          <div className={printPriceWrapper()}>
            <span>
              <Text className={regularLabels()} field={fields?.skuText} />{' '}
              <span className={regularLabels()}>{item?.Product?.xp?.UPC}</span>
            </span>
            <div className={printQtyPriceWrapper()}>
              <span>
                <Text className={regularLabels()} field={fields?.quantityText} />{' '}
                <span className={regularLabels()}>{item?.Quantity}</span>
              </span>
              {!isMemberPrice && (
                <div className={price()}>
                  <span className={boldLabels()}>
                    {getDictionaryValue('CurrencySymbol')}
                    {priceToRender?.toFixed(2)}
                  </span>
                </div>
              )}
              {isMemberPrice && (
                <div className={price()}>
                  <span className={boldLabels({ className: colorRed() })}>
                    {getDictionaryValue('CurrencySymbol')}
                    {item?.xp?.MemberPrice?.toFixed(2)}
                  </span>
                </div>
              )}
            </div>
          </div>
          {/**Product Price e.g. Member price, List price, Autoship Price */}
          <div className={priceAndReviewWrapper()}>
            <div className={priceWrapper()}>
              <div className={price()}>
                <Text tag="span" className={boldLabels()} field={fields?.listPriceText} />
                <span
                  className={boldLabels({
                    className: (isMemberPrice || isAutoShipPrice) && strike(),
                  })}
                >
                  {getDictionaryValue('CurrencySymbol')}
                  {item?.xp?.ListPrice?.toFixed(2)}
                </span>
              </div>
              {isMemberPrice && (
                <div className={price()}>
                  <Text tag="span" className={boldLabels()} field={fields?.memberPriceText} />
                  <span className={boldLabels({ className: colorRed() })}>
                    {getDictionaryValue('CurrencySymbol')}
                    {item?.xp?.MemberPrice?.toFixed(2)}
                  </span>
                </div>
              )}
              {isAutoShipPrice && (
                <div className={price()}>
                  <Text tag="span" className={boldLabels()} field={fields?.autoshipPriceText} />
                  <span className={boldLabels({ className: autoShipLabel() })}>
                    {getDictionaryValue('CurrencySymbol')}
                    {priceToRender?.toFixed(2)}
                  </span>
                </div>
              )}
            </div>
            {isDesktop && (
              <div className={bvRatingWrapper()}>
                {getDictionaryValue('WriteReviewPDP') && (
                  <div className={reviewWrapper()}>
                    <div>
                      <Text tag="span" className={boldLabels()} field={fields?.quantityText} />{' '}
                      {item?.Quantity}
                    </div>
                    {item?.Product?.xp?.UPC && (
                      <BazarVoiceReviews
                        bvProductId={item?.Product?.xp?.UPC}
                        onClick={(e) => {
                          e?.preventDefault();
                          router?.push(productURL);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {/**Favorite Icon */}
          <div className={favoriteIcon()}>
            <button
              onClick={() => item?.Product && addToFavoriteClick(item?.Product)}
              aria-label="add to My Favorites"
            >
              <IconHelper className={heartIcon()} icon={favoriteHeartIcon} />
            </button>
          </div>
        </div>
      </div>

      {/**Add to Cart */}
      <div className={addToCartWrapper()}>
        {!isUnavailable ? (
          <span className={boldLabels({ className: stockColor })}>
            {getDictionaryValue(
              isOutOfStock ? productAvailability.OUT : productInventory?.availability || ''
            )}
          </span>
        ) : (
          <Text field={fields?.unAvailableText} className={boldLabels()} />
        )}

        <button
          disabled={isAddToCartDisable || isAddingToCart}
          aria-label="add to cart"
          onClick={async () => {
            !isAddToCartDisable && (await handleAddToCart());
          }}
          className={clsx(addToCartBtn(), {
            'cursor-not-allowed': isAddToCartDisable || isAddingToCart,
          })}
        >
          <Text
            field={{
              value: `${
                !isAddingToCart
                  ? fields?.addToCartText?.value
                  : getDictionaryValue('AddingItemToCart', 'Adding item to cart ..')
              }`,
            }}
          />
        </button>
        {!isDesktop && getDictionaryValue('WriteReviewPDP') && (
          <div className={reviewWrapper()}>
            {item?.Product?.xp?.UPC && (
              <BazarVoiceReviews
                bvProductId={item?.Product?.xp?.UPC}
                onClick={(e) => {
                  e?.preventDefault();
                  router?.push(productURL);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LineItemCard;
