import { tv } from 'tailwind-variants';

export const newProductDetailStructureTailwindVariant = tv(
  {
    slots: {
      base: 'pDPComponent overflow-hidden',
      productDetailWrapper: '',
      productDetailContainer:
        'flex justify-between xl-for-pdp:w-fit xl-for-pdp:gap-desk-global-spacing-spacing-7',
      productDetailCol3ForDesktop: 'flex flex-col ',
      productDetailCol3ForSmallDesktop: 'mt-[25px] mb-desk-global-spacing-spacing-4',
      productDetailTabWrapper: ['container', 'mx-auto'],
      productDetailInfoSection: [, 'flex', 'flex-col'],
      productDetailInfoTitle: [
        'text-center',
        'text-color-text-black',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
    },
    variants: {
      size: {
        mobile: {
          productDetailWrapper: 'my-mob-global-spacing-spacing-7 mx-mob-global-spacing-spacing-4',
          productDetailContainer: 'flex-col gap-mob-global-spacing-spacing-7',
          productDetailCol3ForDesktop: 'gap-mob-global-spacing-spacing-4',
          productDetailInfoSection:
            'gap-mob-global-spacing-spacing-6 my-mob-global-spacing-spacing-7',
          productDetailInfoTitle: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          productDetailWrapper: 'm-desk-global-spacing-spacing-7 flex lg:justify-center',
          productDetailContainer: 'w-full flex-row',
          productDetailCol3ForDesktop: 'gap-mob-global-spacing-spacing-4',
          productDetailInfoSection:
            'gap-desk-global-spacing-spacing-6 my-desk-global-spacing-spacing-11',
          productDetailInfoTitle: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
