import { tv } from 'tailwind-variants';

const previewSearchTailwind = tv(
  {
    slots: {
      searchInput: ['flex', 'border-[1px]', 'border-color-border-mid', 'relative', 'outline-none'],
      resultItem: [
        'border-b-[1px]',
        'border-color-border-mid',
        'cursor-pointer',
        'border-x-[1px]',
        'hover:bg-color-border-mid',
        'hover:no-underline	',
      ],
      searchSuggestionWrapper: ['list-style-none', 'z-99', 'bg-white', 'flex', 'w-full'],
      listPadding: [''],
      previewSearchForm: ['relative', 'flex', 'items-center'],
      previewSearchContent: ['!z-99', 'bg-white'],
      iconClose: [
        '[&>svg]:w-[24px]',
        '[&>svg]:h-fit',
        'absolute',
        'right-[42px]',
        'top-[50%]',
        '-translate-y-1/2',
        'hover:cursor-pointer',
        '[&_path]:fill-color-border-mid',
        'bg-color-background-white',
      ],
      searchButton: [
        'outline outline-1 outline-color-background-brand-1',
        'bg-brand-primary-1-dark',
        'bg-color-brand-primary-1-base',
        'hover:bg-brand-primary-1-dark',
        'hover:cursor-pointer',
      ],
      iconSearch: [
        '[&>svg]:w-[24px]',
        '[&>svg]:p-[8px]',
        '[&>svg]:h-[48px]',
        '[&>svg]:w-auto',
        '[&_path]:w-[24px]',
      ],
      iconSearchSmall: [
        '[&>svg]:w-[24px]',
        '[&>svg]:h-fit',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'absolute',
        'right-[10%]',
        'top-[50%]',
        '-translate-y-1/2',
        'hover:cursor-pointer',
        'bg-color-background-white',
        'pl-[5px]',
        'pr-[8px]',
      ],
      searchWrapper: [],
      searchBarWrapper: [],
    },
    compoundSlots: [
      {
        slots: ['searchInput'],
        class: ['font-body-large-regular', 'text-body-large-regular', 'leading-body-large-regular'],
      },
      {
        slots: ['resultItem'],
        class: ['font-body-large-bold', 'leading-body-large-bold', 'text-body-large-bold'],
      },
      // {
      // slots: ['searchInput'],
      // class: ['transition-all', 'ease-in-out', 'duration-500', 'delay-700 '],
      // },
    ],
    variants: {
      device: {
        mobile: {
          searchInput: [
            'searchInput',
            '!pr-[40px]',
            'w-full',
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
          ],
          listPadding: ['py-mob-global-spacing-spacing-3', 'px-mob-global-spacing-spacing-4'],
          resultItem: ['py-mob-global-spacing-spacing-3', 'px-mob-global-spacing-spacing-4'],
          searchWrapper: ['w-full'],
          iconClose: ['!right-[15%]'],
          searchBarWrapper: ['w-full'],
        },
        desktop: {
          searchInput: [
            '!pr-[80px]',
            'w-[668px]',
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
          ],
          listPadding: ['py-desk-global-spacing-spacing-3', 'px-desk-global-spacing-spacing-4'],
          resultItem: [
            // 'w-[668px]',
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
          ],
          searchWrapper: ['max-w-[668px]'],
          iconClose: ['!right-[2%]'],
          searchBarWrapper: ['w-[668px]', 'flex justify-end'],
        },
      },
      isSearchBarVariant: {
        true: {
          searchInput: [
            // 'lg:max-w-[164px]',
            'rounded-[4px]',
            '!py-desk-global-spacing-spacing-1',
            '!px-desk-global-spacing-spacing-2',
            // 'lg:max-w-[162px]',
            'lg:max-w-[255px]',
          ],
          iconSearch: [
            'absolute',
            '[&>svg]:w-[16px]',
            '[&>svg]:h-fit',
            '[&>svg>*]:fill-color-brand-primary-1-base',
            '[&_svg]:bg-color-background-white',
            'top-[50%]',
            '!right-[13%]',
            'lg:!right-[5%]',
            '-translate-y-1/2',
          ],
          searchBarWrapper: ['flex flex-end w-full'],
          previewSearchForm: ['justify-end w-full'],
          iconSearchSmall: ['right-[42px] lg:right-[3%]'],
          iconClose: [],
        },
        false: {
          searchInput: ['w-[668px]', 'max-w-[668px]'],
          searchBarWrapper: [''],
        },
      },
      triggerSearchOnMobile: {},
      isLoading: {
        true: {
          searchButton: [
            '[&_path]:fill-color-background-white',
            '[&_svg]:animate-[rotateAnimation_1s_linear]',
          ],
          iconSearchSmall: ['[&_svg]:animate-[rotateAnimation_1s_linear]'],
        },
        false: {
          searchButton: ['[&_path]:fill-color-background-white'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
export default previewSearchTailwind;
