import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// import { MobileBackToMenuProps } from './MobileBackToMenu';
import useDictionary from 'src/hooks/useDictionary';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { mobileBackToMenuTailwindVariant } from 'tailwindVariants/components/mobileBackToMenuTailwindVariant';

const MobileBackToMenuWrapper = () => {
  const {
    base,
    text,
    sideNavItems,
    navLink,
    sideNavItem,
    sideNavItemIcon,
    backToMenuIcon,
    closeMenuIcon,
  } = mobileBackToMenuTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileScreenView, setIsMobileScreenView] = useState(false);
  const router = useRouter();
  const { getDictionaryValue } = useDictionary();

  const handleMenuOpen = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleRouterChange = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const checkMobileScreenSize = () => {
      if (typeof window !== undefined) {
        setIsMobileScreenView(window.innerWidth < 1024);
      }
    };

    checkMobileScreenSize();

    const handleResize = () => {
      if (typeof window !== undefined) {
        setIsMobileScreenView(window.innerWidth < 1024);
      }
      if (!isMobileScreenView && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [isMobileMenuOpen, isMobileScreenView]);

  useEffect(() => {
    const leftNavigationMenu = document.getElementById('LeftNavigationMenu');
    const rightColumnComponents = document.getElementById('AccountManagement-column-2-{*}');
    const doubleCalloutComponent = document.querySelector('.doubleCallout') as HTMLElement;

    if (isMobileScreenView) {
      if (leftNavigationMenu) {
        leftNavigationMenu.style.display = isMobileMenuOpen ? 'block' : 'none';
      }
      if (rightColumnComponents) {
        rightColumnComponents.style.display = isMobileMenuOpen ? 'none' : 'block';
      }
      if (doubleCalloutComponent) {
        doubleCalloutComponent.style.display = isMobileMenuOpen ? 'none' : 'block';
      }
    } else {
      // Reset styles for desktop view
      if (leftNavigationMenu) {
        leftNavigationMenu.style.display = 'block';
      }

      if (rightColumnComponents) {
        rightColumnComponents.style.display = 'block';
      }
      if (doubleCalloutComponent) {
        doubleCalloutComponent.style.display = 'block';
      }
    }

    if (router) {
      router.events.on('routeChangeStart', handleRouterChange);
    }

    return () => {
      if (router) {
        router.events.off('routeChangeStart', handleRouterChange);
      }
    };
  }, [isMobileMenuOpen, isMobileScreenView, router, router.events]);
  return (
    <>
      {!isMobileMenuOpen && (
        <div className={base()}>
          <a aria-label="back to menu" className={navLink()} onClick={() => handleMenuOpen()}>
            <IconHelper className={backToMenuIcon()} icon={'chevron-left'} />

            <div className={text()}>{getDictionaryValue('Accounts-MobileBackToMenuLinkText')}</div>
          </a>
        </div>
      )}
      {isMobileScreenView && isMobileMenuOpen && (
        <div className={sideNavItems()}>
          <a aria-label="menu" className={sideNavItem()} onClick={() => handleMenuOpen()}>
            Menu
            <span className={sideNavItemIcon()}>
              Close
              <IconHelper className={closeMenuIcon()} icon={'close'} />
            </span>
          </a>
        </div>
      )}
    </>
  );
};

export default MobileBackToMenuWrapper;
