import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { productDetailDeliveryMethodTailwindVariant } from 'tailwindVariants/components/ProductDetails/productDetailDeliveryMethodTailwindVariant';
import IconHelper from '../commonComponents/IconHelper';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';
import { useRouter } from 'next/router';
import ComponentContext from 'lib/context/ComponentContext';
import formatPrice from 'src/utils/formatPrice';
import { useOcSelector } from 'src/redux/ocStore';

interface ProductDetailPriceProps {
  strikeThrough?: boolean;
  strikeThroughPrice?: number;
  priceToRender: number;
  productData: {
    ProductPrice?: {
      ViewPriceInCart?: boolean;
      ListPrice?: number;
      IMapPrice?: number;
      PPCPrice?: number;
      PPCDiscount?: boolean;
    };
    Xp?: {
      FirstTimeAutoshipDiscount?: boolean;
      ReccuringAutoshipDiscount?: boolean;
    };
  };
  saveAmount?: {
    amount: number;
    percentage: number;
    savedPrice: number;
  };
  priceType?: 'list' | 'ppc' | 'imap' | 'autoship';
  isAutoship?: boolean;
  firstTimeDiscObj?: {
    renderFirstTimeDiscountPrice: boolean;
    firstTimeDiscountPrice: number;
    firstTimeDiscountPercentage: number;
    avoidFirstTimeDiscount?: boolean;
    avoidDiscount?: boolean;
  };
}

const ProductDetailPrice: React.FC<ProductDetailPriceProps> = ({
  strikeThrough,
  strikeThroughPrice,
  priceToRender,
  productData,
  saveAmount,
  isAutoship,
  // priceType,
  firstTimeDiscObj,
}) => {
  const [priceType, setPriceType] = useState<ProductDetailPriceProps['priceType']>('list');
  const router = useRouter();

  // Logic to maintain font for Autoship Price in it's color:
  useEffect(() => {
    if (!router?.isReady || !router?.query?.sku) return;

    if (productData?.ProductPrice?.ViewPriceInCart) {
      setPriceType('imap');
    } else {
      if (isAutoship) {
        setPriceType('autoship');
      } else {
        setPriceType(saveAmount?.amount && saveAmount.amount > 0 ? 'ppc' : 'list');
      }
    }
  }, [
    saveAmount?.amount,
    isAutoship,
    router?.isReady,
    router?.query?.sku,
    productData?.ProductPrice,
  ]);

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!router?.isReady || !router?.query?.sku) return;

      // logic to keep what price is to keep for per pound - render price:
      if (productData?.ProductPrice?.ViewPriceInCart) {
        setcomponentContextData({
          ...componentContextData,
          priceForDivide: productData?.ProductPrice?.IMapPrice,
        });
      } else if (productData?.ProductPrice?.PPCDiscount) {
        setcomponentContextData({
          ...componentContextData,
          priceForDivide: productData?.ProductPrice?.PPCPrice,
        });
      } else {
        setcomponentContextData({
          ...componentContextData,
          priceForDivide: productData?.ProductPrice?.ListPrice,
        });
      }
    }
  }, [router?.query?.sku, router?.isReady, productData?.ProductPrice]);

  // const priceType = saveAmount?.amount && saveAmount.amount > 0 ? 'ppc' : 'list';
  const { getDictionaryValue } = useDictionary();
  const { strikePrice, price, priceLine } = productDetailDeliveryMethodTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const {
    toolbarIcon,
    topTooltipWrapper,
    tooltipContainer,
    toolbarIconWrapper,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
    topToolTipDescription,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  /**
   * This function renders the price according to the type of price.
   * @param {ProductDetailPriceProps['priceType']} priceType - The type of price to render.
   * @returns {React.ReactElement} - The rendered price.
   * @description
   * If the priceType is 'list', it renders the list price.
   * If the priceType is 'ppc', it renders the price with a strike-through
   * and a saving amount and percentage.
   * If the priceType is 'autoship', it renders the price with a strike-through
   * and a saving amount and percentage, but with a different font color.
   * If the productData?.ProductPrice?.ViewPriceInCart is true, it renders a ViewPriceInCart text.
   */

  // Check on user: Will only get ReceivedFirstTimeAutoshipDiscount field is authenticated.
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  ) as boolean;

  const [calculatedPrice, setCalculatedPrice] = useState<number>(0);

  useEffect(() => {
    if (firstTimeDiscObj && !firstTimeDiscObj?.avoidFirstTimeDiscount) {
      if (productData?.ProductPrice?.PPCPrice && productData?.ProductPrice?.ListPrice) {
        const calprice = parseFloat(
          (
            (productData?.ProductPrice?.PPCDiscount && productData?.ProductPrice?.PPCPrice
              ? productData?.ProductPrice?.PPCPrice
              : productData?.ProductPrice?.ListPrice) - firstTimeDiscObj?.firstTimeDiscountPrice
          )?.toFixed(2)
        ) as number;
        setCalculatedPrice(calprice);
      }
    }
  }, [productData?.ProductPrice?.ListPrice, firstTimeDiscObj]);

  const renderPrice = (priceType: ProductDetailPriceProps['priceType']) => {
    // For Autoship with FirstTime Discount:
    if (!productData?.ProductPrice?.ViewPriceInCart) {
      if (
        isAutoship &&
        firstTimeDiscObj &&
        !firstTimeDiscObj?.avoidFirstTimeDiscount &&
        productData?.ProductPrice?.ListPrice &&
        productData?.Xp?.FirstTimeAutoshipDiscount
      ) {
        // If product doesn't support ReccuringAutoshipDiscount, render just price
        if (receivedFirstTimeAutoshipDiscount) {
          return (
            // PSP-4797: Whenn user has receivedFirstTimeAutoshipDiscount, show autoship price in black
            <p className={price({ priceType: 'list' })} data-pricetype={priceType}>
              {formatPrice(priceToRender)}
            </p>
          );
        }

        return (
          <>
            <p className={price({ priceType })}>{formatPrice(calculatedPrice)}</p>
            {!receivedFirstTimeAutoshipDiscount && (
              <div className={priceLine()} data-pricetype={priceType}>
                <p className={priceLine()}>
                  {getDictionaryValue('Save')}{' '}
                  {formatPrice(firstTimeDiscObj?.firstTimeDiscountPrice)}(
                  {firstTimeDiscObj?.firstTimeDiscountPercentage}%) |{' '}
                  <span className={strikePrice()}>
                    {formatPrice(
                      productData?.ProductPrice?.PPCDiscount && productData?.ProductPrice?.PPCPrice
                        ? productData?.ProductPrice?.PPCPrice
                        : productData?.ProductPrice?.ListPrice ?? 0
                    )}
                  </span>
                </p>
              </div>
            )}
          </>
        );
      }
      if (!productData?.Xp?.FirstTimeAutoshipDiscount) {
        return (
          // PSP-4797: If product doesn't support FirstTimeAutoshipDiscount, show price in black.
          <p className={price({ priceType: 'list' })} data-pricetype={priceType}>
            {formatPrice(priceToRender)}
          </p>
        );
      }
    }

    // For List Price:
    if (!strikeThrough) {
      return (
        <>
          <p className={price({ priceType })} data-pricetype={priceType}>
            {formatPrice(priceToRender)}
          </p>
        </>
      );
    }

    // For IMAP Handling:
    if (productData?.ProductPrice?.ViewPriceInCart) {
      return (
        <>
          <div className="flex">
            <p className={clsx(price({ priceType }))}>{getDictionaryValue('ViewPriceInCartPDP')}</p>
            <div
              className={toolbarIconWrapper({
                className: 'w-fit group cursor-pointer lg:relative',
              })}
              onClick={(e) => e?.stopPropagation()}
            >
              <IconHelper
                className={toolbarIcon({
                  className: '[&>svg>g>*]:fill-color-accent-red [&>svg]:h-[16px]',
                })}
                icon={'info'}
              />

              {/* Imap Tooltip content */}
              {/* {showIMapPriceTooltip && ( */}
              <div className={topTooltipWrapper()}>
                <div className={tooltipContainer()}>
                  <div className={topTooltipArrowFirstDiv()}></div>
                  <div className={topTooltipArrowSecondDiv()}></div>
                  <p className={topToolTipDescription()}>
                    {getDictionaryValue('WhyWeDoThisTooltipPDP')}
                  </p>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
          <p className={priceLine()} data-pricetype={priceType}>
            <span className={strikePrice()}>{formatPrice(strikeThroughPrice ?? 0)}</span>
          </p>
        </>
      );
    }

    // For PPC Handling:
    return (
      <>
        <p className={price({ priceType })} data-pricetype={priceType}>
          {formatPrice(priceToRender)}
        </p>
        <p className={priceLine()}>
          {saveAmount?.savedPrice && (
            <>
              {getDictionaryValue('Save')} {formatPrice(saveAmount?.savedPrice)} (
              {saveAmount?.percentage}%) |{' '}
              <span className={strikePrice()}>{formatPrice(strikeThroughPrice ?? 0)}</span>
            </>
          )}
        </p>
      </>
    );
  };

  return <div>{renderPrice(priceType)}</div>;
};

export default ProductDetailPrice;
