import React, { useEffect, useRef, useState } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { newStoreDetailTabTailwindVariant } from 'tailwindVariants/components/newStoreDetailTabTailwindVariant';
import { renderImage, renderVideo } from './NewStoreDetailTab';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { requiredGuaranteedAnalysisKeys, totalGuaranteedAnalysisKeys } from '../Constants';
import clsx from 'clsx';
import {
  MPCMProduct,
  PdpDatasourceData,
  ProductData,
} from 'components/ProductDetail/ProductDetail';

interface NutritionObjectType {
  title: string;
  content: string;
}
const {
  tabWrapper,
  hidden,
  tableWrapper,
  tableTitle,
  tableDescription,
  rightSideContentWrapper,
  leftSideContentText,
  nutritionContentWrapper,
  accordionTitle,
  accordionTitleWrapper,
  accordionWrapper,
  nutritionWrapper,
  contentTitle,
} = newStoreDetailTabTailwindVariant({
  size: { initial: 'mobile', lg: 'desktop' },
});
const ProductNutritionTab = ({
  currentTab,
  fields,
}: {
  currentTab: string;
  fields: PdpDatasourceData;
}) => {
  const { getDictionaryValue } = useDictionary();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const toggleItem = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const [isImage, _setIsImage] = useState(false);
  const [isVideo, _setIsVideo] = useState(false);

  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData as ProductData;

  useEffect(() => {
    if (productData?.MPCMProduct?.NTRVideo) {
      _setIsVideo(true);
    } else if (productData?.MPCMProduct?.NTRImage) {
      _setIsImage(true);
    } else {
      _setIsImage(false);
      _setIsVideo(false);
    }
  }, [productData?.MPCMProduct?.NTRImage, productData?.MPCMProduct?.NTRVideo]);

  const [nutritionObj, setNutritionObj] = useState<NutritionObjectType[]>([]);

  useEffect(() => {
    const obj: NutritionObjectType[] = [];
    if (productData?.MPCMProduct?.Ingredients && fields?.ingredientsAccordionText?.value) {
      obj.push({
        title: fields?.ingredientsAccordionText?.value, // TODO: should come from CMS
        content: productData.MPCMProduct.Ingredients,
      });
    }

    if (
      productData?.MPCMProduct?.GuaranteedAnalysisNutrientName &&
      fields?.guaranteedAnalysisAccordionText?.value
    ) {
      obj.push({
        title: fields?.guaranteedAnalysisAccordionText?.value, // TODO: should come from CMS
        content: productData.MPCMProduct.GuaranteedAnalysisNutrientName,
      });
    }

    if (
      productData?.MPCMProduct?.FeedingInstructionsTextDescription &&
      fields?.feedingInstructionAccordionText?.value
    ) {
      obj.push({
        title: fields?.feedingInstructionAccordionText?.value, // TODO: should come from CMS
        content: productData.MPCMProduct.FeedingInstructionsTextDescription,
      });
    }

    setNutritionObj(obj);
  }, [
    productData?.MPCMProduct?.Ingredients,
    productData?.MPCMProduct?.GuaranteedAnalysisNutrientName,
    productData?.MPCMProduct?.FeedingInstructionsTextDescription,
  ]);

  const renderTable = () => {
    if (!productData?.MPCMProduct) return null;

    const productDetails = productData.MPCMProduct as MPCMProduct;
    if (!productDetails) return null;
    // Dynamically extract valid values
    const tableData = totalGuaranteedAnalysisKeys
      .map(
        ({ key, title }) =>
          productDetails[key as keyof MPCMProduct] && {
            title,
            answer: productDetails[key as keyof MPCMProduct],
          }
      )
      .filter(Boolean); // Remove null/undefined values

    return tableData.length > 0 ? (
      <div className={tableWrapper({ className: 'text-start' })}>
        {tableData.map((item: { title: string; answer: string }, index) => (
          <React.Fragment key={index}>
            <p
              className={tableTitle({
                className: index === tableData.length - 1 ? 'border-b-0' : '',
              })}
            >
              {item.title}
            </p>
            <p
              className={tableDescription({
                className: index === tableData.length - 1 ? 'border-b-0' : '',
              })}
            >
              {item.answer}
            </p>
          </React.Fragment>
        ))}
      </div>
    ) : null;
  };

  const renderParagraph = () => {
    return <p className="text-start">{productData?.MPCMProduct?.GuaranteedAnalysisNutrientName}</p>;
  };

  // As Ingredients doesn't contain a table
  const renderLeftSideIngredient = (content: string) => (
    <div className={clsx(leftSideContentText(), 'text-start')}>{content}</div>
  );

  // Renders Gauranteed Analysis block
  const renderLeftSideGauranteeAnalysis = () => {
    if (!productData?.MPCMProduct) return null;

    const isValidMPCMKey = (
      key: string,
      product: MPCMProduct | undefined
    ): key is keyof MPCMProduct => {
      return !!product && key in product;
    };

    if (!productData?.MPCMProduct) {
      // Ensure productData.MPCMProduct is defined before proceeding
      return false;
    }

    const showNewGuaranteedAnalysis = requiredGuaranteedAnalysisKeys.every(
      (key) =>
        isValidMPCMKey(key, productData.MPCMProduct) &&
        productData.MPCMProduct &&
        Boolean(productData.MPCMProduct[key])
    );

    const renderGuarantedAnalysisInfo = showNewGuaranteedAnalysis
      ? renderTable() // Renders new PDP as per table definition
      : renderParagraph();

    return (
      <div
        className={nutritionContentWrapper({
          className: '!flex-col items-start gap-desk-global-spacing-spacing-4',
          hasMedia: isVideo || isImage,
        })}
      >
        <div>{renderGuarantedAnalysisInfo}</div>
        {/* TODO: Should be CMS Managed */}
        {(productData?.MPCMProduct?.CaloricContentPerPackage ||
          productData?.MPCMProduct?.CaloricContentPerServing) && (
          <>
            {/* TODO: title should come from CMS */}
            <p className={contentTitle()}>{fields?.caloricContentText?.value}</p>
            <p>
              {productData?.MPCMProduct?.CaloricContentPerPackage}
              {productData?.MPCMProduct?.CaloricContentPerServing
                ? ` , ${productData.MPCMProduct.CaloricContentPerServing}`
                : ''}
            </p>
          </>
        )}

        {/* TOOD: Field still missing from CH - Guaranteed Analysois Description */}
        {productData?.MPCMProduct?.GASupplemental && (
          <p className={'text-start'}>{productData?.MPCMProduct?.GASupplemental}</p>
        )}
      </div>
    );
  };

  // Render Feeding Instructions in a paragraph style
  const renderLeftSideFeedingInstructions = () => {
    return (
      productData?.MPCMProduct?.FeedingInstructionsTextDescription && (
        <div
          className={nutritionContentWrapper({
            className: '!flex-col items-start gap-desk-global-spacing-spacing-4',
            hasMedia: isVideo || isImage,
          })}
        >
          <p className="text-start">
            {productData?.MPCMProduct?.FeedingInstructionsTextDescription}
          </p>
          {productData?.MPCMProduct?.TransitioningInstructions && (
            <>
              {/* TODO: Title must come from CMS */}
              <p className={contentTitle()}>{fields?.transitionInstructionText?.value}</p>
              <p className="text-start">{productData.MPCMProduct.TransitioningInstructions}</p>
            </>
          )}
        </div>
      )
    );
  };

  // PSP-4728: Accordion Collapse management
  useEffect(() => {
    if (currentTab !== getDictionaryValue('TabIngredientsAndCompositionPDP')) {
      setActiveIndex(null);
    }
  }, [currentTab]);

  const renderAccordion = () => {
    return (
      <>
        {nutritionObj.map((item: NutritionObjectType, index) => (
          <button
            key={index}
            onClick={() => {
              toggleItem(index);
            }}
            className={accordionWrapper()}
          >
            <div className={accordionTitleWrapper()}>
              <span className={accordionTitle()}>{item.title}</span>
              {/* TODO: update icon */}
              <span className="text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold">
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>

            <div
              ref={(el) => (contentRefs.current[index] = el)}
              //TODO: Nirmal please refactor this after confirmation
              style={{
                maxHeight:
                  activeIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0',
                transition: 'max-height 0.3s ease-in-out',
              }}
              className={`overflow-hidden`}
            >
              {/* Render Accordion: Ingredients */}
              {item?.title.includes('Ingredients') ? (
                <div className={nutritionContentWrapper({ hasMedia: isVideo || isImage })}>
                  {renderLeftSideIngredient(item?.content)}
                </div>
              ) : null}

              {/* Render Accordion: Guaranteed Analysis */}
              {item?.title.includes('Guaranteed Analysis') ? (
                <>{renderLeftSideGauranteeAnalysis()}</>
              ) : null}

              {/* Render Accordion: Feeding Instructions */}
              {item?.title.includes('Feeding Instructions') ? (
                <>{renderLeftSideFeedingInstructions()}</>
              ) : null}
            </div>
          </button>
        ))}
      </>
    );
  };

  return (
    <div className={currentTab === getDictionaryValue('TabNutrition') ? tabWrapper() : hidden()}>
      <div className={nutritionWrapper()}>
        <div className={`${isImage || isVideo ? 'lg:w-1/2 lg:mr-3' : ''}`}>{renderAccordion()}</div>

        {/* Render Image */}
        {!isVideo && isImage && (
          <div className={rightSideContentWrapper()}>
            {renderImage(productData?.MPCMProduct?.NTRImage)}
          </div>
        )}
        {/* Render Video */}
        {isVideo && (
          <div className={rightSideContentWrapper()}>
            {renderVideo(productData?.MPCMProduct?.NTRVideo)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductNutritionTab;
