import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import ContentHubImage from './ContentHubImages/ContentHubImage';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import { useTheme } from 'lib/context/ThemeContext';
import Loader from 'components/Loader/Loader';
import useDictionary from 'src/hooks/useDictionary';
import { availableOffersTailwindVariant } from 'tailwindVariants/components/availableOffersTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';

type AvailableOffersProps = {
  availableOffersHeading: Field<string>;
  availableOffersSubheading: Field<string>;
  availableOffersExpiryDateLabel: Field<string>;
  availableOffersNoExpiryDateLabel: Field<string>;
  availableOffersQualifyingPurchasesLinkText: Field<string>;
  shopNowButtonText: Field<string>;
  availableOffersViewMoreLinkText: Field<string>;
  noAvailableOffersMessage: Field<string>;
  noExpiryDateText: Field<string>;
  termsAndConditionLabel: Field<string>;
};

type ProductProps = {
  rewardPlu: string;
  rewardLimit: number;
  rewardAmount: number;
  requiresSignup: boolean;
  programName: string;
  shortProgramName: string;
  programid: number;
  pluexpireInDays: number;
  enabled: boolean;
  description: string;
  shortDescription: string;
  daysBetweenReward: number;
  DateStart: string;
  brandid: number;
  termCondition: string;
  ShopNowUrl: string;
  offerExpiresDate: string;
};

const {
  base,
  productGrid,
  productItem,
  productImage,
  productShortProgramNameWrapper,
  productShortProgramName,
  productShortDescription,
  productExpiryDataWrapper,
  shopNowButton,
  loadMoreButtonWrapper,
  loadMoreButton,
  modalProgramStyles,
  modalProgramName,
  modalProgramDescription,
  productNoExpiryDateText,
  termsAndConditionLabelStyle,
  termConditionStyles,
  termsAndConditionTextStyle,
  loadMoreButtonLoadingMore,
} = availableOffersTailwindVariant({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const AvailableOffers = (props: AvailableOffersProps) => {
  const {
    availableOffersHeading,
    availableOffersSubheading,
    availableOffersExpiryDateLabel,
    availableOffersNoExpiryDateLabel,
    availableOffersQualifyingPurchasesLinkText,
    shopNowButtonText,
    availableOffersViewMoreLinkText,
    noAvailableOffersMessage,
    noExpiryDateText,
    termsAndConditionLabel,
  } = props;

  const [products, setProducts] = useState<ProductProps[]>([]);
  const [page, setPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState('');
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const { themeName } = useTheme();
  const size = 12;
  const program = 'Products';
  const { getDictionaryValue } = useDictionary();
  const errorMessage = getDictionaryValue('ErrorOccursTryLater');

  const handleModalClick = (index: number) => {
    setOpenModalIndex(index);
  };

  const handleModalClose = () => {
    setOpenModalIndex(null);
  };

  useEffect(() => {
    fetchProducts(size, page, program);
  }, [page]);

  const fetchProducts = async (size: number, page: number, program: string) => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/loyalty/get-available-programs?group=${themeName}&size=${size}&page=${page}&program=${program}`
      );
      if (!response.ok && response.status !== 404) {
        throw new Error('Error. The API response was not ok.');
      }
      const data = await response.json();
      if (page === 1) {
        setProducts(data?.content);
      } else {
        setProducts((prevResults) => [...prevResults, ...data?.content]);
      }
      setTotalProducts(data?.totalCount);
    } catch (error) {
      setIsError(`Error. An error occured in the API response. ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  if (loading && products?.length === 0) {
    return <Loader />;
  }

  return (
    <div className={base()}>
      {!isError ? (
        <>
          {page > 0 ? (
            <>
              {availableOffersHeading && availableOffersHeading?.value && (
                <RichTextHelper field={availableOffersHeading} />
              )}
              {availableOffersSubheading && availableOffersSubheading?.value && (
                <RichTextHelper field={availableOffersSubheading} />
              )}
              <div className={productGrid()} id="available-programs-grid">
                {products &&
                  products?.length > 0 &&
                  products.map((product: ProductProps, index: number) => (
                    <>
                      <div
                        key={index}
                        className={productItem()}
                        style={{ boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.25)' }}
                        onClick={() => handleModalClick(index)}
                      >
                        <div className={productImage()}>
                          {product && product?.programid && (
                            <ContentHubImage programid={product?.programid} />
                          )}
                        </div>
                        <div className={productShortProgramNameWrapper()}>
                          <h3 className={productShortProgramName()}>{product?.shortProgramName}</h3>
                        </div>
                        <p className={productShortDescription()}>{product?.shortDescription}</p>
                        <div className={productExpiryDataWrapper()}>
                          {product?.offerExpiresDate ? (
                            <div>
                              <span>
                                {availableOffersExpiryDateLabel &&
                                  availableOffersExpiryDateLabel?.value && (
                                    <RichTextHelper field={availableOffersExpiryDateLabel} />
                                  )}

                                {product?.offerExpiresDate}
                              </span>
                            </div>
                          ) : (
                            <>
                              {availableOffersNoExpiryDateLabel &&
                                availableOffersNoExpiryDateLabel?.value && (
                                  <div>
                                    <span>
                                      <RichTextHelper field={availableOffersNoExpiryDateLabel} />
                                    </span>
                                  </div>
                                )}
                            </>
                          )}
                        </div>

                        {availableOffersQualifyingPurchasesLinkText &&
                          availableOffersQualifyingPurchasesLinkText?.value && (
                            <RichTextHelper field={availableOffersQualifyingPurchasesLinkText} />
                          )}

                        {!loading && (
                          <div onClick={(e) => e.stopPropagation()}>
                            <ButtonHelper
                              isLinkField={true}
                              size="small"
                              variant={'primary'}
                              className={shopNowButton()}
                              field={{
                                value: {
                                  href: product?.ShopNowUrl || '#shop-now',
                                  text: shopNowButtonText?.value,
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {openModalIndex === index && (
                        <ModalWrapper
                          showCloseButtonInModalHeader={false}
                          closeModalOnOverlayClick={true}
                          onCloseClick={handleModalClose}
                          showModal={true}
                          customPopup={true}
                          popupWidth="max-w-[500px]"
                          popupSpacing="p-[15px]"
                        >
                          <div>
                            {product && product?.programid && (
                              <ContentHubImage programid={product?.programid} />
                            )}
                          </div>
                          <div className={modalProgramStyles()}>
                            <h3 className={modalProgramName()}>{product?.shortProgramName}</h3>
                          </div>
                          <div className={modalProgramDescription()}>{product?.description}</div>
                          {product?.offerExpiresDate ? (
                            <div className={productShortDescription()}>
                              {product?.offerExpiresDate}
                            </div>
                          ) : (
                            <div className={productNoExpiryDateText()}>
                              {noExpiryDateText && noExpiryDateText?.value && (
                                <RichTextHelper field={noExpiryDateText} />
                              )}
                            </div>
                          )}
                          {product?.termCondition && product?.termCondition?.length > 0 && (
                            <div className={termConditionStyles()}>
                              <div className={termsAndConditionLabelStyle()}>
                                {termsAndConditionLabel && termsAndConditionLabel?.value && (
                                  <RichTextHelper field={termsAndConditionLabel} />
                                )}
                              </div>
                              <div className={termsAndConditionTextStyle()}>
                                {product?.termCondition}
                              </div>
                            </div>
                          )}
                        </ModalWrapper>
                      )}
                    </>
                  ))}
              </div>

              <div>
                {products?.length < totalProducts &&
                  availableOffersViewMoreLinkText &&
                  availableOffersViewMoreLinkText?.value && (
                    <div className={loadMoreButtonWrapper()}>
                      {!loading ? (
                        <ButtonHelper
                          isLinkField={false}
                          size="small"
                          variant={'primary'}
                          className={loadMoreButton()}
                          field={availableOffersViewMoreLinkText}
                          onClickHandler={() => loadMore()}
                        />
                      ) : (
                        <div className={loadMoreButtonLoadingMore()}>
                          <Loader />
                          {getDictionaryValue('LoadingMore')}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </>
          ) : (
            <>
              {noAvailableOffersMessage && noAvailableOffersMessage?.value && (
                <RichTextHelper field={noAvailableOffersMessage} />
              )}
            </>
          )}
        </>
      ) : (
        <>{errorMessage}</>
      )}
    </div>
  );
};

export default AvailableOffers;
