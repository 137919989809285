import React, { useContext, useEffect, useState } from 'react';
import TextHelper from '../commonComponents/TextHelper';
import ImageHelper from '../commonComponents/ImageHelper';
import { productDetailDeliveryMethodTailwindVariant } from 'tailwindVariants/components/ProductDetails/productDetailDeliveryMethodTailwindVariant';
import { useOcSelector } from 'src/redux/ocStore';
import useDictionary from 'src/hooks/useDictionary';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';
import ComponentContext from 'lib/context/ComponentContext';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Order } from 'ordercloud-javascript-sdk';
import { FulfillmentTyeForPDP, FulfillmentType, FulfillmentTypePDPValue } from '../Constants';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { patchOrder } from 'src/redux/ocCurrentOrder';
import { useAutoshipMessage } from '../utility/AutoshipHelper';
import ProductDetailPrice from './ProductDetailPrice';
import { useRouter } from 'next/router';
// import { useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { calculateDiscount, calculateSaveAmount } from 'src/utils/priceUtils';
import { PdpDatasourceData } from 'components/ProductDetail/ProductDetail';
import Link from 'next/link';

const {
  topTooltipWrapper,
  tooltipContainer,
  topToolTipDescription,
  tooltipPhoneNumber,
  topTooltipArrowFirstDiv,
  topTooltipArrowSecondDiv,
  tooltipText,
} = productDetailTailwindVariant({
  size: { initial: 'mobile', lg: 'desktop' },
});

const RenderPickUpMethod = ({
  isSelected,
  productData,
  showLoader,
  setShowLoader,
  priceData,
  fields,
}: {
  isSelected?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productData?: any;
  showLoader: boolean;
  setShowLoader: (a: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  priceData?: any;
  fields: PdpDatasourceData;
}) => {
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const onBOPISClick = async () => {
    if (isSelected || showLoader) {
      return;
    }
    setShowLoader(true);
    if (currentOrder?.order && currentOrder?.order?.ID) {
      const request: Order = { xp: { Fulfillment: FulfillmentType.BOPIS } };
      await dispatch(patchOrder({ request }));
      localStorage.removeItem('Is_Autoship_Method');
      setcomponentContextData({
        ...componentContextData,
        isAutoshipMethod: false,
      });
    } else {
      localStorage.setItem('selected_fulfillment_Method', FulfillmentType.BOPIS);
    }
  };

  return (
    <div className={deliveryMethodWrapper({ isSelected })} onClick={onBOPISClick}>
      <div className={titleWrapper({ isSelected })}>
        {/* Title field to be CMS Configurable: */}
        <TextHelper tag="p" className={deliveryTitle({ isSelected })} field={fields?.pickupTitle} />
      </div>
      <div className={deliveryDetailWrapper()}>
        <div className={priceWrapper({ className: '!w-[54px]' })}>
          <ImageHelper field={fields?.pickupIcon} />
          {fields?.pickupText?.value}
        </div>
        {/* Price UI Component */}
        <ProductDetailPrice
          strikeThrough={priceData?.strikeThrough}
          strikeThroughPrice={priceData?.strikeThroughPrice}
          priceToRender={priceData?.priceToRender}
          productData={priceData?.productData}
          saveAmount={priceData?.saveAmount}
          isAutoship={false}
        />
      </div>

      <div className={deliveryAddressWrapper({ className: 'relative' })}>
        <div className={deliveryAvailabilityMessage()}>
          {/* Pickup at store field to be CMS Configurable: */}
          {getDictionaryValue('PickupAtYourStorePDP')}
          <div
            className={changeAddressText({
              className: 'static lg:relative group ',
            })}
            onClick={(e) => e?.stopPropagation()}
          >
            <span className={changeAddressText({ className: 'cursor-pointer' })}>
              {myStoreData?.storeName}
            </span>
            {/* {showStoreTooltip && ( */}
            <div
              className={topTooltipWrapper({
                className: '!bottom-[32px]',
              })}
            >
              <div className={tooltipContainer()}>
                <div className={topTooltipArrowFirstDiv()}></div>
                <div className={topTooltipArrowSecondDiv()}></div>
                <div className={topToolTipDescription()}>
                  <p>{getDictionaryValue('OrderReadyMessagePDP')}</p>
                  <div className={tooltipText()}>
                    <p>
                      <span>{`${myStoreData?.street1}, ${myStoreData?.city}, ${myStoreData?.state}`}</span>
                    </p>
                    {myStoreData?.phone ? (
                      <Link
                        aria-label="phone number link"
                        href={`tel:${myStoreData?.phone}`}
                        className={tooltipPhoneNumber()}
                      >
                        {myStoreData?.phone as React.ReactNode}
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
          |
          <span
            className={changeAddressText({
              className: `flex items-center cursor-pointer ${showLoader ? '!cursor-wait' : ''}`,
            })}
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (showLoader) {
                return;
              }
              setShowLoader(true);
              setcomponentContextData({
                ...componentContextData,
                showChangeStorePopup: true,
                productId: productData?.ID,
              });
            }}
          >
            {getDictionaryValue('ChangeStorePDP')}&nbsp;»
          </span>
        </div>
      </div>
    </div>
  );
};
const RenderDeliveryMethod = ({
  isSelected,
  productData,
  reRenderComponent,
  showLoader,
  setShowLoader,
  priceData,
  fields,
  address,
}: {
  isSelected?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productData?: any;
  reRenderComponent: () => void;
  showLoader: boolean;
  setShowLoader: (a: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  priceData?: any;
  fields: PdpDatasourceData;
  address?: {
    Street1?: string;
  };
}) => {
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { getDictionaryValue } = useDictionary();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const onDFSClick = async () => {
    if (isSelected || showLoader) {
      return;
    }
    setShowLoader(true);
    if (!address?.Street1) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
        openDeliveryAvailabilityPopupFromPDP: productData?.ID,
      });
    } else {
      if (currentOrder?.order && currentOrder?.order?.ID) {
        const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
        await dispatch(patchOrder({ request }));
        reRenderComponent();
        localStorage.removeItem('Is_Autoship_Method');
        setcomponentContextData({
          ...componentContextData,
          isAutoshipMethod: false,
        });
      } else {
        localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
      }
    }
  };
  return (
    <div className={deliveryMethodWrapper({ isSelected })} onClick={onDFSClick}>
      <div className={titleWrapper({ isSelected })}>
        <TextHelper
          tag="p"
          className={deliveryTitle({ isSelected })}
          field={fields?.deliveryTitle}
        />
      </div>
      <div className={deliveryDetailWrapper()}>
        <div className={priceWrapper({ className: '!w-[54px]' })}>
          <ImageHelper field={fields?.deliveryIcon} />
          {fields?.deliveryText?.value}
        </div>
        {/* Price UI Component */}
        <ProductDetailPrice
          strikeThrough={priceData?.strikeThrough}
          strikeThroughPrice={priceData?.strikeThroughPrice}
          priceToRender={priceData?.priceToRender}
          productData={priceData?.productData}
          saveAmount={priceData?.saveAmount}
          isAutoship={false}
        />
      </div>
      <div className={deliveryAddressWrapper()}>
        <div className={deliveryAvailabilityMessage()}>
          {address?.Street1 ? (
            <>
              {fields?.delivertoLabel?.value ?? 'Deliver to:'}
              <span className={changeAddressText({ className: '!no-underline' })}>
                {address?.Street1}
              </span>
            </>
          ) : (
            <p>{fields?.availabilityNearYouText?.value ?? 'See Availability Near You'}</p>
          )}{' '}
          <span className={changeAddressText()}>|</span>
          <span
            className={changeAddressText({
              className: showLoader ? 'cursor-wait' : 'cursor-pointer',
            })}
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (showLoader) {
                return;
              }
              setShowLoader(true);
              setcomponentContextData({
                ...componentContextData,
                showDeliveryAvailabilityPopup: true,
                openDeliveryAvailabilityPopupFromPDP: productData?.ID,
              });
            }}
          >
            {address?.Street1
              ? getDictionaryValue('ChangeAddressPDP')
              : getDictionaryValue('CheckAvailabilityPDP')}
            &nbsp;»
          </span>
        </div>
        <p className={deliveryMessage()}>{fields?.deliveryInfo?.value}</p>
      </div>
    </div>
  );
};
const RenderAutoshipMethod = ({
  isSelected,
  productData,
  reRenderComponent,
  showLoader,
  setShowLoader,
  priceData,
  firstTimeDiscount,
  fields,
  address,
}: {
  isSelected?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productData?: any;
  reRenderComponent: () => void;
  showLoader: boolean;
  setShowLoader: (a: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  priceData?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firstTimeDiscount?: any;
  fields: PdpDatasourceData;
  address?: {
    Street1?: string;
  };
}) => {
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { getDictionaryValue } = useDictionary();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const {
    tooltipWrapper,
    tooltipContainer,
    tooltipArrowFirstDiv,
    toolTipTitle,
    toolTipDescription,
    toolbarIconWrapper,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const onAutoshipClick = async () => {
    if (isSelected || showLoader) {
      return;
    }
    setShowLoader(true);
    if (!address?.Street1) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
        openDeliveryAvailabilityPopupFromPDP: productData?.ID,
        autoshipProductIdFromPDP: productData?.ID,
      });
    } else {
      if (currentOrder?.order && currentOrder?.order?.ID) {
        const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
        if (address?.Street1) {
          localStorage.setItem('Is_Autoship_Method', 'true');
          setcomponentContextData({
            ...componentContextData,
            isAutoshipMethod: true,
          });
        }
        await dispatch(patchOrder({ request }));
        reRenderComponent();
      } else {
        localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
      }
    }
  };
  const autoshipMessage = useAutoshipMessage({
    product: { ...productData, xp: productData?.Xp },
    autoshipMarketingText: 'AutoshipMarketingPDP',
  });

  return (
    <div className={deliveryMethodWrapper({ isSelected })} onClick={onAutoshipClick}>
      <div className={titleWrapper({ isSelected })}>
        <TextHelper
          tag="p"
          className={deliveryTitle({ isSelected })}
          field={{
            value: autoshipMessage,
          }}
        />
      </div>
      <div className={deliveryDetailWrapper()}>
        <div className={priceWrapper({ className: '!w-[54px]' })}>
          <ImageHelper field={fields?.autoshipIcon} />
          {fields?.autoshipText?.value}
        </div>
        {/* Price UI Component */}
        <ProductDetailPrice
          strikeThrough={priceData?.strikeThrough}
          strikeThroughPrice={priceData?.strikeThroughPrice}
          priceToRender={priceData?.priceToRender}
          productData={priceData?.productData}
          saveAmount={priceData?.saveAmount}
          isAutoship={true}
          firstTimeDiscObj={firstTimeDiscount}
        />
      </div>
      <div className={deliveryInstructionsWrapper()}>
        <div className={deliveryInstructionWrapper()}>
          <ImageHelper field={fields?.scheduleIcon} />
          <p className={deliveryInstruction()}>{fields?.scheduleText?.value}</p>
        </div>
        <div className={deliveryInstructionWrapper()}>
          <ImageHelper field={fields?.deliverySaleIcon} />
          <p className={deliveryInstruction()}>{fields?.deliverySaleText?.value}</p>
        </div>
        <div className={deliveryInstructionWrapper()}>
          <ImageHelper field={fields?.deliveryCancelIcon} />
          <p className={deliveryInstruction()}>{fields?.deliveryCancelText?.value}</p>
        </div>
      </div>
      <div className="flex items-center justify-center mt-[8px] gap-[4px] relative">
        <div className="group" onClick={(e) => e?.stopPropagation()}>
          <p className="cursor-pointer">Learn more about Autoship&nbsp;»</p>
          <div
            className={toolbarIconWrapper({
              className:
                '!static w-fit invisible opacity-0 group-hover:visible group-hover:opacity-100 ',
            })}
          >
            <div
              className={tooltipWrapper({
                className: '!bg-color-autoship-purple border-color-teritary-color-purple',
              })}
            >
              <div className={tooltipContainer()}>
                <div
                  className={tooltipArrowFirstDiv({
                    className: 'border-color-teritary-color-purple',
                  })}
                ></div>
                <ul className={toolTipDescription()}>
                  {getDictionaryValue('DeliveryLearnMoreButtonToolipOrderPDP')
                    ?.split('|')
                    ?.map((value: string, index: number) => (
                      <li key={index}>{value}</li>
                    ))}
                </ul>
                <p className={toolTipTitle()}>{getDictionaryValue('AutoshipEnrollmentPDP')}</p>
                <ul className={toolTipDescription()}>
                  {getDictionaryValue('DeliveryLearnMoreButtonToolipEnrollmentPDP')
                    ?.split('|')
                    ?.map((value: string, index: number) => (
                      <li key={index}>{value}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={deliveryAddressWrapper()}>
        <div className={deliveryAvailabilityMessage()}>
          {address?.Street1 ? (
            <>
              {fields?.delivertoLabel?.value ?? 'Deliver to:'}
              <span className={changeAddressText({ className: '!no-underline' })}>
                {address?.Street1}
              </span>
            </>
          ) : (
            <p>{fields?.availabilityNearYouText?.value ?? 'See Availability Near You'}</p>
          )}{' '}
          <span className={changeAddressText()}>|</span>
          <span
            className={changeAddressText({
              className: showLoader ? 'cursor-wait' : 'cursor-pointer',
            })}
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (showLoader) {
                return;
              }
              setShowLoader(true);
              setcomponentContextData({
                ...componentContextData,
                showDeliveryAvailabilityPopup: true,
                openDeliveryAvailabilityPopupFromPDP: productData?.ID,
                autoshipProductIdFromPDP: productData?.ID,
              });
            }}
          >
            {address?.Street1
              ? getDictionaryValue('ChangeAddressPDP')
              : getDictionaryValue('CheckAvailabilityPDP')}
            &nbsp;»
          </span>
        </div>
        <p className={deliveryMessage()}>{fields?.deliveryInfo?.value}</p>
      </div>
    </div>
  );
};

const {
  base,
  deliveryAddressWrapper,
  title,
  containerWrapper,
  deliveryMethodWrapper,
  titleWrapper,
  deliveryTitle,
  deliveryMessage,
  priceWrapper,
  changeAddressText,
  deliveryInstructionsWrapper,
  deliveryDetailWrapper,
  deliveryInstructionWrapper,
  deliveryInstruction,
  deliveryAvailabilityMessage,
} = productDetailDeliveryMethodTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

const ProductDetailDeliveryMethod = ({ fields }: { fields: PdpDatasourceData }) => {
  const [showLoader, setShowLoader] = useState(true);
  const [state, setState] = useState<FulfillmentTypePDPValue>(FulfillmentTyeForPDP.BOPIS);
  const { sitecoreContext } = useSitecoreContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productData: any = sitecoreContext.productData;
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { componentContextData } = useContext(ComponentContext);

  const [priceToRender, setPriceToRender] = useState(0.0);
  const [strikeThrough, setStrikeThrough] = useState(false);

  const firstTimeDiscountPercentage = useOcSelector(
    (state) => state?.storeReducer?.selectedStore?.autoshipSettings?.firstTimeDiscount
  );
  const firstTimeDiscountLimit = useOcSelector(
    (state) => state?.storeReducer?.selectedStore?.autoshipSettings?.firstTimeDiscountLimit
  );
  const subsequentOrdersDiscount = useOcSelector(
    (state) => state?.storeReducer?.selectedStore?.autoshipSettings?.subsequentOrdersDiscount
  ) as number;
  const isOcAuth = useOcSelector((state) => state?.ocAuth);

  const [address, setAddress] = useState<{
    Street1?: string;
  }>({});
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
        setAddress(currentOrder?.lineItems?.[0]?.ShippingAddress || {});
      } else if (localStorage.getItem('selected_delivery_address')) {
        setAddress(JSON.parse(localStorage.getItem('selected_delivery_address') || ''));
      } else {
        setAddress({});
      }
    }
  }, [currentOrder?.lineItems, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [firstTimeDiscount, setFirstTimeDiscount] = useState<any>({
    renderFirstTimeDiscountPrice: true,
    firstTimeDiscountPrice: 0,
    firstTimeDiscountPercentage: 0,
    firstTimeDiscountLimit: 0,
    avoidDiscount: false,
  });

  useEffect(() => {
    // console.log('firstTimeDiscountLimit:', firstTimeDiscountLimit);
    setFirstTimeDiscount({
      ...firstTimeDiscount,
      firstTimeDiscountPercentage: firstTimeDiscountPercentage,
      firstTimeDiscountLimit: firstTimeDiscountLimit,
    });
  }, [firstTimeDiscountLimit, firstTimeDiscountPercentage]);

  // As per FSD: List price - displayed next to Saved price detail in small font with strike off, when member price or IMAP is available
  const [strikeThroughPrice, setStrikeThroughPrice] = useState(0.0);
  const dummySaveAnmount = {
    amount: 0.0,
    percentage: 0.0,
  };
  const [saveAmount, setSaveAmount] = useState(dummySaveAnmount);
  const router = useRouter();

  // check First time autoship discount:

  // Check on user: Will only get ReceivedFirstTimeAutoshipDiscount field is authenticated.
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  );

  // const priceForPDP = useLineItemPriceForUI(productData, true);
  // console.log('priceForPDP: ', priceForPDP);

  // console.log('firreceivedFirstTimeAutoshipDiscount:', receivedFirstTimeAutoshipDiscount);

  /**
   * Effect to render Price, Strikethrough and saved prices
   */
  useEffect(() => {
    // console.log('productData:', productData);
    if (productData) {
      if (productData?.ProductPrice && productData?.ProductPrice?.ViewPriceInCart) {
        // render IMapPrice
        // Logic to render strikethrough in Imap Showcase:
        const currentStrikePrice =
          productData?.ProductPrice?.ListPrice > productData?.ProductPrice?.IMapPrice
            ? productData?.ProductPrice?.ListPrice
            : productData?.ProductPrice?.IMapPrice;
        setPriceToRender(productData?.ProductPrice?.IMapPrice);
        // setStrikeThroughPrice(productData?.ProductPrice?.IMapPrice);
        setStrikeThroughPrice(currentStrikePrice);
        setStrikeThrough(true);
        setSaveAmount(dummySaveAnmount);
      } else {
        if (productData?.ProductPrice?.PPCDiscount) {
          // Render PPCPrice
          setPriceToRender(productData?.ProductPrice?.PPCPrice);
          setStrikeThroughPrice(productData?.ProductPrice?.ListPrice);
          setStrikeThrough(true);

          // Save amount and percentage logic:
          if (productData?.ProductPrice?.ListPrice > productData?.ProductPrice?.PPCPrice) {
            const calculatedSaveAmount = calculateSaveAmount(
              productData?.ProductPrice?.ListPrice,
              productData?.ProductPrice?.PPCPrice
            );
            // console.log('calculatedSaveAmount: ', calculatedSaveAmount);
            setSaveAmount(calculatedSaveAmount);
          } else {
            setSaveAmount(dummySaveAnmount);
          }
        } else {
          // Render ListPrice
          setPriceToRender(productData?.ProductPrice?.ListPrice);
          setStrikeThrough(false);
          setSaveAmount(dummySaveAnmount);
        }

        if (productData?.Xp?.FirstTimeAutoshipDiscount) {
          if (receivedFirstTimeAutoshipDiscount) {
            // If already availed first time discount, render list or ppc price instead
            setFirstTimeDiscount({
              ...firstTimeDiscount,
              avoidDiscount: true,
            });
          } else {
            if (productData?.ProductPrice?.PPCDiscount) {
              // Calcuate based on PPC PRice
              priceData.strikeThroughPrice = productData?.ProductPrice?.PPCPrice;

              const discountedPrice = calculateDiscount(
                productData?.ProductPrice?.PPCPrice,
                firstTimeDiscount?.firstTimeDiscountPercentage,
                firstTimeDiscountLimit
              );

              if (discountedPrice)
                setFirstTimeDiscount({
                  ...firstTimeDiscount,
                  renderFirstTimeDiscountPrice: true,
                  firstTimeDiscountPrice: discountedPrice,
                });
            } else {
              // fetch amount to get 35% off on productData?.ProductPrice?.ListPrice
              // If actual discount is greater than discount threshold limit, use discount threshold limit instead
              setStrikeThroughPrice(productData?.ProductPrice?.ListPrice);

              const discountedPrice = calculateDiscount(
                productData?.ProductPrice?.ListPrice,
                firstTimeDiscount?.firstTimeDiscountPercentage,
                firstTimeDiscountLimit
              );

              if (discountedPrice)
                setFirstTimeDiscount({
                  ...firstTimeDiscount,
                  renderFirstTimeDiscountPrice: true,
                  firstTimeDiscountPrice: discountedPrice,
                });
            }
          }
        }
      }
    }
  }, [
    router,
    sitecoreContext.productData,
    firstTimeDiscountPercentage,
    firstTimeDiscountLimit,
    receivedFirstTimeAutoshipDiscount,
    subsequentOrdersDiscount,
    isOcAuth,
  ]);

  useEffect(() => {
    // console.log('Current Context: ', sitecoreContext.productData);
  }, [sitecoreContext.productData]);

  useEffect(() => {
    if (
      !componentContextData?.showChangeStorePopup &&
      !componentContextData.showDeliveryAvailabilityPopup
    ) {
      setShowLoader(false);
    }
  }, [
    componentContextData?.showChangeStorePopup,
    componentContextData.showDeliveryAvailabilityPopup,
  ]);

  const switchBetweenTypes = () => {
    const storedMethod = localStorage.getItem('selected_fulfillment_Method');
    const isAutoship =
      componentContextData?.isAutoshipMethod === true ||
      localStorage.getItem('Is_Autoship_Method') === 'true';

    const newMethod = storedMethod || fulfillmentMethod;
    const supportsAutoship = productData?.Xp?.Autoship ?? false;

    let updatedState = state; // Preserve current state to prevent unnecessary updates

    if (isAutoship && supportsAutoship) {
      updatedState = FulfillmentTyeForPDP.AUTOSHIP;
    } else if (
      !supportsAutoship &&
      (isAutoship || fulfillmentMethod === FulfillmentTyeForPDP.DFS)
    ) {
      updatedState = FulfillmentTyeForPDP.DFS;
    } else {
      updatedState = newMethod as FulfillmentTypePDPValue;
    }

    // Set state only if it has changed
    if (updatedState !== state) {
      setState(updatedState);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!router?.isReady && !router?.query?.sku) return;
    switchBetweenTypes();
  }, [
    fulfillmentMethod,
    myStoreData?.storeId,
    componentContextData?.isDFSMethodChanged,
    componentContextData?.isAutoshipMethod,
    componentContextData?.autoshipProductIdFromPDP,
    router?.isReady,
    router?.query?.sku,
  ]);

  useEffect(() => {
    if (componentContextData?.autoshipProductIdFromPDP) {
      switchBetweenTypes();
    }
  }, [componentContextData?.isAutoshipMethod, componentContextData?.autoshipProductIdFromPDP]);

  useEffect(() => {
    setShowLoader(false);
  }, [state]);

  const priceData = {
    strikeThrough: strikeThrough,
    strikeThroughPrice: strikeThroughPrice,
    priceToRender: priceToRender,
    productData: productData,
    saveAmount: saveAmount,
  };

  return (
    <div
      className={base({
        className: showLoader ? '!cursor-wait' : '',
      })}
    >
      <TextHelper field={fields?.selectOptionText} tag="p" className={title()} />
      <div className={containerWrapper()}>
        {productData?.Xp?.Curbside && (
          <RenderPickUpMethod
            isSelected={state === FulfillmentTyeForPDP.BOPIS}
            productData={productData}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            priceData={priceData}
            fields={fields}
            // priceType={saveAmount?.amount > 0 ? 'ppc' : 'list'}
          />
        )}
        {myStoreData?.disableDelivery !== 1 && productData?.Xp?.Delivery && (
          <RenderDeliveryMethod
            isSelected={
              state === FulfillmentTyeForPDP.DFS && Boolean(address?.Street1?.trim()?.length)
            }
            productData={productData}
            reRenderComponent={() => {
              setState(FulfillmentTyeForPDP.DFS);
            }}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            priceData={priceData}
            fields={fields}
            address={address}
            // priceType={saveAmount?.amount > 0 ? 'ppc' : 'list'}
          />
        )}
        {myStoreData?.disableDelivery !== 1 && productData?.Xp?.Autoship && (
          <RenderAutoshipMethod
            isSelected={
              state === FulfillmentTyeForPDP.AUTOSHIP && Boolean(address?.Street1?.trim()?.length)
            }
            productData={productData}
            reRenderComponent={() => {
              setState(FulfillmentTyeForPDP.AUTOSHIP);
            }}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            priceData={priceData}
            firstTimeDiscount={firstTimeDiscount}
            fields={fields}
            address={address}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailDeliveryMethod;
