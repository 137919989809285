import { tv } from 'tailwind-variants';

export const servicePanelVariants = tv(
  {
    slots: {
      base: ['servicePanel container mx-auto pr-[0] lg:pr-[9px]'],
      imgWrapper: ['tileImage [&>img]:w-full [&>img]:h-full [&>img]:object-cover'],
      tileDetailWrapper: [
        'tileDetails h-[206px] flex flex-col w-full justify-between text-body-large-regular font-body-large-regular leading-body-large-regular',
      ],
      panelContent: ['flex relative items-stretch gap-desk-global-grid-gutter', ''],
      singleSlide: [
        'w-full singleSlide hidden',
        '[&_.splide_li]:h-[673px] [&_.splide_li]:!min-w-[404px] [&_.splide_li]:w-full [&_.splide_li>*]:w-full ',
        '[&_.tileImage]:!min-h-[404px] [&_.tileImage]:!h-[404px] [&_.tileImage>img]:!h-[404px]',
        '[&_.tileDetails]:h-full',
        'lg:[&_.splide_.arrow-prev]:-left-[1%]',
        'lg:[&_.splide_.arrow-next]:-right-[-1%]',
        'xl:[&_.splide_.arrow-prev]:-left-[2%]',
        'xl:[&_.splide_.arrow-next]:-right-[1%]',
        '2xl:[&_.splide_.arrow-prev]:-left-[3%]',
        '2xl:[&_.splide_.arrow-next]:-right-[4%]',
      ],
      carouselWrapper: ['mainCarousel', '[&_.splide_ul]:items-end', '[&_.splide_li]:!w-fit'],
      panelDetails: ['flex flex-col gap-desk-global-spacing-spacing-3'],
      panelHeading: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold [&>span]:text-color-brand-primary-1-base',
      ],
      panelWrapper: ['flex  self-stretch items-start justify-between flex-col'],
      panelDescription: [''],
      tileWrapper: [
        'tile w-[240px] lg:w-[256px] border-[1px] overflow-hidden lg:h-full flex flex-col self-stretch items-center border-color-scale-3-light-mid',
      ],
      tileTextWrapper: ['tileTextWrapper', 'flex flex-col'],
      tileHeading: ['tileHeading', 'text-color-scale-8-dark'],
      tileSubHeading: [
        'tileSubhead',
        'text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold',
        'text-color-scale-7-mid-dark',
      ],
      tileDescription: [
        'tileDescription line-clamp-3 text-body-small-regular font-body-small-regular leading-body-small-regular',
        'text-color-scale-8-dark',
      ],
      ctaWrapper: [
        'flex items-center gap-mob-space-between-micro-horizontal',
        'decoration-color-text-brand-1',
      ],
      tileCTA: [
        'ml-auto text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold',
      ],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        '[&>*>*]:fill-color-text-brand-1]',
        'ml-mob-space-between-micro-horizontal',
      ],

      //Events Slot
      eventWrapper: [''],
      eventAndTileWrapper: ['eventAndTileWrapper', 'grid'],
      eventHeading: [
        'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      ],
      eventsTiles: [
        'w-full',
        'flex justify-evenly',
        'gap-mob-global-spacing-spacing-3',
        'p-mob-global-spacing-spacing-4',
      ],
      eventTile: [
        'flex flex-col',
        'item-center text-center',
        'border-color-background-light-mid',
        'pt-desk-global-spacing-spacing-6 pb-[36px] px-desk-global-spacing-spacing-9',
      ],
      eventBorder: ['border-color-scale-3-light-mid '],
      eventTitle: [''],
      eventDataContainer: [
        'flex justify-center items-center',
        'gap-mob-global-spacing-spacing-1',
        'mb-[5px]',
      ],
      eventDate: [''],
      eventLocation: [''],
      marketingTiles: ['flex justify-between'],
    },
    compoundSlots: [
      {
        slots: ['eventDate', 'eventLocation'],
        class: ['text-body-large-regular font-body-large-regular leading-body-large-regular'],
      },
      {
        slots: ['eventBorder', 'eventsTiles'],
        class: ['border-[1px]'],
      },
      {
        slots: ['carouselWrapper', 'panelDetails', 'panelWrapper'],
        class: ['max-w-full'],
      },
    ],
    variants: {
      size: {
        mobile: {
          base: ['!pl-mob-global-spacing-spacing-5 py-mob-padding-micro-y'],
          tileDetailWrapper: [
            'px-mob-global-spacing-spacing-4 pt-mob-global-spacing-spacing-4 pb-mob-global-spacing-spacing-5',
          ],
          tileHeading: [
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          ],
          singleSlide: ['hidden'],
          tileTextWrapper: ['gap-mob-margin-tight-bottom'],
          panelWrapper: ['gap-mob-global-spacing-spacing-4'],
          carouselWrapper: ['gap-mob-global-spacing-spacing-4', '[&_.splide_li]:w-[240px]'],
          panelDetails: ['pr-mob-global-spacing-spacing-5'],
          tileWrapper: [''],
          imgWrapper: ['w-[240px] h-[240px] [&>img]:w-[240px] [&>img]:h-[240px]'],
          eventWrapper: ['py-mob-padding-tight-bottom', 'pr-mob-global-spacing-spacing-5'],
          eventAndTileWrapper: ['flex-col', 'gap-mob-global-spacing-spacing-4'],
          eventHeading: ['mb-mob-margin-base-bottom'],
          eventsTiles: ['flex-col'],
          eventTitle: [
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
            'mb-desk-global-spacing-spacing-4',
          ],
          marketingTiles: ['flex-col', 'gap-mob-global-spacing-spacing-4'],
        },
        desktop: {
          base: ['py-desk-padding-micro-y', 'pr-0'],
          panelWrapper: ['overflow-hidden'],
          tileDetailWrapper: [
            'p-desk-global-spacing-spacing-5 !pt-desk-global-spacing-spacing-3 !pb-desk-global-spacing-spacing-6',
          ],
          tileHeading: [
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          ],
          carouselWrapper: ['gap-desk-global-grid-gutter', '[&_.splide>li]:!w-fit'],
          panelContent: ['pl-desk-global-spacing-spacing-5'],
          tileTextWrapper: ['gap-desk-global-spacing-spacing-2'],
          panelDetails: [
            'pt-[56.5px] ',
            'pb-desk-global-spacing-spacing-6 ',
            'px-desk-global-spacing-spacing-4',
          ],
          imgWrapper: ['h-[255px]', 'w-[255px]', '[&>img]:!h-[255px]', '[&>img]:w-[255px]'],
          tileWrapper: [''],
          singleSlide: [
            'block',
            '[&_.tileDetails]:!pt-desk-global-spacing-spacing-5',
            '[&_.tileDetails]:!pb-desk-global-spacing-spacing-6',
            '[&_.tileTextWrapper]:!gap-desk-margin-tight-bottom',
            '[&_.tileDescription]:text-body-large-regular [&_.tileDescription]:font-body-large-regular [&_.tileDescription]:leading-body-large-regular',
            '[&_.tileSubhead]:text-heading-desk-medium-bold [&_.tileSubhead]:font-heading-desk-medium-bold [&_.tileSubhead]:leading-heading-desk-medium-bold',
            '[&_.tileHeading]:text-heading-desk-large-bold [&_.tileHeading]:font-heading-desk-large-bold [&_.tileHeading]:leading-heading-desk-large-bold',
            // '[&_.splide_.arrow-prev]:-left-[4%]',
            // '[&_.splide_.arrow-next]:-right-[4%]',
            '[&_.splide_.splide-button]:bg-color-background-light',
            '[&_.splide_.splide-button]:h-[40px]',
            '[&_.splide_.splide-button]:w-[40px]',
            '[&_.splide_.splide-button>svg]:text-body-xSmall-regular',
            '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
            '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
            '[&_*_*.splide-button]:border-color-brand-primary-1-base',
            '[&_*_*.splide-button]:border-[2px]',
            '[&_*_*.splide-button]:border-solid',
            '[&_.splide_img]:!w-[404px]',
            '!w-[404px]',
            '[&_.splide_li]:!w-[256px]',
            '[&_.splide_li]:!h-full',
            '[&_.splide_img]:h-[404px]',
            '[&_.splide_.tile]:min-h-[673px]',
            '[&_.splide_.tile]:w-[404px]',
            '[&_.splide_.tileImage]:min-w-[404px]',
            '[&_.splide_.tileImage]:min-w-[404px]',
          ],
          eventWrapper: ['py-desk-padding-tight-bottom', 'px-desk-padding-micro-x'],
          eventAndTileWrapper: ['flex-row', 'gap-mob-global-spacing-spacing-5'],
          eventHeading: ['mb-desk-margin-base-bottom'],
          eventsTiles: ['flex-row'],
          eventTile: ['pb-desk-global-spacing-spacing-7'],
          eventTitle: [
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
            'mb-desk-global-spacing-spacing-5',
            'mt-[5px]',
          ],
          marketingTiles: ['flex-row', 'gap-mob-global-spacing-spacing-5'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
