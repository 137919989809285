import { useOcSelector } from 'src/redux/ocStore';
import { ProductSearchResultModelWithVariants } from './types';
import { useIsSoftLoginEnabled } from 'src/hooks/useIsSoftLoginEnabled';
import useOcCart from 'src/hooks/useOcCart';

export type InventoryStatus = 'InStock' | 'LowStock' | 'OutOfStock';

export function useProductInventory(
  product: ProductSearchResultModelWithVariants
): InventoryStatus {
  const store = useOcSelector((state) => state.storeReducer.selectedStore);

  const inventory =
    product.oc_inventory ??
    product.supplier_inventory ??
    product.inventoryrecords?.find((x) => x.ownerid === store.storeId)?.quantityavailable ??
    0;

  const numInCart = useNumInCart(product.id);
  const realInventory = inventory - numInCart;

  if (realInventory <= 0) {
    return 'OutOfStock';
  } else if (store.deliveryFee?.lowStock && realInventory <= store.deliveryFee?.lowStock) {
    return 'LowStock';
  } else {
    return 'InStock';
  }
}

function useNumInCart(productId?: string) {
  const { getProductLineItemsSoftLogin, getProductLineItems } = useOcCart();
  const isSoftLoginEnabled = useIsSoftLoginEnabled();
  const currentlineItems = isSoftLoginEnabled
    ? getProductLineItemsSoftLogin()
    : getProductLineItems();
  const productInLineItems = currentlineItems?.find((item) => item.ProductID === productId);

  const numInCart = productInLineItems?.Quantity ?? 0;

  return numInCart;
}
