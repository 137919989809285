import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from 'lib/context/ThemeContext';
import Script from 'next/script';
import { Fragment, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';
import OldLoyaltyWidget from './OldLoyaltyWidget/OldLoyaltyWidget';
import ServiceRewardsWidget from './ServiceRewardsWidget/ServiceRewardsWidget';
import styles from './LoyaltyTabSection.module.css';
import clsx from 'clsx';
import Loader from 'components/Loader/Loader';
import { loyaltyTabSectionTailwindVariant } from 'tailwindVariants/components/loyaltyTabSectionTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

export type CrowdTwistFieldsProps = {
  widgetId?: Field<string>;
  widgetType?: Field<string>;
  earnedOffersTileImage?: ImageField;
  inProgressTileImage?: ImageField;
  availableOffersTileImage?: ImageField;
  oldLoyaltyPageLink?: LinkField;
  availableOffersTileAnchorLink?: Field<string>;
  earnedOffersTileAnchorLink?: Field<string>;
  inProgressTileAnchorLink?: Field<string>;
};

type LoyaltyTabSectionProps = {
  fields: {
    crowdTwistWidgets: {
      name: string;
      fields: CrowdTwistFieldsProps;
    }[];
    tabAnchorTarget: Field<string>;
    tabHeading: Field<string>;
    tabName: Field<string>;
    tabSubheading: Field<string>;
  };
};

const {
  base,
  headingWrapper,
  heading,
  subHeadingWrapper,
  loyaltyWidgets,
  loadMoreButton,
  loadMoreButtonWrapper,
} = loyaltyTabSectionTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const LoyaltyTabSection = ({ fields }: LoyaltyTabSectionProps) => {
  const isExperienceEditor = useExperienceEditor();
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const [isDesktop, setIsDesktop] = useState(isExperienceEditor ? true : window.innerWidth > 768);
  const [hasIframe, setHasIframe] = useState(false);
  const [cookies] = useCookies(['ct-auth']);
  const { themeNameUpper } = useTheme();
  const crowdTwistWidgetRetries = 50; // times to call timeout while waiting from previous implementation
  const crowdTwistWidgetTimeout = 100; // in milliseconds from previous implementation
  const iframeIntervalTime = 10; // from previous implementation, after iframe has been found the first time
  const [, setIframeHeight] = useState(0);
  const [stopAutoHeightAdjustment, setStopAutoHeightAdjustment] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [isIframeHeightSet, setIsIframeHeightSet] = useState(false);
  const ctAuthCookie = cookies['ct-auth'];
  let crowdTwistWidgetChecks = 0;
  let urlOfProgram = '';

  // Earn Points
  const [loadMoreEarnPoints, setLoadMoreEarnPoints] = useState(false);
  const initIframeHeight = 1114; // from previous implementation, somewhat arbitrary number designed to [hopefully] show two rows of content for this section.
  let prevHeightOfIframes = -1;

  // Reward History/Coupons
  const [loadMoreRewardCoupons, setLoadMoreRewardCoupons] = useState(false);
  const initIframeHeightRC = 305; // from previous implementation, somewhat arbitrary number designed to [hopefully] show x rows of content for this section.
  let prevHeightOfIframesRC = -1;

  // // Activity History
  const [loadMoreActivityHistory, setLoadMoreActivityHistory] = useState(false);
  const initIframeHeightAC = 195;
  let prevHeightOfIframesAC = -1;

  if (themeNameUpper === 'PSP') {
    urlOfProgram = process.env.CROWD_TWIST_PSP_URL_OF_PROGRAM || '';
  } else {
    urlOfProgram = process.env.CROWD_TWIST_WAGNWASH_URL_OF_PROGRAM || '';
  }

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const intervalId: NodeJS.Timeout = setInterval(checkForIframes, crowdTwistWidgetTimeout);
    let foundIframeWithId = false;

    function checkForIframes() {
      const iframes = document.querySelectorAll('iframe');
      iframes.forEach((iframe) => {
        const widgetId = iframe.getAttribute('data-widget-id');

        if (widgetId) {
          foundIframeWithId = true;

          clearInterval(intervalId);
        }
      });
      if (foundIframeWithId) {
        setTimeout(() => {
          setHasIframe(true);
        }, 1000);
      }

      crowdTwistWidgetChecks++;

      if (crowdTwistWidgetChecks === crowdTwistWidgetRetries) {
        clearInterval(intervalId);
        setIsIframeHeightSet(true);
      }
    }

    checkForIframes();

    return () => {
      clearInterval(intervalId);
    };
  }, [crowdTwistWidgetChecks]);

  useEffect(() => {
    const iframeElem = document.querySelector(`#${fields?.tabAnchorTarget?.value} iframe`);
    const updateIframeHeight = () => {
      const height = iframeElem?.clientHeight || 0;
      setIframeHeight(height);
    };

    const checkIframeHeight = setInterval(() => {
      if (checkCount >= crowdTwistWidgetRetries) {
        clearInterval(checkIframeHeight);
      } else {
        updateIframeHeight();
        setCheckCount((prevCount) => prevCount + 1);
      }
    }, crowdTwistWidgetTimeout);

    window.addEventListener('load', updateIframeHeight);

    return () => {
      window.removeEventListener('load', updateIframeHeight);
      clearInterval(checkIframeHeight);
    };
  }, [checkCount, fields]);

  useEffect(() => {
    if (!stopAutoHeightAdjustment) {
      let checkEPIframeHeightInterval: number | NodeJS.Timeout;

      // Earn points
      if (typeof window !== undefined && window.innerWidth > 768) {
        const checkEPIframeHeight = () => {
          const sectionElem: HTMLElement | null = document.querySelector('#earn-points_section');
          const iframes = sectionElem?.querySelectorAll('iframe');

          let iframeHeightSet = false;
          let numOfIframesWithHeight = 0;
          let totalHeightOfIframes = 0;

          iframes?.forEach(function (iframe) {
            const height = iframe.offsetHeight;
            if (height > 0) {
              numOfIframesWithHeight++;
              totalHeightOfIframes += height;
            }
          });

          if (numOfIframesWithHeight === 2) {
            // if both iframes have a height
            if (totalHeightOfIframes != prevHeightOfIframes) {
              // this will force the heights to be checked at least twice and the current height will need to
              // match the previously checked heights hopefully meaning the iframe is finished loading
              if (prevHeightOfIframes < totalHeightOfIframes) {
                prevHeightOfIframes = totalHeightOfIframes;
              }
            } else if (!iframeHeightSet) {
              if (totalHeightOfIframes > initIframeHeight) {
                if (sectionElem instanceof HTMLElement) {
                  sectionElem.style.height = initIframeHeight + 'px';
                  sectionElem.classList.add('selected');
                  sectionElem.classList.remove('selected-no-after'); //remove class with gradient
                  setLoadMoreEarnPoints(true);
                  iframeHeightSet = true;
                  setIsIframeHeightSet(true);
                }
              } else {
                if (sectionElem instanceof HTMLElement) {
                  sectionElem.classList.add('selected-no-after');
                  sectionElem.classList.remove('selected');
                  setLoadMoreEarnPoints(false);
                  setIsIframeHeightSet(true);
                }
              }
            }
          }
        };

        checkEPIframeHeightInterval = setInterval(checkEPIframeHeight, iframeIntervalTime);
      } else {
        // revert the iframe height on mobile
        const sectionElem: HTMLElement | null = document.querySelector('#earn-points_section');
        if (!isDesktop && sectionElem instanceof HTMLElement) {
          sectionElem.style.height = 'auto';
        }
      }

      // Reward History/Coupons
      const checkRCIframeHeight = () => {
        const sectionRCElem: HTMLElement | null = document.querySelector('#reward-history_section');
        const iframesRC = sectionRCElem?.querySelectorAll('iframe');

        let iframeHeightSet = false;
        let numOfIframesWithHeight = 0;
        let totalHeightOfIframes = 0;

        iframesRC?.forEach(function (iframe) {
          const height = iframe.offsetHeight;
          if (height > 0) {
            numOfIframesWithHeight++;
            totalHeightOfIframes += height;
          }
        });

        if (numOfIframesWithHeight === 1) {
          // if both iframes have a height
          if (totalHeightOfIframes != prevHeightOfIframesRC) {
            // this will force the heights to be checked at least twice and the current height will need to
            // match the previously checked heights hopefully meaning the iframe is finished loading
            if (prevHeightOfIframesRC < totalHeightOfIframes) {
              prevHeightOfIframesRC = totalHeightOfIframes;
            }
          } else if (!iframeHeightSet) {
            if (totalHeightOfIframes > initIframeHeightRC) {
              if (sectionRCElem instanceof HTMLElement) {
                sectionRCElem.style.height = initIframeHeightRC + 'px';
                sectionRCElem.classList.add('selected');
                sectionRCElem.classList.remove('selected-no-after'); //remove class with gradient
                setLoadMoreRewardCoupons(true);
                iframeHeightSet = true;
              }
            } else {
              if (sectionRCElem instanceof HTMLElement) {
                sectionRCElem.classList.add('selected-no-after');
                sectionRCElem.classList.remove('selected');
                setLoadMoreRewardCoupons(false);
              }
            }
          }
        }
      };

      // Activity History
      const checkACIframeHeight = () => {
        const sectionACElem: HTMLElement | null = document.querySelector(
          '#activity-history_section'
        );
        const iframesAC = sectionACElem?.querySelectorAll('iframe');

        let iframeHeightSet = false;
        let numOfIframesWithHeight = 0;
        let totalHeightOfIframes = 0;

        iframesAC?.forEach(function (iframe) {
          const height = iframe.offsetHeight;
          if (height > 0) {
            numOfIframesWithHeight++;
            totalHeightOfIframes += height;
          }
        });

        if (numOfIframesWithHeight === 1) {
          // if both iframes have a height
          if (totalHeightOfIframes != prevHeightOfIframesAC) {
            // this will force the heights to be checked at least twice and the current height will need to
            // match the previously checked heights hopefully meaning the iframe is finished loading
            if (prevHeightOfIframesAC < totalHeightOfIframes) {
              prevHeightOfIframesAC = totalHeightOfIframes;
            }
          } else if (!iframeHeightSet) {
            if (totalHeightOfIframes > initIframeHeightAC) {
              if (sectionACElem instanceof HTMLElement) {
                sectionACElem.style.height = initIframeHeightAC + 'px';
                sectionACElem.classList.add('selected');
                sectionACElem.classList.remove('selected-no-after'); //remove class with gradient
                setLoadMoreActivityHistory(true);
                iframeHeightSet = true;
              }
            } else {
              if (sectionACElem instanceof HTMLElement) {
                sectionACElem.classList.add('selected-no-after');
                sectionACElem.classList.remove('selected');
                setLoadMoreActivityHistory(false);
              }
            }
          }
        }
      };

      const checkRCIframeHeightInterval = setInterval(checkRCIframeHeight, iframeIntervalTime);
      const checkACIframeHeightInterval = setInterval(checkACIframeHeight, iframeIntervalTime);

      const stopCheckingEPIframe = () => {
        if (checkEPIframeHeightInterval) {
          clearInterval(checkEPIframeHeightInterval);
        }
      };

      const stopCheckingRCIframe = () => {
        if (checkRCIframeHeightInterval) {
          clearInterval(checkRCIframeHeightInterval);
        }
      };

      const stopCheckingACIframe = () => {
        if (checkACIframeHeightInterval) {
          clearInterval(checkACIframeHeightInterval);
        }
      };

      const stopCheckingIframesTimeout = setTimeout(() => {
        stopCheckingEPIframe();
        stopCheckingRCIframe();
        stopCheckingACIframe;
      }, 30000); // Increase the timeout from 5s to 30s to match the site version 2.0

      // Clear the interval of the Iframe the timeout when the iframe are loaded
      if (loadMoreEarnPoints) {
        stopCheckingEPIframe();
      }

      if (loadMoreRewardCoupons) {
        stopCheckingRCIframe();
      }

      if (loadMoreActivityHistory) {
        stopCheckingACIframe();
      }

      return () => {
        stopCheckingEPIframe();
        stopCheckingRCIframe();
        stopCheckingACIframe();

        clearTimeout(stopCheckingIframesTimeout);
      };
    }

    return undefined;
  }, [isDesktop, loadMoreEarnPoints, loadMoreRewardCoupons, loadMoreActivityHistory]);

  // Anchor Scroll Behavior from an URL
  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const anchorHash = window.location.hash;
      if (anchorHash) {
        const anchorSection = document.querySelector(anchorHash);
        if (anchorSection) {
          setTimeout(() => {
            anchorSection.scrollIntoView({ behavior: 'smooth' });
          }, 1250); // Add a delay
        }

        if (
          anchorHash?.replace('#', '') === fields?.tabAnchorTarget?.value &&
          !anchorSection &&
          isIframeHeightSet // checking if Iframes height are set or not
        ) {
          setTimeout(() => setActiveTabFromUrl(), 1000);
        }
      }
    };
    setActiveTabFromUrl();

    window.addEventListener('hashchange', setActiveTabFromUrl);

    return () => {
      window.removeEventListener('hashchange', setActiveTabFromUrl);
    };
  }, [isIframeHeightSet]);

  const handleShowPointsClick = () => {
    const sectionElem: HTMLElement | null = document.querySelector('#earn-points_section');
    if (sectionElem instanceof HTMLElement) {
      sectionElem.classList.add('selected-no-after'); //switch to class without the gradient
      sectionElem.classList.remove('selected'); //remove class with gradient
      sectionElem.style.height = 'auto';
    }
    setLoadMoreEarnPoints(false);
    setStopAutoHeightAdjustment(true);
  };

  const handleShowRewardsClick = () => {
    const sectionRCElem: HTMLElement | null = document.querySelector('#reward-history_section');
    if (sectionRCElem instanceof HTMLElement) {
      sectionRCElem.classList.add('selected-no-after'); //switch to class without the gradient
      sectionRCElem.classList.remove('selected'); //remove class with gradient
      sectionRCElem.style.height = 'auto';
    }
    setLoadMoreRewardCoupons(false);
    setStopAutoHeightAdjustment(true);
  };

  const handleShowActivityClick = () => {
    const sectionACElem: HTMLElement | null = document.querySelector('#activity-history_section');
    if (sectionACElem instanceof HTMLElement) {
      sectionACElem.classList.add('selected-no-after'); //switch to class without the gradient
      sectionACElem.classList.remove('selected'); //remove class with gradient
      sectionACElem.style.height = 'auto';
    }
    setLoadMoreActivityHistory(false);
    setStopAutoHeightAdjustment(true);
  };

  if (isExperienceEditor) {
    return <div>{fields?.tabName?.value}: Content not available in Experience Editor</div>;
  }

  if (isLoyaltyAccepted === true && !isExperienceEditor) {
    return (
      <div id={fields?.tabAnchorTarget?.value} className={clsx(base(), styles.wrapperStyles)}>
        {ctAuthCookie ? (
          <>
            <Script src="https://resources.crowdtwist.com/v259/widgets/javascripts/widgets-sdk.0.3.1.js" />
            {fields?.tabHeading && (
              <div className={headingWrapper()}>
                <RichTextHelper className={heading()} field={fields?.tabHeading} />
              </div>
            )}
            {fields?.tabHeading && (
              <div className={subHeadingWrapper()}>
                <RichTextHelper field={fields?.tabSubheading} />
              </div>
            )}

            {!hasIframe && <Loader />}
          </>
        ) : (
          <>
            {fields?.tabName?.value.includes('Product Rewards') && (
              <>
                {fields?.tabHeading && (
                  <div className={headingWrapper()}>
                    <RichTextHelper className={heading()} field={fields?.tabHeading} />
                  </div>
                )}
                {fields?.tabHeading && (
                  <div className={subHeadingWrapper()}>
                    <RichTextHelper field={fields?.tabSubheading} />
                  </div>
                )}
              </>
            )}
            {fields?.tabName?.value.includes('Service Rewards') && (
              <>
                {fields?.tabHeading && (
                  <div className={headingWrapper()}>
                    <RichTextHelper className={heading()} field={fields?.tabHeading} />
                  </div>
                )}
                {fields?.tabHeading && (
                  <div className={subHeadingWrapper()}>
                    <RichTextHelper field={fields?.tabSubheading} />
                  </div>
                )}
              </>
            )}
          </>
        )}

        <section id={`${fields?.tabAnchorTarget?.value}_section`} className={loyaltyWidgets()}>
          {fields?.crowdTwistWidgets.map((item, index) => (
            <Fragment key={index}>
              {ctAuthCookie && item?.fields?.widgetId?.value && item?.fields?.widgetType?.value ? (
                <div
                  key={index}
                  className="ct-widget"
                  data-widget-id={item.fields.widgetId?.value}
                  data-src={`${urlOfProgram}/widgets/t/${item.fields.widgetType?.value}/${item.fields.widgetId?.value}`}
                ></div>
              ) : (
                <>
                  {item.name.includes('Old Loyalty Widget') && <OldLoyaltyWidget props={item} />}
                  {item.name.includes('Service Rewards Widget') && (
                    <ServiceRewardsWidget props={item} />
                  )}
                </>
              )}
            </Fragment>
          ))}
        </section>

        {loadMoreEarnPoints && (
          <>
            {fields?.tabAnchorTarget?.value === 'earn-points' && isDesktop && (
              <div className={loadMoreButtonWrapper()}>
                <button
                  aria-label="show more"
                  type="button"
                  className={loadMoreButton()}
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowPointsClick();
                  }}
                >
                  Show More
                </button>
              </div>
            )}
          </>
        )}
        {loadMoreRewardCoupons && (
          <>
            {fields?.tabAnchorTarget?.value === 'reward-history' && (
              <div className={loadMoreButtonWrapper()}>
                <button
                  aria-label="View All Rewards Button"
                  type="button"
                  className={loadMoreButton()}
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowRewardsClick();
                  }}
                >
                  View All Rewards
                </button>
              </div>
            )}
          </>
        )}
        {loadMoreActivityHistory && (
          <>
            {fields?.tabAnchorTarget?.value === 'activity-history' && (
              <div className={loadMoreButtonWrapper()}>
                <button
                  aria-label="show more"
                  type="button"
                  className={loadMoreButton()}
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowActivityClick();
                  }}
                >
                  Show More
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
  return;
};

export default LoyaltyTabSection;
