import { FulfillmentType, purchaseOrderStatus } from '../Constants';

export function getOrderStatus({
  orderStatus,
  fulFillmentType,
  manhattenStatus,
}: {
  orderStatus: string;
  fulFillmentType: string;
  manhattenStatus: string;
}) {
  const statusMapping = {
    commonMapping: {
      '6000.000': {
        DFS: 'Order is out for Delivery',
      },
      '6000.100': {
        DFS: 'Order was Delivered',
      },
      '6000.200': {
        DFS: 'Order was not Delivered',
      },
    } as StatusMap,
  };

  const statusKey = purchaseOrderStatus?.find((x) =>
    Object.keys(x).find((x) => x?.toLowerCase() == orderStatus?.toLowerCase())
  );
  if (statusKey) {
    const validOrderStatuses = ['readyforsettlement', 'settled', 'complete', 'sentforsettlement'];
    const statusValue = Object?.values(statusKey)?.[0];
    const status = Object?.keys(statusKey)?.[0]?.toLowerCase();
    if (validOrderStatuses.includes(status)) {
      if (fulFillmentType?.toUpperCase() === FulfillmentType?.BOPIS?.toUpperCase()) {
        return 'Order was Picked up';
      }
      const manhattanStatusMap = statusMapping.commonMapping[manhattenStatus];
      const orderTypeStatus = manhattanStatusMap
        ? manhattanStatusMap[fulFillmentType]
        : orderStatus?.toLowerCase() === 'complete'
        ? 'Order was Delivered'
        : 'Order is Processing';
      return orderTypeStatus;
    }
    return statusValue;
  }
  return 'Order is Processing';
}

interface StatusMap {
  [manhattanStatus: string]: {
    [orderType: string]: string;
  };
}
