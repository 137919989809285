/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { logError } from './ocErrors';
import { OcRootState, OcThunkApi } from './ocStore';
// import { DiscreteLineItem } from 'components/helpers/Constants';
import logout from './ocAuth/logout';
import { calculateOrder } from './ocCurrentOrder';
import { Orders } from 'ordercloud-javascript-sdk';
import toExecuteBeforeSoftLogin from 'src/utils/handleSoftLogin';
import { getSerializableObject } from './utils';
import { setIsAnonymous } from './ocAuth';

function getDescendantProp(obj: any, desc: string) {
  const arr: string[] | undefined = desc.split('.');
  let result = obj;
  while (arr.length) {
    result = result[arr.shift() as string];
  }
  return result;
}

export interface OcThrottle {
  location: keyof OcRootState;
  property: string;
}

// Code to execute before logging out for SoftLogin
// async function toExecuteBeforeSoftLogout(ocUser: any, ocCurrentOrder: any) {
//   let currentLineItems, softlogin_dependencies;

//   const name = ocUser?.user?.FirstName;
//   currentLineItems =
//     ocCurrentOrder?.lineItems?.filter(
//       (x: { ProductID: string }) => ![DiscreteLineItem.TIP].includes(x.ProductID)
//     ) ?? [];
//   const subTotal = ocCurrentOrder?.order?.Subtotal;

//   if (currentLineItems.length <= 0) {
//     currentLineItems = JSON.parse(sessionStorage.getItem('cartLineItems') as any);
//     softlogin_dependencies = JSON.parse(sessionStorage.getItem('softlogin_dependencies') as any);
//   }
//   const softLoginState = {
//     preferredStore: localStorage.getItem('storeId'),
//     accountName: name,
//     cartItem: currentLineItems,
//     subTotal: subTotal ?? 0,
//   };
//   await sessionStorage.setItem('isSoftLoginEnabled', 'true');
//   await sessionStorage.setItem('softLoginState', JSON.stringify(softLoginState));
// }

async function handleCouponError(orderId?: string, errors?: any) {
  let recalculate = false;
  if (orderId && errors && errors.length) {
    try {
      await Promise.all(
        errors.map(async (error: any) => {
          if (
            error &&
            error?.Data &&
            error?.Data?.Code &&
            error?.ErrorCode.includes('Promotion.')
          ) {
            try {
              await Orders.RemovePromotion('All', orderId as string, error?.Data?.Code);
              recalculate = true;
            } catch (removeError) {
              console.warn('Error removing promotion:', removeError);
            }
          }
        })
      );
    } catch (err) {
      console.error('Error processing coupon errors:', err);
    }
  }
  return recalculate;
}

async function handleSoftLogin(ocUser: any, ocCurrentOrder: any, errors?: any) {
  let softLoginActicated = false;
  if (errors && errors.length) {
    await Promise.all(
      errors.map(async (error: any) => {
        if (
          error &&
          error?.ErrorCode.includes('Authorization.') &&
          process.env.NEXT_PUBLIC_ENABLE_SOFTLOGIN == 'true'
        ) {
          await toExecuteBeforeSoftLogin(ocUser, ocCurrentOrder);
          softLoginActicated = true;
        }
      })
    );
  }
  return softLoginActicated;
}

export function createOcAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, OcThunkApi>,
  throttle?: OcThrottle
): AsyncThunk<Returned, ThunkArg, OcThunkApi> {
  return createAsyncThunk<Returned, ThunkArg, OcThunkApi>(
    typePrefix,
    async (args, thunkAPI) => {
      try {
        return (await payloadCreator(args, thunkAPI)) as any;
      } catch (err: any) {
        if (err.isOrderCloudError) {
          const { ocUser, ocCurrentOrder } = await thunkAPI.getState();

          //You can get both 400, 401 codes for promotion error. Not sure if thats a sitecore bug.
          const recalculate = await handleCouponError(ocCurrentOrder?.order?.ID, err?.errors);
          if (recalculate == true) {
            thunkAPI.dispatch(calculateOrder());
          }

          switch (err.status) {
            case 400:
              break;
            case 401:
              if (process.env.NEXT_PUBLIC_ENABLE_SOFTLOGIN == 'true') {
                console.error('$$$ into 401 error: ', err);
                const softLoginActivationStatus = await handleSoftLogin(
                  ocUser,
                  ocCurrentOrder,
                  err?.errors
                );
                if (softLoginActivationStatus == true) {
                  thunkAPI.dispatch(setIsAnonymous(true));
                  thunkAPI.dispatch(logout());
                }
              } else {
                thunkAPI.dispatch(setIsAnonymous(true));
                thunkAPI.dispatch(logout());
              }
              break;

            // Using Internal Server error for demoing the functionality without 401 error
            // case 500:
            // if (sessionStorage.getItem('isAnonymous') !== 'true') {
            //   await toExecuteBeforeSoftLogin(ocUser, ocCurrentOrder);
            // }
            // thunkAPI.dispatch(logout());
            // break;

            default:
              thunkAPI.dispatch(logError(getSerializableObject(err)));
              break;
          }
        }
        throw err;
      }
    },
    {
      condition: (_arg, api) => {
        if (throttle) {
          const state = api.getState()[throttle.location];
          const isThrottled = getDescendantProp(state, throttle.property);
          if (typeof isThrottled === 'boolean' && isThrottled) {
            return false;
          }
        }
        return true;
      },
    }
  );
}
