import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
export interface HeaderComponentContextData {
  isChangeStoreOpen?: boolean;
  openMiniCart?: boolean;
  showMiniCartLoader?: boolean;
  outOfStockProductIds?: string[];
  isReorderAll?: boolean;
  isPartiallyAdded?: boolean;
  isFullyAdded?: boolean;
  idFullyNotAdded?: boolean;
  isMegaMenuOpen?: boolean;
}
interface HeaderComponentContextProps {
  headerContextData: HeaderComponentContextData;
  setHeaderContextData: Dispatch<SetStateAction<HeaderComponentContextData>>;
}

const HeaderContext = createContext<HeaderComponentContextProps>({
  headerContextData: {},
  setHeaderContextData: (_state: HeaderComponentContextData) => {
    _state;
  },
});

const HeaderContextProvider = ({ children }: React.PropsWithChildren) => {
  const [headerContextData, setHeaderContextData] = useState<HeaderComponentContextData>({});
  return (
    <HeaderContext.Provider value={{ headerContextData, setHeaderContextData }}>
      {children}
    </HeaderContext.Provider>
  );
};

const useHeaderContext = () => {
  return useContext(HeaderContext);
};

export { HeaderContextProvider, useHeaderContext };
