import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';
import urlConfig from 'src/utils/urlConfig';

const AuthenticationCheck = (): JSX.Element => {
  const isOcAuth = useOcSelector((state) => state?.ocAuth);
  const router = useRouter();
  const isEE = useExperienceEditor();
  const loginUrl = useLoginWithReturnUrl();
  const ocUserDetails = useOcSelector((s) => s?.ocUser);

  useEffect(() => {
    if (!isEE) {
      if (
        Object.keys(isOcAuth).length > 0 &&
        !isOcAuth?.loading &&
        isOcAuth?.isAnonymous === true &&
        !ocUserDetails?.loading &&
        ocUserDetails?.user?.Email
      ) {
        router.push(loginUrl);
      }
    }
  }, [isEE, isOcAuth, ocUserDetails]);
  return <></>;
};

export function useLoginWithReturnUrl() {
  const router = useRouter();
  const returnURL = encodeURIComponent(router.asPath);
  const loginUrl = `${urlConfig?.loginWithReturnUrl}${returnURL}`;

  return loginUrl;
}
export default AuthenticationCheck;
