import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
export type FormFieldsProps = {
  id?: Field<string>;
  name?: Field<string>;
  label?: Field<string>;
  placeholdertext?: Field<string>;
  required?: Field<string>;
  fieldtype?: Field<string>;
  characterlimit?: Field<string> | null;
  helptext?: Field<string>;
  disabled?: Field<string>;
  requiredvalidationmessage?: Field<string>;
  validationpattern?: Field<string>;
  validationerrormessage?: Field<string>;
  hidden?: Field<string> | null;
  checkboxvalues?: radioListWrapper;
  mincharacterlimit?: Field<string>;
  mincharacterlimitvalidationmessage?: Field<string>;
  maxcharacterlimitvalidationmessage?: Field<string>;
  defaultvalue?: Field<string>;
};

interface radioList {
  name?: string;
  value?: string;
}
interface radioListWrapper {
  values: radioList[];
}

// type FieldType = 'text' | 'email' | 'select' | 'number' | 'textarea';

type FieldMap = Record<string, Record<string, string | boolean | number | undefined>>;

export const transformData = (data: { targetItems: FormFieldsProps[] }): FieldMap => {
  const result: FieldMap = {};

  data?.targetItems?.forEach((field) => {
    const fieldName = field.name?.value;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    result[fieldName!] = {
      id: field.id?.value || '',
      name: field.name?.value || '',
      label: field.label?.value || '',
      characterlimit: field.characterlimit?.value || '',
      helptext: field.helptext?.value || '',
      disabled: field.disabled?.value === '1' ? true : false,
      placeholdertext: field.placeholdertext?.value || '',
      required: field.required?.value === '1' ? true : false,
      requiredvalidationmessage: field.requiredvalidationmessage?.value || '',
      validationpattern: field.validationpattern?.value || '',
      fieldtype: field.fieldtype?.value || '',
      validationerrormessage: field.validationerrormessage?.value || '',
      mincharacterlimit: field.mincharacterlimit?.value || '',
      mincharacterlimitvalidationmessage: field.mincharacterlimitvalidationmessage?.value || '',
      maxcharacterlimitvalidationmessage: field.maxcharacterlimitvalidationmessage?.value || '',
      // Add other properties as needed
    };
  });

  return result;
};
