import { Field, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { DiscreteLineItem, GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import AddToCartWarningModal from 'src/helpers/purchaseDetails/AddToCartWarningModal';
import Details from 'src/helpers/purchaseDetails/Details';
import PrintOrderDetails from 'src/helpers/purchaseDetails/PrintOrderDetails';
import PrintOrderSummary from 'src/helpers/purchaseDetails/PrintOrderSummary';
import PurchaseDetailsOrderSummary from 'src/helpers/purchaseDetails/PurchaseDetailsOrderSummary';
import PurchaseDetailsSkeleton from 'src/helpers/purchaseDetails/PurchaseDetailsSkeleton';
import PurchaseLineItems from 'src/helpers/purchaseDetails/PurchaseLineItems';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { useOcSelector } from 'src/redux/ocStore';
import { LineItemWithXp, OrderWorksheetWithXP } from 'src/redux/xp';
import { apiRequest } from 'src/utils/apiWrapper';
import { useBreakpoints } from 'src/utils/breakpoints';
import { getMyOrdersAPI } from 'src/utils/nextApiConfig';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';

export type PurchaseDetailsFields =
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.MyPurchaseDetails.Fields.MyPurchaseDetails;

const PurchaseDetails: React.FC<PurchaseDetailsFields> = ({ fields }) => {
  const {
    componentWrapper,
    sectionWrapper,
    detailsAndLineItemsWrapper,
    purchaseLineItemsWrapper,
    orderSummary,
    topWrapper,
    pageTitle,
    printButton,
    colorBgBrand,
  } = purchaseDetailsVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { headerContextData } = useHeaderContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isReorderAll, setIsReorderAll] = useState(false);
  const [isPartiallyAdded, setIsPartiallyAdded] = useState(false);
  const siteContext = useSitecoreContext();
  const { screenWidth } = useBreakpoints();
  const title: Field<string> = siteContext?.sitecoreContext?.route?.fields?.title as Field<string>;
  const purchaseData = useOcSelector((s) => s?.ocPastOrder?.data);
  const router = useRouter();
  const orderId = router?.asPath?.split('#')?.[1];
  const data: OrderWorksheetWithXP = (purchaseData &&
    (purchaseData as OrderWorksheetWithXP[])?.filter(
      (x) => x?.Order?.xp?.OrderId === orderId
    )?.[0]) as OrderWorksheetWithXP;
  const [orderData, setOrderData] = useState<OrderWorksheetWithXP>(data);
  const [lineItemInventory, setLineItemInventory] = useState<{ [key: string]: number }[]>();
  const [inventoryForAllLineItems, setInventoryForAllLineItems] = useState<
    | {
        [key: string]: number;
      }[]
    | undefined
  >();
  //Excluding tip lineItem
  const lineItems = orderData?.LineItems?.filter(
    (product) => product?.ProductID !== DiscreteLineItem.TIP
  )?.filter((x) => x?.Product?.xp?.UPC != undefined);

  const isReorderAllDisabled =
    (lineItemInventory &&
      lineItemInventory.every((obj) => Object.values(obj).every((value) => value <= 0))) ||
    false;

  //getting single order if no order data available in redux
  const getOrder = async () => {
    setIsFetching(true);
    try {
      const options = {
        method: REQUEST_METHOD?.get,
      };
      const response: OrderWorksheetWithXP = await apiRequest<OrderWorksheetWithXP>(
        getMyOrdersAPI?.getMyOrderById + '?onumber=' + orderId,
        options
      );
      setOrderData(response);
    } catch (error) {
      router?.push('/404');
      console.error('Error during sign-in into Crowd Twist: ', error);
    } finally {
      setIsFetching(false);
    }
  };

  //if order data not found from redux
  useEffect(() => {
    if (!purchaseData) {
      getOrder();
    }
  }, [purchaseData]);

  //GTM for View_item_list
  useEffect(() => {
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) =>
            item.ecommerce?.items &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item.ecommerce.items.some((i: any) => i.item_list_id === GTMLabels?.myPurchaseDetails)
        )?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (
        !isEventFired &&
        lineItemInventory &&
        lineItems &&
        inventoryForAllLineItems &&
        lineItems?.length > 0 &&
        inventoryForAllLineItems?.length >= lineItems?.length &&
        isGTMLoad
      ) {
        const productDataObj = lineItems?.map((lineItem) => {
          const inventory =
            inventoryForAllLineItems &&
            inventoryForAllLineItems?.find(
              (val) => Object?.keys(val)?.[0] == (lineItem?.ProductID as string)
            )?.[lineItem?.ProductID as string];
          // lineItemInventory?.find((val) => Object?.keys(val)?.[0])
          return {
            ...lineItem?.Product,
            quantity: lineItem?.Quantity,
            BasePrice: lineItem?.UnitPrice,
            listPrice: lineItem?.UnitPrice,
            availableInventory: inventory && inventory,
          };
        });
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewItemList,
          data: productDataObj as ProductSearchResultModelWithVariants[],
          pageItem: GTMLabels?.myPurchaseDetails,
          currentPage: GTMLabels?.myPurchaseDetails,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [lineItems]);

  useEffect(() => {
    if (!isFetching && !purchaseData)
      if (orderData && !orderData?.Order) {
        router?.push('/404');
      }
  }, [isFetching, orderData, purchaseData]);

  if (isFetching) {
    return <PurchaseDetailsSkeleton />;
  }

  if (!isFetching && !orderData) {
    return <PurchaseDetailsSkeleton />;
  }

  if (!isFetching && !orderData?.Order) {
    return <PurchaseDetailsSkeleton />;
  }

  return (
    <>
      <div
        data-component="Component/PurchaseDetails/PurchaseDetails"
        className={componentWrapper()}
      >
        <AddToCartWarningModal
          isModalOpen={isModalOpen || isReorderAll || (headerContextData?.isReorderAll as boolean)}
          setIsModalOpen={setIsModalOpen}
          setIsReorderAll={setIsReorderAll}
          isPartiallyAdded={isPartiallyAdded}
          setIsPartiallyAdded={setIsPartiallyAdded}
          modalCTA={fields?.confirmCTAText as Field<string>}
          modalDescription={
            isReorderAll || (headerContextData?.isReorderAll as boolean)
              ? (fields?.reorderAllDescription as Field<string>)
              : (fields?.description as Field<string>)
          }
          modalTitle={
            isReorderAll || (headerContextData?.isReorderAll as boolean)
              ? (fields?.reorderAllTitle as Field<string>)
              : (fields?.modalTitle as Field<string>)
          }
        />
        <div data-wrapper="top-section" className={topWrapper()}>
          <Text tag="h1" field={title} className={pageTitle()} />
          <button
            data-attr={'printReceipt'}
            className={clsx(printButton(), colorBgBrand())}
            onClick={() => window.print()}
          >
            <Text field={fields?.printReceiptText} />
          </button>
        </div>
        <div className={sectionWrapper()}>
          <div data-wrapper="order-details-and-lineItems" className={detailsAndLineItemsWrapper()}>
            {/**Top Order Details */}
            <Details
              lineItemInventory={lineItemInventory}
              inventoryForAllLineItems={inventoryForAllLineItems}
              fields={fields}
              orderData={orderData}
              isReorderAllDisabled={isReorderAllDisabled}
              setIsReorderAll={setIsReorderAll}
              setIsPartiallyAdded={setIsPartiallyAdded}
            />
            {/**Mobile Order Summary*/}
            {screenWidth && screenWidth < 1311 && (
              <div data-wrapper="order-summary" className={orderSummary()}>
                <PurchaseDetailsOrderSummary fields={fields} orderData={orderData} />
              </div>
            )}

            {/**Print Order Details */}
            <PrintOrderDetails fields={fields} data={orderData} />
            {/**Order LineItems */}
            <div data-wrapper="purchase-line-items" className={purchaseLineItemsWrapper()}>
              <PurchaseLineItems
                lineItemInventory={lineItemInventory}
                setLineItemInventory={setLineItemInventory}
                setInventoryForAllLineItems={setInventoryForAllLineItems}
                setIsModalOpen={setIsModalOpen}
                fields={fields}
                lineItems={lineItems as unknown as LineItemWithXp[]}
              />
            </div>
            {/**Print Layout Order Summary */}
            <div data-wrapper="purchase-print-order-summary" className={purchaseLineItemsWrapper()}>
              <PrintOrderSummary fields={fields} orderData={orderData} />
            </div>
          </div>
          {/**Desktop Order Summary */}
          {screenWidth && screenWidth > 1311 && (
            <div className="orderSummary relative w-full max-w-[342px]">
              <PurchaseDetailsOrderSummary
                fields={fields}
                orderData={orderData}
                className={'sticky top-0'}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PurchaseDetails;
