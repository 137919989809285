/* eslint-disable @typescript-eslint/no-explicit-any */
import { NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { AddPet, Breed, PetResponse } from 'components/MyPet/MyPet';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import React, { useContext, useEffect, useState } from 'react';
import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import useDictionary from 'src/hooks/useDictionary';
import { Gender } from 'src/redux/myPetsSlice';
import { apiRequest } from 'src/utils/apiWrapper';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import { GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import ComponentContext from 'lib/context/ComponentContext';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import Loader from 'components/Loader/Loader';
import { groomingStepsData } from 'src/utils/sendGTMEvent';
import { isValid, parse, format } from 'date-fns';
import { groomingApi, petsApi } from 'src/utils/nextApiConfig';
import { scheduleAppointmentStep2TailwindVariant } from 'tailwindVariants/components/scheduleAppointmentStep2TailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import dynamic from 'next/dynamic';
import { removePetPopUpTailwindVariant } from 'tailwindVariants/components/removePetPopUpTailwindVariant';
import { myPetTailwindVariant } from 'tailwindVariants/components/myPetTailwindVariant';
import { convertToBase64 } from 'src/helpers/convertToBase64';

const DatePicker = dynamic(() => import('react-datepicker')); //Dynamically import react datepicker library for performance improvement.

// Add/Edit a PET

//save modal
const SavePetPopUp: React.FC<AddPet> = ({
  isSaveModal,
  setIsSaveModal,
  getAllPets,
  setIsEdit,
  setShowAddPetsForm,
}): JSX.Element => {
  const { wrapper, title, btnWrapper, btnPrimary } = removePetPopUpTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { getDictionaryValue } = useDictionary();

  useEffect(() => {
    isSaveModal && isSaveModal
      ? document.body.classList.add('overflow-hidden', 'touch-none')
      : document.body.classList.remove('overflow-hidden', 'touch-none');
  }, [isSaveModal]);
  return (
    <ModalWrapper
      showModal={isSaveModal}
      onCloseClick={async () => {
        setIsSaveModal && setIsSaveModal(false);
        getAllPets && (await getAllPets());
        setShowAddPetsForm && setShowAddPetsForm(false);
      }}
      additionalClassForPopUpModal={`!item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      popupBG="bg-[rgba(0,0,0,0.65)]"
      closeModalOnOverlayClick
      customPopup
    >
      <div className={wrapper()}>
        <p className={title()}>{getDictionaryValue('savePetInfoTitle')}</p>
        <p className="mt-8">{getDictionaryValue('savePetInfoBody')}</p>

        <div className={btnWrapper()}>
          {getDictionaryValue('deleteButtonText') &&
            getDictionaryValue('deletePetCancelButtonText') && (
              <button
                aria-label="save Info"
                className={btnPrimary()}
                onClick={async () => {
                  setIsEdit && setIsEdit(false);
                  setIsSaveModal && setIsSaveModal(false);
                  getAllPets && (await getAllPets());
                  setShowAddPetsForm && setShowAddPetsForm(false);
                }}
              >
                {getDictionaryValue('savePetInfoTitle')}
              </button>
            )}
        </div>
      </div>
    </ModalWrapper>
  );
};

//formattingDate
const formatDate = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

/** Date Picker*/
const FormDatePicker: React.FC<{
  isEdit?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  birthDate?: any;
  setBirthDate: React.Dispatch<React.SetStateAction<string | null>>;
  selectedDate: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
}> = ({ setBirthDate, selectedDate, isEdit, formik, name }) => {
  const { textLargeBold } = myPetTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { getDictionaryValue } = useDictionary();

  const [date, setDate] = useState<Date | null>();
  const [inputValue, setInputValue] = useState<string>(
    selectedDate ? formatDate(new Date(selectedDate)) : ''
  );
  const [error, setError] = useState<string | null>(null);

  //formatting in MM/DD/YYY format
  const formatDateString = (dateString: string): string => {
    const match = dateString.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
    if (!match) return dateString;

    const [, month, day, year] = match;
    let formattedDate = '';
    if (month) formattedDate += month;
    if (day) formattedDate += `/${day}`;
    if (year) formattedDate += `/${year}`;

    return formattedDate;
  };

  //formatting a date in MM/DD/YYY format and validating the Date
  const formatInputValue = (e: React.ChangeEvent<HTMLInputElement>): string => {
    const value = e?.target?.value;
    let newValue = value.replace(/[^\d]/g, '');
    if (value?.length <= 10) {
      newValue = value.replace(/[^\d]/g, '');
      const month = newValue.slice(0, 2);
      const day = newValue.slice(2, 4);
      const year = newValue.slice(6);
      const inputYear = newValue?.slice(4);
      const currentYear = new Date()?.getFullYear();
      const cursorPosition = e.target.selectionStart || 0;
      const formattedDate = formatDateString(newValue);

      setInputValue(formattedDate);

      // Calculate new cursor position based on changes made to the string length
      const newCursorPosition = cursorPosition + (formattedDate.length - value.length);

      // Use requestAnimationFrame to ensure the cursor position is set after the value update
      requestAnimationFrame(() => {
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      });

      //set an error if month is invalid
      if (month?.length >= 1) {
        Number(month) > 12 && setError(getDictionaryValue('InvalidBirthDate')), setBirthDate('');
      }

      //set an error if day is invalid
      if (day?.length >= 1) {
        Number(day) > 31 && setError(getDictionaryValue('InvalidBirthDate')), setBirthDate('');
      }

      //set an error if year is invalid or if valid date then set selected date.
      if (year?.length < 2) {
        setError(getDictionaryValue('InvalidBirthDate'));
        setBirthDate('');
      } else {
        const parsedDate = parse(formattedDate, 'MM/dd/yyyy', new Date());
        const today = new Date();
        if (Number(inputYear) <= Number(currentYear)) {
          if (!isValid(parsedDate)) {
            setError(getDictionaryValue('InvalidBirthDate'));
            setBirthDate('');
          } else if (parsedDate > today) {
            setError(getDictionaryValue('BirthDateCanNotBeFuture'));
            setBirthDate('');
          } else {
            setError('');
            setDate(new Date(formattedDate));
            setBirthDate(formattedDate);
            formik?.setFieldValue('Birthdate', formattedDate);
          }
        } else if (
          (Number(month) <= 12 || Number(day) <= 31) &&
          Number(inputYear) > Number(currentYear)
        ) {
          setError(getDictionaryValue('BirthDateCanNotBeFuture'));
        } else if (Number(month) >= 12 || Number(day) >= 31) {
          setError(getDictionaryValue('InvalidBirthDate'));
        }
      }

      return newValue;
    }
    return inputValue;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (date: any) => {
    if (date !== null) {
      formik?.setFieldValue('Birthdate', date);
    } else {
      setDate(null);
      formik?.setFieldValue('Birthdate', '');
    }
  };

  return (
    <div>
      {error && <span className="text-system-red">{error}</span>}
      <DatePicker
        selected={
          isEdit ? (date ? date : selectedDate ? new Date(selectedDate) : null) : date ? date : null
        }
        onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => {
          e?.target?.value !== undefined ? formatInputValue(e) : null;
        }}
        id={'date_picker'}
        value={inputValue}
        onSelect={(date) => {
          setBirthDate(date as any);
          setDate(date);
          setError('');
          setInputValue(formatDate(date));
          formik?.setFieldValue('Birthdate', date);
        }}
        weekDayClassName={() => textLargeBold({ className: 'flex w-full justify-center' })}
        onChange={handleDateChange}
        maxDate={new Date()}
        name={name}
        monthClassName={() => 'text-lg'}
        placeholderText="mm/dd/yyyy"
        className="[&>*>*>button]:bg-color-brand-primary-1-base"
      />
    </div>
  );
};

/**Add Pets Form */
const AddPetsForm: React.FC<AddPet> = ({
  fields,
  setShowAddPetsForm,
  setshowPetsScreen,
  getAllPets,
  currentEditPetId,
  isEdit,
  setIsEdit,
  petGenders,
  petDetails,
  geteditformdata,
  selectedpetitem,
  onPetImageUpdate,
  companyID,
}): JSX.Element => {
  const {
    formWrapper,
    link,
    formFieldWrapper,
    formHeaderWrapper,
    textLargeBold,
    textSmall,
    fieldGroup,
    FieldWrapper,
    formInput,
    imageWrapper,
    img,
    textLargeRegular,
    imgFixWidth,
    imageUploadWrapper,
    imageUploadField,
    loaderWrapper,
    genderField,
    dropDownArrow,
    imgSizeErrorMessage,
    radioButton,
    checkbox,

    radioButtonFiller,
  } = myPetTailwindVariant({ size: { initial: 'mobile', lg: 'desktop' } });

  //Current Pet Details
  const currentEditingPet: PetResponse[] = isEdit
    ? (petDetails as unknown as PetResponse[])?.filter(
        (pet: PetResponse) => pet?.PetId === currentEditPetId
      )
    : [];

  //sate
  const [image, setImage] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender] = useState<Gender | undefined>(petGenders);
  const [imgSizeError, setImgSizeError] = useState(false);
  const [petDuplicateError, setPetDuplicateError] = useState(false);
  const [imgSize, setImgSize] = useState(0);
  const [birthDate, setBirthDate] = useState<string | number | null>(
    currentEditingPet[0]?.Birthdate || ''
  );
  const [updatedPetDetails, setUpdatedPetDetails] = useState<boolean>(false);
  const [updatedPetDetailsPopup, setUpdatedPetDetailsPopup] = useState<boolean>(false);
  const [updatedPetData, setUpdatedPetData] = useState<any>(false);
  const [breed, setBreed] = useState<Breed>();
  const [imgFileTypeError, setFileTypeError] = useState(false);
  // const { componentContextData } = useContext(ComponentContext);
  const { sitecoreContext } = useSitecoreContext();

  const { getDictionaryValue } = useDictionary();

  useEffect(() => {
    const callAPIs = async () => {
      getBreed('2');
    };
    callAPIs();
  }, [isEdit]);
  /**
   * Info: To fetch breed list
   * @param id Species ID
   */
  const getBreed = async (id: string) => {
    setLoading(true);
    if (id?.length > 0) {
      try {
        const options = {
          method: REQUEST_METHOD?.post,
          data: {
            speciesID: id,
            GroomingCompanyId: Number(companyID),
          },
        };
        const res = await apiRequest(petsApi?.getBreed, options);
        setBreed(res as Breed);
      } catch (err) {
        console.error('@@Error', err);
      }
    } else {
      setBreed([]);
    }
    setLoading(false);
  };
  //converting an image into base64
  // const convertToBase64 = async (file: Blob | MediaSource) => {
  //   const validTypes = [
  //     'image/jpeg',
  //     'image/png',
  //     'image/svg+xml',
  //     'image/jpg',
  //     'video/mp4',
  //     'image/heif',
  //     'image/heic',
  //   ];
  //   if (!validTypes.includes((file as Blob).type)) {
  //     console.error('Error');
  //     setFileTypeError(true);
  //     return;
  //   }
  //   let convertedBlob: Blob;
  //   if (
  //     (file as Blob).type === 'image/heic' ||
  //     ((file as Blob).type === 'image/heif' && typeof window !== undefined)
  //   ) {
  //     const heic2any = (await import('heic2any')).default;
  //     convertedBlob = (await heic2any({ blob: file as Blob, toType: 'image/jpeg' })) as Blob;
  //   } else {
  //     convertedBlob = file as Blob;
  //   }
  //   return new Promise((resolve, reject) => {
  //     setFileTypeError(false);
  //     const fileReader = new FileReader();
  //     fileReader?.readAsDataURL(convertedBlob as Blob);
  //     fileReader.onload = () => {
  //       setFileTypeError(false);
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  //validation Schema
  const validationSchema = yup.object({
    Name: yup
      .string()
      .required(fields?.fieldRequired?.value || '')
      .matches(
        new RegExp(fields?.nameRegex?.value || '^[A-Za-z]+$'),
        fields?.nameValidation?.value
      ),
    Gender: yup.string().required(getDictionaryValue('requiredField')),
    Breed: yup.number().required(getDictionaryValue('requiredField')),
  });

  const formik = useFormik({
    //set the initial values
    initialErrors: {
      ...(!isEdit && { Species: !isEdit ? getDictionaryValue('requiredField') : '' }),
    },
    initialValues: {
      Name: (isEdit === true && currentEditingPet[0]?.Petname) || '',
      Gender: currentEditingPet[0]?.GenderId || '',
      Species: currentEditingPet[0]?.SpeciesId || '',
      Breed: currentEditingPet[0]?.BreedId || '',
      Image: currentEditingPet[0]?.PetImageName || '',
      Birthdate: isEdit
        ? currentEditingPet[0]?.Birthdate
          ? currentEditingPet[0]?.Birthdate
          : birthDate
          ? birthDate
          : null
        : null,
      SaveToProfile: true,
      breedname: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    //submitting the Pet details

    onSubmit: async (values) => {
      setUpdatedPetDetails(true);
      if (isEdit) {
        setUpdatedPetDetailsPopup(true);
      } else {
        handlePetUpdates(values, values?.SaveToProfile ? true : false);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setUpdatedPetData(values as any);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePetUpdates = async (values: any, donotupdate?: boolean) => {
    setSubmitLoading(true);
    setUpdatedPetDetailsPopup(false);
    try {
      const addPetRequest = {
        method: REQUEST_METHOD?.post,
        data: {
          PetId: currentEditingPet[0]?.PetId,
          petName: values?.Name || values?.Petname,
          speciesID: 2,
          genderID: values?.Gender || values?.GenderId,
          breedID: values?.Breed || values?.BreedId,
          birthdate:
            values?.Birthdate && values?.Birthdate != ''
              ? formatDate(new Date(values?.Birthdate as string))
              : null,
          SaveToProfile: values?.SaveToProfile || currentEditingPet[0]?.SaveToProfile,
          GroomingCompanyId: Number(companyID),
        },
      };

      if (values?.SaveToProfile) {
        if (typeof geteditformdata === 'function') {
          geteditformdata(values.SaveToProfile);
        }
      }

      const addPetImageRequest = {
        method: REQUEST_METHOD?.post,
        data: {
          PetId: '',
          Image: image?.toString()?.replace('data:', '').replace(/^.+,/, ''),
          GroomingCompanyId: Number(companyID),
        },
      };

      if (!isEdit) {
        if (donotupdate === true) {
          const addPet: PetResponse = await apiRequest(petsApi?.addPet, addPetRequest);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (addPet) {
            setIsEdit && setIsEdit(false);

            addPetImageRequest['data'] = {
              ...addPetImageRequest?.data,
              PetId: addPet?.PetId as unknown as string,
            };
            const getPetRequest = {
              method: REQUEST_METHOD?.post,
              data: {
                PetId: addPet?.PetId,
                GroomingCompanyId: Number(companyID),
              },
            };

            const getPet: PetResponse = await apiRequest(petsApi.getPet, getPetRequest);
            const matchingBreed =
              breed && breed.find((b) => b.BreedId.toString() === formik?.values.Breed.toString());
            if (matchingBreed) {
              matchingBreed.image = image;
            }
            setIsEdit && setIsEdit(false);
            if (typeof selectedpetitem === 'function')
              selectedpetitem(getPet, false, matchingBreed);
          }
        } else if (donotupdate === false) {
          setIsEdit && setIsEdit(false);
          setSubmitLoading(false);
          const matchingBreed =
            breed && breed.find((b) => b.BreedId.toString() === formik?.values.Breed.toString());
          if (matchingBreed) {
            matchingBreed.image = image;
          }

          if (typeof selectedpetitem === 'function')
            selectedpetitem(formik?.values as any, true, matchingBreed);
        } else {
          setPetDuplicateError(true);
          setSubmitLoading(false);
        }
      } else {
        if (donotupdate == false) {
          const updatePet: PetResponse = await apiRequest(petsApi?.updatePet, addPetRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!(updatePet as any)?.Message && updatePet) {
            addPetImageRequest['data'] = {
              ...addPetImageRequest?.data,
              PetId: updatePet?.PetId as unknown as string,
            };
            setIsEdit && setIsEdit(false);

            setSubmitLoading(false);
            if (typeof selectedpetitem === 'function') selectedpetitem(updatePet, false);
          } else {
            setPetDuplicateError(true);
            setSubmitLoading(false);
          }
        } else {
          setIsEdit && setIsEdit(false);
          values.PetImageName == image;
          setSubmitLoading(false);
          const matchingBreed =
            breed && breed.find((b) => b.BreedId.toString() === formik?.values.Breed.toString());
          if (matchingBreed) {
            matchingBreed.image = image;
          }
          if (typeof selectedpetitem === 'function') selectedpetitem(values, false, matchingBreed);
        }
      }

      if (image && !imgSizeError) {
        const addPetImageResponse = await apiRequest(petsApi?.addPetImage, addPetImageRequest);

        if (onPetImageUpdate) {
          onPetImageUpdate((addPetImageResponse as any)?.PetImageName);
        }
      }
      setUpdatedPetDetails(false);
      setUpdatedPetDetailsPopup(false);
    } catch (err) {
      setPetDuplicateError(true);
      setSubmitLoading(false);
      console.error('@@Error', err);
    }
  };
  //handling image
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const img = e?.target?.files?.[0];
    if (img) {
      const size = Number((img?.size / 1000000).toFixed(3));
      setImgSize(Number(size));
      // const type = img?.type;
      if (size <= 2) {
        setImgSizeError(false);
        const base64Image = (await convertToBase64(
          img as unknown as Blob,
          setFileTypeError
        )) as string;
        setImage(base64Image);
        formik.setFieldValue('Image', base64Image);
      } else {
        setImgSizeError(true);
      }
    }
  };

  const handleModalClose = () => {
    setUpdatedPetDetailsPopup(false);
  };

  const removeDuplicatesBreedAndSort = (breed: Breed) => {
    const breedMap = new Map();

    breed &&
      breed?.forEach((obj) => {
        // Add object to map if BreedName is not already present
        if (!breedMap?.has(obj.BreedName)) {
          breedMap?.set(obj.BreedName, obj);
        }
      });
    const sortedBreed = Array.from(breedMap.values()).sort((a, b) => {
      if (a?.BreedName?.toString()?.toLowerCase() < b?.BreedName?.toString()?.toLowerCase())
        return -1;
      if (a?.BreedName?.toString()?.toLowerCase() > b?.BreedName?.toString()?.toLowerCase())
        return 1;
      return 0;
    });
    return sortedBreed;
  };

  return (
    <>
      <div className={clsx(formWrapper(), '!p-0 !bg-transparent')}>
        <SavePetPopUp
          setIsEdit={setIsEdit}
          fields={fields}
          isSaveModal={isSaveModal}
          setIsSaveModal={setIsSaveModal}
          getAllPets={getAllPets}
          setShowAddPetsForm={setShowAddPetsForm}
        />
        <form onSubmit={formik?.handleSubmit}>
          <div className={formHeaderWrapper()}>
            <p className={textSmall()}>
              {getDictionaryValue('ScheduleAppointmentsStep4RequiredFieldsMessage')}
            </p>

            <div className={imageUploadWrapper()}>
              <div
                className={imageWrapper({
                  className: imgFixWidth(),
                })}
              >
                <NextImage
                  field={{
                    value: {
                      src:
                        image && image?.length > 0
                          ? image
                          : currentEditingPet[0]?.PetImageName &&
                            currentEditingPet[0]?.PetImageName?.length > 0
                          ? (process.env.NEXT_PUBLIC_PSP_PET_CDN || '') +
                            '/' +
                            currentEditingPet[0]?.PetImageName
                          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (sitecoreContext as any)?.route?.fields?.groomingConfiguration[0]
                              ?.fields?.defaultPetImage?.value?.src,

                      alt: 'AddPet',
                      width: '64',
                      height: '64',
                    },
                  }}
                  className={img({ className: 'h-[64px] max-h-[64px]' })}
                />
              </div>

              <div className={imageUploadField()}>
                <label htmlFor="Image">
                  {!isEdit ? (
                    <p className={link()}>{getDictionaryValue('addImage')}</p>
                  ) : (
                    <p className={link()}>{getDictionaryValue('changeImage')}</p>
                  )}
                </label>
                <input
                  aria-label="imageUpload"
                  type="file"
                  id="Image"
                  name="Image"
                  className="hidden"
                  onBlur={formik?.handleBlur}
                  onChange={(e) => {
                    // formik?.handleChange(e);
                    handleImage(e);
                  }}
                />
                {imgSizeError && (
                  <p className={textSmall({ className: 'text-system-red' })}>
                    {getDictionaryValue('imageMaximumSize')
                      .replace(
                        '{1}',
                        (() => {
                          const sizeLimit = getDictionaryValue('petImageSizeLimit');

                          return typeof sizeLimit === 'string' && sizeLimit
                            ? (parseInt(sizeLimit) / 1024).toString()
                            : '';
                        })()
                      )
                      .replace('{0}', imgSize?.toString())}
                  </p>
                )}
              </div>
            </div>
            {imgSizeError && (
              <RichTextHelper
                field={fields?.imageMaximumSizeError}
                {...(fields?.imageMaximumSizeError &&
                  fields?.imageMaximumSizeError?.value?.includes('{0}' || '{1}') && {
                    updatedField: {
                      value: fields?.imageMaximumSizeError?.value
                        ?.replace(
                          '{0}',
                          (Number(fields?.petImageSizeLimit?.value) / 1024).toString() || ''
                        )
                        ?.replace('{1}', imgSize.toString()),
                    },
                  })}
                className={imgSizeErrorMessage()}
              />
            )}
            {imgFileTypeError && (
              <p className={imgSizeErrorMessage()}>{getDictionaryValue('InValidFileType')}</p>
            )}
          </div>
          <div className={formFieldWrapper()}>
            {/**First Wrapper */}
            <div className={fieldGroup()}>
              <div className={FieldWrapper()}>
                <label className={textLargeBold()} htmlFor={getDictionaryValue('nameText')}>
                  *<span>{getDictionaryValue('nameText')} </span>
                </label>
                {formik?.errors?.Name && (
                  <p className="text-system-red">
                    {getDictionaryValue('SpecialCharactersNotAllowed')}
                  </p>
                )}
                <input
                  aria-label="input"
                  id={getDictionaryValue('nameText')}
                  name={getDictionaryValue('nameText')}
                  value={formik?.values?.Name}
                  className={formInput({ className: textLargeRegular() })}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
              </div>
              <div className={FieldWrapper()}>
                <label className={textLargeBold()} htmlFor={getDictionaryValue('birthDateText')}>
                  <span>*{getDictionaryValue('birthDateText')}</span>
                </label>
                <FormDatePicker
                  formik={formik}
                  isEdit={isEdit}
                  name={getDictionaryValue('birthDateText')}
                  setBirthDate={setBirthDate}
                  birthDate={birthDate}
                  selectedDate={currentEditingPet[0]?.Birthdate as string}
                />
              </div>
              <div className={FieldWrapper()}>
                <label
                  className={textLargeBold()}
                  htmlFor={getDictionaryValue('ScheduleAppointmentsStep2BreedDropdownLabel')}
                >
                  *<span>{getDictionaryValue('ScheduleAppointmentsStep2BreedDropdownLabel')}</span>
                </label>
                <select
                  aria-label="select scheduleAppointmentsStep2BreedDropdownLabel"
                  id={getDictionaryValue('ScheduleAppointmentsStep2BreedDropdownLabel')}
                  className={formInput({
                    className: textLargeRegular({
                      className: dropDownArrow(),
                    }),
                  })}
                  onBlur={formik?.handleBlur}
                  defaultValue={currentEditingPet[0]?.BreedId || ''}
                  onChange={formik?.handleChange}
                >
                  <option value={''}>
                    {loading ? (
                      <span>{getDictionaryValue('genericSpinnerText')}</span>
                    ) : (
                      <span>{getDictionaryValue('selectOptionText')}</span>
                    )}
                  </option>
                  {breed &&
                    removeDuplicatesBreedAndSort(breed)?.map((b) => {
                      return (
                        <option
                          value={b?.BreedId}
                          key={b?.BreedId}
                          selected={currentEditingPet[0]?.BreedId.toString() == b?.BreedId}
                        >
                          {b?.BreedName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {/**Second Wrapper */}
            {/* <div className={fieldGroup()}></div> */}
            <div className={FieldWrapper()}>
              <span className={textLargeBold()}>
                * <span>{getDictionaryValue('genderText')}</span>
              </span>
              <div className={FieldWrapper()}>
                <div className={fieldGroup({ className: '!flex-row' })}>
                  {gender &&
                    gender?.map((genItem) => {
                      return (
                        <div key={genItem?.GenderId} className={clsx(genderField(), 'relative')}>
                          <input
                            aria-label="input"
                            type="radio"
                            value={genItem?.GenderId}
                            name={'Gender'}
                            id={genItem?.GenderName}
                            onBlur={formik?.handleBlur}
                            onChange={formik?.handleChange}
                            defaultChecked={currentEditingPet[0]?.GenderId === genItem?.GenderId}
                            className={radioButton()}
                          />
                          <label htmlFor={genItem?.GenderName}>{genItem?.GenderName}</label>

                          {formik.values.Gender == genItem?.GenderId && (
                            <div className={radioButtonFiller()}></div>
                          )}
                        </div>
                      );
                    })}
                </div>
                {formik?.errors?.Gender && formik?.touched?.Gender && (
                  <span className="text-system-red">{formik?.errors?.Gender}</span>
                )}
              </div>
            </div>
            <div className={FieldWrapper()}>
              <div className={FieldWrapper()}>
                <div className={fieldGroup({ className: '!flex-row relative' })}>
                  {formik?.values?.SaveToProfile && (
                    <label
                      htmlFor="SaveToProfile"
                      className="[&>*>svg>*>*]:fill-color-background-white absolute [&>*>svg]:w-[24px] [&>*>svg]:h-[24px] left-0"
                    >
                      <IconHelper icon={'icon-checkmark'} />
                    </label>
                  )}
                  <input
                    aria-label="input"
                    type="checkbox"
                    name={'SaveToProfile'}
                    id={'SaveToProfile'}
                    onBlur={formik?.handleBlur}
                    onChange={formik?.handleChange}
                    className={checkbox()}
                    defaultChecked={true}
                  />
                  <label htmlFor="SaveToProfile">
                    {getDictionaryValue('ScheduleAppointmentsStep4SaveToProfileText')}
                  </label>
                </div>
              </div>
            </div>
            {petDuplicateError && (
              <div className={fieldGroup()}>
                <span className="text-system-red">
                  {getDictionaryValue('ScheduleAppointmentsStep2DuplicatePetErrorMessage')}
                </span>
              </div>
            )}

            <div className={fieldGroup()}>
              {submitLoading ? (
                <div className={loaderWrapper()}>
                  <Loader />
                  <span>{getDictionaryValue('genericSpinnerText')}</span>
                </div>
              ) : isEdit ? (
                <ButtonHelper
                  field={{
                    value: getDictionaryValue('scheduleAppointmentSaveAndSelectDog'),
                  }}
                  isLinkField={false}
                  size="medium"
                  variant={
                    imgSizeError
                      ? !(
                          formik.isValid &&
                          formik?.dirty &&
                          formik?.values?.Birthdate !== '' &&
                          birthDate
                        )
                        ? 'disabled'
                        : 'primary'
                      : !(
                          formik.isValid &&
                          formik?.dirty &&
                          formik?.values?.Birthdate !== '' &&
                          birthDate
                        )
                      ? 'disabled'
                      : 'primary'
                  }
                  className="w-full"
                  isDisabled={
                    imgSizeError
                      ? !(
                          formik.isValid &&
                          formik?.dirty &&
                          formik?.values?.Birthdate !== '' &&
                          birthDate
                        )
                        ? true
                        : false
                      : !(
                          formik.isValid &&
                          formik?.dirty &&
                          formik?.values?.Birthdate !== '' &&
                          birthDate
                        )
                      ? true
                      : false
                  }
                />
              ) : (
                <ButtonHelper
                  field={{
                    value: getDictionaryValue('scheduleAppointmentSaveAndSelectDog'),
                  }}
                  isLinkField={false}
                  size="medium"
                  variant={
                    !birthDate ||
                    formik?.values?.Birthdate == '' ||
                    !formik.isValid ||
                    imgFileTypeError
                      ? 'disabled'
                      : 'primary'
                  }
                  className="w-full"
                  isDisabled={
                    !birthDate ||
                    formik?.values?.Birthdate == '' ||
                    !formik.isValid ||
                    imgFileTypeError
                  }
                />
              )}
              <ButtonHelper
                field={{
                  value: getDictionaryValue('Cancel'),
                }}
                isLinkField={false}
                size="medium"
                variant="secondary"
                className="w-full"
                onClickHandler={(e) => {
                  e?.preventDefault();
                  setShowAddPetsForm && setShowAddPetsForm(false);
                  // getAllPets && getAllPets();
                  setshowPetsScreen && setshowPetsScreen(true);
                  setIsEdit && setIsEdit(false);
                }}
              />
            </div>
          </div>
        </form>
      </div>
      {updatedPetDetails && updatedPetDetailsPopup && (
        <ModalWrapper
          showModal={true}
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          customPopup={true}
          onCloseClick={handleModalClose}
          popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x"
          popupWidth="max-w-[1200px]"
        >
          <div>
            <div className="pet-update-warning">
              <p className="font-heading-desk-xLarge-bold text-color-text-black text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold mb-[40px]">
                {getDictionaryValue('petProfileUpdate')}
              </p>
              <p className="text-body-large-bold leading-body-large-bold font-body-large-bold text-color-accent-red mb-[20px]">
                {getDictionaryValue('petProfileUpdateDescription')}
              </p>
              <div className="flex flex-row gap-[12px]">
                <ButtonHelper
                  field={{
                    value: getDictionaryValue('Update'),
                  }}
                  isLinkField={false}
                  size="medium"
                  variant={'primary'}
                  onClickHandler={() => {
                    handlePetUpdates(updatedPetData, false);
                  }}
                />
                <ButtonHelper
                  field={{
                    value: getDictionaryValue('Do Not Update'),
                  }}
                  isLinkField={false}
                  size="medium"
                  variant="secondary"
                  onClickHandler={() => {
                    if (currentEditingPet && currentEditingPet[0]) {
                      currentEditingPet[0].Petname = updatedPetData?.Name;
                      currentEditingPet[0].GenderName =
                        updatedPetData?.Gender == '1'
                          ? 'Male'
                          : updatedPetData?.Gender == '2'
                          ? 'Female'
                          : 'Unknown';
                      currentEditingPet[0].PetImageName = updatedPetData?.Image;
                      currentEditingPet[0].Birthdate = updatedPetData?.Birthdate;
                      currentEditingPet[0].BreedId = updatedPetData?.Breed;
                      currentEditingPet[0].SpeciesId = updatedPetData?.Species;
                      const date = new Date(updatedPetData?.Birthdate);

                      // Format the date to MM/DD/YYYY
                      const formattedDate = format(date, 'MM/dd/yyyy');
                      currentEditingPet[0].BirthdateString = formattedDate;
                      // Convert string to number and find the matching breed
                      const matchingBreed =
                        breed &&
                        breed.find(
                          (b) => b.BreedId.toString() === updatedPetData?.Breed.toString()
                        );
                      if (matchingBreed) {
                        currentEditingPet[0].BreedName = matchingBreed.BreedName;
                      }
                    }

                    handlePetUpdates(currentEditingPet[0], true);
                    // setIsEdit && setIsEdit(false);
                    // setIsSaveModal && setIsSaveModal(false);

                    // setShowAddPetsForm && setShowAddPetsForm(false);
                  }}
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

// Birthday date picker - simple approch

/** Date Picker*/
export const BirthdayDatePicker: React.FC<{
  setBirthDate: React.Dispatch<React.SetStateAction<string | null>>;
  selectedDate?: string;
}> = ({ setBirthDate }) => {
  const { textLargeBold } = myPetTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { getDictionaryValue } = useDictionary();

  const [date, setDate] = useState<Date | null>();
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  //formatting in MM/DD/YYY format
  const formatDateString = (dateString: string): string => {
    const match = dateString.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
    if (!match) return dateString;

    const [, month, day, year] = match;
    let formattedDate = '';
    if (month) formattedDate += month;
    if (day) formattedDate += `/${day}`;
    if (year) formattedDate += `/${year}`;

    return formattedDate;
  };

  //formatting a date in MM/DD/YYY format and validating the Date
  const formatInputValue = (e: React.ChangeEvent<HTMLInputElement>): string => {
    const value = e?.target?.value;
    let newValue = value.replace(/[^\d]/g, '');
    if (value?.length <= 10) {
      newValue = value.replace(/[^\d]/g, '');
      const month = newValue.slice(0, 2);
      const day = newValue.slice(2, 4);
      const year = newValue.slice(6);
      const inputYear = newValue?.slice(4);
      const currentYear = new Date()?.getFullYear();
      const cursorPosition = e.target.selectionStart || 0;
      const formattedDate = formatDateString(newValue);

      setInputValue(formattedDate);

      // Calculate new cursor position based on changes made to the string length
      const newCursorPosition = cursorPosition + (formattedDate.length - value.length);

      // Use requestAnimationFrame to ensure the cursor position is set after the value update
      requestAnimationFrame(() => {
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      });

      //set an error if month is invalid
      if (month?.length >= 1) {
        Number(month) > 12 && setError(getDictionaryValue('InvalidBirthDate')), setBirthDate('');
      }

      //set an error if day is invalid
      if (day?.length >= 1) {
        Number(day) > 31 && setError(getDictionaryValue('InvalidBirthDate')), setBirthDate('');
      }

      //set an error if year is invalid or if valid date then set selected date.
      if (year?.length < 2) {
        setError(getDictionaryValue('InvalidBirthDate'));
        setBirthDate('');
      } else {
        const parsedDate = parse(formattedDate, 'MM/dd/yyyy', new Date());
        const today = new Date();
        if (Number(inputYear) <= Number(currentYear)) {
          if (!isValid(parsedDate)) {
            setError(getDictionaryValue('InvalidBirthDate'));
            setBirthDate('');
          } else if (parsedDate > today) {
            setError(getDictionaryValue('BirthDateCanNotBeFuture'));
            setBirthDate('');
          } else {
            setError('');
            setDate(new Date(formattedDate));
            setBirthDate(formattedDate);
          }
        } else if (
          (Number(month) <= 12 || Number(day) <= 31) &&
          Number(inputYear) > Number(currentYear)
        ) {
          setError(getDictionaryValue('BirthDateCanNotBeFuture'));
        } else if (Number(month) >= 12 || Number(day) >= 31) {
          setError(getDictionaryValue('InvalidBirthDate'));
        }
      }

      return newValue;
    }
    return inputValue;
  };

  return (
    <div>
      {error && <span className="text-system-red">{error}</span>}
      <DatePicker
        selected={date}
        onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => {
          e?.target?.value !== undefined ? formatInputValue(e) : null;
        }}
        id={'date_picker'}
        value={inputValue}
        onSelect={(date) => {
          setBirthDate(date as any);
          setDate(date);
          setError('');
          setInputValue(formatDate(date));
        }}
        weekDayClassName={() => textLargeBold({ className: 'flex w-full justify-center' })}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        maxDate={new Date()}
        monthClassName={() => 'text-lg'}
        placeholderText="mm/dd/yyyy"
        className="[&>*>*>button]:bg-color-brand-primary-1-base"
      />
    </div>
  );
};

//  Birthday date picker - simple approch

const ScheduleAppointmentStep2 = ({
  fields,
  storeSelected,
  onInitiateStep3,
  selectedStoreFromStep1,
  step2data,
  setmyTimeChildid,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any;
  storeSelected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInitiateStep3: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  step2data: (data: any) => void;

  setmyTimeChildid: (myTimeChildID: number) => void;
  selectedStoreFromStep1: StoreProps | null;
}) => {
  const { step1HeadingWrapper, step1HeadingTitle, step1HeadingLink, img, loaderWrapper } =
    scheduleAppointmentStep2TailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const {
    addPetWrapper,
    rightContent,
    imageWrapper,
    link,
    textSmall,
    textSmallBold,
    petDetailsWraper,
    checkbox,
    checkboxIcon,
  } = myPetTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { getDictionaryValue } = useDictionary();
  const [petList, setPetList] = useState<PetResponse[]>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userDetails = useOcSelector((state: any) => state?.ocUser?.user);
  const { sitecoreContext } = useSitecoreContext();
  const [showAddPetsForm, setShowAddPetsForm] = useState<boolean>(false);
  const [currentUserID, setCurrentUserId] = useState('');
  const petGenders = useOcSelector((state) => state?.myPetReducer?.gender);
  const petSpecies = useOcSelector((state) => state?.myPetReducer?.species);
  const [currentEditPetId, setCurrentEditPetId] = useState<string | number>();
  const [isEdit, setIsEdit] = useState(false);
  const [showStep2, setshowStep2] = useState(true);
  const [inProgressLoading, setInProgressLoading] = useState<{ [key: string]: boolean }>({});
  const [selectedPetLoader, setSelectedPetLoader] = useState<boolean>(false);
  const [selectedPetwhilerescheduleLoader, setSelectedPetWhileRescheduleLoader] =
    useState<boolean>(false);
  const [showSelectedPet, setshowSelectedPet] = useState<PetResponse | null>(null);
  const [showSelectedPetData, setshowSelectedPetData] = useState<PetResponse | null>(null);
  const [showPetsScreen, setshowPetsScreen] = useState(true);
  const [petFetchLoader, setpetFetchLoader] = useState(false);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [isSaveToProfileTicked, setisSaveToProfileTicked] = useState(true);
  const [showBirthdayMissingPopup, setShowBirthdayMissingPopup] = useState(false);
  const [saveToProfileChecked, setSaveToProfileChecked] = useState(true);
  const [createUpdatePetWhileReschdulingLoader, setcreateUpdatePetWhileReschdulingLoader] =
    useState<boolean>(false);
  const [hasstep2gtmrun, setHasStep2GtmRun] = useState(false);

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const [selectedpetItemtosubmut, setSelectedPetItemToSubmit] = useState<PetResponse | null>(null);
  const [updatedPetImage, setUpdatedPetImage] = useState<string>();
  const [petbirthDate, setPetBirthDate] = useState<string | number | null>('');
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);

  const getAllPets = async () => {
    setpetFetchLoader(true);
    document?.body?.classList?.contains('overflow-hidden') &&
      document.body.classList.contains('touch-none') &&
      document.body.classList.remove('overflow-hidden', 'touch-none');
    //setPetLoader(true);
    try {
      const options = {
        method: REQUEST_METHOD?.post,
      };

      const res: PetResponse[] = await apiRequest(petsApi?.allPets, options);
      res && setpetFetchLoader(false);
      setPetList(res);
      setshowSelectedPet(null);
      setshowPetsScreen(true);
    } catch (err) {
      console.error('@@Error', err);
    }
    //setPetLoader(false);
  };

  useEffect(() => {
    // const res = await Me.Get();
    if (!isAnonymous && !currentUserID && userDetails?.user?.xp?.PPC) {
      setCurrentUserId(userDetails?.user?.xp?.PPC);
    }

    if (
      storeSelected === true &&
      userDetails?.user?.xp?.PPC &&
      !userDetails?.loading &&
      !currentUserID
    ) {
      getAllPets();
    }
  }, [userDetails]);

  useEffect(() => {
    if (storeSelected === true) {
      getAllPets();
    }
  }, [storeSelected, selectedStoreFromStep1]);

  //.1 Add new breed and assign to pet

  const addNewBreedAndAssignToPet = async (petItem: PetResponse) => {
    const addNewBreedAndAssignToPetData = {
      petId: petItem?.PetId,
      SpeciesId: petItem?.SpeciesId,
      MyTimeBreedId: petItem?.BreedId,
      PetCurrentBreed: petItem?.BreedName,
      breedName: petItem?.BreedName,
      GroomingCompanyId:
        Number(selectedStoreFromStep1?.xp_onlinebookingcompany) ||
        Number(componentContextData?.bookedAppointmentForStep1?.Location?.CompanyId),
    };
    try {
      const options = {
        method: REQUEST_METHOD?.post,
        data: addNewBreedAndAssignToPetData,
      };
      await apiRequest(groomingApi.addNewBreedAndAssignToPet, options);
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  //.2 createUpdatePet
  const createUpdatePet = async (petItem: PetResponse, petBreed?: any) => {
    const createUpdatePetData = {
      userPetId: '',
      PetName: petItem?.Petname || petItem?.Name,
      petdateofbirth:
        petItem?.BirthdateString ||
        petbirthDate ||
        (petItem?.Birthdate
          ? new Date(petItem.Birthdate).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })
          : ''),
      BreedName: petItem?.BreedName || petBreed,
      PreviousPetName: '',
      firstName: userDetails?.FirstName,
      lastName: userDetails?.LastName,
      email: userDetails?.Email,
      phone: userDetails?.Phone || userDetails?.xp?.HomePhone,
      SaveToProfile: isSaveToProfileTicked,
      GroomingCompanyId:
        Number(selectedStoreFromStep1?.xp_onlinebookingcompany) ||
        Number(componentContextData?.bookedAppointmentForStep1?.Location?.CompanyId),
    };
    // // setPetLoader(true);

    try {
      const options = {
        method: REQUEST_METHOD?.post,
        data: createUpdatePetData,
      };
      const res: PetResponse[] = await apiRequest(groomingApi.createUpdatePet, options);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setmyTimeChildid(res as any);
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  //.3 getGroomingServices
  const groomingServiceIDs = (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]
    ?.fields.serviceIds?.value;

  const getGroomingServices = async (petItem: PetResponse, petBreed?: any) => {
    if (petItem) {
      const getGroomingServices = {
        SelectedPetId: petItem?.PetId,
        SelectedPetName: petItem?.Petname,
        SelectedPetBreedName: petItem?.BreedName || petBreed,
        SelectedStoreLocationId:
          selectedStoreFromStep1?.xp_onlinebookinglocation ||
          componentContextData?.bookedAppointmentForStep1.Location.Id,
        ServicesId: groomingServiceIDs,
        GroomingCompanyId:
          Number(selectedStoreFromStep1?.xp_onlinebookingcompany) ||
          Number(componentContextData?.bookedAppointmentForStep1?.Location?.CompanyId),
      };

      // // setPetLoader(true);

      try {
        const options = {
          method: REQUEST_METHOD?.post,
          data: getGroomingServices,
        };
        const res: PetResponse[] = await apiRequest(groomingApi.groomingServices, options);
        onInitiateStep3(res);

        // Common logic for updating pet information and UI
        if (
          (petbirthDate &&
            petbirthDate !== '' &&
            (petItem?.SaveToProfile || saveToProfileChecked)) ||
          petItem.Birthdate === null
        ) {
          petItem.Birthdate = petbirthDate as string;
        }
        if (petBreed) {
          petItem.breedname = petBreed;
        }

        step2data(petItem);
        setshowSelectedPet(petItem);
        setshowPetsScreen(false);
        setcomponentContextData({
          ...componentContextData,
          resetStep3: false,
          bookedAppointmentForStep2: undefined,
          bookedAppointmentForStep3: undefined,
          ...((componentContextData?.bookedAppointmentForStep1 === undefined ||
            !componentContextData?.bookedAppointmentForStep1) && {
            bookedAppointmentForStep1: {
              ChildId: petItem?.PetId,
              ChildName: petItem?.Petname,
              ChildBreed: petItem?.BreedName || petBreed,
              Location: {
                Id:
                  selectedStoreFromStep1?.xp_onlinebookinglocation ||
                  componentContextData?.bookedAppointmentForStep1.Location.Id,
              },
            },
          }),
        });

        // Log grooming steps event if data is present
        if (res) {
          groomingStepsData({
            eventName: GTM_EVENT?.groomingSteps,
            storeId: myStoreData?.storeId,
            groomingStoreId: selectedStoreFromStep1?.storeid,
            stepName: GTMLabels?.groomingStep2StepName,
          });
        }
      } catch (err) {
        setshowSelectedPet(petItem);
        console.error('@@Error', err);
      }
    }
  };

  const getEditFormData = (data: boolean | ((prevState: boolean) => boolean)) => {
    data && setisSaveToProfileTicked(data);
  };

  const selectedPetItem = async (data: PetResponse, addPetFlag?: boolean, petBreed?: any) => {
    if (addPetFlag === true && data) {
      if (petBreed?.image?.includes('data:image')) {
        data.Image = petBreed.image;
      }
    }
    setSelectedPetLoader(true);
    setshowPetsScreen(false);
    setShowAddPetsForm(false);
    if (data && petBreed) {
      if (petBreed?.image?.includes('data:image')) {
        data.PetImage = petBreed.image;
        data.PetImageName = petBreed.image;
      }
    }
    if (addPetFlag === false && data) {
      await addNewBreedAndAssignToPet(data);
    }
    await createUpdatePet(data, petBreed?.BreedName);
    await getGroomingServices(data, petBreed?.BreedName);
    setshowStep2(false);
    setSelectedPetLoader(false);
  };

  const handleButtonClick = async (petItem: PetResponse) => {
    if (petItem?.Birthdate !== null) {
      const petId = petItem?.PetId;
      setInProgressLoading((prevState) => ({ ...prevState, [petId as number]: true }));
      try {
        await addNewBreedAndAssignToPet(petItem);
        await createUpdatePet(petItem);
        await getGroomingServices(petItem);
        setshowStep2(false);
      } catch (error) {
        console.error('Error in handling pet actions:', error);
      } finally {
        setInProgressLoading((prevState) => ({ ...prevState, [petId as number]: false }));
      }
    } else {
      setShowBirthdayMissingPopup(true);
      setSelectedPetItemToSubmit(petItem);
    }
  };

  const LoaderComponent = () => {
    return (
      <div className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}>
        <Loader />
        <span>{getDictionaryValue('ScheduleAppointmentsStep2LoadingText')}</span>
      </div>
    );
  };
  const ServiceLoaderComponent = () => {
    return (
      <div className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}>
        <Loader />
        <span>{getDictionaryValue('ScheduleAppointmentsStep3LoadingText')}</span>
      </div>
    );
  };

  const FetchPetsWhileReschdule = () => {
    return (
      <div className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}>
        <Loader />
        <span>{getDictionaryValue('loadingtext')}</span>
      </div>
    );
  };

  useEffect(() => {
    if (hasstep2gtmrun === false && componentContextData?.bookedAppointmentForStep2) {
      groomingStepsData({
        eventName: GTM_EVENT?.groomingSteps,
        storeId: myStoreData?.storeId,
        groomingStoreId: componentContextData?.bookedAppointmentForStep1?.Location?.PspStoreId,
        stepName: GTMLabels?.groomingStep2StepName,
      });
      setHasStep2GtmRun(true);
    }
  }, []);

  useEffect(() => {
    if (componentContextData?.bookedAppointmentForStep2 && componentContextData?.petsData) {
      // Set loader to true immediately
      setSelectedPetWhileRescheduleLoader(true);

      // Delay the rest of the logic by 1 second

      setshowSelectedPet(componentContextData?.bookedAppointmentForStep2);
      setshowPetsScreen(false);

      const showSelectedPetDataArray = Array.isArray(componentContextData?.petsData)
        ? componentContextData?.petsData
        : [componentContextData?.petsData];

      const matchingPet = showSelectedPetDataArray.find(
        (pet) => pet.Petname === componentContextData?.bookedAppointmentForStep1?.ChildName
      );

      setshowSelectedPetData(matchingPet);
      setshowSelectedPet(matchingPet);

      // Turn off the loader after the rest is done
      setTimeout(() => {
        setSelectedPetWhileRescheduleLoader(false);
      }, 1000);
    }

    // If the condition is not met, return an empty function or undefined
    return () => {
      console.info('No Pets Found'); // This needs to be change (Ignore during PR review)
    };
  }, [
    componentContextData?.bookedAppointmentForStep2,
    componentContextData?.petsData,
    showSelectedPetData,
  ]);

  // RESET ON STEP 1 EDIT

  useEffect(() => {
    if (
      componentContextData?.resetStep2 === false &&
      componentContextData?.bookedAppointmentForStep2 === undefined
    ) {
      getAllPets();
    }
    if (componentContextData?.resetStep2 === true) {
      setshowSelectedPet(null);
    }
  }, [componentContextData?.resetStep2]);

  const handleModalClose = () => {
    setShowBirthdayMissingPopup(false);
  };
  const profileChecked = () => {
    setSaveToProfileChecked(!saveToProfileChecked);
  };

  const updatePetFromBdyPopuop = async () => {
    const addPetRequest = {
      method: REQUEST_METHOD?.post,
      data: {
        PetId: selectedpetItemtosubmut?.PetId,
        petName: selectedpetItemtosubmut?.Petname,
        speciesID: 2,
        genderID: selectedpetItemtosubmut?.Gender || selectedpetItemtosubmut?.GenderId,
        breedID: selectedpetItemtosubmut?.BreedId,
        birthdate:
          petbirthDate && petbirthDate != '' ? formatDate(new Date(petbirthDate as string)) : null,
        SaveToProfile: saveToProfileChecked,
        GroomingCompanyId:
          Number(selectedStoreFromStep1?.xp_onlinebookingcompany) ||
          Number(componentContextData?.bookedAppointmentForStep1?.Location?.CompanyId),
      },
    };
    if (saveToProfileChecked) {
      await apiRequest(petsApi?.updatePet, addPetRequest);
    }
  };

  const createUpdatePetWhileReschduling = async (appointment: any) => {
    const createUpdatePetData = {
      userPetId: '',
      PetName: appointment?.Petname,
      petdateofbirth: petbirthDate,
      BreedName: appointment?.BreedName,
      PreviousPetName: '',
      firstName: userDetails?.FirstName,
      lastName: userDetails?.LastName,
      email: userDetails?.Email,
      phone: userDetails?.Phone,
      SaveToProfile: saveToProfileChecked,
      GroomingCompanyId:
        Number(selectedStoreFromStep1?.xp_onlinebookingcompany) ||
        Number(componentContextData?.bookedAppointmentForStep1?.Location?.CompanyId),
    };
    // // setPetLoader(true);

    try {
      const options = {
        method: REQUEST_METHOD?.post,
        data: createUpdatePetData,
      };
      const res: PetResponse[] = await apiRequest(groomingApi.createUpdatePet, options);
      if (res) {
        setShowBirthdayMissingPopup(false);
        setSelectedPetLoader(true);
        setshowPetsScreen(false);
        setShowAddPetsForm(false);
        await addNewBreedAndAssignToPet(appointment);
        await createUpdatePet(appointment);
        await getGroomingServices(appointment);
        setshowStep2(false);
        setSelectedPetLoader(false);
      }
      setcreateUpdatePetWhileReschdulingLoader(false);
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  // Callback function to update the state in the parent
  const handlePetImageUpdate = (newImage: string) => {
    setUpdatedPetImage(newImage);
  };

  function isValidDate(value: any): value is Date {
    return value instanceof Date && !isNaN(value.getTime());
  }

  return (
    <div>
      <div>
        <div className={step1HeadingWrapper()}>
          <p className={clsx(step1HeadingTitle())}>
            {getDictionaryValue('ScheduleAppointmentsStep2Heading')}
          </p>
          {((!selectedPetwhilerescheduleLoader &&
            storeSelected &&
            !petFetchLoader &&
            showPetsScreen &&
            componentContextData?.resetStep2 === false) ||
            (!selectedPetwhilerescheduleLoader &&
              showPetsScreen &&
              componentContextData?.bookedAppointmentForStep1)) && (
            <>
              <p
                className={step1HeadingLink()}
                onClick={(e) => {
                  e?.preventDefault();
                  if (setIsEdit) setIsEdit(false);
                  setshowPetsScreen(false);
                  setshowSelectedPet(null);
                  setShowAddPetsForm(true);
                  setUpdatedPetImage('');
                  setcomponentContextData({
                    ...componentContextData,
                    resetStep3: true,
                    resetStep4: true,
                    bookedAppointmentForStep2: undefined,
                  });
                }}
              >
                {getDictionaryValue('ScheduleAppointmentsStep2AddDogLinkText')}
              </p>
            </>
          )}
          {((!selectedPetwhilerescheduleLoader &&
            componentContextData?.resetStep2 === false &&
            showSelectedPet !== null) ||
            (!selectedPetwhilerescheduleLoader &&
              componentContextData?.bookedAppointmentForStep1 &&
              showSelectedPet !== null)) && (
            <>
              <p
                className={step1HeadingLink()}
                onClick={(e) => {
                  e?.preventDefault();
                  getAllPets();
                  setshowPetsScreen(true);
                  setUpdatedPetImage('');
                  setcomponentContextData({
                    ...componentContextData,
                    resetStep3: true,
                    resetStep4: true,
                    bookedAppointmentForStep2: undefined,
                    bookedAppointmentForStep3: undefined,
                    bookedAppointmentForStep4: undefined,
                  });
                  setshowSelectedPet(null);
                }}
              >
                {getDictionaryValue('ScheduleAppointmentStep1EditLinkText')}
              </p>
            </>
          )}
        </div>
      </div>

      {componentContextData?.resetStep2 === false && (
        <>
          {petFetchLoader && <LoaderComponent />}
          {selectedPetLoader && <ServiceLoaderComponent />}
          {!petFetchLoader && !selectedPetLoader && (
            <>
              {componentContextData?.bookedAppointmentForStep2 == undefined && (
                <>
                  {' '}
                  {((storeSelected && !showAddPetsForm && showStep2) || showPetsScreen) && (
                    <div
                      className={clsx(
                        addPetWrapper(),
                        'mt-desk-space-between-tight-horizontal border-none flex-wrap items-stretch'
                      )}
                    >
                      {petList
                        ?.filter((petItem) => petItem?.SpeciesId === 2)
                        .map((petItem, index: number) => (
                          <div
                            key={index}
                            className="flex flex-col lg:mr-desk-margin-loose-right lg:min-w-[240px] lg:max-w-[240px] w-full mb-[35px]"
                          >
                            <div className="flex flex-row">
                              <div>
                                <div className={clsx(imageWrapper(), 'mr-desk-margin-tight-right')}>
                                  {petItem?.PetImageName ? (
                                    <NextImage
                                      field={{
                                        value: {
                                          src:
                                            process.env.NEXT_PUBLIC_PSP_PET_CDN +
                                            '/' +
                                            petItem?.PetImageName,
                                          alt: 'AddPet',
                                          width: '48',
                                          height: '48',
                                        },
                                      }}
                                      className={img({ className: 'max-w-[48px]' })}
                                    />
                                  ) : (
                                    <NextImage
                                      field={{
                                        value: {
                                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                          src: (sitecoreContext as any)?.route?.fields
                                            ?.groomingConfiguration[0]?.fields?.defaultPetImage
                                            ?.value?.src,
                                          alt: 'AddPet',
                                          width: '48',
                                          height: '48',
                                        },
                                      }}
                                      className={img({ className: 'max-w-[48px]' })}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className={(rightContent(), 'px-0 ')}>
                                <div
                                  className={petDetailsWraper({
                                    className: 'py-mob-margin-tight-right lg:py-0',
                                  })}
                                >
                                  <div className={'flex gap-desk-margin-tight-right pr-[10px]'}>
                                    <p className={textSmallBold({ className: 'break-all' })}>
                                      {petItem?.Petname}
                                    </p>
                                    <TextHelper
                                      field={fields?.editLinkText}
                                      tag="button"
                                      className={link()}
                                    />
                                  </div>
                                  <div className={textSmall({ className: 'flex flex-col' })}>
                                    <p>{petItem?.BirthdateString}</p>
                                    <p>{petItem?.GenderName}</p>
                                    <p>{petItem?.BreedName}</p>
                                  </div>
                                  <TextHelper
                                    field={fields?.removePetLinkText}
                                    className={link({ className: 'text-left' })}
                                    tag="button"
                                  />
                                </div>
                              </div>

                              <div
                                className={clsx(step1HeadingLink(), 'ml-auto')}
                                onClick={() => {
                                  setIsEdit(true);
                                  setCurrentEditPetId && setCurrentEditPetId(petItem?.PetId);
                                  setShowAddPetsForm(true);
                                  setshowPetsScreen(false);
                                }}
                              >
                                {getDictionaryValue('ScheduleAppointmentStep1EditLinkText')}
                              </div>
                            </div>
                            <div className="mt-auto">
                              {petItem && !inProgressLoading[petItem.PetId] ? (
                                <ButtonHelper
                                  field={{
                                    value: getDictionaryValue(
                                      'ScheduleAppointmentsStep2SelectDogButtonText'
                                    ),
                                  }}
                                  isLinkField={false}
                                  size="medium"
                                  variant="primary"
                                  className="w-full mt-[10px]"
                                  onClickHandler={() => handleButtonClick(petItem)}
                                />
                              ) : (
                                <div className={loaderWrapper()}>
                                  <Loader />
                                  <span>
                                    {getDictionaryValue(
                                      'ScheduleAppointmentsStep2SelectDogInProgressText'
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </>
              )}
              {showAddPetsForm && (
                <AddPetsForm
                  fields={fields}
                  setshowPetsScreen={setshowPetsScreen}
                  setShowAddPetsForm={setShowAddPetsForm}
                  getAllPets={getAllPets}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  petGenders={petGenders}
                  petSpecies={petSpecies}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  petDetails={petList as any}
                  currentEditPetId={currentEditPetId}
                  geteditformdata={getEditFormData}
                  selectedpetitem={selectedPetItem}
                  onPetImageUpdate={handlePetImageUpdate}
                  companyID={
                    selectedStoreFromStep1?.xp_onlinebookingcompany ||
                    componentContextData?.bookedAppointmentForStep1?.Location?.CompanyId
                  }
                />
              )}
              {selectedPetwhilerescheduleLoader && <FetchPetsWhileReschdule />}
              {(!selectedPetwhilerescheduleLoader && showSelectedPet && !showPetsScreen) ||
              (!selectedPetwhilerescheduleLoader &&
                componentContextData?.bookedAppointmentForStep2 &&
                !showPetsScreen) ? (
                <>
                  <div className="flex flex-col mr-desk-margin-loose-right min-w-[240px] max-w-[240px] mb-[35px] mt-[24px]">
                    <div className="flex flex-row">
                      <div>
                        <div className={clsx(imageWrapper(), 'mr-desk-margin-tight-right')}>
                          {updatedPetImage && updatedPetImage !== '' ? ( // Check if updatedPetImage is valid first
                            <NextImage
                              field={{
                                value: {
                                  src: `${process.env.NEXT_PUBLIC_PSP_PET_CDN}/${updatedPetImage}`, // Use updatedPetImage if valid
                                  alt: 'AddPet',
                                  width: 48,
                                  height: 48,
                                },
                              }}
                              className={img({ className: 'max-w-[48px]' })}
                            />
                          ) : showSelectedPet?.PetImageName ||
                            showSelectedPet?.petImage ||
                            showSelectedPet?.Image ||
                            showSelectedPet?.PetImage ? (
                            <NextImage
                              field={{
                                value: {
                                  src: showSelectedPet?.PetImageName // First priority
                                    ? showSelectedPet.PetImageName.includes('data:image')
                                      ? showSelectedPet.PetImageName
                                      : `${process.env.NEXT_PUBLIC_PSP_PET_CDN}/${showSelectedPet.PetImageName}`
                                    : showSelectedPet?.petImage // Second priority
                                    ? showSelectedPet.petImage.includes('data:image')
                                      ? showSelectedPet.petImage
                                      : `${process.env.NEXT_PUBLIC_PSP_PET_CDN}/${showSelectedPet.petImage}`
                                    : showSelectedPet?.Image // Third priority
                                    ? showSelectedPet?.Image.includes('data:image')
                                      ? showSelectedPet?.Image
                                      : `${process.env.NEXT_PUBLIC_PSP_PET_CDN}/${showSelectedPet?.Image}`
                                    : '', // Return empty string if no valid images found

                                  alt: 'AddPet',
                                  width: 48,
                                  height: 48,
                                },
                              }}
                              className={img({ className: 'max-w-[48px]' })}
                            />
                          ) : (
                            <NextImage
                              field={{
                                value: {
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  src: (sitecoreContext as any)?.route?.fields
                                    ?.groomingConfiguration[0]?.fields?.defaultPetImage?.value?.src,
                                  alt: 'AddPet',
                                  width: 48,
                                  height: 48,
                                },
                              }}
                              className={img({ className: 'max-w-[48px]' })}
                            />
                          )}
                        </div>
                      </div>
                      <div className={rightContent()}>
                        <div
                          className={petDetailsWraper({
                            className: 'py-mob-margin-tight-right lg:py-0',
                          })}
                        >
                          <div className={'flex gap-desk-margin-tight-right pr-[10px]'}>
                            <p className={textSmallBold({ className: 'break-all' })}>
                              {showSelectedPet?.Petname
                                ? showSelectedPet.Petname
                                : showSelectedPetData?.ChildName
                                ? showSelectedPetData.ChildName
                                : componentContextData?.bookedAppointmentForStep1?.ChildName
                                ? componentContextData.bookedAppointmentForStep1.ChildName
                                : showSelectedPet?.Name
                                ? showSelectedPet.Name
                                : ''}
                            </p>
                            <TextHelper
                              field={fields?.editLinkText}
                              tag="button"
                              className={link()}
                            />
                          </div>
                          <div className={textSmall({ className: 'flex flex-col' })}>
                            <p>
                              {showSelectedPet?.BirthdateString ||
                                showSelectedPetData?.BirthdateString ||
                                (petbirthDate && isValidDate(petbirthDate)
                                  ? petbirthDate.toLocaleDateString()
                                  : petbirthDate || '') ||
                                (componentContextData?.newPetBdy
                                  ? new Date(componentContextData.newPetBdy).toLocaleDateString(
                                      'en-US',
                                      {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                      }
                                    )
                                  : '') ||
                                (showSelectedPet?.Birthdate
                                  ? new Date(showSelectedPet.Birthdate).toLocaleDateString(
                                      'en-US',
                                      {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                      }
                                    )
                                  : '')}
                            </p>

                            <p>
                              <p>
                                {showSelectedPet?.GenderName ||
                                  showSelectedPetData?.GenderName ||
                                  (showSelectedPet?.Gender == '1'
                                    ? 'Male'
                                    : showSelectedPet?.Gender == '2'
                                    ? 'Female'
                                    : 'Unknown')}
                              </p>
                            </p>
                            <p>
                              {showSelectedPet?.BreedName
                                ? showSelectedPet.BreedName
                                : showSelectedPetData?.BreedName
                                ? showSelectedPetData.BreedName
                                : componentContextData?.bookedAppointmentForStep1?.ChildBreed
                                ? componentContextData?.bookedAppointmentForStep1?.ChildBreed
                                : showSelectedPet?.breedname
                                ? showSelectedPet?.breedname
                                : ''}
                            </p>
                          </div>
                          <TextHelper
                            field={fields?.removePetLinkText}
                            className={link({ className: 'text-left' })}
                            tag="button"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {showBirthdayMissingPopup && (
                <ModalWrapper
                  showCloseButtonInModalHeader={false}
                  closeModalOnOverlayClick={true}
                  onCloseClick={handleModalClose}
                  showModal={true}
                  customPopup={true}
                  popupWidth="max-w-[1200px] overflow-y-visible"
                  popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x"
                >
                  <div className="birthday-warning-popup">
                    <span className="font-heading-desk-xLarge-bold text-color-text-black text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold mb-[24px] block">
                      {getDictionaryValue('ScheduleAppointmentsStep4BirthDateRequired')}
                    </span>
                    <p className="text-body-large-bold leading-body-large-bold font-body-large-bold  text-color-text-black  mb-[20px]">
                      {getDictionaryValue(
                        'ScheduleAppointmentsStep2DogDateOfBirthMissingText'
                      ).replace('{0}', `${selectedpetItemtosubmut?.Petname}`)}
                    </p>
                    <div className="birthday-date-picker max-w-[200px]">
                      <span className="text-body-large-bold font-body-large-bold leading-body-large-bold">
                        {getDictionaryValue('ScheduleAppointmentsStep4BirthDateLabel')}
                      </span>
                      <BirthdayDatePicker setBirthDate={setPetBirthDate} />
                    </div>
                    <div className="save-to-profile flex flex-row gap-[10px] my-[12px]">
                      <label htmlFor="saveToProfile" className="relative">
                        <input
                          type="checkbox"
                          name="saveToProfile"
                          value="checked"
                          onChange={() => {
                            profileChecked();
                          }}
                          checked={saveToProfileChecked}
                          className={checkbox()}
                        />
                        {saveToProfileChecked && (
                          <IconHelper
                            className={checkboxIcon()}
                            icon={'icon-checkmark'}
                            onClick={() => {
                              profileChecked();
                            }}
                          />
                        )}
                      </label>
                      <span>
                        {' '}
                        {getDictionaryValue('ScheduleAppointmentsStep4SaveToProfileText')}
                      </span>
                    </div>
                    <div className="flex flex-row gap-[12px] mt-[24px]">
                      {createUpdatePetWhileReschdulingLoader && (
                        <div
                          className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}
                        >
                          <Loader />
                          <span>
                            {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                          </span>
                        </div>
                      )}
                      {!createUpdatePetWhileReschdulingLoader && (
                        <ButtonHelper
                          field={{
                            value: getDictionaryValue(
                              'ScheduleAppointmentsStep2UpdateDogButtonText'
                            ),
                          }}
                          isLinkField={false}
                          size="medium"
                          variant={'primary'}
                          isDisabled={!petbirthDate}
                          className={!petbirthDate ? 'opacity-50' : ''}
                          onClickHandler={() => {
                            if (saveToProfileChecked) {
                              updatePetFromBdyPopuop();
                            }
                            setcreateUpdatePetWhileReschdulingLoader(true);
                            createUpdatePetWhileReschduling(selectedpetItemtosubmut);
                          }}
                        />
                      )}

                      <ButtonHelper
                        field={{
                          value: getDictionaryValue('ScheduleAppointmentsStep4CancelButtonText'),
                        }}
                        isLinkField={false}
                        size="medium"
                        variant="secondary"
                        onClickHandler={() => {
                          handleModalClose();
                        }}
                      />
                    </div>
                  </div>
                </ModalWrapper>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ScheduleAppointmentStep2;
