import React from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { navigationTailwindVariant } from 'tailwindVariants/components/navigationTailwindVariant';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import { PurchaseDetailsProps } from './Details';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { DiscreteLineItem } from '../Constants';

const PrintOrderSummary = ({ orderData, fields }: PurchaseDetailsProps) => {
  const { printOrderSummary, colorRed, colorBgLight, boldLabels, smallRegularLabel } =
    purchaseDetailsVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });
  const { justifyBetween } = navigationTailwindVariant({});
  const { getDictionaryValue } = useDictionary();
  const currencySymbol = getDictionaryValue('CurrencySymbol');
  const deliveryTip = orderData?.LineItems?.filter(
    (product) => product?.ProductID == DiscreteLineItem.TIP
  )?.[0]?.LineTotal;
  return (
    <div
      className={printOrderSummary()}
      data-component={'/helpers/purchaseDetails/PrintOrderSummary.tsx'}
    >
      <div className={justifyBetween()}>
        <Text tag="span" field={fields?.listPriceText} />
        <span>
          {currencySymbol + '' + orderData?.Order?.xp?.EmailContent?.ListPrice?.toFixed(2)}
        </span>
      </div>
      {orderData?.Order?.xp?.EmailContent?.TotalMemberSaving &&
      orderData?.Order?.xp?.EmailContent?.TotalMemberSaving > 0 ? (
        <>
          <div className={justifyBetween({ className: colorRed({ className: boldLabels() }) })}>
            <Text tag="span" field={fields?.totalMemberSavingText} />
            <span>
              {currencySymbol +
                '' +
                orderData?.Order?.xp?.EmailContent?.TotalMemberSaving?.toFixed(2)}
            </span>
          </div>
          <div
            className={colorBgLight({ className: 'colorLight p-desk-global-spacing-spacing-2' })}
          >
            {orderData?.Order?.xp?.EmailContent?.SalePrice &&
            orderData?.Order?.xp?.EmailContent?.SalePrice > 0 ? (
              <div className={justifyBetween()}>
                <Text tag="span" field={fields?.salePriceText} />
                <span>
                  {currencySymbol + '' + orderData?.Order?.xp?.EmailContent?.SalePrice?.toFixed(2)}
                </span>
              </div>
            ) : null}
            {orderData?.Order?.xp?.EmailContent?.AutoshipDiscount &&
            orderData?.Order?.xp?.EmailContent?.AutoshipDiscount > 0 ? (
              <div className={justifyBetween()}>
                <Text tag="span" field={fields?.autoshipDiscountText} />
                <span>
                  {currencySymbol +
                    '' +
                    orderData?.Order?.xp?.EmailContent?.AutoshipDiscount?.toFixed(2)}
                </span>
              </div>
            ) : null}
            {orderData?.Order?.xp?.EmailContent?.Coupons &&
            orderData?.Order?.xp?.EmailContent?.Coupons?.length > 0 ? (
              <div className={justifyBetween()}>
                <Text tag="span" field={fields?.couponText} />
                {orderData?.Order?.xp?.EmailContent?.Coupons?.map((coupon) => (
                  <>
                    <span className={smallRegularLabel({ className: 'grow' })}>
                      {coupon?.Coupon}
                    </span>
                    <div key={coupon?.Coupon} className={smallRegularLabel()}>
                      {getDictionaryValue('CurrencySymbol')}
                      <span>{Number(coupon?.CouponDiscount)?.toFixed(2)}</span>
                    </div>
                  </>
                ))}
              </div>
            ) : null}
          </div>{' '}
        </>
      ) : null}

      <div className={justifyBetween()}>
        <Text tag="span" field={fields?.subTotalText} />
        <span>{currencySymbol + '' + orderData?.Order?.Subtotal?.toFixed(2)}</span>
      </div>
      {orderData?.Order?.ShippingCost && orderData?.Order?.ShippingCost > 0 ? (
        <div className={justifyBetween()}>
          <Text tag="span" field={fields?.deliveryFeeText} />
          <span>{currencySymbol + '' + orderData?.Order?.ShippingCost?.toFixed(2)}</span>
        </div>
      ) : null}
      {deliveryTip && deliveryTip > 0 ? (
        <div className={justifyBetween()}>
          <Text tag="span" field={fields?.deliveryTipText} />
          <span>{currencySymbol + '' + deliveryTip?.toFixed(2)}</span>
        </div>
      ) : null}
      <div className={justifyBetween()}>
        <Text tag="span" field={fields?.taxText} />
        <span>{currencySymbol + '' + orderData?.Order?.xp?.EmailContent?.Tax?.toFixed(2)}</span>
      </div>
      <div className={justifyBetween({ className: boldLabels() })}>
        <Text tag="span" field={fields?.orderTotalText} />
        <span>{currencySymbol + '' + orderData?.Order?.Total?.toFixed(2)}</span>
      </div>
      <div className={justifyBetween()}>
        <Text tag="span" field={fields?.paymentMethodText} />
        <span>{'********' + orderData?.Order?.xp?.CardEndingIn}</span>
      </div>
    </div>
  );
};

export default PrintOrderSummary;
