import clsx from 'clsx';
import React from 'react';
import { PurchaseDetailsFields } from 'components/PurchaseDetails/PurchaseDetails';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { formatStorePhoneForDisplay } from 'src/helpers/Form/PhoneField';
import { OrderWorksheetWithXP } from 'src/redux/xp';
import { formatDateToDdYyMm } from 'src/utils/formatDate';
import { getPurchaseType } from 'src/utils/myPurchase';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import { useBarcode } from 'next-barcode';
import { FulfillmentType } from '../Constants';

interface PrintOrderDetailsProps extends PurchaseDetailsFields {
  data: OrderWorksheetWithXP;
}

const PrintOrderDetails = ({ data, fields }: PrintOrderDetailsProps) => {
  const {
    colorBgBrand,
    colorTextBrand,
    printOrderLabel,
    printOrderDetails,
    printOrderInfo,
    printAddressAndCodeWrapper,
    printStoreDetail,
    printStoreDetailLabel,
    printOrderTopSection,
    boldLabels,
    colorBgLight,
    pageTitle,
  } = purchaseDetailsVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { inputRef } = useBarcode({
    value: data?.Order?.xp?.OrderId || '',
    options: {
      background: '#fff',
      height: 38,
      width: 1,
      margin: 5,
    },
  });
  return (
    <div
      className={printOrderTopSection()}
      data-component={'/helpers/purchaseDetails/PrintOrderDetails.tsx'}
    >
      <Text
        tag="button"
        className={printOrderLabel({ className: clsx(pageTitle(), colorBgBrand()) })}
        field={fields?.orderDetailsText}
      />

      <div className={printOrderDetails()}>
        <div className={printAddressAndCodeWrapper()}>
          <div className={printStoreDetail()}>
            <div className={printStoreDetailLabel()}>
              <Text field={fields?.fromStoreText} />
              <div>{data?.LineItems?.[0]?.ShipFromAddress?.City}</div>
              <div
                className={printStoreDetail({
                  className: clsx(colorTextBrand(), '!px-0 no-underline'),
                })}
              >
                {formatStorePhoneForDisplay(data?.LineItems?.[0]?.ShipFromAddress?.Phone)}
              </div>
            </div>
            <svg ref={inputRef} />
          </div>
        </div>
        <div className={printOrderInfo({ className: colorBgLight({}) })}>
          <div className="grow">
            <div>
              <Text tag="span" className={boldLabels()} field={fields?.nameText} />{' '}
              {data?.Order?.FromUser?.FirstName + ' ' + data?.Order?.FromUser?.LastName}
            </div>
            <div>
              <Text tag="span" className={boldLabels()} field={fields?.orderText} />{' '}
              {data?.Order?.xp?.OrderId}{' '}
            </div>
            <div>
              <Text tag="span" className={boldLabels()} field={fields?.fulFillMentMethodText} />{' '}
              {getPurchaseType(
                data?.Order?.xp?.Fulfillment as string,
                data?.Order?.xp?.IsSubscription as boolean
              )}
            </div>
            {data?.Order?.DateSubmitted && (
              <div>
                <Text tag="span" className={boldLabels()} field={fields?.orderDateText} />{' '}
                {formatDateToDdYyMm(data?.Order?.DateSubmitted as string)}
              </div>
            )}
            {data?.Order?.xp?.DeliveryDueDate && (
              <div>
                <Text tag="span" className={boldLabels()} field={fields?.deliveryDateText} />{' '}
                {formatDateToDdYyMm(data?.Order?.xp?.DeliveryDueDate as string)}
              </div>
            )}
            <div>
              <Text tag="span" className={boldLabels()} field={fields?.storePhoneText} />
              <span className={colorTextBrand({ className: boldLabels() })}>
                {' '}
                {formatStorePhoneForDisplay(data?.LineItems?.[0]?.ShipFromAddress?.Phone)}
              </span>
            </div>
          </div>
          <div className="grow">
            {data?.Order?.xp?.Fulfillment === FulfillmentType?.DFS ? (
              <div>
                <Text tag="span" className={boldLabels()} field={fields?.deliveryAddressText} />
                <div>
                  <div>
                    {data?.Order?.xp?.DeliveryAddress?.Street1 ||
                      data?.LineItems?.[0]?.ShippingAddress?.Street1}
                  </div>
                  <div>
                    <span>
                      {data?.Order?.xp?.DeliveryAddress?.City ||
                        data?.LineItems?.[0]?.ShippingAddress?.City}
                      ,{' '}
                      {data?.Order?.xp?.DeliveryAddress?.State ||
                        data?.LineItems?.[0]?.ShippingAddress?.State}{' '}
                    </span>
                    <div>
                      {data?.Order?.xp?.DeliveryAddress?.Zip ||
                        data?.LineItems?.[0]?.ShippingAddress?.Zip}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {/**TODO: need to change static text */}
                <Text tag="span" className={boldLabels()} field={{ value: 'Address' }} />
                <div>
                  <div>
                    {data?.Order?.xp?.DeliveryAddress?.Street1 ||
                      data?.LineItems?.[0]?.ShipFromAddress?.Street1}
                  </div>
                  <div>
                    <span>
                      {data?.Order?.xp?.DeliveryAddress?.City ||
                        data?.LineItems?.[0]?.ShipFromAddress?.City}
                      ,{' '}
                      {data?.Order?.xp?.DeliveryAddress?.State ||
                        data?.LineItems?.[0]?.ShipFromAddress?.State}{' '}
                    </span>
                    <div>
                      {data?.Order?.xp?.DeliveryAddress?.Zip ||
                        data?.LineItems?.[0]?.ShipFromAddress?.Zip}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <Text tag="span" className={boldLabels()} field={fields?.paymentMethodText} />
              <span>{' ********' + data?.Order?.xp?.CardEndingIn}</span>
            </div>
          </div>
        </div>
      </div>
      <Text
        tag="div"
        className={printOrderLabel({ className: clsx(pageTitle(), colorBgBrand()) })}
        field={fields?.orderSummaryText}
      />
    </div>
  );
};

export default PrintOrderDetails;
