import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { PreviewSearchInitialState } from '@sitecore-search/react';
import { WidgetDataType, usePreviewSearch, widget } from '@sitecore-search/react';
import { Presence, PreviewSearch } from '@sitecore-search/ui';

import styles from './styles.module.css';
import previewSearchTailwind from './SearchInput';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useBreakpoints } from 'src/utils/breakpoints';
import useDictionary from 'src/hooks/useDictionary';

type ProductModel = {
  id: string;
  name: string;
  image_url: string;
  price: string;
  url: string;
  source_id?: string;
};
type InitialState = PreviewSearchInitialState<'suggestionsList'>;
interface PreviewSearchProps {
  // defaultProductsPerPage: number | 6;

  /**
   * An optional custom redirection handler that will be called when the user clicks on a product.
   * You can use your own redirection logic here, or any other side effect.
   * Examples
   * * (product: ProductModel) => history.push(`/search?q=${product.id}`);
   * * (product: ProductModel) => window.location.href = `/search?q=${product.id}`;
   * * (product: ProductModel) => setRoute(`/custom/search/endpoint?q=${product.id}`);
   * @param product The product that was clicked.
   */
  itemRedirectionHandler?: (product: ProductModel) => void;

  /**
   * An optional custom submit handler that will be called when the user submits the form by pressing the enter key.
   * You can use your own submit logic here, or any other side effect.
   * Most common use case is to redirect the user to a custom search page with the query string.
   * Examples
   * * (query: string) => history.push(`/search?q=${query}`);
   * * (query: string) => window.location.href = `/search?q=${query}`;
   * * (query: string) => setRoute(`/custom/search/endpoint?q=${query}`);
   * @param query The query string that was submitted.
   */
  submitRedirectionHandler?: (query: string) => void;

  /**
   * @param isSearchBar: Boolean for handling variants for search on header and on page.
   */
  isSearchBar?: boolean;

  /**
   * @param uponClick: function to call upon click.
   */
  uponClick?: () => void;

  /**
   * @param triggerSearchOnMobile: function to render as per search.
   */
  triggerSearchOnMobile?: boolean;

  /**
   * @param activateSearchonMobile: function to toggle search input and close
   */
  activateSearchonMobile?: () => void;
}

export const PreviewSearchComponent = ({
  // defaultProductsPerPage = 6,
  isSearchBar,
  // uponClick,
  triggerSearchOnMobile,
  activateSearchonMobile,
}: // itemRedirectionHandler,
// submitRedirectionHandler,

PreviewSearchProps) => {
  const {
    widgetRef,
    actions: { onKeyphraseChange },
    queryResult: {
      data: {
        suggestion: {
          auto_name_suggestion: productSuggestions = [],
          // recent_history: recentHistory = [],
        } = {},
      } = {},
    },
    query,
  } = usePreviewSearch<ProductModel, InitialState>({
    query: (query) => query.getRequest(),
    // Optionally, you can customize the query further here
    // .addFields([]), // Ensures no unnecessary fields are fetched

    state: {
      suggestionsList: [
        {
          suggestion: 'auto_name_suggestion',
          max: 5,
        },
        // {
        //   suggestion: 'recent_history',
        //   max: 5,
        // },
      ],
    },
  });

  // const _loading = isLoading || isFetching;  // Not needed now, but keeping for reference in future
  const [currentSuggestionValue, setCurrentSuggestionValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true); // New state to control visibility
  // const [showRecentHistory, setShowRecentHistory] = useState(true); // New state to control visibility

  const [loadingState, setLoadingState] = useState(false);
  const { getDictionaryValue } = useDictionary();

  // const keyphraseHandler = useCallback(
  //   (event: ChangeEvent<HTMLInputElement>) => {
  //     const target = event.target;
  //     onKeyphraseChange({
  //       keyphrase: target.value,
  //     });
  //     setCurrentSuggestionValue(target.value);
  //     setShowSuggestions(true); // Show suggestions when input changes
  //     setOnInputTriggered(false);
  //   },
  //   [onKeyphraseChange]
  // );

  const keyphraseHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      // Optimize by grouping state updates together
      onKeyphraseChange({ keyphrase: value });
      setCurrentSuggestionValue(value);

      // Batch state updates to prevent multiple re-renders
      setShowSuggestions((prev) => {
        if (!prev) return true;
        return prev;
      });

      setOnInputTriggered(false);
    },
    [onKeyphraseChange]
  );

  const router = useRouter();

  useEffect(() => {
    const currentRouteQuery = (router?.query?.q || router?.query?.query) ?? '';
    setOnInputTriggered(false);
    setCurrentSuggestionValue(currentRouteQuery as string);
  }, [router?.query?.q, router?.query?.query]);
  const {
    searchInput,
    searchSuggestionWrapper,
    // listPadding,
    previewSearchForm,
    previewSearchContent,
    resultItem,
    iconClose,
    iconSearch,
    iconSearchSmall,
    searchWrapper,
    searchBarWrapper,
    searchButton,
  } = previewSearchTailwind({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { isDesktop: desktopDetected } = useBreakpoints();
  const hasQueryAppended = router?.query?.q || router?.query?.query;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoadingState(true);
    setShowSuggestions(false);
    router.push(`/search?q=${encodeURIComponent(currentSuggestionValue)}`);
  };

  const handleRouteChange = () => {
    setLoadingState(false);
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', handleRouteChange);
    };
  }, [router.events]);

  const [onInputTriggered, setOnInputTriggered] = useState(false);

  const trialFunction = () => {
    // if (onInputTriggered && !currentSuggestionValue) {
    //   setShowRecentHistory(true);
    // }

    if (onInputTriggered && currentSuggestionValue) {
      query.getRequest();
      onKeyphraseChange({
        keyphrase: currentSuggestionValue,
      });
      // setShowRecentHistory(false);
    }
    // if (!onInputTriggered) {
    //   setShowRecentHistory(false);
    // }
    // upon trigger change when true, need to fire recent history again
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (!currentSuggestionValue) {
      setShowSuggestions(true);
      // to trigger recent search history
      // setShowRecentHistory(true);
      setOnInputTriggered(true);
    } else {
      // check is has space, if yes, trigger with `AND` operator.
      const containsWhiteSpace = /\s/.test(currentSuggestionValue);
      if (containsWhiteSpace) {
        query.getRequest().setSearchQueryOperator('and');
      }
    }
  }, [currentSuggestionValue]);

  //handling keydown event for accessibility
  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setCurrentSuggestionValue('');
      onKeyphraseChange({
        keyphrase: '',
      });
    }
  };

  //handling keydown for search event for accessibility
  const handleSearch = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setLoadingState(true);
      setShowSuggestions(false);
      router.push(`/search?q=${encodeURIComponent(currentSuggestionValue)}`);
    }
  };

  return (
    <PreviewSearch.Root>
      <div
        className={clsx(
          'searchForm',
          styles['sitecore-preview-search-root'],
          { 'w-full': isSearchBar },
          searchWrapper()
        )}
      >
        <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   const { value: query } = e.currentTarget.elements[0] as HTMLInputElement;
          //   submitRedirectionHandler && submitRedirectionHandler(query);
          // }}
          onSubmit={(event) => {
            event.preventDefault();
            if (currentSuggestionValue?.trim().length > 0) {
              setLoadingState(true);
              handleSubmit(event);
            }
          }}
          className={previewSearchForm({
            isSearchBarVariant: isSearchBar,
          })}
        >
          <div className={searchBarWrapper()}>
            <PreviewSearch.Input
              onChange={keyphraseHandler}
              onFocus={() => setOnInputTriggered(true)}
              ref={inputRef}
              autoComplete="off"
              placeholder={isSearchBar ? getDictionaryValue('Search') ?? 'Search' : ''}
              data-testid="productsPSLeftInput"
              // className={styles['sitecore-preview-search-input']}
              className={clsx(
                searchInput({
                  isSearchBarVariant: isSearchBar,
                  className: '!justify-end',

                  // isSearchBar: 'lg:max-w-[167px]',
                }),
                'searchInput'
              )}
              aria-label="searchBox"
              value={currentSuggestionValue}
              onClick={() => {
                setOnInputTriggered(true);
                trialFunction();
                // e.stopPropagation();
                query.getRequest();
              }}
            />
            {isSearchBar ? (
              <>
                {currentSuggestionValue && desktopDetected && (
                  <IconHelper
                    tabIndex={0}
                    icon={'close'}
                    className={iconClose({
                      className:
                        'mr-[36px] pl-[5px] pr-[8px] bg-color-background-white [&>svg]:!h-auto',
                    })}
                    onClick={() => {
                      setCurrentSuggestionValue('');
                      onKeyphraseChange({
                        keyphrase: '',
                      });
                    }}
                    onKeyDown={handleKeyDown}
                  />
                )}
                <IconHelper
                  tabIndex={0}
                  icon={loadingState ? 'search-loader' : 'search'}
                  className={clsx(
                    iconSearchSmall({
                      isSearchBarVariant: isSearchBar,
                      isLoading: loadingState,
                    }),
                    '[&>svg]:!h-auto'
                  )}
                  onClick={() => {
                    if (currentSuggestionValue?.trim().length > 0) {
                      setLoadingState(true);
                      handleSubmit(event);
                    }
                  }}
                  onKeyDown={handleSearch}
                />
              </>
            ) : (
              <IconHelper
                tabIndex={0}
                icon={'close'}
                className={iconClose()}
                onClick={() => {
                  setOnInputTriggered(false);
                  setCurrentSuggestionValue('');
                  onKeyphraseChange({
                    keyphrase: '',
                  });
                  // router.push(`/search`);
                  // setShowRecentHistory(true);
                }}
                onKeyDown={handleKeyDown}
              />
            )}
          </div>
          {triggerSearchOnMobile && !desktopDetected && (
            <IconHelper
              icon={'close'}
              tabIndex={0}
              // className={iconClose()}
              onClick={() => {
                setCurrentSuggestionValue('');
                if (activateSearchonMobile) {
                  activateSearchonMobile();
                }
                // TODO: will need to check if any query is appended than trigger below:
                if (hasQueryAppended) {
                  onKeyphraseChange({
                    keyphrase: '',
                  });
                  router.push(`/search`);
                }
              }}
              onKeyDown={handleKeyDown}
            />
          )}
          {!isSearchBar && (
            <>
              <button aria-label="search" className={searchButton({ isLoading: loadingState })}>
                <IconHelper
                  tabIndex={0}
                  icon={loadingState ? 'search-loader' : 'search'}
                  className={iconSearch()}
                  onClick={() => {
                    if (currentSuggestionValue?.trim().length > 0) {
                      setLoadingState(true);
                      handleSubmit(event);
                    }
                  }}
                  onKeyDown={handleSearch}
                />
              </button>
            </>
          )}
        </form>
        {showSuggestions && (
          <PreviewSearch.Content
            ref={widgetRef}
            className={clsx(styles['sitecore-preview-search-content'], previewSearchContent())}
          >
            <Presence
              present={true} // to resolve flicker of suggestion box when fetching new data. As per PSP-2603.
            >
              <>
                <PreviewSearch.Suggestions
                  className={clsx(
                    searchSuggestionWrapper(),
                    styles['sitecore-preview-search-suggestions']
                  )}
                >
                  {/* Render Recent Search history to user: */}
                  {/* {!currentSuggestionValue &&
                  showRecentHistory &&
                  onInputTriggered &&
                  recentHistory.length > 0 ? (
                    <PreviewSearch.SuggestionsGroup
                      id="recent_history"
                      className={styles['sitecore-preview-search-suggestions-group']}
                    >
                      {recentHistory.map(({ text }) => (
                        <PreviewSearch.SuggestionTrigger
                          id={text}
                          key={text}
                          asChild
                          className={clsx('flex flex-col w-full')}
                        >
                          <a
                            aria-label="search_suggestions"
                            className={clsx(resultItem(), 'text-left w-full')}
                            onClick={() => {
                              // e.stopPropagation();
                              setCurrentSuggestionValue(text);
                              setShowRecentHistory(false); // Show suggestions when input changes
                              setOnInputTriggered(false);
                              setLoadingState(true);
                              router.push(`/search?q=${encodeURIComponent(text)}`);
                            }}
                          >
                            {text}
                          </a>
                        </PreviewSearch.SuggestionTrigger>
                      ))}
                    </PreviewSearch.SuggestionsGroup>
                  ) : (
                    <></>
                  )} */}

                  {/* Render product suggestions */}
                  {currentSuggestionValue && !onInputTriggered && productSuggestions.length > 0 && (
                    <PreviewSearch.SuggestionsGroup
                      id="auto_name_suggestion"
                      className={styles['sitecore-preview-search-suggestions-group']}
                    >
                      {productSuggestions.map(({ text }) => (
                        <PreviewSearch.SuggestionTrigger
                          id={text}
                          key={text}
                          asChild
                          className={clsx(
                            'flex flex-col w-full'
                            // styles['sitecore-preview-search-suggestion-trigger']
                          )}
                        >
                          <a
                            aria-label="search link"
                            className={clsx(resultItem(), 'text-left w-full')}
                            onClick={() => {
                              // e.stopPropagation();
                              setCurrentSuggestionValue(text);
                              setShowSuggestions(false); // Show suggestions when input changes
                              setOnInputTriggered(false);

                              setLoadingState(true);
                              router.push(`/search?q=${encodeURIComponent(text)}`);
                            }}
                          >
                            {text}
                          </a>
                        </PreviewSearch.SuggestionTrigger>
                      ))}
                    </PreviewSearch.SuggestionsGroup>
                  )}
                </PreviewSearch.Suggestions>
              </>
            </Presence>
          </PreviewSearch.Content>
        )}
      </div>
      {/* //{' '} */}
    </PreviewSearch.Root>
  );
};
const PreviewSearchWidget = widget(
  PreviewSearchComponent,
  WidgetDataType.PREVIEW_SEARCH,
  'product'
);
export default PreviewSearchWidget;
