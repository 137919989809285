import React from 'react';

const FrequentlyBoughtTogetherSkeleton = () => {
  // Create an array with the number of products to display
  const productCount = 3;
  const productSkeletons = Array(productCount).fill(null);

  return (
    <div className="flex flex-col bg-gray-50 lg:px-6 lg:gap-6 pl-4 py-7 gap-4">
      {/* Header skeleton */}
      <div className="border-b border-gray-200 pb-2">
        <div className="h-8 w-64 bg-gray-200 rounded animate-pulse"></div>
      </div>

      {/* Products and CTA Wrapper */}
      <div className="flex lg:flex-row flex-col gap-7">
        {/* Products Container */}
        <div className="flex w-full overflow-x-auto">
          <div className="flex">
            {productSkeletons.map((_, index) => (
              <React.Fragment key={index}>
                {/* Product Skeleton */}
                <ProductSkeleton />

                {/* Plus Sign (except after the last product) */}
                {index < productCount - 1 && (
                  <span className="mt-[84px] mx-[14px] text-gray-200">
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.1535 26.6673V16.0007M16.1535 16.0007V5.33398M16.1535 16.0007H26.8202M16.1535 16.0007H5.48682"
                        stroke="currentColor"
                        strokeWidth="2.66667"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Total Price and CTA Skeleton */}
        <div className="flex flex-col gap-4 lg:items-start items-center justify-center">
          <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-12 w-56 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

// Product Skeleton Component
const ProductSkeleton = () => {
  return (
    <div className="max-w-[256px] h-full flex flex-col w-full">
      {/* Product Image Skeleton */}
      <div className="px-4 h-[208px] flex justify-center items-center">
        <div className="w-36 h-36 bg-gray-200 rounded animate-pulse"></div>
      </div>

      {/* Product Details Skeleton */}
      <div className="flex gap-4">
        {/* Checkbox Skeleton */}
        <div className="relative">
          <div className="w-6 h-6 bg-gray-200 rounded animate-pulse"></div>
        </div>

        {/* Product Info Skeleton */}
        <div className="flex flex-col gap-2 w-full">
          {/* Title Skeleton */}
          <div className="h-5 w-full bg-gray-200 rounded animate-pulse"></div>
          <div className="h-5 w-3/4 bg-gray-200 rounded animate-pulse"></div>

          {/* Price Skeleton */}
          <div className="h-6 w-16 mt-2 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyBoughtTogetherSkeleton;
