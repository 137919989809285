import { WidgetDataType, useRecommendation, widget } from '@sitecore-search/react';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect } from 'react';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useRouter } from 'next/router';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { ProductLineItemCard } from './ProductLineItemCard';
import { ProductSearchResultModelWithVariants } from '../search/SearchResults/types';
import { useProductVariants } from '../search/SearchResults/use-product-variants';
import { ProductOrMarketingTileResults } from '../search/SearchResults';
import { recommendationProductVariant } from 'tailwindVariants/components/productRecommendationTailwindVariant';
import { setProductEntity } from 'lib/utils/search/setStoreEntity';
import ProductTileSkeleton from './ProductTileSkeleton';
import { useBreakpoints } from 'src/utils/breakpoints';
import clsx from 'clsx';

//component variant

//types
interface FeaturedProductProps {
  title: Field<string> | undefined;
  pageLimit: string | undefined;
  brandName?: string;
  minRecord: number;
  fieldNames?: string;
}

//main component
const RecommendedProduct: React.FC<FeaturedProductProps> = ({
  title,
  pageLimit,
  minRecord,
  fieldNames,
}): JSX.Element => {
  //destructuring the variants
  const { container, base, heading, carouselWrapper } = recommendationProductVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const { isMobile } = useBreakpoints();
  const router = useRouter();
  setProductEntity({ isDefaultContext: true });
  //calling an useRecommendation for getting the discoverData
  const {
    widgetRef,
    actions,
    queryResult: { isFetching, isLoading, data: { content: products = [], total_item = 0 } = {} },
  } = useRecommendation({
    state: {
      itemsPerPage: Number(pageLimit),
    },
  });

  const productsWithVariants = useProductVariants(products);
  const productsData: ProductOrMarketingTileResults = [...productsWithVariants];

  //sending a product data on GTM, on change of product data
  useEffect(() => {
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) =>
            item.ecommerce?.items &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item.ecommerce.items.some((i: any) => i.item_list_id === GTMLabels?.recommendedProducts)
        )?.length > 0;

      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (productsData && productsData?.length > 0 && !isEventFired && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewItemList,
          data: productsData as ProductSearchResultModelWithVariants[],
          currentPage: GTMLabels?.recommendedProducts,
          pageItem: GTMLabels?.recommendedProducts,
          affiliation: myStoreData?.storeId,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [productsData]);

  if (!isLoading && !isFetching && products && products?.length < Number(minRecord)) return <></>;
  if (!isLoading && !isFetching) {
    if (total_item < Number(minRecord || total_item === 0)) {
      return <></>;
    }
  }

  return (
    <div
      ref={widgetRef}
      className={clsx(container(), fieldNames)}
      data-component={'helpers/search/ProductRecommendation'}
    >
      <div className={base()}>
        {!isLoading &&
        !isFetching &&
        productsData &&
        productsData?.length > 0 &&
        router?.isReady ? (
          <div className={carouselWrapper()}>
            {title && <RichTextHelper field={title} className={heading()} />}

            <SplideSlider
              onMounted={(splide) => {
                splide.refresh();
              }}
              options={{
                arrows: products?.length > 5 ? true : false,
                perPage: 5,
                gap: '24px',
                perMove: 1,
                focus: isMobile ? 'center' : undefined,
                pagination: false,
                classes: {
                  prev: 'splide__arrow--prev slider-prev',
                  next: 'splide__arrow--next slider-next',
                },
              }}
            >
              {(productsData as ProductSearchResultModelWithVariants[])?.map((product, index) => {
                return (
                  <ProductLineItemCard
                    product={product}
                    index={index}
                    actions={actions}
                    key={index}
                    title={title?.value}
                  />
                );
              })}
            </SplideSlider>
          </div>
        ) : (
          <ProductTileSkeleton />
        )}
      </div>
    </div>
  );
};

//creating widget
const RecommendedProductWidget = widget(
  RecommendedProduct,
  WidgetDataType.RECOMMENDATION,
  'product'
);

export default RecommendedProductWidget;
