import { tv } from 'tailwind-variants';

export const newNavigationTailwindVariant = tv(
  {
    slots: {
      // *common
      justifyBetween: ['justify-between '],
      justifyCenter: [' justify-center '],
      justifyNormal: ['!justify-normal'],
      itemsEnd: ['!items-end'],
      itemsBaseLine: ['!items-baseline'],
      itemStrech: ['items-strech'],
      itemStart: ['!items-start'],
      flexEnd: ['justify-end'],
      border0: ['!border-0'],
      bodyRegular: [''],
      relative: [''],
      flexColWmax: ['flex-col w-max'],
      wFit: ['w-fit'],
      wFull: ['w-full'],
      w30: ['max-w-[30px] max-h-[30px]'],
      textBlack: ['!text-color-text-black'],
      gap2: ['gap-2'],
      hover: ['hover:text-color-brand-primary-1-base'],
      cursorNone: ['hover:cursor-default'],
      borderBottom: ['border-b-[1px] border-color-scale-5-mid'],
      // *base
      base: ['font-primary'],
      deskBase: ['lg:overflow-visible relative font-primary'],
      // *mobile primary nav
      mobRightWrapper: ['lg:!px-0 ', 'lg:py-3', 'lg:justify-self-end'],
      mobLeftWrapper: ['lg:hidden'],
      mobDivWrapper: [''],
      subMenuWrapper: [''],
      mobileWrapper:
        'mobileNavigation overflow-hidden lg:hidden block relative z-[21] leading-5 font-body-large-bold text-body-xLarge-regular mt-[0px] bg-color-background-brand-white bg-color-background-white capitalize text-xxs  w-full transition-all duration-700 ease-in-out ',
      mobileMenuWrapper: [
        'flex flex-col gap-mob-global-spacing-spacing-4 h-full overflow-y-auto pb-[170px]',
      ],
      mobileMenuTitle: [
        'w-full text-center py-mob-global-spacing-spacing-3 border-b-[1px] border-color-border-mid',
      ],
      mobileNavOpen: [
        'absolute lg:hidden translate-x-full overscroll-contain backface-hidden overflow-hidden overflow-y-auto pb-[170px] transition-all duration-300 ease-in w-full  left-0 top-0 bg-color-background-white',
      ],
      mobCloseFont: ['text-color-text-brand-1 flex items-center'],
      pr10: ['pr-[10px]'],
      h250: ['h-[250px]'],
      mobIcon: ['text-color-scale-1-white text-2xl'],
      miniCartIcon: ['minicart-icon'],
      closeIcon: ['cursor-pointer [&>svg>*>*]:fill-color-scale-8-dark'],
      fillBlack: ['[&>svg>*>*]:fill-color-scale-8-dark [&>svg>*]:fill-color-scale-8-dark'],
      fillBase: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base [&>svg>*]:fill-color-brand-primary-1-base',
      ],
      levelOneCarrot: [
        '[&>svg>*>*]:fill-color-background-white [&>svg>*]:fill-color-background-white cursor-pointer',
      ],
      iconFit: ['[&>svg]:h-fit'],
      iconSmall: ['w-6 flex'],
      iconXSmall: ['w-4 flex'],
      // * dsktop primary nav
      desktopWrapper:
        'flex px-desk-global-spacing-spacing-3  lg:px-[52px] py-mob-global-spacing-spacing-3 lg:py-0 leading-5 bg-color-brand-primary-1-base justify-between lg:justify-center flex-wrap  flex-nowrap mobile-headerlogo-wrapper',
      deskNavItem: ['hidden lg:flex lg:col-span-5 lg:col-start-1'],
      deskSubNavWrapper: ['flex flex-col gap-desk-margin-tight-bottom'],
      deskSubNavHeading: [
        'w-full flex flex-col gap-desk-margin-tight-bottom pb-desk-margin-base-bottom px-desk-global-spacing-spacing-5 py-desk-global-spacing-spacing-4 lg:min-w-0 xl:min-w-[320px]',
      ],

      normalFont: ['text-body-small-regular font-body-small-regular leading-body-small-regular'],
      navigationOpen: [
        'deskNavOpen z-20  mt-[34px] w-auto translate-x-[-40%] bg-color-scale-1-white shadow-lg top-[24px] lg:max-h-[630px] overflow-hidden',
      ],
      navigationOpenBg: [
        'hidden lg:block navOpenBg absolute top-full left-0 h-[calc(100vh+930px)] w-full bg-[#000] z-[10] opacity-[0.15]',
      ],
      shopNavigation: [
        'shopNavigation z-20  mt-[34px] w-auto left-0 bg-color-scale-1-white shadow-lg top-[24px] lg:max-h-[630px] overflow-hidden',
      ],
      brandNavigation: [
        'z-20  mt-[34px] w-[calc(100%-104px)] left-1/2 transform -translate-x-1/2 bg-color-scale-1-white shadow-lg top-[24px] lg:max-h-[630px] overflow-hidden',
      ],
      liWrapper: [
        'w-full lg:w-fit lg:px-desk-padding-micro-x font-bold text-sm lg:py-desk-padding-micro-y items-center',
      ],
      navigationLink: [
        'hover:no-underline lg:text-color-text-white lg:hover:text-color-text-black lg:relative text-body-small-regular font-body-small-regular leading-body-small-regular',
      ],
      navigationClose: ['hidden'],
      navigationAfter: [''],
      linkWrapper: ['hover:no-underline'],
      ulWrapper: [
        'w-full py-0 lg:col-span-4 text-body-small-regular font-body-small-regular leading-body-small-regular',
      ],
      h4: [
        'text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold font-primary',
      ],
      fontMedium: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      flexGrid: ['items-center w-full justify-between lg:items-start'],
      flexCol: ['flex-col'],
      flexRow: [''],
      mobSubWrapper: [
        'items-start gap-mob-space-between-tight-horizontal !justify-normal py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4  border-b-[1px] border-color-border-mid',
      ],
      iconWrapper: ['lg:flex lg:justify-between lg:pb-[25px]'],
      searchwrapper: [
        'lg:py-desk-global-spacing-spacing-1 hidden lg:block px-desk-global-spacing-spacing-2 rounded-desk-global-radius-small placeholder:text-sm w-full',
      ],
      deskHidden: ['lg:hidden gap-mob-space-between-tight-horizontal'],
      leading39: ['leading-[39px]'],
      desktopHeading: ['text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold'],
      desktopIcon: ['!justify-normal gap-desk-space-between-base-vertical'],
      //*mobile utility
      mobUtilityNav: ['lg:hidden fixed w-full bottom-0 z-20'],
      mobNavigation: [
        'flex w-full items-center overscroll-contain justify-between py-desk-global-spacing-spacing-2 border-b-[1px] border-color-border-mid',
      ],
      mobNavTitle: [
        'block text-body-large-bold leading-body-large-bold font-body-large-bold w-full',
      ],
      mobNavLinkWrapper: ['w-full flex items-center gap-mob-global-spacing-spacing-1'],
      mobNavMarketingTile: [
        'py-mob-global-spacing-spacing-4',
        'px-[20px]',
        '[&>*>img]:w-full',
        '[&>*>*>img]:w-full',
      ],
      utilityLi: ['text-color-text-white cursor-pointer'],
      utilityOpen: [
        'px-mob-padding-micro-x shadow-menuCard py-mob-padding-tight-top flex-col -z-10 top bg-white w-full h-fit overflow-hidden  text-color-text-black left-0 transition-all duration-1000',
      ],
      utilityImageMaxWidth: ['max-w-10'],
      utilityItem: ['flex-col w-max cursor-pointer'],
      utilityLiOpen: ['py-3 border-b-[1px] border-color-border-mid'],
      utilityUl: [
        'bg-color-brand-primary-1-base py-mob-padding-micro-y px-mob-global-grid-margin bottom-utility-nav',
      ],
      utilityImage: ['utilityImage', 'left-1/2 -translate-x-1/2'],
      utilityLogo: ['max-w-[50%] lg:max-w-[55%] xl:max-w-[95%] 2xl:max-w-full'],
      utilityFont: [
        'text-[12px] xl:text-body-small-regular leading-body-small-bold font-body-xSmall-bold',
      ],
      utilitLinkText: ['cursor-normal text-body-small-regular !border-0'],
      itemGap: ['gap-desk-space-between-micro-vertical'],
      leftUtilityText: ['lg:text-body-small-regular !flex-row !self-end'],
      storeLocatorText: [''],
      bodySmallBold: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      //*desktop utility
      deskUtilityWrapper: [
        'deskUtilityWrapper hidden lg:flex py-desk-padding-micro-y lg:px-desk-padding-micro-x px-mob-padding-micro-x',
      ],
      itemWrapper: ['xl:px-[20px] xl:pt-[12px] px-[5px] flex-col items-center top-level-parent'],
      deskUtilityOpen: [
        'flex-col top-full rounded-desk-global-radius-medium shadow-menuCard mt-2 bg-white z-[999] min-w-[378px] px-desk-padding-micro-x py-desk-padding-tight-y',
      ],
      miniCartUtilityOpen: [
        'flex-col top-0 rounded-desk-global-radius-medium shadow-menuCard mt-2 bg-white z-[999] lg:min-w-[378px] w-full md:w-fit md:max-w-[378px] px-desk-padding-micro-x py-desk-padding-micro-y mini-cart-utility-open',
      ],
      img: ['w-auto'],
      utlityServiceHeding: ['text-body-large-bold font-body-large-bold !border-color-border-brand'],
      utlitiCloseIcon: ['top-4 right-4 w-6'],
      //*Store Locator
      storeLocator: [
        'block lg:hidden gap-mob-space-between-micro-horizontal p-mob-global-spacing-spacing-4 text-body-xSmall-regular',
      ],
      numberOfProduct: [
        'bg-color-brand-primary-1-base',
        'rounded-[200px]',
        'h-[16px]',
        'w-[20px]',
        'absolute',
        'right-0',
        'top-0',
        'flex',
        'justify-center',
        'items-center',
        'font-body-small-regular',
        'text-body-small-regular',
        'leading-body-small-regular',
        'text-white', // Token is not there in Figma, need to get replaced with token

        // 'px-6',
      ],
      numberOfMobProduct: [
        'bg-color-background-white',
        'rounded-[200px]',
        'h-[16px]',
        'w-[20px]',
        'absolute',
        'right-[3px]',
        'top-[8px]',
        'flex',
        'justify-center',
        'items-center',
        'font-body-small-regular',
        'text-body-small-regular',
        'leading-body-small-regular',
        'text-color-text-brand-1',
        // 'px-6',
      ],
      miniCartCloseBtn: ['absolute', 'right-0'],
      miniCartCloseBtnIcon: ['[&>svg>*>*]:fill-color-brand-primary-1-base', 'cursor-pointer'],
      uponSearchTrigger: [''],
      shopAllLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'hover:text-color-brand-tertiary-3-base',
        'underline',
        'ml-[5px]',
      ],

      // Nav-V2 slots
      threeColLayout: ['three-col-layout flex flex-row '],
      colLayoutOne: [
        'col-one-parent min-w-[200px] border-solid border-r-2 border-color-border-mid block py-desk-global-spacing-spacing-4',
      ],
      colLayoutOneParentItem: [
        'cursor-pointer',
        'font-body-large-bold',
        'text-body-large-bold',
        'leading-body-large-bold',
        'text-color-text-dark',
      ],
      colLayoutOneCategories: [
        'flex flex-row items-center cursor-pointer justify-between  px-desk-global-spacing-spacing-5 py-desk-global-spacing-spacing-2',
      ],
      colLayoutTwo: ['col-two-parent overflow-y-auto max-h-[630px]'],
      colLayoutTopWrapper: [
        'shopColumnWrapper flex flex-row px-desk-global-spacing-spacing-5 pr-desk-global-spacing-spacing-6 py-desk-global-spacing-spacing-4 ',
      ],
      colLayoutTwoCols: ['col-two-items xl:px-desk-global-spacing-spacing-4 min-w-[250px]'],
      colLayoutTwoChilds: ['pb-desk-global-spacing-spacing-4 '],
      colLayoutThree: ['col-three-parent min-w-[280px]'],
      colLinkWrapper: ['link-wrapper flex flex-col'],
      colLinkTitle: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold pb-desk-global-spacing-spacing-2',
      ],
      colLinkItems: [
        'colLinkItems',
        'font-normal',
        'text-body-large-regular',
        'leading-body-large-regular',
        'pb-desk-global-spacing-spacing-2',
      ],
      popularCategoriesWrapper: [
        'popular-categories-wrapper',
        'bg-color-scale-2-light',
        'p-mob-global-spacing-spacing-4',
        'pr-0',
        'lg:px-desk-global-spacing-spacing-6 ',
        'lg:py-desk-global-spacing-spacing-4',
      ],
      popularCategoriesTitleLinkWrapper: [
        'flex',
        'items-baseline',
        'gap-desk-global-spacing-spacing-1',
      ],
      categoryTitle: [
        'mb-desk-global-spacing-spacing-3',
        'pr-mob-global-spacing-spacing-4 ',
        'lg:pr-0',
      ],
      categoryShopAllLink: [
        'text-color-brand-primary-1-base',
        'text-body-small-bold-underline',
        'font-body-small-bold-underline',
        'leading-body-small-bold-underline',
        'hover:text-color-brand-tertiary-3-base',
        'underline',
        'ml-[5px]',
      ],
      popularCategoriesTiles: [
        'popularCategoriesTiles',
        'flex',
        'flex-row',
        'gap-[52px]',
        'overflow-x-scroll',
        'lg:overflow-x-auto',
      ],
      popularCategoriesTitleItem: ['popular-category-tile-item'],
      popularCategoriesTitleItemImgWrapper: [
        'popularCategoriesTitleItemImgWrapper',
        'mb-desk-global-spacing-spacing-1',
      ],
      popularCategoriesTitleItemImg: [
        'block',
        'w-full',
        'h-auto',
        'fit-cover',
        'rounded-desk-global-radius-small',
      ],
      popularCategoriesTitleItemText: [
        'text-body-xSmall-regular font-body-xSmall-regular leading-body-xSmall-regular',
      ],
      brandsWrapper: [''],
      brandsMainWrapper: ['brands-main-wrapper flex flex-col'],
      brandsTabs: ['brands-tabs flex flex-row gap-desk-global-spacing-spacing-4'],
      brandsTabsItem: [
        'cursor-pointer py-desk-padding-micro-y px-desk-padding-micro-x rounded-[4px] border border-2 border-color-border-brand my-desk-global-spacing-spacing-5',
      ],
      brandIconsWrapper: ['brand-icons-wrapper grid grid-cols-10 gap-4'],
      brandIconLink: ['no-underline'],
      brandIconLinkText: [
        'no-underline	 text-body-xSmall-regular font-body-xSmall-regular leading-body-xSmall-regular text-color-text-black mt-desk-global-spacing-spacing-1 font-normal',
      ],
      brandsTitle: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
      ],
      //**New header slots */
      menuTitle: [
        'block text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold pb-mob-global-spacing-spacing-2',
      ],
      bodyText: ['text-body-large-bold', 'font-body-large-bold', ' leading-body-large-bold'],
    },
    compoundSlots: [
      {
        slots: [
          'deskUtilityOpen',
          'miniCartUtilityOpen',
          'liWrapper',
          'ulWrapper',
          'flexGrid',
          'itemWrapper',
          'mobUtilityNav',
          'utilityOpen',
          'utlitiCloseIcon',
          'flexCol',
          'flexRow',
          'flexEnd',
          'itemsEnd',
        ],
        class: ['flex'],
      },
      {
        slots: ['justifyBetween', 'justifyCenter'],
        class: ['flex items-center w-full'],
      },
      {
        slots: ['utilityOpen', 'bodyRegular'],
        class: ['text-body-small-regular font-normal'],
      },
      {
        slots: ['leftUtilityText', 'storeLocatorText'],
        class: ['text-body-xSmall-regular'],
      },
      {
        slots: [
          'navigationOpen',
          'utilityOpen',
          'utilityOpen',
          'utilityImage',
          'deskUtilityOpen',
          'utlitiCloseIcon',
          'shopNavigation',
          'brandNavigation',
        ],
        class: ['absolute'],
      },

      {
        slots: ['desktopWrapper', 'relative'],
        class: ['relative'],
      },
      {
        slots: ['mobRightWrapper', 'mobLeftWrapper'],
        class: ['flex justify-between gap-mob-space-between-tight-horizontal items-center'],
      },
    ],
    variants: {
      isShown: {
        false: {
          mobileWrapper: ['translate-y-full'],
        },
        true: {
          mobileWrapper: [''],
        },
      },
      device: {
        mobile: {
          mobRightWrapper: ['flex-grow flex-shrink-0 basis-0 !w-full'],
          popularCategoriesTitleItemImgWrapper: ['h-[88px]', 'w-[88px]'],
          popularCategoriesTitleLinkWrapper: ['justify-between', 'pr-mob-global-spacing-spacing-4'],
          categoryTitle: [
            'text-heading-mob-small-bold',
            'font-heading-mob-small-bold',
            'leading-heading-mob-small-bold',
          ],
        },

        desktop: {
          popularCategoriesTitleItemImgWrapper: ['h-[88px]', 'w-[88px]'],
          // mobRightWrapper: ['flex-grow-0 flex-shrink basis-auto w-fit'],
          popularCategoriesTitleLinkWrapper: ['justify-normal', 'pr-0'],
          categoryTitle: [
            'text-heading-desk-small-bold',
            'font-heading-desk-small-bold',
            'leading-heading-desk-small-bold',
          ],
        },
      },
      turnSearchOn: {
        true: {
          uponSearchTrigger: ['!w-fit'],
        },
        false: {
          uponSearchTrigger: ['w-full'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
