import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { breadcrumbVariants } from 'tailwindVariants/components/breadcrumbTailwindVariant';
import clsx from 'clsx';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { decodeUrlQuery } from 'lib/utils/url-formatter';

interface BreadcrumbItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

interface SiblingItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

export interface BreadcrumbData {
  breadcrumb: {
    breadcrumbTitle?: { value: string };
    title: { value: string };
    url: { path: string };
    id: string;
    ancestors: BreadcrumbItem[];
  };
  siblings: {
    children: {
      results: SiblingItem[];
    };
  };
}

interface BreadCrumbWithSiblingsProps {
  gqldata: BreadcrumbData;
  siteName: string;
}

interface IconProps {
  sitename?: string;
  className?: string;
}

//Tailwind Variants Styles
const {
  breadcrumbText,
  breadcrumbContainer,
  ancestorsWrapper,
  siblingsWrapper,
  siblingsList,
  siblingItem,
  siblingLink,
  breadCrumbSiblingsMenu,
} = breadcrumbVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const DividerIcon = () => {
  return (
    <span className={''}>
      <IconHelper icon={'breadcrumb-divider'} />
    </span>
  );
};

const LinkIcon = ({ className }: IconProps): JSX.Element => {
  return (
    <span className={className}>
      <IconHelper icon={'breadcrumb-down'} className="[&>svg>path]:fill-color-text-brand-1" />
    </span>
  );
};

const buildBreadcrumb = (
  breadcrumbData: BreadcrumbItem[],
  currentItem: BreadcrumbItem
): BreadcrumbItem[] => {
  const pathsToExclude = ['/categories', '/'];
  const filteredAncestors =
    breadcrumbData?.filter((item) => !pathsToExclude.includes(item.url.path)).reverse() || '';

  return [...filteredAncestors, currentItem];
};

const getSiblings = (siblings: SiblingItem[], currentItemId: string): SiblingItem[] => {
  return siblings?.filter((sibling) => sibling.id !== currentItemId && sibling.breadcrumbTitle);
};

const BreadCrumbWithSiblings: React.FC<BreadCrumbWithSiblingsProps> = ({ gqldata, siteName }) => {
  const { breadcrumb, siblings } = gqldata;
  const breadcrumbItems = buildBreadcrumb(breadcrumb?.ancestors, breadcrumb);
  const filteredSiblings = getSiblings(siblings?.children?.results, breadcrumb?.id);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef<HTMLLIElement>(null);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  // Handle clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isVisible]);

  return (
    <div>
      {/* Breadcrumb Rendering */}
      <nav aria-label="breadcrumb" className={breadcrumbContainer()}>
        <ul className={ancestorsWrapper()}>
          {breadcrumbItems.map((item, index) => {
            const title = item?.breadcrumbTitle?.value || item?.title?.value;
            const isLastItem = index === breadcrumbItems.length - 1;
            const isSecondLastItem = index === breadcrumbItems.length - 2;

            return (
              <li
                key={item?.id}
                className={clsx(ancestorsWrapper(), 'relative', isLastItem ? 'flex' : 'hidden')}
                ref={isLastItem ? contentRef : null}
              >
                {isLastItem ? (
                  <>
                    <span
                      tabIndex={0}
                      className={clsx(breadcrumbText(), breadCrumbSiblingsMenu())}
                      onClick={(e) => {
                        e.preventDefault();
                        handleToggle();
                      }}
                      onKeyDown={(e) => e.key === 'Enter' && handleToggle()}
                      role={'button'}
                      aria-expanded={isVisible}
                    >
                      {title}
                      {filteredSiblings?.length > 0 && (
                        <LinkIcon sitename={siteName} className={clsx(isVisible && 'rotate-180')} />
                      )}
                    </span>
                    {/* Siblings Rendering */}
                    {filteredSiblings?.length > 0 && isVisible && (
                      <div className={siblingsWrapper()} id={'breadcrumbSiblings'}>
                        <ul className={siblingsList()}>
                          {filteredSiblings.map((sibling, _index) => (
                            <li
                              key={sibling?.id}
                              className={siblingItem()}
                              // id={'breadcrumbSiblings' + index}
                            >
                              <Link
                                tabIndex={0}
                                onKeyDown={(e) => e.stopPropagation()}
                                aria-label={
                                  sibling?.breadcrumbTitle?.value || sibling?.title?.value
                                }
                                href={decodeUrlQuery(sibling?.url?.path)}
                                className={clsx(siblingLink(), 'block hover:no-underline')}
                                onClick={() => setIsVisible(false)}
                              >
                                {sibling?.breadcrumbTitle?.value || sibling?.title?.value}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                ) : isSecondLastItem && breadcrumbItems.length === 3 ? (
                  <>
                    <Link
                      aria-label={title}
                      href={decodeUrlQuery(item?.url?.path)}
                      className={clsx(breadcrumbText())}
                    >
                      {title}
                    </Link>
                    <DividerIcon />
                  </>
                ) : (
                  <>
                    <span className={clsx(breadcrumbText())}>{title}</span>
                    {!isLastItem && <DividerIcon />}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default BreadCrumbWithSiblings;
