import React, { useEffect, useState } from 'react';
import {
  FacetLabels,
  getEncryptedProductPath,
  getProductPromoTag,
  productAvailability,
} from '../Constants';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcSelector } from 'src/redux/ocStore';
import TextHelper from '../commonComponents/TextHelper';
import { productVariantsTailwindVariant } from 'tailwindVariants/components/ProductDetails/productVariantsTailwindVariant';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ProductFacetData } from 'lib/page-props-factory/plugins/pdp-sku';
import useDictionary from 'src/hooks/useDictionary';
import { ProductData } from 'components/ProductDetail/ProductDetail';
import { ProductXp } from 'src/redux/xp';
import useOcCart from 'src/hooks/useOcCart';
import useOcCurrentOrder from 'src/hooks/useOcCurrentOrder';
import { productDetailTailwindVariant } from 'tailwindVariants/components/ProductDetails/productDetailTailwindVariant';
import { inventoryStateProps } from 'components/ProductDetailR2/ProductDetailR2';

const { base, title, promoTag, variantsWrapper, priceText, variantContainer, variantLabel } =
  productVariantsTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

type selectedFacetsType = { [key: string]: string };

interface ProductVariantsForPDPProps {
  productStockAvailability?: inventoryStateProps;
}

const ProductVariantsForPDP = (props: ProductVariantsForPDPProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const productData = sitecoreContext.productData;
  const siblingsProductData = sitecoreContext?.siblingsProductData as ProductData[];
  const productFacetData = sitecoreContext.productFacetData as ProductFacetData[];
  const [selectedFacets, setSelectedFacets] = useState<selectedFacetsType>({});
  const [isSiblingInventoryChecked, setIsSiblingInventoryChecked] = useState(false);
  const [_siblingProductInventory, setSiblingProductInventory] = useState<
    ({
      productInventory: number;
      availability: productAvailability;
      inventoryRecordId: string;
      productId: string;
    } | null)[]
  >([]);

  const { stockStatus } = productDetailTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

  interface siblingsAvailabilityType {
    ID?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Inventory?: any;
  }

  const router = useRouter();
  const { getDictionaryValue } = useDictionary();
  const { getProductAvailability } = useOcCart();

  const [allProductsData, setAllProductsData] = useState<ProductData[]>([]);

  useEffect(() => {
    if (typeof window !== 'undefined' && router?.isReady && productData && siblingsProductData) {
      setAllProductsData([productData, ...siblingsProductData]);
    }
  }, [productData, siblingsProductData, router?.isReady]);

  const generateFacetCombinations = (selectedFacets: selectedFacetsType) => {
    const facetKeys = Object.keys(selectedFacets);
    const combinations = [];

    // Generate combinations of all sizes (1 facet, 2 facets, ..., all facets)
    for (let i = 1; i <= facetKeys.length; i++) {
      const combinationsForThisSize = getCombinations(facetKeys, i).map((combination) => {
        const facetCombination: { [key: string]: string } = {};
        combination.forEach((facetKey: string) => {
          facetCombination[facetKey] = selectedFacets[facetKey];
        });
        return facetCombination;
      });
      combinations.push(...combinationsForThisSize);
    }

    return combinations;
  };

  function getCombinations<T>(array: T[], size: number): T[][] {
    const result: T[][] = [];

    const combine = (start: number, combo: T[]): void => {
      if (combo.length === size) {
        result.push(combo);
        return;
      }
      for (let i = start; i < array.length; i++) {
        combine(i + 1, combo.concat(array[i]));
      }
    };

    combine(0, []);
    return result;
  }

  // Function to check if product matches the given facets combination
  const productMatchesFacets = (product: ProductData, facets: selectedFacetsType) => {
    const matchingCount = Object.keys(facets).reduce((count, facetKey) => {
      if (product.Xp && facetKey in product.Xp) {
        const facetValue = (product.Xp as { [key: string]: string })[facetKey]?.toString();
        if (facetValue === facets[facetKey]) {
          return count + 1;
        }
      }
      return count;
    }, 0);
    return matchingCount >= 1; // At least 1 matching facet (adjust if necessary)
  };

  // Function to filter products based on combinations of facets
  const filterProductsByFacets = async (selectedFacets: selectedFacetsType) => {
    const facetCombinations = generateFacetCombinations(selectedFacets);
    const result: ProductData[] = [];
    facetCombinations.forEach((facetCombo) => {
      // Filter products that match at least one facet from the combination
      const matchingProducts = siblingsProductData.filter((product) => {
        return productMatchesFacets(product, facetCombo);
      });

      // If there are matching products, add them to the result
      result.push(...matchingProducts);
    });

    const uniqueProducts = [...new Map(result.map((p) => [p.ID, p])).values()];

    const inventoryPromises = uniqueProducts.map((product: ProductData) => {
      if (product?.ID) {
        return getProductAvailability(
          product?.ID,
          myStoreData?.storeId,
          myStoreData?.deliveryFee?.lowStock
        );
      }
      return null;
    });
    // Wait for all promises to resolve concurrently
    try {
      const inventoryResults = await Promise.all(inventoryPromises);
      return inventoryResults; // This will be an array of results for each product
    } catch (error) {
      console.error('Error fetching inventory:', error);
      return [];
    }
  };
  const getOtherProductInventory = async (selectedFacets: selectedFacetsType) => {
    if (!isSiblingInventoryChecked) {
      const filteredResults = await filterProductsByFacets(selectedFacets);
      setIsSiblingInventoryChecked(true);
      setSiblingProductInventory(filteredResults);
    }
  };

  useEffect(() => {
    const facets: selectedFacetsType = {};
    if (productFacetData) {
      productFacetData?.forEach((facet: ProductFacetData) => {
        if (facet?.name) {
          facets[facet?.name] = productData?.Xp?.[
            facet.name as keyof ProductXp
          ]?.toString() as string;
        }
      });
    }
    setSelectedFacets(facets);
    getOtherProductInventory(facets);
  }, [productData?.Xp?.UPC]);

  // Helper function to calculate price per pound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calculatePricePerPound = (product: any) => {
    if (!product?.Xp?.RetailMeasure) return null;

    // Extract weight value (e.g., "4 LB" → 4)
    const [weightValueStr, unit] = product?.Xp?.RetailMeasure?.split(' ');
    const weightValue = parseFloat(weightValueStr);
    if (!weightValue) return null;

    // Determine price based on available price fields
    if (product?.ProductPrice?.ViewPriceInCart) {
      return (
        getDictionaryValue('CurrencySymbol') +
        Number(product?.ProductPrice?.IMapPrice / weightValue).toFixed(2) +
        `/` +
        unit.toLowerCase()
      );
    } else if (product?.ProductPrice?.PPCDiscount) {
      return (
        getDictionaryValue('CurrencySymbol') +
        Number(product?.ProductPrice?.PPCPrice / weightValue).toFixed(2) +
        `/` +
        unit.toLowerCase()
      );
    } else {
      return (
        getDictionaryValue('CurrencySymbol') +
        Number(product?.ProductPrice?.ListPrice / weightValue).toFixed(2) +
        `/` +
        unit.toLowerCase()
      );
    }
  };

  // Logic for Per Pound React Component:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DivideForPound = (facetObj?: any) => {
    const retailUnitMatch = facetObj?.divideBy;
    const currentSKU = router?.query?.UPC || router?.query?.sku;

    if (!facetObj?.facetObj?.values || !Array.isArray(facetObj?.facetObj?.values)) return null;

    const allProducts = [productData, ...(siblingsProductData ?? [])];
    // Map for iterating through all
    const priceComponents = allProducts.map((product, index) => {
      // Check if the product's RetailMeasure is in the facet values
      const isMatchingRetailUnit = product?.Xp?.RetailUnit === retailUnitMatch;
      if (product?.Xp?.UPC !== currentSKU && isMatchingRetailUnit) {
        return (
          <div key={index} className={priceText()}>
            {calculatePricePerPound(product)}
          </div>
        );
      } else if (product?.Xp?.UPC === currentSKU && isMatchingRetailUnit) {
        return (
          <div key={index} className={priceText()}>
            {calculatePricePerPound(product)}
          </div>
        );
      }
      return null; // Skip rendering if conditions don't match
    });
    return <>{priceComponents}</>;
  };

  // Fetch IDs from the passed array
  const fetchProductIDs = async (allProductsDataObj: ProductData[]) => {
    const productsInventoryMap: string[] = [];

    allProductsDataObj?.forEach((prodData: ProductData) => {
      if (prodData?.ID) {
        productsInventoryMap.push(prodData.ID);
      }
    });

    return productsInventoryMap;
  };

  // Fetch product Inventory of the passed productIDs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchProductsInventory = async (productData: any) => {
    const productsInventoryObj: Array<siblingsAvailabilityType> = [];

    for (const ID of productData || []) {
      const productInventoryStatus = await getProductAvailability(
        ID,
        myStoreData?.storeId,
        myStoreData?.deliveryFee?.lowStock
      );

      // Push the result into the array
      productsInventoryObj.push({
        ID,
        Inventory: productInventoryStatus, // Array of inventory status.
      });
    }
    return productsInventoryObj;
  };

  const [productAvailabilityArray, setProductAvailabilityArray] =
    useState<Array<siblingsAvailabilityType>>();

  const { lineItems } = useOcCurrentOrder();

  useEffect(() => {
    const fetchData = async () => {
      if (!router?.isReady && !router?.query?.sku) return;
      // Extract all products into one array
      if (sitecoreContext?.siblingsProductData) {
        const productIdsList = await fetchProductIDs(allProductsData);
        const getProductStockAvailability = await fetchProductsInventory(productIdsList);
        setProductAvailabilityArray(getProductStockAvailability);
      }
    };
    fetchData(); // Call the async function inside useEffect
  }, [
    sitecoreContext?.siblingsProductData,
    router?.isReady,
    router?.query?.sku,
    allProductsData,
    lineItems,
  ]);

  return (
    <div className={base()}>
      {productData?.Xp?.PromoTag &&
        getProductPromoTag(
          productData?.Xp?.PromoTag,
          myStoreData?.dataareaid,
          myStoreData?.priceGroupArray
        ) && (
          <div>
            {/* //TODO: Need Dictionary for Deals text */}
            <TextHelper field={{ value: 'Deals:' }} tag="p" className={title()} />
            <p className={promoTag()}>
              {productData?.Xp?.PromoTag &&
                getProductPromoTag(
                  productData?.Xp?.PromoTag,
                  myStoreData?.dataareaid,
                  myStoreData?.priceGroupArray
                )}
            </p>
          </div>
        )}

      {/* PSP-4800: Inventory Status */}
      {props?.productStockAvailability?.availability && (
        <div
          className={stockStatus({
            availability: props?.productStockAvailability?.availability,
          })}
        >
          {getDictionaryValue(props?.productStockAvailability?.availability)}
        </div>
      )}

      {/* PDP Variants */}
      {productFacetData?.map((productFacetDataObj, index) => {
        return (
          <div key={index}>
            <TextHelper
              tag="p"
              field={{
                value: getDictionaryValue(productFacetDataObj?.name),
              }}
              className={title()}
            />
            <div className={variantsWrapper()}>
              {productFacetDataObj?.values?.map((value, index) => {
                if (!value.trim()) return null;

                const optionProductData = allProductsData?.find((product) => {
                  return Object.entries(selectedFacets).every(([key, keyValue]) => {
                    if (key === productFacetDataObj?.name) {
                      return (
                        product &&
                        product.Xp?.[key as keyof ProductXp]?.toString()?.toLowerCase() ===
                          value?.toLowerCase()
                      );
                    }
                    return (
                      product &&
                      product.Xp?.[key as keyof ProductXp]?.toString()?.toLowerCase() ===
                        keyValue?.toString()?.toLowerCase()
                    );
                  });
                });
                // const isOutOfStock =
                //   siblingProductInventory.find(
                //     (p) => optionProductData?.ID && p?.productId === optionProductData?.ID
                //   )?.availability === productAvailability.OUT;

                // Checks every all products of parent id:
                const isOutOfStock2 = Boolean(
                  productAvailabilityArray?.some(
                    (p) =>
                      optionProductData?.ID &&
                      p?.ID === optionProductData?.ID &&
                      p?.Inventory?.availability === productAvailability.OUT
                  )
                );

                const productPath = optionProductData
                  ? `/categories/${getEncryptedProductPath(
                      optionProductData?.Xp?.Path
                    )}/${optionProductData?.Xp?.ParentFamilyName?.replace(/[\W_]+/g, '-')}/${
                      productData?.ParentID
                    }?sku=${optionProductData?.Xp?.UPC}`?.toLowerCase()
                  : '';
                return (
                  <Link
                    href={productPath}
                    key={index}
                    onClick={(e) => {
                      if (!productPath) {
                        e.preventDefault();
                      }
                    }}
                    className={variantContainer({
                      isSelected:
                        value?.toLowerCase() ===
                        selectedFacets?.[productFacetDataObj?.name]?.toLowerCase(),
                      isOutOfStock: isOutOfStock2,
                      isSelectedOOS:
                        isOutOfStock2 &&
                        value?.toLowerCase() ===
                          selectedFacets?.[productFacetDataObj?.name]?.toLowerCase(),
                    })}
                  >
                    <div
                      className={variantLabel({
                        isOutOfStock: isOutOfStock2,
                      })}
                    >
                      {value}
                    </div>
                    {/* New logic to accomodate price per pound. */}
                    {productFacetDataObj?.name === FacetLabels?.RetailMeasure && (
                      <DivideForPound
                        facetObj={productFacetDataObj}
                        divideBy={productFacetDataObj?.values?.[index].split(' ')[0]}
                      />
                    )}
                  </Link>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ProductVariantsForPDP;
