import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import styles from './styles/MyRewardsPointsProgressBar.module.css';
import { pSPCrowdTwistLoyaltyProgressBarTailwindVariant } from 'tailwindVariants/components/pSPCrowdTwistLoyaltyProgressBarTailwindVariant';

type MyRewardsProgressBarProps = {
  userPoints: number;
  pointRedemptionLabel: Field<string>;
  redeemNowLink: LinkField;
  redeemNowText: Field<string>;
};

const {
  myRewardsBar,
  myRewardsProgressBar,
  redemptionText,
  redeemPoints,
  progressMeter,
  redeemLinkUrl,
  progressValue,
  progressIndicator,
  progressPoints,
  pointsItem,
  completed,
} = pSPCrowdTwistLoyaltyProgressBarTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

export default function LoyaltyPointsProgressBar({
  userPoints,
  pointRedemptionLabel,
  redeemNowLink,
  redeemNowText,
}: MyRewardsProgressBarProps) {
  const points = userPoints || 0;
  const valuePointIncrements = 250;
  const milestones = 4;

  const progressPercent = (points / 1000) * 100;

  const pointsleft = 1000 - points;
  const redeemPointsText = pointRedemptionLabel?.value
    ? pointRedemptionLabel?.value.replace('{0}', pointsleft.toString())
    : 'Redeem now.';

  const isLoyalotyRedemptionTextShow = points < 1000 ? false : true;
  console.log(
    'points',
    points,
    progressPercent,
    pointRedemptionLabel,
    redeemNowLink,
    redeemNowText
  );
  return (
    <div className={myRewardsBar()}>
      <div className={myRewardsProgressBar()}>
        <div className={redemptionText()}>
          {!isLoyalotyRedemptionTextShow ? (
            <p className={redeemPoints()}>{redeemPointsText}</p>
          ) : (
            <p className={redeemPoints()}>
              {redeemNowText?.value}
              <a
                aria-label="redeem now"
                tabIndex={0}
                className={redeemLinkUrl()}
                href={redeemNowLink?.value?.anchor ? `#${redeemNowLink.value.anchor}` : '#'}
              >
                {redeemNowLink?.value?.text}
              </a>
            </p>
          )}
        </div>

        <div className={progressMeter()}>
          <div className={progressValue()} style={{ width: `${progressPercent}%` }}>
            {Array.from({ length: milestones }, (_, index) => {
              const indicatorPercent = (index + 1) * 25;
              return (
                <div
                  key={index}
                  className={`${progressIndicator()} ${styles.myRewardsProgressIndicator} ${
                    progressPercent >= indicatorPercent ? completed() : ''
                  }`}
                ></div>
              );
            })}
          </div>
        </div>
        <div className={progressPoints()}>
          {Array.from({ length: milestones }, (_, index) => {
            const pointValue = (index + 1) * valuePointIncrements;
            return (
              <div key={index} className={pointsItem()}>{`${pointValue.toLocaleString()} pts`}</div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
