import { useSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import ProductDetailAddToCart from 'src/helpers/NewProductDetailComponents/ProductDetailAddToCart';
import ProductDetailDeliveryMethod from 'src/helpers/NewProductDetailComponents/ProductDetailDeliveryMethod';
import ProductDetailSchema from 'src/helpers/NewProductDetailComponents/ProductDetailSchema';
import ProductDetailSEOMetaData from 'src/helpers/NewProductDetailComponents/ProductDetailSEOMetaData';
import ProductDetailsForPDP from 'src/helpers/NewProductDetailComponents/ProductDetailsForPDP';
import ProductImageCarousel from 'src/helpers/NewProductDetailComponents/ProductImageCarousel';
import ProductVariantsForPDP from 'src/helpers/NewProductDetailComponents/ProductVariantsForPDP';
import { useBreakpoints } from 'src/utils/breakpoints';
import { newProductDetailStructureTailwindVariant } from 'tailwindVariants/components/ProductDetails/newProductDetailStructureTailwindVariant';
import { PdpDatasourceData } from 'components/ProductDetail/ProductDetail';
import ProductDetailInfoSection from 'tailwindVariants/components/ProductDetails/productDetailInfoSection';
import { setProductEntity } from 'lib/utils/search/setStoreEntity';
import { trackEntityPageViewEvent } from '@sitecore-search/react';
import { useOcSelector } from 'src/redux/ocStore';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT, GTMLabels, productAvailability } from 'src/helpers/Constants';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import clsx from 'clsx';
import useOcCurrentOrder from 'src/hooks/useOcCurrentOrder';
import useOcCart from 'src/hooks/useOcCart';

export interface inventoryStateProps {
  productInventory: number;
  availability: productAvailability;
  inventoryRecordId: string;
  remainingInventory?: number;
}

import { ComponentProps } from 'lib/component-props';

const {
  base,
  productDetailWrapper,
  productDetailContainer,
  productDetailCol3ForDesktop,
  productDetailCol3ForSmallDesktop,
} = newProductDetailStructureTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

type ProductDetailR2Props = ComponentProps & {
  fields: PdpDatasourceData;
};
/**
 * Renders the ProductDetailR2 component, which displays detailed information about a product.
 * Utilizes responsive breakpoints to conditionally render components based on screen size.
 * Includes SEO metadata, product schema, product details, an image carousel, variants, delivery methods, and add-to-cart functionality.
 * Returns an empty fragment if product data is not available or if there's an error.
 */
const ProductDetailR2 = ({ fields, rendering }: ProductDetailR2Props) => {
  const { isMobile, isSmallDesktop } = useBreakpoints();
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  const parentId = productData?.ParentID;
  const productSku = productData?.Xp?.UPC;
  const [isBrowser, setIsBrowser] = React.useState(false);
  const { pageItem } = getGTMSessionStorage();
  const router = useRouter();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (typeof parentId === 'string' && typeof productSku === 'string') {
      const getData = async () => {
        try {
          //Triggering PDP tracking event for Search
          if (productData?.ID) {
            trackEntityPageViewEvent('product', {
              items: [
                {
                  id: productData?.ID,
                  attributes: {
                    brand: productData?.Xp?.Brand ?? '',
                  },
                },
              ],
            });
          }
        } catch (err) {
          console.error('@@ Error: ', err);
        }
      };
      if (parentId && productSku && myStoreData?.storeId && productData?.ID) {
        getData();
      }
    }
  }, [myStoreData?.storeId, productData?.ID]);

  useEffect(() => {
    productData &&
      setProductEntity({
        productEntity: { product: [productData?.ID as string] },
      });
  }, [productData]);

  // Logic to update the 'sku' parameter in the URL
  useEffect(() => {
    if (!router.isReady || !router.query.sku || !productData?.Xp?.UPC) return;

    const currentSku = router?.query?.sku;
    const newSku = productData?.Xp?.UPC;

    if (currentSku === newSku) return; // Prevents unnecessary route updates

    router.replace(
      {
        pathname: router.pathname, // Keep the same path
        query: { ...router.query, sku: newSku }, // Only update the SKU
      },
      undefined,
      { shallow: true } // Use shallow routing to avoid a page reload
    );
  }, [router.isReady, productData?.Xp?.UPC]);

  const pathArray = router?.asPath?.split('/');
  const productCategory = pathArray?.[4];

  // Sending GTM promotion data
  useEffect(() => {
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItem)
          ?.length > 0;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any)?.google_tag_manager?.dataLayer?.gtmLoad && !isEventFired) {
        productData &&
          sendProductsPromotion({
            eventName: GTM_EVENT?.viewItem,
            data: productData as ProductSearchResultModelWithVariants,
            currentPage: GTMLabels.PDP,
            pageItem: pageItem,
            parentId: parentId,
            category: productCategory,
            affiliation: myStoreData?.storeId,
            isRatingShow: true,
          });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };

    checkGtmLoad();
  }, [productData]);

  useEffect(() => {
    // productData?.error is for exception cases if found
    // parentId is dependent on productData and if there is no parentId, than no product data found means store association missing as no response will come if true.
    if (isBrowser && router?.isReady && router?.query?.sku) {
      if ((!parentId && !productData?.ID) || (parentId && productData?.error)) {
        console.error('no parentID or product data error:', parentId, productData?.error);
        router.push('/404');
      }
    }
  }, [
    myStoreData?.storeId,
    isBrowser,
    parentId,
    productData?.error,
    router?.isReady,
    router?.query?.sku,
  ]);

  // PSP-4787: Keeping Inventory logic at parent component
  const { lineItems } = useOcCurrentOrder();
  const { getProductAvailability } = useOcCart();
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  // Fetch the inventory logic here to trim the number of calls to inventory
  const [productStockAvailability, setProductStockAvailability] = useState<inventoryStateProps>();

  const getProductStockAvailability = async () => {
    if (productData?.ID) {
      const productAvailabilityData = await getProductAvailability(
        productData?.ID,
        myStoreData?.storeId,
        myStoreData?.deliveryFee?.lowStock
      );
      if (productAvailabilityData) {
        setProductStockAvailability(productAvailabilityData);
      }
    }
  };

  useEffect(() => {
    if (!myStoreData?.storeId) return;
    getProductStockAvailability();
  }, [lineItems, currentOrder?.initialized, productData?.ID, productSku, myStoreData?.storeId]);

  return (
    <>
      <div className={base()}>
        <ProductDetailSEOMetaData />
        <ProductDetailSchema />
        <div className={productDetailWrapper()}>
          <div className={productDetailContainer()}>
            {/* For Mobile devices */}
            {isMobile && <ProductDetailsForPDP />}
            <ProductImageCarousel />

            <div>
              {!isMobile && <ProductDetailsForPDP />}

              <ProductVariantsForPDP productStockAvailability={productStockAvailability} />

              <div
                className={clsx({
                  'pt-mob-global-spacing-spacing-7 pb-mob-global-spacing-spacing-4': isMobile,
                  [productDetailCol3ForSmallDesktop()]: isSmallDesktop,
                })}
              >
                {(isMobile || isSmallDesktop) && <ProductDetailDeliveryMethod fields={fields} />}
              </div>
              {isSmallDesktop && (
                <ProductDetailAddToCart productStockAvailability={productStockAvailability} />
              )}
            </div>

            {/* Larger Desktops: */}
            {!isMobile && !isSmallDesktop && (
              <div className={productDetailCol3ForDesktop()}>
                <ProductDetailDeliveryMethod fields={fields} />
                <ProductDetailAddToCart productStockAvailability={productStockAvailability} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile && <ProductDetailAddToCart />}

      {/* Frequently Bought Together */}
      {rendering && <Placeholder name="pdp-frequently-bought-together" rendering={rendering} />}

      {/* About This Item component */}
      <ProductDetailInfoSection fields={fields} />
    </>
  );
};
export default ProductDetailR2;
