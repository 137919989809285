import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { PSP } from 'models/PetSuppliesPlus.Model';
import React from 'react';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import RecommendedProductWidget from 'src/helpers/ProductRecommendation';
import { RerenderOnRouteChange } from 'src/helpers/utility/RerenderOnRouteChange';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

export type ProductRecommendationProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.ProductRecommendation.Fields.ProductRecommendationData;
const ProductRecommendation: React.FC<ProductRecommendationProps> = ({ fields, params }) => {
  const isEE = useExperienceEditor();
  if (isEE) {
    return (
      <div className="w-full flex flex-col gap-4 m-8">
        <div>
          {' '}
          Heading: <RichTextHelper field={fields?.heading} />
        </div>
        <div>
          Items to Show: <TextHelper field={fields?.pageSize} />
        </div>
        <div>
          Min Record: <Text field={fields?.minRecords} />
        </div>
        <div>
          RFKID: <TextHelper field={fields?.rfkID} />
        </div>
      </div>
    );
  }

  //if no data then returned empty fragment
  // if (fields === undefined || fields === null) return <></>;
  // if (!fields?.rfkID?.value && (fields?.rfkID?.value?.length as number) <= 0) return <></>;
  // if (!fields?.showComponent?.value) return <></>;

  return (
    <RerenderOnRouteChange>
      <RecommendedProductWidget
        rfkId={(fields?.rfkID?.value as string) || 'rfk_RecHomePage2'}
        title={{ value: 'Recommended Products' }}
        pageLimit={fields?.pageSize?.value}
        minRecord={Number(fields?.minRecords?.value) || 0}
        fieldNames={params?.FieldNames}
      />
    </RerenderOnRouteChange>
  );
};

export const Default = (props: ProductRecommendationProps): JSX.Element => {
  return <ProductRecommendation {...props} />;
};

export const FullWidthPages = (props: ProductRecommendationProps): JSX.Element => {
  return <ProductRecommendation {...props} />;
};

export const TwoColumnPages = (props: ProductRecommendationProps): JSX.Element => {
  return <ProductRecommendation {...props} />;
};
