import { tv } from 'tailwind-variants';

export const earnedOffersTailwindVariant = tv(
  {
    slots: {
      base: ['flex', 'flex-col', 'gap-desk-padding-micro-bottom'],
      earnedOffersIntro: ['flex', 'flex-col', 'gap-desk-padding-micro-bottom'],
      couponInfoHeading: [
        'row',
        'border-y',
        'border-color-scale-3-light-mid',
        'border-1',
        'py-desk-padding-micro-y',
        'mb-3',
      ],
      couponInfoHeadingRow: ['flex'],
      couponInfoTableColumn1Heading: ['w-1/4', 'font-bold'],
      couponInfoTableColumn2Row: ['flex', 'w-3/4', 'gap-1'],
      couponInfoTableColumn2Heading: ['w-1/6', 'font-bold', 'min-w-[105px]'],
      couponInfoTableColumn3Heading: ['w-1/6', 'font-bold', 'min-w-[105px]'],
      couponInfoTableColumn4Heading: ['w-3/6', 'font-bold'],
      couponInfoTableColumn5Heading: ['w-1/6', 'font-bold', 'min-w-[120px]'],
      couponProductItem: [''],
      couponProductItemProgramName: [''],
      couponProductItemProgramDetails: [''],
      couponProductItemProgramDetailsColumn1: [''],
      couponProductItemProgramDetailsColumn2: [''],
      couponProductItemProgramDetailsColumn3: [''],
      couponProductItemProgramDetailsColumn4: [''],
      productShortDescription: [
        'text-heading-mob-small-regular',
        'font-heading-mob-small-regular',
        'leading-heading-mob-small-regular',
      ],
      loadMoreButtonWrapper: ['flex', 'justify-center'],
      loadMoreButton: [
        'flex',
        'w-[188px]',
        'justify-center',
        '!py-desk-component-button-condensed-padding-y',
        'mt-desk-global-spacing-spacing-5',
      ],
      modalProgramName: [
        'text-heading-mob-small-bold',
        'font-heading-mob-small-bold',
        'leading-heading-mob-small-bold',
      ],
      modalProgramDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      productNoExpiryDateText: [
        'text-color-text-dark',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold py-3',
      ],
      termsAndConditionLabelStyle: [
        'font-bold',
        'text-color-scale-5-mid',
        'mb-desk-margin-base-bottom',
      ],
      termsAndConditionTextStyle: ['text-color-scale-5-mid'],
      rewardProgramName: [
        'reward-program-name',
        'text-color-background-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'mb-3',
        'pr-2',
      ],
      loadMoreButtonLoadingMore: ['flex items-center gap-4 mt-4'],
    },
    variants: {
      device: {
        mobile: {
          couponProductItem: [
            'block',
            'border-y',
            'border-color-scale-3-light-mid',
            'border-1',
            'py-desk-padding-micro-y',
            'mb-3',
          ],
          couponProductItemProgramName: ['w-full'],
          couponProductItemProgramDetails: ['block'],
        },
        desktop: {
          couponProductItem: ['flex', 'border-0', 'py-0', 'mb-0'],
          couponProductItemProgramName: ['w-1/4'],
          couponProductItemProgramDetails: ['flex', 'w-3/4'],
          couponProductItemProgramDetailsColumn1: ['w-1/6', 'min-w-[105px]'],
          couponProductItemProgramDetailsColumn2: ['w-1/6', 'min-w-[105px]'],
          couponProductItemProgramDetailsColumn3: ['w-3/6', 'min-w-[105px]'],
          couponProductItemProgramDetailsColumn4: ['w-1/6 coupon-status min-w-[120px]'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
