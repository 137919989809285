import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { newStoreDetailTabTailwindVariant } from 'tailwindVariants/components/newStoreDetailTabTailwindVariant';
import { renderImage, renderVideo } from './NewStoreDetailTab';
import { PdpDatasourceData } from 'components/ProductDetail/ProductDetail';
const {
  flex,
  gap,
  listUl,
  listLi,
  leftSideContentWrapper,
  rightSideContentWrapper,
  hidden,
  productDetailTitle,
  productDetailDescription,
} = newStoreDetailTabTailwindVariant({
  size: { initial: 'mobile', lg: 'desktop' },
});
const ProductDetailsTab = ({
  currentTab,
  fields,
}: {
  currentTab: string;
  fields: PdpDatasourceData;
}) => {
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  const { getDictionaryValue } = useDictionary();
  const [isImage, _setIsImage] = useState(false);
  const [isVideo, _setIsVideo] = useState(false);

  useEffect(() => {
    if (productData?.MPCMProduct?.MKTVideo) {
      _setIsVideo(true);
    } else if (productData?.MPCMProduct?.MKTImage) {
      _setIsImage(true);
    } else {
      _setIsImage(false);
      _setIsVideo(false);
    }
  }, [productData?.MPCMProduct?.MKTImage, productData?.MPCMProduct?.MKTVideo]);

  const renderLeftSideContent = () => (
    // <div className={'flex flex-col desk-global-spacing-spacing-6'}>
    <div className={`flex flex-col ${gap()}`}>
      <p className={productDetailTitle()}>
        {/* TODO: create a dictionary for this - should be CMS Driven */}
        {fields?.detailsTabInfo?.value}
      </p>
      {productData?.MPCMProduct?.ProductDetailsSummary && (
        <p className={productDetailDescription()}>
          {productData?.MPCMProduct?.ProductDetailsSummary}
        </p>
      )}
      <ul className={listUl()}>
        {productData?.MPCMProduct?.ProductDetailsBullets?.filter(Boolean)?.map(
          (detail: string, index: number) => (
            <li key={index} className={listLi()}>
              {detail}
            </li>
          )
        )}
      </ul>
    </div>
  );
  return (
    <div className={currentTab === getDictionaryValue('TabDeailsPDP') ? flex() : hidden()}>
      <div className={`${isImage || isVideo ? leftSideContentWrapper() : ''}`}>
        {renderLeftSideContent()}
      </div>
      {isVideo ? (
        <div className={rightSideContentWrapper()}>
          {renderVideo(productData?.MPCMProduct?.MKTVideo)}
        </div>
      ) : isImage ? (
        <div className={rightSideContentWrapper()}>
          {renderImage(productData?.MPCMProduct?.MKTImage)}
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsTab;
