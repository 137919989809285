import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { ComponentProps } from 'lib/component-props';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import { PSP } from 'models/PetSuppliesPlus.Model';
import React from 'react';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { useOcSelector } from 'src/redux/ocStore';
import { newNavigationTailwindVariant } from 'tailwindVariants/components/NewnavigationTailwindVariant';

type PopularCategoryProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.PopularCategory.Fields.PopularCategoryFolder &
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.PopularCategory.Fields.PopularCategoryItem &
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.PopularCategory.Fields.PopularCategoryTile;

const PopularCategory = (props: PopularCategoryProps) => {
  const {
    popularCategoriesWrapper,
    categoryTitle,
    popularCategoriesTitleItem,
    popularCategoriesTiles,
    popularCategoriesTitleItemImgWrapper,
    popularCategoriesTitleItemImg,
    popularCategoriesTitleLinkWrapper,
    categoryShopAllLink,
  } = newNavigationTailwindVariant({ device: { initial: 'mobile', lg: 'desktop' } });

  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const categoriesLength = props?.fields?.tileItems?.length || 0;
  if (categoriesLength < 1) return <></>;

  return (
    <div className={popularCategoriesWrapper()}>
      {/**Title and shop all link */}
      <div className={popularCategoriesTitleLinkWrapper()}>
        <RichTextHelper
          updatedField={{
            value:
              props?.fields?.title?.value?.replace('${store-name}', storeData?.storeName ?? '') ||
              '',
          }}
          field={props?.fields?.title}
          className={clsx(categoryTitle())}
        />
        {props?.fields?.link?.value?.href && (
          <LinkHelper
            field={props?.fields?.link}
            className={categoryShopAllLink()}
            onClick={() => {
              setHeaderContextData({ ...headerContextData, isMegaMenuOpen: false });
            }}
          />
        )}
      </div>
      {/** Image tiles */}
      <div className={popularCategoriesTiles()}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
        {props?.fields?.tileItems?.map((tileItem: any, index: number) => {
          return (
            <div
              className={popularCategoriesTitleItem({
                className:
                  index === categoriesLength - 1 && 'mr-mob-global-spacing-spacing-4 lg:mr-0', //for mobile there will be margin for last item
              })}
              key={index}
            >
              <LinkHelper
                key={index}
                field={tileItem?.fields?.link}
                onClick={() => {
                  setHeaderContextData({ ...headerContextData, isMegaMenuOpen: false });
                }}
              >
                <div className={popularCategoriesTitleItemImgWrapper()}>
                  <NextImage
                    field={{
                      value: {
                        src: tileItem?.fields?.tileImage?.value.src,
                        alt: tileItem?.fields?.tileImage?.value.alt,
                        width: '100',
                        height: '100',
                      },
                    }}
                    className={popularCategoriesTitleItemImg()}
                  />
                </div>
              </LinkHelper>
              <p>{tileItem?.fields?.link?.value?.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularCategory;
