import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Options } from '@splidejs/splide';
import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { ProductXpImage } from 'src/redux/xp';
import IconHelper from '../commonComponents/IconHelper';
import { getSortedProductImages } from '../productDetailHelper';
import { useOcSelector } from 'src/redux/ocStore';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { getGTMSessionStorage } from 'src/utils/sendGTMEvent';
import { GTMLabels } from '../Constants';
import { ProductData } from 'components/ProductDetail/ProductDetail';
import urlConfig from 'src/utils/urlConfig';
import { useRouter } from 'next/router';
import { productDetailImageCarouselTailwindVariant } from 'tailwindVariants/components/ProductDetails/productDetailImageCarouselTailwindVariant';
import ImageHelper from '../commonComponents/ImageHelper';

const {
  base,
  imageCarouselWrapper,
  splideSlide,
  mainSlideWrapper,
  tagWrapper,
  usImage,
  heartIcon,
  mainVideoPlayer,
  thumbnailSlideWrapper,
  thumbnailImage,
  thumbnailWrapper,
  thumbnailContainer,
  thumbnailVideo,
  thumbnailVideoWrapper,
  prevPDPArrow,
  nextPDPArrow,
} = productDetailImageCarouselTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

const mainSlideOptions: Options = {
  type: 'loop',
  perPage: 1,
  perMove: 1,
  gap: '1rem',
  pagination: false,
  classes: {
    prev: prevPDPArrow(),
    next: nextPDPArrow(),
  },
};

const thumbnailSlideOptions: Options = {
  type: 'slide',
  gap: '1rem',
  perPage: 4,
  perMove: 1,
  pagination: false,
  fixedWidth: 80,
  fixedHeight: 80,
  isNavigation: true,
  classes: {
    prev: '!hidden',
    next: '!hidden',
  },
};
type ProductImageCarouselProps = {
  imageData: ProductXpImage[];
  videoUrl?: string;
  Position?: number;
  thumbsRef?: React.Ref<Splide>;
  mainRef?: React.Ref<Splide>;
  isUSMade?: boolean;
  productData?: ProductData | null;
};

/**
 * Renders the main slides in the product image carousel
 * @param {Array<{ Url: string; Name: string }>} imageData - an array of objects with Url and Name properties
 * @returns {React.ReactElement[]} - an array of SplideSlide React elements
 */
const renderSlides = (imageData: Array<{ Url: string; Name: string }>) =>
  imageData.map((slide: { Url: string; Name: string }, index: number) => (
    <SplideSlide className={splideSlide()} key={index}>
      <img className={mainSlideWrapper()} src={slide.Url} alt={slide.Name} />
    </SplideSlide>
  ));

/**
 * Renders the main slide of the product image carousel, including product images and videos.
 * Displays a US Made badge and sale tag if applicable, and allows users to add products to their wishlist.
 *
 * @param {Object} props - The component properties.
 * @param {React.Ref<Splide>} props.mainRef - Reference to the main Splide slider.
 * @param {Array<ProductXpImage>} props.imageData - Array of image data to display in the carousel.
 * @param {string} [props.videoUrl] - URL of the video to display in the carousel.
 * @param {boolean} [props.isUSMade] - Flag indicating if the product is US made.
 * @param {ProductData | null} [props.productData] - The product data object.
 *
 * @returns {React.ReactElement} - The rendered main slide component.
 */
const RenderMainSlide = ({
  mainRef,
  imageData,
  videoUrl,
  isUSMade,
  productData,
}: ProductImageCarouselProps) => {
  const wishlistData = useOcSelector((s) => s.wishlist?.wishlist);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { currentPage, pageItem, position } = getGTMSessionStorage();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const router = useRouter();

  const onSaleImage = useOcSelector((state) => state?.otherSettingSlice?.data?.onSaleImage);

  const onFavoriteClick = () => {
    if (productData) {
      if (isAnonymous) {
        router.push(
          `${urlConfig?.loginWithReturnUrl}${router.asPath}&favoritesku=${productData?.ID}`
        );
      } else {
        dispatch(
          updateProductInWishlist({
            product: productData,
            index: Number(position),
            storeId: myStoreData?.storeId,
            clickFrom: GTMLabels?.PDP,
            currentPage: currentPage,
            pageItem: pageItem,
          })
        );
      }
    }
  };

  return (
    <div className="relative">
      <div className={tagWrapper()}>
        {isUSMade && <img src="/images/badge-flag-1.png" alt="US Made" className={usImage()} />}
        <ImageHelper field={onSaleImage?.jsonValue} className="w-[50px]" />
      </div>

      <IconHelper
        tabIndex={0}
        onClick={onFavoriteClick}
        className={heartIcon()}
        icon={
          !!(wishlistData && productData?.ID && isProductInWishlist(wishlistData, productData?.ID))
            ? 'heart'
            : 'heart-stroke'
        }
      />
      <Splide
        options={
          imageData.length > 1
            ? mainSlideOptions
            : {
                ...mainSlideOptions,
                classes: {
                  prev: '!hidden',
                  next: '!hidden',
                },
              }
        }
        ref={mainRef}
        aria-labelledby="thumbnail-slider-example"
      >
        {renderSlides(imageData)}
        {videoUrl && (
          <SplideSlide className={splideSlide()}>
            <div className={mainVideoPlayer()}>
              <ReactPlayer width="100%" height="100%" url={videoUrl} controls />
            </div>
          </SplideSlide>
        )}
      </Splide>
    </div>
  );
};
/**
 * Renders the thumbnail slides in the product image carousel
 * @param {Array<{ Url: string; Name: string }>} imageData - an array of objects with Url and Name properties
 * @returns {React.ReactElement[]} - an array of SplideSlide React elements
 */
const renderThumbnailSlides = (imageData: Array<{ Url: string; Name: string }>) =>
  imageData.map((slide: { Url: string; Name: string }, index: number) => (
    <SplideSlide className={thumbnailSlideWrapper()} key={index}>
      <img className={thumbnailImage()} src={slide.Url} alt={slide.Name} />
    </SplideSlide>
  ));

/**
 * Extracts the YouTube video ID from a given YouTube video URL.
 *
 * @param {string} videoUrl - The URL of the YouTube video.
 * @returns {string | undefined} - The extracted video ID if matched, otherwise undefined.
 */
const getYoutubeVideoId = (videoUrl: string) => {
  const match = videoUrl?.match(
    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&\n?#]+)/
  );
  return match && match[1];
};

/**
 * Renders the thumbnail carousel based on the given product images and video URL.
 * If a video URL is given, it adds a thumbnail slide with a video preview image from YouTube.
 *
 * @param {React.RefObject<Splide>} thumbsRef - The reference to the Splide instance.
 * @param {ProductXpImage[]} imageData - The array of product images to render as thumbnail slides.
 * @param {string | undefined} videoUrl - The URL of the YouTube video to render as a thumbnail slide.
 * @returns {JSX.Element} The rendered thumbnail carousel.
 */
const RenderThumbnail = ({ thumbsRef, imageData, videoUrl }: ProductImageCarouselProps) => {
  return (
    <div className={thumbnailWrapper()}>
      <div className={thumbnailContainer()}>
        <Splide
          options={thumbnailSlideOptions}
          ref={thumbsRef}
          className={`${imageData?.length > 3 && '!pl-[6px] lg:!pl-0'}`}
          aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
        >
          {renderThumbnailSlides(imageData)}
          {videoUrl && (
            <SplideSlide className={thumbnailSlideWrapper()}>
              <div className={thumbnailVideoWrapper()}>
                <img
                  src={`https://img.youtube.com/vi/${getYoutubeVideoId(videoUrl)}/1.jpg`}
                  className={thumbnailVideo()}
                />
              </div>
            </SplideSlide>
          )}
        </Splide>
      </div>
    </div>
  );
};

/**
 * Renders the main and thumbnail product image carousels on the product detail page.
 *
 * The main carousel renders the product images and video, while the thumbnail carousel
 * renders the images and video as thumbnail slides.
 *
 * The main carousel is synchronized with the thumbnail carousel, so that when a thumbnail
 * is selected, the corresponding image or video is displayed in the main carousel.
 */
const ProductImageCarousel: React.FC = () => {
  const mainRef = useRef<Splide>(null);
  const thumbsRef = useRef<Splide>(null);
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  const imageData: ProductXpImage[] = getSortedProductImages(productData?.Xp?.Images);
  useEffect(() => {
    if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide);
    }
  }, []);
  return (
    <div className={base()}>
      <div className={imageCarouselWrapper()}>
        <RenderMainSlide
          mainRef={mainRef}
          imageData={
            imageData?.length && imageData?.length > 0
              ? imageData
              : ([
                  {
                    Url: dummyProductImage?.jsonValue?.value?.src,
                    Name: dummyProductImage?.jsonValue?.value?.alt,
                  },
                ] as ProductXpImage[])
          }
          videoUrl={productData?.Xp?.Video}
          isUSMade={productData?.Xp?.USMade}
          productData={productData}
        />

        <RenderThumbnail
          thumbsRef={thumbsRef}
          imageData={
            imageData?.length && imageData?.length > 0
              ? imageData
              : ([
                  {
                    Url: dummyProductImage?.jsonValue?.value?.src,
                    Name: dummyProductImage?.jsonValue?.value?.alt,
                  },
                ] as ProductXpImage[])
          }
          videoUrl={productData?.Xp?.Video}
        />
      </div>
    </div>
  );
};

export default ProductImageCarousel;
