import { tv } from 'tailwind-variants';

export const pSPCrowdTwistLoyaltyProgressBarTailwindVariant = tv(
  {
    slots: {
      myRewardsBar: ['myRewardsBar', 'flex', 'flex-end', 'flex-1', 'justify-end'],
      myRewardsProgressBar: ['flex-1'],
      redemptionText: ['loyalty-redemption-text'],
      redeemPoints: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-center',
      ],
      redeemLinkUrl: ['leading-body-large-bold', 'text-body-large-bold', 'underline'],
      progressAmount: ['flex', 'justify-between', 'items-center', 'py-[10px]', 'pl-[10%]'],
      amount: [
        'inline-block',
        'w-[20%]',
        'pl-0',
        'pr-0',
        'cursor-pointer',
        'border-l',
        'border-white',
        'mr-0',
        'uppercase',
        'list-none',
        'text-center',
      ],
      progressMeter: [
        'bg-white',
        'justify-start',
        'border-2',
        'border-color-background-brand-1',
        'rounded-full items-center relative',
        'flex',
        'h-10',
        'w-full',
        'overflow-hidden',
      ],
      progressValue: [
        'animate-load',
        'shadow-lg',
        'bg-color-background-brand-1',
        'h-10',
        'w-0',
        'rounded-[100px]',
      ],
      progressIndicator: [
        'z-1',
        'h-10',
        'w-0.5',
        'cursor-pointer',
        'bg-color-background-brand-1',
        'absolute',
      ],
      progressPoints: ['flex justify-between', 'items-center', 'py-[10px]', 'pl-[10%]'],
      pointsItem: ['points'],
      completed: ['bg-white'],
    },
    variants: {
      size: {
        mobile: {
          myRewardsProgressBar: ['w-full', 'max-w-full'],
          pointsItem: ['w-1/5', 'text-center'],
        },
        desktop: {
          myRewardsProgressBar: ['w-full', 'max-w-[453px]'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
