import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import TextHelper from '../commonComponents/TextHelper';
import BazarVoiceReviews from 'components/BazarVoice/BazarVoiceReviews';
import { productDetailTailwindVariant } from 'tailwindVariants/components/ProductDetails/productDetailTailwindVariant';
import { useOcSelector } from 'src/redux/ocStore';
import { decodeUrlQuery } from 'lib/utils/url-formatter';

/**
 * Renders the product details section for the product detail page.
 * The section contains the product name, brand, and availability.
 * The product name and brand are rendered as links that navigate to the product detail page.
 * The availability is rendered as a text displaying 'In Stock', 'Low Stock', or 'Out of Stock'.
 * The section also renders the BazaarVoice reviews for the product if the product has a valid UPC.
 * @returns {JSX.Element} A JSX element representing the product details section.
 */
const ProductDetailsForPDP = () => {
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;

  const { base, productName, brandSection, brandName, bazaarVoiceWrapper } =
    productDetailTailwindVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

  const brandsListStringArray = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.brandsList?.value
  );

  const [brandList, setBrandList] = useState<string[]>(['']);

  useEffect(() => {
    if (brandsListStringArray) {
      const refinedBrandsList = brandsListStringArray.split(',').map((brand) =>
        brand
          .trim()
          .toLowerCase()
          .replace(/[\W_]+/g, '-')
      );
      setBrandList(refinedBrandsList);
    }
  }, [brandsListStringArray]);

  return (
    <>
      <div className={base()}>
        <div>
          <p className={productName()}>{productData?.Name}</p>
          <div className={brandSection()}>
            <TextHelper
              tag="p"
              //TODO: Krushna
              field={{ value: 'by' }}
            />
            <Link
              className={brandName()}
              href={
                decodeUrlQuery(
                  productData?.Xp?.Brand &&
                    brandList &&
                    brandList?.includes(
                      productData?.Xp?.Brand?.toLowerCase()?.replace(/[\W_]+/g, '-')
                    )
                    ? `brands/${productData?.Xp?.Brand?.toLowerCase()?.replace(/[\W_]+/g, '-')}`
                    : `/search?q=${productData?.Xp?.Brand?.toLowerCase()?.replace(/[\W_]+/g, '+')}`
                )
                //  : `/search#f-xp_brand=${productData?.Xp?.Brand?.replace(/[\W_]+/g, '+')}`
              }
            >
              {productData?.Xp?.Brand}
            </Link>
          </div>
          {productData?.Xp?.UPC && (
            <div className={bazaarVoiceWrapper()}>
              <BazarVoiceReviews bvProductId={productData?.Xp?.UPC} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetailsForPDP;
