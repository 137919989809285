import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { ProductSearchResultModelWithVariants } from './types';
import useOcCart from 'src/hooks/useOcCart';
import useDictionary from 'src/hooks/useDictionary';
import { ProductPrice } from 'src/helpers/search/SearchResults/products/ProductPrice';
import { ProductLink } from './products/ProductLink';
import { RecommendationWidget, SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import { ProductAutoship } from './products/ProductAutoship';
import { ProductInventoryMessage } from './products/ProductInventoryMessage';
import { ProductMoreOptions } from './products/ProductMoreOptions';
import { ProductMadeInUsa } from './products/ProductMadeInUsa';
import clsx from 'clsx';

import searchResultsTailwind from './SearchResultsTailwind';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import BVPLPRating from 'components/BazarVoice/BVPLPRating';
import { useContext, useEffect, useState } from 'react';
import ComponentContext from 'lib/context/ComponentContext';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import {
  ErrorCodes,
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'src/helpers/Constants';
import { useProductInventory } from './use-product-inventory';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import { trackAddEvent } from '@sitecore-search/react';
import { myFavoriteVariants } from 'tailwindVariants/components/myFavoriteTailwindVariant';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';
import { useRouter } from 'next/router';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
// import { v5 as uuidv5 } from 'uuid';
import { BuyerProductWithXp } from 'src/redux/xp';

export interface PspProductRootProps {
  actions: SearchResultsWidget['ActionProps'] | RecommendationWidget['ActionProps'];
  product: ProductSearchResultModelWithVariants;
  index: number;
  currentPage?: string;
  styles?: string;
  className?: string;
  linkClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event?: any) => void;
}

export const PspProductRoot = ({ product, index, actions, onClick }: PspProductRootProps) => {
  const {
    sitecoreProductRoot,
    cardWrapper,
    productInfoWrapper,
    sitecoreProductName,
    submitBtn,
    productBottomWrapper,
    productImage,
    productTopWrapper,
    sitecoreImageWrapper,
    productStockPriceInfo,
    sitecoreImage,
  } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { addToFavoritesWrapper, addToFavoriteIcon } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  // Get the OC product so we can get promo tags from OC instead of Search.  TODO look into doing this more generally for all data.
  const ocProduct = product.variants?.find((x) => x.ID === product.id);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useOcDispatch();
  const { addToCart } = useOcCart();
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const image = getFirstProductImage(ocProduct?.xp?.Images || product?.xp?.Images);
  const productPriceForPLP = getProductPriceFromSearch(product);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const wishlistData = useOcSelector((s) => s.wishlist);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [isPickUpSelected, setIsPickUpSelected] = useState<boolean>();
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { push } = useRouter();
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const { offerLabel } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const onSaleImage = useOcSelector((state) => state?.otherSettingSlice?.data?.onSaleImage);

  const inventoryStatus = useProductInventory(product);

  // getting current page from GTM local Storage
  const { currentPage, pageItem } = getGTMSessionStorage();

  // function to call upon addToFavourite button:
  const addToFavoriteClick = (
    product: BuyerProductWithXp | ProductSearchResultModelWithVariants,
    index: number
  ) => {
    const productId =
      (product as BuyerProductWithXp)?.ID || (product as ProductSearchResultModelWithVariants)?.id;
    if (productId) {
      if (isAnonymous) {
        setcomponentContextData({
          ...componentContextData,
          ToggleLoginOverlayForm: true,
          productToAddInWishlistAfterLogin: product,
          productIdToAddInWishlistAfterLogin: productId,
          clickFrom: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
        });
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: index,
            storeId: myStoreData?.storeId,
            clickFrom: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
            currentPage: currentPage,
            pageItem: pageItem,
            wishlistData: wishlistData,
          })
        );
      }
    }
  };
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
    setIsPickUpSelected(newMethod === FulfillmentType.DFS);
  }, [fulfillmentMethod, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  const ecommerceDisabled = myStoreData?.ecommerceDisabled;

  const addToCartDisabled = ecommerceDisabled === 1 || inventoryStatus === 'OutOfStock';

  // Use PromoTag from OC.  In case it's not found, fall back to current logic of using from Search.
  const promoTagData = ocProduct?.xp?.PromoTag ?? product?.xp?.PromoTag;
  const onSale = ocProduct?.xp?.OnSale ?? product?.xp?.OnSale;

  const promoTag =
    promoTagData &&
    getProductPromoTag(promoTagData, myStoreData?.dataareaid, myStoreData.priceGroupArray);
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;

  // Commenting below code in case we want to go ahead with UUID approch

  // const MY_NAMESPACE = '6ba7b810-9dad-11d1-80b4-00c04fd430c8'; // Temporary UUID for Add to cart ID
  // function generateUUIDv5(name: string): string {
  //   return uuidv5(name, MY_NAMESPACE);
  // }

  return (
    <div key={product.id} className={clsx(cardWrapper())}>
      {showOfferTooltip == (ocProduct?.ID || product.id) && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[85%] !left-1/2 -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div
        className={clsx(sitecoreProductRoot({ className: '!border-0 !p-0' }))}
        onClick={() => {
          push(
            getProductDetailPath({
              ...(ocProduct ?? product),
              ParentID: ocProduct?.ParentID ?? product?.parentid,
            })
          );
          window && window?.localStorage?.setItem(GTMLabels?.position, index?.toString());
          sendProductsPromotion({
            eventName: GTM_EVENT?.selectItem,
            data: ocProduct || (product as ProductSearchResultModelWithVariants),
            currentPage: currentPage,
            pageItem: pageItem,
            position: index,
            isRatingShow: true,
          });
        }}
      >
        <div className={clsx(productTopWrapper({ className: 'relative !cursor-normal' }))}>
          <ProductMadeInUsa product={ocProduct || product} />
          {/* On Sale Tag: */}
          {onSale && (
            <ImageHelper
              field={onSaleImage?.jsonValue}
              className="absolute top-[150%] !w-[50px] !z-[2]"
            />
          )}
          <span className="w-full cursor-pointer"></span>
          {/* <span>[Add to Favorite Icon]</span> */}
          <button
            aria-label="add to favorites"
            className={addToFavoritesWrapper()}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event?.stopPropagation();
              addToFavoriteClick(ocProduct || product, index);
            }}
          >
            <IconHelper
              className={addToFavoriteIcon({
                className: '[&>svg]:!h-[16px]',
              })}
              icon={
                !isAnonymous &&
                wishlistData?.wishlist &&
                isProductInWishlist(wishlistData?.wishlist, product.id || '')
                  ? 'heart'
                  : 'heart-stroke'
              }
            />
          </button>
        </div>
        {/* <div className={styles['sitecore-image-wrapper']}> */}
        <ProductLink {...{ actions, index, product: ocProduct || product }}>
          <div className={sitecoreImage({})}>
            <div className={sitecoreImageWrapper()} onClick={onClick}>
              <ImageHelper
                className={productImage()}
                field={{
                  value: {
                    src: image?.Url || dummyProductImage?.jsonValue?.value?.src,
                    alt: product?.name || dummyProductImage?.jsonValue?.value?.alt,
                    width: 140,
                    height: 140,
                  },
                }}
              />
            </div>
            {promoTag && (
              <div
                className={offerLabel()}
                onMouseEnter={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(ocProduct?.ID || product?.id);
                  }
                }}
                onMouseLeave={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(false);
                  }
                }}
              >
                {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
              </div>
            )}
          </div>
        </ProductLink>

        {(ocProduct?.xp?.RetailUnit || product?.xp?.RetailUnit) && (
          <div className="product-weight-container relative">
            <p className="absolute pr-3 pl-1 bottom-[100px] right-[0px] text-left bg-color-brand-primary-1-base text-color-text-white product-weight-clip text-body-small-bold font-body-text-bold leading-body-small-bold">
              {ocProduct?.xp?.RetailUnit ?? product?.xp?.RetailUnit}{' '}
              {ocProduct?.xp?.RetailMeasure ?? product?.xp?.RetailMeasure}
            </p>
          </div>
        )}
        {/* Product Tile Info */}
        <ProductLink
          {...{ actions, index, product: ocProduct || product }}
          styles={sitecoreProductName()}
        >
          <h2
            // className={sitecoreProductName()}
            onClick={(event: React.MouseEvent<HTMLHeadingElement>) => {
              event?.stopPropagation();
              window && window?.localStorage?.setItem(GTMLabels?.position, index?.toString());
              sendProductsPromotion({
                eventName: GTM_EVENT?.selectItem,
                data: product as ProductSearchResultModelWithVariants,
                currentPage: currentPage,
                pageItem: pageItem,
                position: index,
                isRatingShow: true,
              });
            }}
          >
            {ocProduct?.Name ?? product.name}
          </h2>
        </ProductLink>
      </div>
      <div className={productInfoWrapper()}>
        {/* Product Tile Middle  */}
        <div className={productStockPriceInfo()}>
          <div className={'flex h-[80px] items-center flex-col justify-between w-full'}>
            <ProductInventoryMessage product={product} />
            <ProductMoreOptions {...{ actions, product, index }} />
          </div>
          <ProductPrice productPrice={productPriceForPLP} />
        </div>
        {/* Product Tile Bottom */}
        <div className={productBottomWrapper()}>
          <ProductAutoship product={ocProduct ?? product} />
          <div>
            <button
              aria-label="add to cart"
              disabled={(addToCartDisabled as boolean) || isAddingToCart}
              onClick={async () => {
                setIsAddingToCart(true);
                const response = await addToCart({
                  productId: ocProduct?.ID ?? (product.id as string),
                  quantity: 1,
                  ID: `${ocProduct?.ID ?? product.id}`, // To prevent racing condition
                });
                if (
                  (response && typeof response === 'object' && 'error' in response) ||
                  (response &&
                    response?.payload?.errorCode &&
                    response?.payload?.errorCode == ErrorCodes.InventoryInsufficient)
                ) {
                  setcomponentContextData({
                    ...componentContextData,
                    openMiniCart: true,
                    showMiniCartLoader: false,
                    outOfStockProductIds: [product.id as string],
                  });
                } else {
                  setHeaderContextData({
                    ...headerContextData,
                    openMiniCart: true,
                    showMiniCartLoader: false,
                    outOfStockProductIds: [''],
                  });
                }
                setIsAddingToCart(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sendProductsPromotion({
                  eventName: GTM_EVENT?.addToCart,
                  data: product as ProductSearchResultModelWithVariants,
                  isRatingShow: true,
                  currentPage: currentPage,
                  pageItem: pageItem,
                  position: index,
                  click_from: currentPage === 'PLP Page' ? GTMLabels?.PLP : currentPage,
                  storeId: myStoreData?.storeId,
                  fulfillment_option: isPickUpSelected ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                });

                //Sending Add Cart event to Search
                trackAddEvent('product', 'cart', {
                  items: [
                    {
                      entityType: 'product',
                      id: ocProduct?.ID ?? (product.id as string),
                      quantity: 1,
                      price:
                        product?.priceschedule?.xp_listprice &&
                        product?.priceschedule?.xp_listprice,
                      finalPrice:
                        product?.priceschedule?.xp_listprice &&
                        product?.priceschedule?.xp_listprice * 1,
                    },
                  ],
                  actionSubtype: 'conversion',
                });
              }}
              className={clsx(
                submitBtn({ outlineCta: false }),
                'font-heading-desk-medium-bold',
                'text-heading-desk-medium-bold',
                'leading-heading-desk-medium-bold'
              )}
            >
              {!isAddingToCart
                ? productPriceForPLP?.hasImapPrice || productPriceForPLP?.showViewPriceInCart
                  ? getDictionaryValue('ViewPriceInCartPDP')
                  : getDictionaryValue('AddToCartCTAPDP')
                : getDictionaryValue('AddingItemToCart')}
            </button>
          </div>
          {/* Ratings to be placed here */}
          <BVPLPRating
            bvProductId={ocProduct?.xp?.UPC || product?.xp?.UPC}
            bvRedirectUrl={getProductDetailPath({ ...product, ParentID: product?.parentid })}
          />
        </div>
      </div>
    </div>
  );
};
