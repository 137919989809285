/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Text,
  NextImage,
  Placeholder,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
//local
import { newNavigationTailwindVariant } from 'tailwindVariants/components/NewnavigationTailwindVariant';
import { UtilityNavTargetItem } from 'lib/types/header';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ComponentContext from 'lib/context/ComponentContext';
import useOcCart from 'src/hooks/useOcCart';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import useDictionary from 'src/hooks/useDictionary';
import { handleLogout } from 'src/helpers/authHandler';
import UserGroupProfileImage, {
  ProfileImagesType,
} from 'components/AccountManagement/UserGroupProfileImage/UserGroupProfileImage';
import { Tokens } from 'ordercloud-javascript-sdk';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { useIsSoftLoginEnabled } from 'src/hooks/useIsSoftLoginEnabled';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

interface utilityProps {
  liref?: React.LegacyRef<HTMLLIElement | undefined> | undefined;
  item: UtilityNavTargetItem;
  direction?: string;
  isUtilitiOpen: string;
  rendering?: ComponentRendering;
  setisutilitiOpen: (val: string) => void;
  profileImages?: Array<ProfileImagesType>;
  navIndex: number;
}

export default function UtilityNav({
  item,
  direction,
  isUtilitiOpen,
  setisutilitiOpen,
  liref,
  rendering,
  profileImages,
  navIndex,
}: utilityProps): JSX.Element {
  //TailwindVariants
  const {
    itemWrapper,
    justifyBetween,
    linkWrapper,
    utilityLiOpen,
    deskUtilityOpen,
    miniCartUtilityOpen,
    closeIcon,
    utilityFont,
    utlitiCloseIcon,
    utlityServiceHeding,
    fillBase,
    utilitLinkText,
    itemGap,
    relative,
    flexEnd,
    itemStrech,
    utilityItem,
    flexColWmax,
    numberOfProduct,
    miniCartCloseBtn,
    miniCartCloseBtnIcon,
  } = newNavigationTailwindVariant();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const [miniCartOpen, setminiCartOpen] = useState(false);
  const { numberOfItemsInCart, numberOfItemsInCartSoftLogin } = useOcCart();
  const [isSticky, setIsSticky] = useState(false);
  const [minicartToTop, setMinicartToTop] = useState(false);
  const dispatch = useOcDispatch();
  const router = useRouter();
  const [cookies, removeCookie] = useCookies(['ct-auth']);
  const ctAuthCookie = cookies['ct-auth'];
  const { getDictionaryValue } = useDictionary();
  const isSoftLoginEnabled = useIsSoftLoginEnabled();
  const ocCurrentOrder = useOcSelector((s) => s.ocCurrentOrder);
  const localUserToken = Tokens.GetAccessToken();

  // Minicart position fixes
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('header');
      const headerBottom = header?.getBoundingClientRect().bottom;

      if (headerBottom !== undefined && headerBottom <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [localUserToken]);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      setMinicartToTop(windowHeight >= 540 && windowHeight <= 770);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setHeaderContextData({ ...headerContextData, openMiniCart: false });
  }, []);

  useEffect(() => {
    if (headerContextData?.openMiniCart === true) {
      setisutilitiOpen('My Cart');
      setminiCartOpen(true);
      document.body.classList.add('overflow-hidden');
    } else {
      setminiCartOpen(false);
      document.body.classList.remove('overflow-hidden');
    }
  }, [headerContextData.openMiniCart]);

  useEffect(() => {
    isUtilitiOpen == 'My Cart'
      ? setminiCartOpen(true)
      : (setminiCartOpen(false),
        setHeaderContextData({ ...headerContextData, openMiniCart: false }));
  }, [isUtilitiOpen]);

  //store data
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const myStoreServices = myStoreData?.services;
  const storeName = myStoreData?.city + ', ' + myStoreData?.state;
  const myStoreServicesArray = myStoreServices ? myStoreServices.split('|') : [];
  const storeServices = useGraphQlDataContext();

  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const selectedService = myStoreServicesArray?.map(
    (storeServiceId) =>
      storeServices &&
      storeServices?.storeServices?.find(
        (service) => service?.serviceId?.value?.toString() === storeServiceId
      )
  );

  useEffect(() => {
    // for subtotal:
    if (
      ocCurrentOrder?.initialized === true &&
      ocCurrentOrder?.lineItems &&
      ocCurrentOrder?.order?.Subtotal
    ) {
      sessionStorage.setItem('cartLineItems', JSON.stringify(ocCurrentOrder?.lineItems));
    }

    const softData = {
      isAnonymous: isAnonymous,
      username: '',
      subtotal: 0,
    };

    if (userDetails?.FirstName?.toLowerCase() !== 'default') {
      softData.username = userDetails?.FirstName as string;
    }

    if (ocCurrentOrder?.order?.Subtotal) {
      softData.subtotal = ocCurrentOrder?.order?.Subtotal;
    }

    // only insert when softLogin is not enabled
    const checkSoftLoginEnabled = sessionStorage.getItem('isSoftLoginEnabled') == 'true';

    if (!checkSoftLoginEnabled) {
      // needed when refreshed and redux is empty
      const getData = JSON.parse(sessionStorage.getItem('softlogin_dependencies') as any);
      if (getData?.username) {
        softData.username = getData?.username;
      }

      if (getData?.subtotal) {
        softData.subtotal = getData?.subtotal;
      }
      sessionStorage.setItem('softlogin_dependencies', JSON.stringify(softData));
    } else {
      const hasPetImages = JSON.parse(sessionStorage.getItem('softlogin_pets') as any);
      setLocalPetImage(hasPetImages);
    }

    // if (!isAnonymous) {
    sessionStorage.setItem('isAnonymous', isAnonymous.toString());
    // }
  }, [ocCurrentOrder, userDetails?.FirstName, isAnonymous]);

  const [localPetImage, setLocalPetImage] = useState([]);
  const [localIsUserPetsAvailable, setLocalIsUserPetsAvailable] = useState<boolean>();

  useEffect(() => {
    if (typeof window !== undefined) {
      // const hasLocalPetImage = JSON.parse(localStorage.getItem('softlogin_pets') as any);
      const hasLocalPetImage = JSON.parse(sessionStorage.getItem('softlogin_pets') as any);
      // const hasLocalPetImage = cookies?.softlogin_pets as any;
      if (hasLocalPetImage) {
        setLocalPetImage(hasLocalPetImage);
        setLocalIsUserPetsAvailable(hasLocalPetImage && Object.keys(hasLocalPetImage).length > 0);
      }
    }
  }, []);

  // todo: remove the "any" type when "pets" is added to the types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userPets = useOcSelector((s: any) => s.ocUser?.user?.xp?.Pets);
  const isUserPetsAvailable = userPets && Object.keys(userPets).length > 0;

  // const localPetImage = JSON.parse(localStorage.getItem('softlogin_pets') as any);
  // const localIsUserPetsAvailable = localPetImage && Object.keys(localPetImage).length > 0;

  if (isUserPetsAvailable) {
    sessionStorage.setItem('softlogin_pets', JSON.stringify(userPets));
  }

  const navigationServices = selectedService?.filter(
    (service) => !service?.hideInNavigation?.value
  );

  const secondaryServices = selectedService?.filter(
    (service) => service?.isSecondaryService?.value
  );
  const secondaryServicesToken = '{secondary-services}';

  // Handle keyboard interaction with correct TypeScript types
  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (item?.desktopText?.value === 'My Cart') {
        setHeaderContextData({
          ...headerContextData,
          openMiniCart: true,
        });
        setcomponentContextData({
          ...componentContextData,
          showDeliveryThreashold: false,
        });
      } else {
        setisutilitiOpen(item?.desktopText?.value || '');
      }
    } else if (event.key === 'Escape' && isUtilitiOpen === item?.desktopText?.value) {
      setisutilitiOpen('');
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: false,
        showMiniCartLoader: false,
        outOfStockProductIds: [],
      });
      document.body.classList.remove('overflow-hidden');

      // Focus back on the trigger element
      const triggerElement = document.getElementById('utility-trigger-' + item?.desktopText?.value);
      triggerElement?.focus();
    }
  };

  // Handle close button keyboard events
  const handleCloseKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setisutilitiOpen('');
      // Focus back on the trigger element
      const triggerElement = document.getElementById('utility-trigger-' + item?.desktopText?.value);
      triggerElement?.focus();
    }
  };

  const handleLogoutClick = async () => {
    handleLogout(dispatch, router, removeCookie, ctAuthCookie);
  };

  // renders account name based on various conditions including SoftLogin:

  {
    !isAnonymous &&
    userDetails &&
    userDetails?.FirstName &&
    userDetails?.FirstName?.toLowerCase() !== 'default' &&
    item?.desktopText?.value == 'My Account' ? (
      <span className={utilityFont()}>{userDetails?.FirstName}</span>
    ) : (
      <Text tag={'p'} field={item?.desktopText} className={utilityFont()} />
    );
  }
  return (
    <>
      {item?.childrens?.results && item?.childrens?.results?.length > 0 ? (
        <li className={clsx(itemWrapper())}>
          <ul className={clsx(justifyBetween(), relative())}>
            <li
              id={'utility'}
              className={clsx(justifyBetween(), utilityItem(), itemGap())}
              tabIndex={0}
              aria-controls={'utility-panel-' + item?.desktopText?.value}
              aria-label={`${item?.desktopText?.value} menu`}
              onClick={() => {
                item?.desktopText?.value === 'My Cart'
                  ? (setHeaderContextData({
                      ...headerContextData,
                      openMiniCart: true,
                    }),
                    setcomponentContextData({
                      ...componentContextData,
                      showDeliveryThreashold: false,
                    }))
                  : setisutilitiOpen(item?.desktopText?.value || '');
              }}
              onKeyDown={handleKeyDown}
            >
              <div className={relative()}>
                <RenderProfileOrImage
                  item={item}
                  isSoftLoginEnabled={isSoftLoginEnabled}
                  profileImages={profileImages}
                  localIsUserPetsAvailable={localIsUserPetsAvailable}
                  localPetImage={localPetImage}
                  isUserPetsAvailable={isUserPetsAvailable}
                  userPets={userPets}
                />

                {!isSoftLoginEnabled && item?.desktopText?.value == 'My Cart' && (
                  <span className={numberOfProduct({ className: 'cartItem' })}>
                    {numberOfItemsInCart() !== undefined ? numberOfItemsInCart() : '0'}
                  </span>
                )}
                {isSoftLoginEnabled && item?.desktopText?.value == 'My Cart' && (
                  <span className={numberOfProduct()}>
                    {numberOfItemsInCartSoftLogin() !== undefined
                      ? numberOfItemsInCartSoftLogin()
                      : '0'}
                  </span>
                )}
              </div>
              {/* TODO: Account name */}
              <div className={clsx(justifyBetween(), itemGap())}>
                <ShowAccountNameTextDesktop item={item} />
                <IconHelper icon={'Caret'} className={fillBase()} />
              </div>
            </li>
            {isUtilitiOpen === item?.desktopText?.value ? (
              item?.desktopText?.value == 'My Cart' ? (
                <>
                  <div
                    className={
                      miniCartOpen && componentContextData?.showDeliveryAvailabilityPopup !== true
                        ? 'backdrop-shadow'
                        : ''
                    }
                  ></div>

                  <li
                    ref={liref as React.LegacyRef<HTMLLIElement>}
                    className={clsx(
                      miniCartUtilityOpen(),
                      'minicart',
                      `sticky-div ${isSticky ? 'fixed top-0 right-[40px]' : 'absolute top-full'}`,
                      minicartToTop
                        ? isSticky
                          ? '!fixed !top-0 right-[40px]'
                          : '!fixed !top-[5px] right-[40px]'
                        : '',
                      `${direction === 'left' ? 'left-0' : 'right-0'}`
                    )}
                  >
                    <button
                      aria-label={'close the menu'}
                      aria-expanded={miniCartOpen}
                      className={(flexEnd(), miniCartCloseBtn)()}
                      onKeyDown={handleCloseKeyDown}
                    >
                      <IconHelper
                        tabIndex={0}
                        icon={'close'}
                        className={clsx(
                          utlitiCloseIcon(),
                          miniCartCloseBtnIcon(),
                          '!top-[-15px] !right-[10px]'
                        )}
                        onClick={() => {
                          setHeaderContextData({
                            ...headerContextData,
                            openMiniCart: false,
                            showMiniCartLoader: false,
                            outOfStockProductIds: [],
                          });
                          setisutilitiOpen(item?.desktopText?.value || '');
                          document.body.classList.remove('overflow-hidden');
                        }}
                      />
                    </button>

                    <div className={(justifyBetween(), 'w-full')}>
                      {rendering && <Placeholder name="mini-cart" rendering={rendering} />}
                    </div>
                  </li>
                </>
              ) : (
                <li
                  ref={liref as React.LegacyRef<HTMLLIElement>}
                  className={clsx(
                    deskUtilityOpen(),
                    'nav-elements',
                    `${direction === 'left' ? 'left-0' : 'right-0'}`
                  )}
                >
                  <button
                    aria-label={'close the menu'}
                    className={flexEnd()}
                    onKeyDown={handleCloseKeyDown}
                    aria-expanded={isUtilitiOpen === item?.desktopText?.value}
                  >
                    <IconHelper
                      tabIndex={0}
                      icon={'close'}
                      className={clsx(utlitiCloseIcon(), closeIcon())}
                      onClick={() => setisutilitiOpen(item?.desktopText?.value || '')}
                    />
                  </button>
                  <div className={(justifyBetween(), 'w-full')}>
                    {item?.storeLocation?.value && item?.isStoreMenu?.jsonValue?.value && (
                      <TextHelper
                        tag={'p'}
                        field={{ value: storeName + ' ' + item?.storeLocation?.value }}
                        className={clsx(utilityLiOpen(), utlityServiceHeding())}
                      />
                    )}
                  </div>
                  {/*Store Services*/}
                  {item?.isStoreMenu?.jsonValue?.value &&
                    navigationServices?.map((service, index) => (
                      <LinkHelper
                        field={{
                          value: {
                            href: service?.link?.value?.href,
                            text: service?.name?.value,
                          },
                        }}
                        tabIndex={0}
                        key={index}
                        onClick={() => setisutilitiOpen('')}
                        className={utilityLiOpen()}
                      />
                    ))}
                  {/* Display children except store services */}
                  {!item?.isStoreMenu?.jsonValue?.value &&
                    item?.childrens?.results?.map((sublink, key) => {
                      return sublink?.link?.jsonValue?.value?.href &&
                        sublink?.link?.jsonValue?.value?.href.length > 0 ? (
                        <Fragment key={key}>
                          {!sublink?.link?.jsonValue?.value?.href?.includes(
                            '/accountmanagement/my-rewards'
                          ) ? (
                            <LinkHelper
                              editable={false}
                              field={sublink?.link?.jsonValue}
                              onClick={() => setisutilitiOpen('')}
                              className={clsx(utilityLiOpen())}
                            />
                          ) : (
                            // {The iframe widgets won't load without refresh (with router)}
                            <a
                              aria-label="sublink"
                              href={
                                sublink?.link?.jsonValue?.value?.href +
                                '#' +
                                sublink?.link?.jsonValue?.value?.anchor
                              }
                              onClick={() => {
                                setisutilitiOpen('');
                              }}
                              className={clsx(utilityLiOpen())}
                            >
                              {sublink?.link?.jsonValue?.value?.text}
                            </a>
                          )}
                        </Fragment>
                      ) : (
                        ''
                      );
                    })}
                  {item?.desktopText?.value == 'My Account' && !isAnonymous && (
                    <LinkHelper
                      editable={false}
                      field={{
                        value: {
                          href: '/',
                          text: getDictionaryValue('LogoutLabel'),
                        },
                      }}
                      tabIndex={0}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLogoutClick(), setisutilitiOpen('');
                      }}
                      className={clsx(utilityLiOpen())}
                    />
                  )}
                  <div className={clsx(utilityLiOpen(), utilitLinkText())}>
                    {item?.isStoreMenu?.jsonValue?.value &&
                    item?.storeFacility?.value?.includes(secondaryServicesToken) &&
                    secondaryServices?.length !== 0 ? (
                      <span>{item?.storeFacility?.value?.replace(secondaryServicesToken, '')}</span>
                    ) : (
                      <span>{item?.storeFacility?.value}</span>
                    )}
                    {item?.isStoreMenu?.jsonValue?.value &&
                      item?.storeFacility?.value?.includes(secondaryServicesToken) &&
                      secondaryServices?.map((service, index, array) => {
                        if (secondaryServices.length === 2 && index < array.length - 1) {
                          return service?.name?.value + ' and ';
                        }
                        if (secondaryServices.length > 2 && index < array.length - 1) {
                          if (index < array.length - 2) {
                            return service?.name?.value + ', ';
                          } else {
                            return service?.name?.value + ' and ';
                          }
                        }
                        return service?.name?.value;
                      })}
                  </div>
                  {rendering && isUtilitiOpen === item?.desktopText?.value && (
                    <div className={'mt-desk-margin-tight-top'}>
                      <Placeholder
                        name={`utility-nav-${direction}-${navIndex}`}
                        rendering={rendering}
                      />
                    </div>
                  )}
                </li>
              )
            ) : (
              ''
            )}
          </ul>
        </li>
      ) : item?.desktopLink?.jsonValue?.value?.href !== '' && item?.desktopLink !== undefined ? (
        <li className={clsx(itemWrapper())}>
          {item?.desktopLink && (
            <LinkHelper
              editable={false}
              tabIndex={0}
              hideLinkInEE
              onClick={() => setisutilitiOpen('')}
              field={item?.desktopLink?.jsonValue}
              className={clsx(
                linkWrapper(),
                justifyBetween(),
                flexColWmax(),
                itemGap(),
                itemStrech()
              )}
            >
              <>
                <div>
                  <NextImage field={item?.desktopImage?.jsonValue} height={40} width={40} />
                </div>
                <Text tag={'p'} field={item?.desktopText} className={utilityFont()} />
              </>
            </LinkHelper>
          )}
        </li>
      ) : (
        ''
      )}
    </>
  );
}

// function for image to render on profile based on user authentication
const RenderProfileOrImage = (props: any) => {
  const {
    item,
    isSoftLoginEnabled,
    profileImages,
    localIsUserPetsAvailable,
    localPetImage,
    isUserPetsAvailable,
    userPets,
  } = props;

  const { utilityImageMaxWidth } = newNavigationTailwindVariant();
  const shouldShowProfileImage =
    (isSoftLoginEnabled && item?.name === 'My Account' && localIsUserPetsAvailable) ||
    (!isSoftLoginEnabled && item?.name === 'My Account' && isUserPetsAvailable);

  const selectedProfileImages = isSoftLoginEnabled ? profileImages ?? [] : profileImages;
  const selectedUserPets = isSoftLoginEnabled ? localPetImage : userPets;
  const isProfileImageAvailable = isSoftLoginEnabled
    ? localIsUserPetsAvailable
    : isUserPetsAvailable;

  return (
    <div>
      {shouldShowProfileImage ? (
        <UserGroupProfileImage
          profileImages={selectedProfileImages}
          userPets={selectedUserPets}
          isUserPetsAvailable={isProfileImageAvailable}
        />
      ) : (
        <NextImage
          field={item?.desktopImage?.jsonValue}
          className={utilityImageMaxWidth()}
          height={40}
          width={40}
        />
      )}
    </div>
  );
};

const ShowAccountNameTextDesktop = ({ item }: { item: UtilityNavTargetItem }) => {
  const isSoftLoginEnabled = useIsSoftLoginEnabled();
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const userDetails = useOcSelector((state) => state?.ocUser?.user);

  const { utilityFont } = newNavigationTailwindVariant();
  if (isSoftLoginEnabled) {
    // has softLogin, take from SessionStorage:
    if (sessionStorage.getItem('softLoginState')) {
      // const softLoginState = JSON.parse(sessionStorage.getItem('softLoginState') as any);sessionStorage
      const softLoginDepandentData = JSON.parse(
        sessionStorage.getItem('softlogin_dependencies') as any
      );
      if (softLoginDepandentData?.username) {
        if (item?.desktopText?.value == 'My Account') {
          return <span className={utilityFont()}>{softLoginDepandentData?.username}</span>;
        } else {
          return <Text tag={'p'} field={item?.desktopText} className={utilityFont()} />;
        }
      } else {
        //  TODO: For handling case where username is missing in SoftLogin enabled state.
        return <Text tag={'p'} field={item?.desktopText} className={utilityFont()} />;
      }
    }
  }
  // no softlogin, show regular stuff
  if (
    !isAnonymous &&
    userDetails &&
    userDetails.FirstName &&
    userDetails?.FirstName?.toLowerCase() !== 'default' &&
    item?.desktopText?.value == 'My Account'
  ) {
    return <span className={utilityFont()}>{userDetails?.FirstName}</span>;
  } else {
    return <Text tag={'p'} field={item?.desktopText} className={utilityFont()} />;
  }
  return null; // Ensure to return something if isSoftLoginEnabled is true
};
