// store/otherSettingSlice.ts
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Field, LinkField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';

interface OtherSetting {
  gtmId: Field<string>;
  //crdf: Field<string>;
  noProductImage: {
    jsonValue: {
      value: {
        [key: string]: any;
        alt?: string;
        height?: string;
        width?: string;
        src?: string;
        thumbnailsrc?: string;
      };
    };
  };
  onSaleImage: {
    jsonValue: {
      value: {
        [key: string]: any;
        alt?: string;
        height?: string;
        width?: string;
        src?: string;
        thumbnailsrc?: string;
      };
    };
  };
  maxLengthForPromoTag?: Field<string>;
  enableLogin: {
    jsonValue: {
      value: string;
    };
  };
  enableAccountCreation: {
    jsonValue: {
      value: string;
    };
  };
  migratedUserMessage: {
    value: string;
  };
  storeLeadRoles: {
    jsonValue: [
      {
        fields: {
          description: {
            value: string;
          };
          title: {
            value: string;
          };
        };
      }
    ];
  };
  defaultStoreManagerImage: {
    jsonValue: {
      value: {
        [key: string]: any;
        alt?: string;
        height?: string;
        width?: string;
        src?: string;
        thumbnailsrc?: string;
      };
    };
  };
  defaultEmpLink: {
    jsonValue: LinkField;
  };
  defaultEmpBio: RichTextField;
  defaultEmpTitle: RichTextField;
  brandsList: {
    value: string;
  };
}

interface OtherSettingState {
  data: OtherSetting | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: OtherSettingState = {
  data: null,
  status: 'idle',
  error: null,
};

export const fetchOtherSetting = createAsyncThunk(
  'otherSetting/fetchOtherSetting',
  async ({ language, siteName }: { language: string; siteName: string }) => {
    const publicURL = publicURLTrailingSlash();
    const response = await fetch(
      `${publicURL}api/settings/otherSetting?language=${language}&siteName=${siteName}`
    );
    const data = await response.json();
    return data?.search?.results[0] as OtherSetting;
  }
);

const otherSettingSlice = createSlice({
  name: 'otherSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOtherSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOtherSetting.fulfilled, (state, action: PayloadAction<OtherSetting>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchOtherSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default otherSettingSlice.reducer;
