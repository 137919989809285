import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { createOcAsyncThunk } from '../ocReduxHelpers';
import { getSerializableObject } from '../utils';

export interface BrandsState {
  brandList?: string[];
  loading: boolean;
  error?: SerializedError;
}

const initialState: BrandsState = {
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBrandsList = createOcAsyncThunk<any>('BrandsList/get', async () => {
  return ['natural-balance', 'play-on'];
});

const brandsSlice = createSlice({
  name: 'BrandsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrandsList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getBrandsList.fulfilled, (state, action) => {
      state.brandList = action.payload;
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(getBrandsList.rejected, (state, action) => {
      state.brandList = [];
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });
  },
});

export default brandsSlice.reducer;
