import formatPrice from 'src/utils/formatPrice';
import searchResultsTailwind from '../SearchResultsTailwind';
import { UIProductPrice } from 'src/helpers/ProductPriceUI';

const { listPrice } = searchResultsTailwind({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

export function ProductPrice({ productPrice }: { productPrice: UIProductPrice }) {
  return (
    <div className="w-full h-[48px]">
      {productPrice.hasListPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div>List Price</div>
          <div className={`${productPrice.showViewPriceInCart ? 'line-through' : ''}`}>
            ${productPrice.listPrice.toFixed(2)}
          </div>
        </div>
      ) : null}
      {productPrice.hasMemberPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div>Member Price</div>
          <div>${productPrice.memberPrice.toFixed(2)}</div>
        </div>
      ) : null}
      {productPrice.hasImapPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div>List Price</div>
          <div className="line-through">${productPrice.imapPrice.toFixed(2)}</div>
        </div>
      ) : null}
      {productPrice.hasListandMemberPrice ? (
        <>
          <div className={listPrice()}>
            <div>List Price</div>
            <div className="line-through">${productPrice.listPrice.toFixed(2)}</div>
          </div>
          <div className={listPrice()}>
            <div>Member Price</div>
            <div className="text-color-global-outof-stock">
              ${productPrice.memberPrice.toFixed(2)}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export function ProductRecommendationPrice({
  productPrice,
  className,
}: {
  productPrice: UIProductPrice;
  className?: string;
}) {
  return (
    <div className="w-full">
      {productPrice.hasListPrice && !productPrice.hasListandMemberPrice ? (
        <div
          className={listPrice({
            className:
              !productPrice.showViewPriceInCart && `font-heading-desk-large-regular ${className}`,
          })}
        >
          <div
            className={`${
              productPrice.showViewPriceInCart
                ? `line-through text-body-large-bold-striketethrough opacity-50 ${className}`
                : `text-heading-desk-large-regular ${className}`
            }`}
          >
            {formatPrice(productPrice.listPrice)}
          </div>
        </div>
      ) : null}
      {productPrice.hasMemberPrice && !productPrice.hasListandMemberPrice ? (
        <div
          className={listPrice({
            className: `text-heading-desk-large-regular font-heading-desk-large-regular ${className}`,
          })}
        >
          <div>{formatPrice(productPrice.memberPrice)}</div>
        </div>
      ) : null}
      {productPrice.hasImapPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div
            className={`line-through text-body-large-bold-striketethrough opacity-50 ${className}`}
          >
            {formatPrice(productPrice.imapPrice)}
          </div>
        </div>
      ) : null}
      {productPrice.hasListandMemberPrice ? (
        <div className={`flex items-center gap-desk-global-spacing-spacing-2 ${className}`}>
          <div
            className={listPrice({
              className: `text-heading-desk-large-regular font-heading-desk-large-regular !w-auto !block ${className}`,
            })}
          >
            <div className={`text-color-global-outof-stock ${className}`}>
              {formatPrice(productPrice.memberPrice)}
            </div>
          </div>
          <div className={listPrice({ className: '' })}>
            <div className={`line-through opacity-50 !text-color-text-dark ${className}`}>
              {formatPrice(productPrice.listPrice)}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
