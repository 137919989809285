import React, { MouseEventHandler, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
// import clsx from 'clsx';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import clsx from 'clsx';
import IconHelper from './IconHelper';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

// export type ModalWrapperProps = {
//   rendering?: { componentName: string };
//   params?: { [key: string]: string };
//   fields: Props;
// };

export interface ModalWrapperProps extends React.PropsWithChildren {
  title?: Field<string>;
  closeModalOnOverlayClick?: boolean;
  onCloseClick?: (event?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  onSubmitClick?: MouseEventHandler;
  showCloseButtonInModalHeader?: boolean;
  removeCloseIconFromModal?: boolean;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  showModal?: boolean;
  customPopup?: boolean;
  buttonsPosition?: string;
  popupSpacing?: string;
  popupWidth?: string;
  popupBG?: string;
  additionalClassForModal?: string;
  additionalClassForPopUpModal?: string;
  showSmallPopup?: boolean;
  componentName?: string;
  isMiniCart?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
}
const ModalWrapper: React.FC<ModalWrapperProps> = ({
  closeModalOnOverlayClick = false,
  onCloseClick,
  showModal,
  customPopup,
  popupSpacing,
  popupWidth,
  popupBG,
  additionalClassForPopUpModal,
  showCloseButtonInModalHeader = true, // removes Close icon from Modal header
  removeCloseIconFromModal = false, // removes Close icon from Modal itself
  showSmallPopup,
  componentName,
  isMiniCart,
  tabIndex,
  ariaLabel,
  ...props
}: ModalWrapperProps) => {
  // const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { headerContextData } = useHeaderContext();
  const modalRef = useRef<HTMLDivElement>(null);
  const previousFocusRef = useRef<Element | null>(null);
  useEffect(() => {
    if (!showModal) return;

    // Store the currently focused element
    previousFocusRef.current = document.activeElement;

    // Add class to body to prevent scrolling
    document.body.classList.add('modal-open');

    // Focus the first focusable element in the modal
    const focusableElements = modalRef.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (focusableElements && focusableElements.length > 0) {
      (focusableElements[0] as HTMLElement).focus();
    }

    // Function to trap focus
    const trapFocus = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return;

      const focusableElements = modalRef.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (!focusableElements || focusableElements.length === 0) return;

      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    };

    // Add event listener for focus trapping
    document.addEventListener('keydown', trapFocus);

    return () => {
      // Remove event listener
      document.removeEventListener('keydown', trapFocus);

      // Restore previous focus
      if (previousFocusRef.current instanceof HTMLElement) {
        previousFocusRef.current.focus();
      }

      // Remove body class
      document.body.classList.remove('modal-open');
    };
  }, [showModal]);

  const ModalWrapperTV = tv({
    slots: {
      modalWrapperBase: [
        'fixed',
        'z-[950]',
        'flex',
        'h-screen',
        'w-screen',
        'justify-center',
        'items-center',
        'top-0',
        'left-0',
        'bottom-0',
        'right-0',
      ],
      modalWrapperInner: [
        'z-50',
        'h-full',
        'w-full',
        'bg-transparent',
        'rounded',
        'py-12',
        'px-4',
        'lg:py-10',
        'absolute',
        'overflow-auto',
        'left-0',
        'top-0',
      ],
      modalWrapperCloseIcon: [
        'absolute',
        'z-[1]',
        'right-2',
        'top-2',
        'lg:right-4',
        'lg:top-4',
        'cursor-pointer',
        'w-6',
        'h-6',
        'lg:w-10',
        'lg:h-10',
      ],
      modalWrapperCloseIconHelper: [
        'iconWrapper',
        '&>svg',
        'w-6',
        '&>svg',
        'h-6',
        '&>svg',
        'lg:w-10',
        '&>svg',
        'lg:h-10',
      ],
    },
  });
  const {
    modalWrapperBase,
    modalWrapperInner,
    modalWrapperCloseIcon,
    modalWrapperCloseIconHelper,
  } = ModalWrapperTV({});

  //handling keydown event for accessibility
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      onCloseClick && onCloseClick();
    }
  };
  // if (!showModal) { // Removed this as it was causing issue as this modal popup is required to present on initial load.
  //   return <></>;
  // }

  return (
    <div
      ref={modalRef}
      className={clsx(
        modalWrapperBase(),
        `${!isMiniCart && componentName}`,
        !showSmallPopup && (popupBG && !showSmallPopup ? popupBG : 'bg-[rgba(0,0,0,0.5)]'),
        headerContextData?.openMiniCart === true ? 'bg-[rgba(0,0,0,0.5)]' : '',
        { block: showModal, hidden: !showModal },
        props?.additionalClassForModal
      )}
      {...(closeModalOnOverlayClick && onCloseClick && { onClick: onCloseClick })}
    >
      <div
        className={clsx(
          modalWrapperInner(),
          showSmallPopup !== true ? 'lg:px-20' : 'py-4 lg:px-[55px]'
        )}
      >
        {showCloseButtonInModalHeader && (
          <a
            role="modal close"
            aria-label="close"
            className={modalWrapperCloseIcon()}
            onClick={onCloseClick}
          >
            {<IconHelper className={modalWrapperCloseIconHelper()} icon={'close'} />}
          </a>
        )}
        {customPopup ? (
          <div
            className={`flex h-full w-full ${additionalClassForPopUpModal} ${
              showSmallPopup !== true
                ? 'justify-center items-center'
                : 'justify-end lg:items-end items-start'
            }`}
            aria-label={ariaLabel}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={`bg-color-background-white modal-wrapper rounded-xl overflow-y-auto max-h-full w-full relative ${
                popupWidth ? popupWidth : 'max-w-[526px]'
              } ${
                popupSpacing
                  ? popupSpacing
                  : 'lg:py-desk-padding-tight-y py-mob-padding-tight-y lg:px-desk-padding-tight-x px-mob-padding-tight-x'
              } `}
            >
              {!removeCloseIconFromModal && (
                <a
                  role="modal close"
                  tabIndex={0}
                  aria-label="close"
                  className="absolute z-[1] right-2 top-2 lg:right-2 lg:top-2 cursor-pointer w-8 h-8 "
                  onClick={onCloseClick}
                  onKeyDown={handleOnKeyDown}
                >
                  {
                    <IconHelper
                      className="iconWrapper [&>svg]:w-8 [&>svg]:h-8 [&>svg_*]:fill-color-background-brand-1"
                      icon={'close'}
                    />
                  }
                </a>
              )}
              {props.children}
            </div>
          </div>
        ) : (
          <div
            className="flex h-full [&>div]:w-full "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
};
export default ModalWrapper;
