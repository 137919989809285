import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';
import React from 'react';

/**
 * Generates SEO metadata tags for the product detail page.
 *
 * @returns {JSX.Element} A Head element containing title, description, and keywords tags.
 */

const ProductDetailSEOMetaData = () => {
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  return (
    <Head>
      <title>{productData?.SEOMetadata?.Title?.toString() || 'Page'}</title>
      {productData?.SEOMetadata?.Description && (
        <meta name="description" content={productData?.SEOMetadata?.Description?.toString()} />
      )}
      {productData?.SEOMetadata?.Keywords && (
        <meta name="keywords" content={productData?.SEOMetadata?.Keywords?.toString()} />
      )}
    </Head>
  );
};

export default ProductDetailSEOMetaData;
