import React from 'react';
import { breadcrumbVariants } from 'tailwindVariants/components/breadcrumbTailwindVariant';
import clsx from 'clsx';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import Link from 'next/link';
import { decodeUrlQuery } from 'lib/utils/url-formatter';

interface BreadcrumbItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

interface SiblingItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

export interface BreadcrumbData {
  breadcrumb: {
    breadcrumbTitle?: { value: string };
    title: { value: string };
    url: { path: string };
    id: string;
    ancestors: BreadcrumbItem[];
  };
  siblings: {
    children: {
      results: SiblingItem[];
    };
  };
}

interface BreadCrumbDefaultProps {
  gqldata: BreadcrumbData;
  siteName: string;
}

//Tailwind Variants Styles
const { breadcrumbText, breadcrumbContainer, ancestorsWrapper } = breadcrumbVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const DividerIcon = () => {
  return (
    <span className={''}>
      <IconHelper icon={'breadcrumb-divider'} />
    </span>
  );
};

const buildBreadcrumb = (
  breadcrumbData: BreadcrumbItem[],
  currentItem: BreadcrumbItem
): BreadcrumbItem[] => {
  const pathsToExclude = ['/categories', '/'];
  const filteredAncestors =
    breadcrumbData?.filter((item) => !pathsToExclude.includes(item.url.path)).reverse() || '';

  return [...filteredAncestors, currentItem];
};

const BreadCrumbDefault: React.FC<BreadCrumbDefaultProps> = ({ gqldata }) => {
  const { breadcrumb } = gqldata;
  const breadcrumbItems = buildBreadcrumb(breadcrumb?.ancestors, breadcrumb);

  return (
    <div>
      {/* Breadcrumb Rendering */}
      <nav aria-label="breadcrumb" className={breadcrumbContainer()}>
        <ul className={clsx(ancestorsWrapper(), 'flex', 'overflow-x-auto')}>
          {breadcrumbItems.map((item, index) => {
            const title = item?.breadcrumbTitle?.value || item?.title?.value;
            const isLastItem = index === breadcrumbItems.length - 1;

            return (
              <li key={item?.id} className={clsx(ancestorsWrapper(), 'relative', 'group', 'flex')}>
                {isLastItem ? (
                  <>
                    <span className={clsx(breadcrumbText())}>{title}</span>
                  </>
                ) : (
                  <>
                    <Link
                      aria-label="title link"
                      href={decodeUrlQuery(item?.url?.path)}
                      className={clsx(breadcrumbText())}
                    >
                      {title}
                    </Link>
                    <DividerIcon />
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default BreadCrumbDefault;
