/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useRef, useState } from 'react';
import { purchaseListingVariant } from 'tailwindVariants/components/purchaseListing';
import PurchaseListingOrderItem from './PurchaseListingOrderItem';
import { OrderWorksheetWithXP } from 'src/redux/xp';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { useSiteName } from 'src/hooks/useSiteName';
import { setPastOrders } from 'src/redux/ocPastOrders';
import { Field, Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { apiRequest } from 'src/utils/apiWrapper';
import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import { getMyOrdersAPI } from 'src/utils/nextApiConfig';
import clsx from 'clsx';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import AddToCartWarningModal from 'src/helpers/purchaseDetails/AddToCartWarningModal';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import PurchaseOrderSkeleton from './PurchaseOrderSkeleton';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import {
  DiscreteLineItem,
  GTM_EVENT,
  GTMLabels,
  purchaseHistoryFilter,
} from 'src/helpers/Constants';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

export type PurchaseListingProps =
  PSP.Sitecore.templates.PetSuppliesPlus.Version2.MyPurchases.Fields.PurchaseListing &
    ComponentProps;

type filterType = 'day' | 'month' | 'year';

export interface PurchaseOrdersResponse {
  Page: number;
  PageSize: number;
  TotalCount: number;
  TotalPages: number;
  Item: OrderWorksheetWithXP[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PurchaseListing({ fields, rendering }: PurchaseListingProps) {
  const {
    purchaseListingWrapper,
    pageTitle,
    myPurchaseOrderWrapper,
    myPurchasePaginationItems,
    purchaseListingTransactionWrapper,
    myPurchaseOrderItemDate,
  } = purchaseListingVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { headerContextData } = useHeaderContext();
  const siteName = useSiteName();
  const isEE = useExperienceEditor();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReorderAll, setIsReorderAll] = useState(false);
  const [isPartiallyAdded, setIsPartiallyAdded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrdersResponse>();
  const [isGTMEventSent, setIsGTMEventSent] = useState(false);
  const [inventoryForAllLineItems, setInventoryForAllLineItems] = useState<
    | {
        [key: string]: number;
      }[]
    | undefined
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const pageSize = fields?.orderPerPage?.value || 5;
  const dispatch = useOcDispatch();
  const defaultFilter =
    fields?.historyDropdown?.filter(
      (x) =>
        x?.fields?.value?.value === 30 &&
        x?.fields?.intervalLabel?.fields?.Value?.value?.toLowerCase() == 'day'
    )[0] || fields?.historyDropdown?.[0];

  const purchaseData = useOcSelector((s) => s?.ocPastOrder?.data);
  const [historyFilter, setHistoryFilter] = useState(defaultFilter);
  const productData =
    purchaseData &&
    (purchaseData as OrderWorksheetWithXP[])
      ?.map((item) => item?.LineItems)
      ?.flatMap((x) => x)
      ?.filter((x) => x?.ProductID !== DiscreteLineItem.TIP)
      ?.filter((x) => x?.Product?.xp?.UPC != undefined);

  //handle scroll to top on click of page selection
  const handleScroll = () => {
    window.scroll({ behavior: 'smooth', top: 0 });
  };

  //Generating pagination based on totalPages
  const generatePages = () => {
    const pages: string[] = [];
    if (purchaseOrderData?.TotalPages && purchaseOrderData?.TotalPages > 0) {
      if (purchaseOrderData?.TotalPages && purchaseOrderData?.TotalPages <= 7) {
        Array.from({ length: purchaseOrderData?.TotalPages || 1 })?.map((_, p) =>
          pages.push(String(p + 1))
        );
      } else {
        if (currentPage - 1 < 4) {
          Array.from({ length: purchaseOrderData?.TotalPages || 1 })
            ?.slice(0, 5)
            ?.map((_, i) => pages.push(String(i + 1)));
          pages.push('...');
          pages.push(String(purchaseOrderData?.TotalPages));
        }
        if (Number(purchaseOrderData?.TotalPages) - currentPage < 4) {
          const start =
            Number(currentPage) - (4 - (Number(purchaseOrderData?.TotalPages) - currentPage));
          const end = Number(purchaseOrderData?.TotalPages);
          pages.push('1');
          pages.push('...');
          for (let i = start; i <= end; i++) {
            pages.push(String(i));
          }
        }
        if (!(Number(purchaseOrderData?.TotalPages) - currentPage < 4 || currentPage - 1 < 4)) {
          const start = Number(currentPage) - 1;
          const end = Number(currentPage) + 1;
          pages.push('1');
          pages.push('...');
          for (let i = start; i <= end; i++) {
            pages.push(String(i));
          }
          pages.push('...');
          pages.push(String(purchaseOrderData?.TotalPages));
        }
      }
    }
    return pages;
  };

  //handling page click/change and previous and next CTA click
  const handlePageClick = (page: string | number) => {
    if (page !== '...') {
      if (Number(page) != currentPage) {
        const dateVal = historyFilter?.fields?.value?.value;
        const interval = historyFilter.fields?.intervalLabel?.fields?.Value?.value;
        handleScroll();
        setCurrentPage(Number(page));
        getMyOrders(Number(page), dateVal, interval);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePurchaseFilter = (filter: any) => {
    setHistoryFilter(filter);
    setCurrentPage(1);
    const dateVal = filter?.fields?.value?.value;
    const interval = filter.fields?.intervalLabel?.fields?.Value?.value;
    getMyOrders(1, dateVal, interval);
  };

  //getting initial orders on page load
  useEffect(() => {
    const dateVal = defaultFilter?.fields?.value?.value;
    const interval = defaultFilter.fields?.intervalLabel?.fields?.Value?.value;
    getMyOrders(1, dateVal, interval);
  }, [siteName]);

  //GTM for View_item_list
  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    const checkGtmLoad = () => {
      // const isEventFired =
      //   window &&
      //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
      //   (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItemList)
      //     ?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (
        // productDataObj &&
        // productDataObj?.length > 0 &&
        productData &&
        isGTMLoad &&
        inventoryForAllLineItems &&
        inventoryForAllLineItems?.length >= productData?.length &&
        !isGTMEventSent
      ) {
        const productDataObj = productData?.map((lineItem) => {
          const inventory =
            inventoryForAllLineItems &&
            inventoryForAllLineItems?.find(
              (val) => Object?.keys(val)?.[0] == (lineItem?.ProductID as string)
            )?.[lineItem?.ProductID as string];
          return {
            ...lineItem?.Product,
            quantity: lineItem?.Quantity,
            BasePrice: lineItem?.UnitPrice,
            listPrice: lineItem?.UnitPrice,
            availableInventory: inventory && inventory,
          };
        });

        sendProductsPromotion({
          shouldPreciousDataLaLayerRemoved: true,
          eventName: GTM_EVENT?.viewItemList,
          data: productDataObj as ProductSearchResultModelWithVariants[],
          pageItem: GTMLabels?.myPurchases,
          currentPage: GTMLabels?.myPurchases,
        });
        setIsGTMEventSent(true);
        clearTimeout(timeOutId);
        return;
      } else {
        timeOutId = setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
    return () => clearTimeout(timeOutId);
  }, [purchaseData, inventoryForAllLineItems]);

  //handle dropdown outside click
  useEffect(() => {
    function handleDropdown(event: MouseEvent) {
      if (dropDownRef && !dropDownRef?.current?.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mouseup', handleDropdown);
    return () => {
      document.removeEventListener('mouseup', handleDropdown);
    };
  }, []);

  // API Call to get orders
  const getMyOrders = async (page: number, dateVal?: number, interval?: filterType) => {
    setIsFetching(true);
    setIsGTMEventSent(false);
    setInventoryForAllLineItems(undefined);
    try {
      const options = {
        method: REQUEST_METHOD?.get,
        headers: {
          'Content-Type': 'application/json', // Define header type for JSON
        },
      };

      const filterVal =
        dateVal && interval
          ? `&${purchaseHistoryFilter[interval?.toLocaleLowerCase() as filterType]}=${dateVal}`
          : '';
      const url =
        getMyOrdersAPI?.getMyOrders + '?Page=' + page + '&PageSize=' + pageSize + filterVal;
      const response = await apiRequest<PurchaseOrdersResponse>(url, options);
      dispatch(setPastOrders(response?.Item));
      setPurchaseOrderData(response);
    } catch (error) {
      console.error('Error during sign-in into Crowd Twist: ', error);
    } finally {
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <PurchaseOrderSkeleton />;
  }

  if ((!isFetching && purchaseOrderData?.Item && purchaseOrderData?.Item?.length < 1) || isEE) {
    return (
      <div className={purchaseListingWrapper()}>
        <div className={purchaseListingTransactionWrapper()}>
          <h1 className={pageTitle()}>{fields?.title?.value}</h1>
          <PurchaseHistoryDropDown
            dropDownRef={dropDownRef}
            handlePurchaseFilter={handlePurchaseFilter}
            historyFilter={historyFilter}
            isDropdownOpen={isDropdownOpen}
            purchaseOrderData={purchaseOrderData}
            setIsDropdownOpen={setIsDropdownOpen}
            fields={fields}
          />
        </div>
        <div className="flex flex-col">
          {!isEE && (
            <RichText
              className={myPurchaseOrderItemDate({ className: 'text-center my-8' })}
              tag="span"
              field={fields?.noPurchaseHostory}
            />
          )}
          {(isEE || fields?.hideBanner?.value === false) && (
            <Placeholder
              name="my-purchase-listing"
              rendering={rendering}
              params={{ className: 'p-0' }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <AddToCartWarningModal
        isModalOpen={isModalOpen || isReorderAll || (headerContextData?.isReorderAll as boolean)}
        setIsReorderAll={setIsReorderAll}
        setIsModalOpen={setIsModalOpen}
        setIsPartiallyAdded={setIsPartiallyAdded}
        isPartiallyAdded={isPartiallyAdded}
        modalCTA={fields?.confirmCTAText as Field<string>}
        modalDescription={
          isReorderAll || (headerContextData?.isReorderAll as boolean)
            ? (fields?.reorderAllDescription as Field<string>)
            : (fields?.description as Field<string>)
        }
        modalTitle={
          isReorderAll || (headerContextData?.isReorderAll as boolean)
            ? (fields?.reorderAllTitle as Field<string>)
            : (fields?.modalTitle as Field<string>)
        }
      />
      <div className={purchaseListingWrapper()}>
        <div className={purchaseListingTransactionWrapper()}>
          <h1 className={pageTitle()}>{fields?.title?.value}</h1>
          <PurchaseHistoryDropDown
            dropDownRef={dropDownRef}
            handlePurchaseFilter={handlePurchaseFilter}
            historyFilter={historyFilter}
            isDropdownOpen={isDropdownOpen}
            purchaseOrderData={purchaseOrderData}
            setIsDropdownOpen={setIsDropdownOpen}
            fields={fields}
          />
        </div>
        <div className={myPurchaseOrderWrapper()}>
          {/* <Placeholder name="my-purchase-listing" rendering={rendering} /> */}
          {purchaseData &&
            Object.values(purchaseData as OrderWorksheetWithXP).map((orderItem, index: number) => {
              return (
                <React.Fragment key={index}>
                  {index === 0 &&
                    fields?.showBannerOnTop?.value !== false &&
                    fields?.hideBanner?.value === false && (
                      <Placeholder
                        name="my-purchase-listing"
                        rendering={rendering}
                        params={{ className: 'p-0' }}
                      />
                    )}
                  <PurchaseListingOrderItem
                    orderItem={orderItem as OrderWorksheetWithXP}
                    fields={fields}
                    setIsModalOpen={setIsModalOpen}
                    setIsReorderAll={setIsReorderAll}
                    setIsPartiallyAdded={setIsPartiallyAdded}
                    inventoryForAllLineItems={inventoryForAllLineItems}
                    setInventoryForAllLineItems={setInventoryForAllLineItems}
                    productDataLength={productData?.length as number}
                  />
                  {index === 0 &&
                    !fields?.showBannerOnTop?.value &&
                    fields?.hideBanner?.value === false && (
                      <Placeholder
                        name="my-purchase-listing"
                        rendering={rendering}
                        params={{ className: 'p-0' }}
                      />
                    )}
                </React.Fragment>
              );
            })}
          {purchaseOrderData &&
          purchaseOrderData?.TotalPages &&
          purchaseOrderData?.TotalPages > 0 ? (
            <div className="pagination-wrapper flex gap-mob-space-between-tight-horizontal justify-center items-center pb-mob-padding-loose-bottom">
              {currentPage != 1 && (
                <IconHelper
                  icon="chevron-left"
                  className="[&>svg>*]:fill-color-scale-7-mid-dark cursor-pointer"
                  onClick={() => {
                    handlePageClick(currentPage - 1);
                  }}
                />
              )}
              {generatePages().map((page, index) => (
                <button
                  key={index}
                  className={clsx(
                    `pagination-item ${
                      page == String(currentPage) &&
                      'bg-color-brand-primary-1-base text-color-text-white'
                    }`,
                    myPurchasePaginationItems()
                  )}
                  onClick={() => {
                    handlePageClick(page);
                  }}
                >
                  {page}
                </button>
              ))}
              {currentPage != purchaseOrderData?.TotalPages && (
                <IconHelper
                  icon="chevron-right"
                  className="[&>svg>*]:fill-color-scale-7-mid-dark cursor-pointer"
                  onClick={() => {
                    handlePageClick(currentPage + 1);
                  }}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

interface PurchaseHistoryDropDownProps extends Pick<PurchaseListingProps, 'fields'> {
  purchaseOrderData: PurchaseOrdersResponse | undefined;
  historyFilter: any;
  handlePurchaseFilter: (filter: any) => void;
  setIsDropdownOpen: (val: boolean) => void;
  isDropdownOpen: boolean;
  dropDownRef: React.RefObject<HTMLDivElement>;
}

const PurchaseHistoryDropDown = ({
  fields,
  purchaseOrderData,
  historyFilter,
  handlePurchaseFilter,
  setIsDropdownOpen,
  isDropdownOpen,
  dropDownRef,
}: PurchaseHistoryDropDownProps) => {
  const { purchaseHistory, historyWrapper, filterBlock, largeBoldLabel } = purchaseListingVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isYearLabel = (filter: any) => {
    if (filter?.fields?.intervalLabel?.fields?.Value?.value?.toLowerCase() == 'year') {
      return true;
    }
    return false;
  };

  return (
    <div className={purchaseHistory()}>
      <div>
        <span className={largeBoldLabel()}>{purchaseOrderData?.TotalCount}</span>{' '}
        <Text field={fields?.transactionText} />
      </div>
      <div ref={dropDownRef} id={'historyDropdown'} className="relative grow">
        <div
          className={historyWrapper()}
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          <div className={filterBlock()}>
            <RichTextHelper
              field={historyFilter?.fields?.titleToShow?.value}
              updatedField={{
                value: !isYearLabel(historyFilter)
                  ? historyFilter?.fields?.titleToShow?.value
                      ?.replace('{value}', historyFilter?.fields?.value?.value)
                      .replace(
                        '{interval}',
                        historyFilter?.fields?.intervalLabel?.fields?.Text?.value || ''
                      )
                  : historyFilter?.fields?.value?.value,
              }}
            />
            <IconHelper icon="Caret" className="[&>svg>*]:fill-color-background-brand-1" />
          </div>
        </div>
        <ul
          className={`${
            isDropdownOpen ? 'flex' : 'hidden'
          } bg-color-background-white absolute w-full px-mob-padding-micro-y py-mob-padding-micro-y  flex-col gap-desk-global-spacing-spacing-3 border-[1px] border-color-border-mid border-t-0`}
        >
          {fields?.historyDropdown
            ?.filter((x) => x !== historyFilter)
            ?.map((filter) => {
              return (
                <li
                  className="cursor-pointer"
                  key={filter?.displayName}
                  onClick={() => {
                    handlePurchaseFilter(filter);
                    setIsDropdownOpen(false);
                  }}
                >
                  {
                    <RichTextHelper
                      field={filter?.fields?.titleToShow?.value}
                      updatedField={{
                        value: !isYearLabel(filter)
                          ? filter?.fields?.titleToShow?.value
                              ?.replace('{value}', filter?.fields?.value?.value)
                              .replace(
                                '{interval}',
                                filter?.fields?.intervalLabel?.fields?.Text?.value
                              )
                          : filter?.fields?.value?.value,
                      }}
                    />
                  }
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default PurchaseListing;
