import React from 'react';
import { newProductDetailStructureTailwindVariant } from './newProductDetailStructureTailwindVariant';
import NewStoreDetailTab from 'src/helpers/NewProductDetailComponents/NewStoreDetailTab';
import { PdpDatasourceData } from 'components/ProductDetail/ProductDetail';

const ProductDetailInfoSection = ({ fields }: { fields: PdpDatasourceData }) => {
  const { productDetailInfoSection, productDetailTabWrapper, productDetailInfoTitle } =
    newProductDetailStructureTailwindVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });

  return (
    <div className={productDetailTabWrapper()}>
      <div className={productDetailInfoSection()}>
        <p className={productDetailInfoTitle()}>{fields?.aboutThisItemTitle?.value}</p>
        <NewStoreDetailTab fields={fields} />
      </div>
    </div>
  );
};

export default ProductDetailInfoSection;
