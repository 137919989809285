/**
 * This Layout is needed for Starter Kit.
 */
import React from 'react';
import Head from 'next/head';
import { Placeholder, LayoutServiceData, HTMLLink } from '@sitecore-jss/sitecore-jss-nextjs';
import config from 'temp/config';
import Scripts from 'src/Scripts';
import 'react-datepicker/dist/react-datepicker.css';
import { getSiteName } from './helpers/siteNameHelper';
import preconnectUrls from './utils/preconnectUrls';
import WarningSubmittingCart from 'components/WarningSubmittingCart/WarningSubmittingCart';

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
const publicUrl = config.publicUrl;

interface LayoutProps {
  layoutData: LayoutServiceData;
  headLinks: HTMLLink[];
}

// interface RouteFields {
//   [key: string]: unknown;
//   title?: Field;
// }
const Layout = ({ layoutData, headLinks }: LayoutProps): JSX.Element => {
  const { route } = layoutData?.sitecore;
  // const fields = route?.fields as RouteFields;
  const isPageEditing = layoutData?.sitecore?.context?.pageEditing;
  const mainClassPageEditing = isPageEditing ? 'editing-mode' : 'prod-mode';
  const { site } = layoutData?.sitecore?.context;
  // setting the current site theme based on the site name as well as page monde
  const currentTheme = getSiteName(site?.name);
  //unbaling the crowling
  const isCrawler = process.env.NEXT_PUBLIC_DISABLE_CRAWLERS || 'true';

  return (
    <>
      <Scripts />
      <Head>
        {isCrawler === 'true' && <meta name="robots" content="noindex,nofollow" />}
        <link rel="icon" href={`${publicUrl}/${currentTheme}-favicon-16x16.png`} />
        {headLinks.map((headLink) => (
          <link rel={headLink.rel} key={headLink.href} href={headLink.href} />
        ))}
        {/* Preconnect URLs */}
        {preconnectUrls.map((url, index) => (
          <link key={index} rel="preconnect" href={url} />
        ))}
      </Head>

      {/* root placeholder for the app, which we add components to using route data */}
      <div className={`${mainClassPageEditing} ${currentTheme}`}>
        <a
          href="#content"
          className="skip-to-main absolute -top-full left-0 w-full justify-center z-0 text-heading-desk-large-medium text-color-text-white py-desk-padding-micro-y bg-color-brand-primary-1-base focus:top-0 focus:z-[99] focus:flex"
        >
          Skip to main
        </a>
        <header>
          {route && <Placeholder name="headless-head" rendering={route} />}
          <div id="header">{route && <Placeholder name="headless-header" rendering={route} />}</div>
        </header>
        <main>
          <div id="content">{route && <Placeholder name="headless-main" rendering={route} />}</div>
          <WarningSubmittingCart />
        </main>
        <footer>
          <div id="footer">{route && <Placeholder name="headless-footer" rendering={route} />}</div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
