import React from 'react';
import { extractDomainUrlFromConfig } from '../publicURLTrailingSlash';
import { getProductDetailPath } from '../Constants';
import { getFirstProductImage } from '../productDetailHelper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

/**
 * Generates a schema.org Product entity, which is used to provide search engines with structured data about the product.
 * The schema.org data is rendered as a JSON-LD script tag, and contains the product's name, image, description, SKU, and brand.
 * The data is retrieved from the Sitecore context, and the image is retrieved from the Xp images.
 * The product's ID is generated by combining the domain URL with the product detail path.
 */
const ProductDetailSchema = () => {
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  const domainUrl = extractDomainUrlFromConfig(sitecoreContext?.site?.name || '');
  const dataObj = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': `${domainUrl}${getProductDetailPath({
      ...productData,
      xp: productData?.Xp,
      ParentID: productData?.ParentID,
    })}`,
    name: productData?.Name,
    image: getFirstProductImage(productData?.Xp?.Images)?.Url,
    description: productData?.Xp?.Bullets?.[0],
    sku: productData?.Xp?.UPC,
    brand: productData?.Xp?.Brand,
  };
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(dataObj) }}
    />
  );
};

export default ProductDetailSchema;
