//global
import React, { useContext, useEffect, useState } from 'react';
import { Field, ImageField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

//lib
import { ComponentProps } from 'lib/component-props';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { useOcSelector } from 'src/redux/ocStore';
import useDictionary from 'src/hooks/useDictionary';
import { FulfillmentType } from 'src/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import { autoshipAlertMessageTailwindVariant } from 'tailwindVariants/components/autoshipAlertMessageTailwindVariant';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
//type
export type AutoshipAlertMessageProps = ComponentProps & {
  fields: {
    title?: Field<string>;
    readMoreText?: Field<string>;
    readMoreModalImage?: ImageField;
    readMoreModalText?: Field<string>;
  };
};

//main component
const AutoshipAlertMessage: React.FC<AutoshipAlertMessageProps> = ({ fields, params }) => {
  const {
    base,
    titleWrapper,
    description,
    descriptionWrapper,
    modalDiv,
    link,
    autoshipAlertMessageWrapper,
    modalTitle,
  } = autoshipAlertMessageTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const [showModal, setShowModal] = useState(false);
  const { getProductLineItems } = useOcCart();
  const productLineItems: LineItemWithXp[] = getProductLineItems();
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const [newMethod, setNewMethod] = useState<string>(FulfillmentType.BOPIS);
  const hasAutoship = productLineItems.some((x) => x.Product?.xp?.Autoship);
  const store = useOcSelector((state) => state.storeReducer.selectedStore);
  const { getDictionaryValueWithReplacement } = useDictionary();
  const [autoshipMessage, setAutoshipMessage] = useState<null | string>(null);
  const { componentContextData } = useContext(ComponentContext);

  useEffect(() => {
    if (localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod) {
      setNewMethod(localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod || '');
    }
  }, [fulfillmentMethod, componentContextData?.isDFSMethodChanged]);
  const totalPossibleAutoship = productLineItems.reduce((acc, item) => {
    // If autoship is selected (option 2) for this item, use LineSubttotal,
    // otherwise (option 1) use LineTotal.
    return acc + (item.xp?.Autoship ? item.LineSubtotal ?? 0 : item.LineTotal ?? 0);
  }, 0);
  const maxFirstTime =
    (store.autoshipSettings?.firstTimeDiscount ?? 0) * 0.01 * totalPossibleAutoship;
  const handleModalClose = () => {
    setShowModal(false);
  };
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  );
  const renderMessage = () => {
    if (receivedFirstTimeAutoshipDiscount) {
      if (
        productLineItems.some(
          (x) =>
            x.Product?.xp?.Autoship &&
            x.Product?.xp?.FirstTimeAutoshipDiscount &&
            x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageForFutureOrder';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.FirstTimeAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageWithoutDiscount';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageForFutureOrder';
      } else if (productLineItems.some((x) => x.Product?.xp?.Autoship)) {
        return 'AutoshipAlertMessageWithoutDiscount';
      }
    } else {
      if (
        productLineItems.some(
          (x) =>
            x.Product?.xp?.Autoship &&
            x.Product?.xp?.FirstTimeAutoshipDiscount &&
            x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageFirstTimeWithAdditionalDiscount';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.FirstTimeAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageFirstTimeDiscount';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageForFutureOrder';
      } else if (productLineItems.some((x) => x.Product?.xp?.Autoship)) {
        return 'AutoshipAlertMessageWithoutDiscount';
      }
    }

    return null;
  };

  useEffect(() => {
    setAutoshipMessage(renderMessage());
  }, [productLineItems]);
  if (fields === undefined || fields === null) return <></>;
  return (
    <>
      {hasAutoship && newMethod === FulfillmentType.DFS && autoshipMessage && (
        <>
          <div className={base({ className: params?.Style ?? '' })}>
            <div className={autoshipAlertMessageWrapper()}>
              <div className={titleWrapper()}>
                <TextHelper tag="p" field={fields?.title} />
              </div>
              <div className={descriptionWrapper()}>
                <RichText
                  tag="p"
                  field={{
                    value: getDictionaryValueWithReplacement(
                      autoshipMessage,
                      {
                        xx: Math.min(
                          store.autoshipSettings?.firstTimeDiscountLimit ?? 25,
                          maxFirstTime
                        ).toFixed(2),
                        x: store.autoshipSettings?.firstTimeDiscount?.toString() ?? '35',
                        y: store.autoshipSettings?.subsequentOrdersDiscount?.toString() ?? '5',
                        z: store.autoshipSettings?.firstTimeDiscountLimit?.toString() ?? '25',
                      },
                      // Fallback explicitly empty string because we don't want to display they key if dictionary lookup fails
                      autoshipMessage
                    ),
                  }}
                  className={description()}
                />
                <LinkHelper
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                  field={{ value: { text: fields?.readMoreText?.value, href: '/' } }}
                  className={link()}
                />
              </div>
            </div>
          </div>
          <ModalWrapper
            showCloseButtonInModalHeader={false}
            closeModalOnOverlayClick={true}
            onCloseClick={handleModalClose}
            showModal={showModal}
            customPopup={true}
            popupWidth="max-w-[997px]"
            popupSpacing="p-0"
          >
            <div className={modalDiv()}>
              <TextHelper className={modalTitle()} tag="p" field={fields?.readMoreModalText} />
              <ImageHelper className="w-full object-cover" field={fields?.readMoreModalImage} />
            </div>
          </ModalWrapper>
        </>
      )}
    </>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default AutoshipAlertMessage;
