import { tv } from 'tailwind-variants';

export const newStoreDetailTabTailwindVariant = tv(
  {
    slots: {
      accordionWrapper: 'border-b border-color-border-dark w-full focus:outline-none',
      accordionTitleWrapper: 'flex items-center justify-between w-full',
      accordionTitle: 'font-primary',
      nutritionContentWrapper: 'flex',
      tableWrapper:
        'grid grid-cols-2 gap-0 w-full mx-auto border border-color-border-dark rounded-[4px]',
      leftSideContentDescription:
        'font-primary font-heading-mob-large-bold leading-heading-mob-large-bold text-heading-mob-large-bold',
      leftSideContentText:
        'flex flex-col font-primary text-body-large-regular leading-body-large-regular font-body-large-regular text-left',
      tableTitle:
        'border-b border-r border-color-border-dark color-text-dark font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      tableDescription:
        'border-b border-color-border-dark color-text-dark font-primary text-body-large-regular leading-body-large-regular font-body-large-regular',
      imageWrapper: 'block w-full h-auto rounded-[4px]',
      base: [
        'tabbed',
        // 'container',
        // 'mx-auto',
        'product-details-section',
        'bg-color-background-white',
      ],
      tabs: [
        'text-center',
        // 'flex',
        // 'justify-center',
        'items-center',
        'border-2',
        'border-color-scale-1-white',
        // '!rounded-desk-global-radius-small',
        // 'p-desk-global-radius-medium',
        'p-[8px]',
        'shadow-tabWrapper',
        'rounded-[8px]',
      ],
      tabWrapper: [''],
      tabTitle: [
        'color-text-black',
        '!w-[50%]',
        'patelll',
        // 'px-global-spacing-spacing-5',
        '!py-desk-global-spacing-spacing-3',
        '!px-desk-global-spacing-spacing-5',
        '!rounded-desk-global-radius-small',
      ],
      description: [
        'product-details-tabs-section',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      contentWrapper: ['product-details-section-wrapper'],
      flex: ['flex'],
      gap: [''],
      leftSideContentWrapper: 'flex flex-col',
      listUl: 'list-disc	ml-[26px]',
      listLi: 'text-body-large-bold font-body-large-bold leading-body-large-bold',
      rightSideContentWrapper: '',
      nutritionWrapper: 'w-full flex',
      hidden: ['hidden'],
      ul: ['flex-col'],
      whiteText: ['text-color-text-white', 'bg-color-background-brand-1'],
      guaranteedAnalysisPDPLabel: [
        'block',
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
        'mb-2',
      ],
      productDetailTitle:
        'text-color-text-black text-heading-mob-medium-bold leading-heading-mob-medium-bold font-heading-mob-medium-bold',
      productDetailDescription:
        'text-color-text-black text-body-large-regular leading-body-large-regular font-body-large-regular',
      contentTitle:
        'text-color-text-dark text-heading-mob-large-bold leading-heading-mob-large-bold font-heading-mob-large-bold',
    },
    compoundSlots: [{ slots: ['tabs', 'description', 'ul'], class: ['flex'] }],
    variants: {
      size: {
        mobile: {
          nutritionWrapper: 'flex-col-reverse gap-6',
          accordionWrapper: 'py-mob-global-spacing-spacing-5',
          accordionTitle:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          nutritionContentWrapper: 'pt-mob-global-spacing-spacing-5 w-full',
          leftSideContentText: 'gap-mob-global-spacing-spacing-4 w-full',
          tableTitle: 'p-mob-global-spacing-spacing-2',
          tableDescription: 'p-mob-global-spacing-spacing-2',
          flex: ['flex-col-reverse', 'gap-mob-global-grid-gutter'],
          base: ['maaasd p-0'],
          tabs: [
            'rounded-desk-global-radius-small',
            // 'px-mob-padding-micro-x',
            // 'py-mob-padding-micro-y',
            // 'gap-mob-global-spacing-spacing-5',
          ],
          leftSideContentWrapper: 'gap-mob-global-spacing-spacing-4',
          tabTitle: [
            'text-heading-mob-medium-regular',
            'font-heading-mob-medium-regular',
            'leading-heading-mob-medium-regular',
          ],
          description: [
            'mt-mob-global-spacing-spacing-6',
            'flex-col',
            'gap-mob-global-spacing-spacing-6',
          ],
          tabWrapper: ['flex', 'flex-col', 'gap-mob-space-between-loose-vertical'],
          ul: ['gap-mob-margin-base-bottom'],
          whiteText: [
            'text-heading-desk-small-bold',
            'font-heading-desk-small-bold',
            'leading-heading-desk-small-bold',
          ],
          gap: ['gap-mob-global-spacing-spacing-4'],
        },
        desktop: {
          nutritionWrapper: 'gap-0 flex-row',
          accordionWrapper: 'py-desk-global-spacing-spacing-5',
          accordionTitle:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          nutritionContentWrapper: 'flex-row pt-desk-global-spacing-spacing-5 w-[50%]',
          leftSideContentText: 'gap-desk-global-spacing-spacing-4',
          tableTitle: 'p-desk-global-spacing-spacing-2',
          tableDescription: 'p-desk-global-spacing-spacing-2',
          leftSideContentWrapper: 'w-1/2 mr-3 gap-desk-global-spacing-spacing-6',
          rightSideContentWrapper: 'w-1/2 ml-3',
          flex: ['flex-row', 'gap-0'],
          base: [''],
          // tabs: ['px-desk-padding-loose-x', 'py-desk-padding-micro-y', 'gap-desk-padding-loose-x'],
          tabTitle: [
            'cursor-pointer',
            'text-heading-desk-medium-regular',
            'font-heading-desk-medium-regular',
            'leading-heading-desk-medium-regular',
          ],
          description: [
            'mt-desk-global-spacing-spacing-7',
            'flex-row',
            'gap-desk-space-between-loose-vertical',
          ],
          tabWrapper: ['w-full', 'gap-desk-space-between-loose-vertical'],
          ul: ['gap-desk-margin-base-bottom'],
          whiteText: [
            'text-heading-desk-small-bold',
            'font-heading-desk-small-bold',
            'leading-heading-desk-small-bold',
          ],
          gap: ['gap-desk-global-spacing-spacing-6'],
        },
      },
      hasMedia: {
        true: {
          nutritionContentWrapper: ['!w-full'],
        },
        false: {
          nutritionContentWrapper: ['w-[50%]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
