/* eslint-disable @typescript-eslint/no-explicit-any */
import { FulfillmentType } from 'src/helpers/Constants';
import { LineItemWithXp } from 'src/redux/xp';

/**
 * Checks if current cart has all required data or not. If not, return false and stop proceeding ahead with payment.
 * @param {any} currentOrderObject - the ordercloud object which has whole data of the current cart.
 * @returns {boolean} - Returns true if it passes preflight check rules.
 */
export const onSubmitValidateData = async (currentOrderObject: any): Promise<boolean> => {
  // todo: Add null check for order
  const checkBillingAddress = (billingData: {
    FirstName: string;
    LastName: string;
    Street1: string;
    City: string;
    State: string;
    Zip: string;
  }): boolean => {
    return !!(
      billingData?.FirstName &&
      billingData.LastName &&
      billingData.Street1 &&
      billingData.City &&
      billingData.State &&
      billingData.Zip
    );
  };

  const checkShippingAddress = (shippingData: any): boolean => {
    return !!(
      shippingData?.FirstName &&
      shippingData.LastName &&
      shippingData.Phone &&
      shippingData.xp?.Email &&
      shippingData.xp?.SelfPickup !== undefined &&
      shippingData.xp?.IsMobile !== undefined
    );
  };

  const checkPickupInfo = (pickupData: any): boolean => {
    return !!(
      pickupData?.FirstName &&
      pickupData.LastName &&
      pickupData.Email &&
      pickupData.PhoneNumber &&
      pickupData.StoreId
    );
  };

  const checkCartData = async (): Promise<boolean> => {
    // return if current cart object doesn't have required sub-objects
    if (!currentOrderObject?.order || !currentOrderObject?.lineItems) {
      return false;
    }

    const order = currentOrderObject?.order;
    const lineItem = currentOrderObject?.lineItems;

    if (order?.xp?.Fulfillment === FulfillmentType?.DFS) {
      const shippingData = lineItem[0]?.ShippingAddress;
      if (!shippingData || !checkShippingAddress(shippingData)) {
        return false;
      }
    } else {
      const pickupData = order?.xp?.PickupInfo;
      if (!pickupData || !checkPickupInfo(pickupData)) {
        return false;
      }
    }

    return checkBillingAddress(order?.BillingAddress);
  };

  return await checkCartData();
};

/**
 * Checks if current cart has a lineItem or not. If it has, should have a price with it.
 * @param {number} numberOfItemsInCart - The lineItemObjet from which we need to check if count is more than 0 and has prices available.
 * @param {number} subTotal - The lineItemObjet from which we need to check if count is more than 0 and has prices available.
 * @returns {boolean} - Returns true if it passes preflight check rules that is it should have a lineItem and LineItem should have a price.
 */

export const checkoutPreflightChecks = (productlineitems: LineItemWithXp[]): boolean => {
  const noWEItem = productlineitems?.every((item: any) => !item?.Product?.xp?.WE);

  // LineItem Subtotal should not be 0
  const hasInvalidLineTotal = productlineitems?.some((item: any) => item?.LineSubtotal === 0);

  // If any LineSubtotal is 0, return false
  if (hasInvalidLineTotal) {
    return false;
  }

  return productlineitems?.length > 0 && noWEItem;
};

export const isItemValidForCheckout = async (line: any): Promise<boolean> => {
  const hasWEItem = line?.filter((item: any) => item?.Product?.xp?.WE === true);
  return hasWEItem;
};

/**
 * This was an earlier logic used to check on each lineItemObject. Keeping this commented as we can change to this if required.
 */
// check lineItems cound should be atleast one
//   if (!lineItemsObject) {
//     return false;
//   }

//   // lineItems should have atleast a prize:
//   lineItemsObject.forEach((element) => {
//     if (element?.UnitPrice && element?.UnitPrice <= 0) {
//       return false;
//     }
//     return true;
//   });
//   return true;
// };
