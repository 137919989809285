import { FulfillmentType, PurchaseType } from 'src/helpers/Constants';

export function getPurchaseType(fulFillMentType: string, isAutoship: boolean) {
  if (fulFillMentType === FulfillmentType?.DFS && isAutoship) {
    return PurchaseType?.Autoship;
  } else if (fulFillMentType === FulfillmentType?.DFS) {
    return PurchaseType?.OneTimeDelivery;
  }
  return PurchaseType?.CurbsidePickup;
}
