/**
 * Returns a discount price amount based on discount percentage
 * @param listPrice
 * @param discountPercentage
 * @param discountLimit
 * @returns
 */
export const calculateDiscount = (
  listPrice: number,
  discountPercentage: number,
  discountLimit?: number
) => {
  const discount = (listPrice * discountPercentage) / 100;
  return discountLimit && discount > discountLimit ? discountLimit : discount;
};

/**
 * Returns an object that has dicounted price, saved price from discount & discount percentage
 * @param listPrice
 * @param ppcPrice
 * @param avoidCalculate
 * @returns
 */
export const calculateSaveAmount = (
  listPrice: number,
  ppcPrice: number,
  avoidCalculate?: boolean
) => {
  if (avoidCalculate) {
    // To return if product doesn't support Autoship or force restrict autoship calculation
    return { amount: 0, savedPrice: 0, percentage: 0 };
  }
  const discountedPrice = parseFloat((listPrice - ppcPrice).toFixed(2));

  const amount = listPrice - discountedPrice; //  Price after Discount:
  const savedPrice = discountedPrice; // Saved Price
  const percentage = Math.floor((discountedPrice / listPrice) * 100 * 100) / 100; // Saved Percentage:

  return { amount, savedPrice, percentage };
};
