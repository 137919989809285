import React, { useEffect, useState } from 'react';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import Link from 'next/link';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT } from 'src/helpers/Constants';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { useRouter } from 'next/router';
import { heroCarouselTailwindVariant } from 'tailwindVariants/components/heroCarouselTailwindVariant';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { decodeUrlQuery } from 'lib/utils/url-formatter';

export type HeroCarouselProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.HeroCarousel.Fields.HeroCarouselContainer;

interface ReusableCarouselProps extends HeroCarouselProps {
  applyPersonalization?: boolean;
}

const ReusableHeroCarousel: React.FC<ReusableCarouselProps> = ({
  fields,
  params,
  applyPersonalization = false,
}) => {
  const { base, card, img, deskImg, mobImg } = heroCarouselTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const [viewedSlideIds, setViewedSlideIds] = useState<number[]>([]);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { deviceName } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore?.storeId) as string;
  const { isReady } = useRouter();
  const isEE = useExperienceEditor();
  // useState with default value to prevent hydration error
  const [isDevEditor, setIsDevEditor] = useState(false);
  useEffect(() => {
    // temporary way of viewing the experience editor while developing locally (set isEditor to true in localStorage)
    const isDevEditor = localStorage.getItem('isEditor') === 'true';
    setIsDevEditor(isDevEditor);
  }, []);
  const listLength = fields?.selectCarouselItems?.length || 0;
  const isArrow = listLength > 1;

  const setPromotionData = (gtmEvent: string, clicked?: boolean) => {
    const gtmPromotion = [];
    const currentIndex = visibleIndex || 0;
    const visibleIndexData = fields?.selectCarouselItems?.[currentIndex];

    if (!visibleIndexData) return;

    const promotionObject = {
      promotion_id: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.componentName?.value),
      creative_slot: Number(visibleIndexData?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${visibleIndexData?.fields?.promotionDateFROM?.value} - ${visibleIndexData?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionURL?.value?.href),
    };

    if (
      !viewedSlideIds.includes(visibleIndex) &&
      myStoreId &&
      visibleIndexData?.fields?.componentName?.value !== ''
    ) {
      gtmPromotion.push(promotionObject);
      trackObjectForPromotion(gtmEvent, myStoreId, gtmPromotion);
    }

    if (clicked) {
      const data = [{ ...promotionObject }];
      trackObjectForPromotion(gtmEvent, myStoreId, data);
    }
  };

  useEffect(() => {
    if (isReady && myStoreId) {
      setPromotionData(GTM_EVENT?.viewPromotion);
      setViewedSlideIds((prev) => (!prev.includes(visibleIndex) ? [...prev, visibleIndex] : prev));
    }
  }, [deviceName, visibleIndex, isReady, myStoreId]);

  if (!fields) return <></>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderBanner = (banner: any, index: number) => (
    <div
      className={card()}
      onClick={() => setPromotionData(GTM_EVENT?.selectPromotion, true)}
      key={index}
      data-creative-id={banner?.fields?.componentName?.value}
      data-promotion-cta={banner?.fields?.cTAButtonCopy?.value}
      data-promo-id={banner?.fields?.promotionId?.value}
      data-promotion-name={banner?.fields?.promotionName?.value}
      data-promotion-copy={banner?.fields?.promotionCopy?.value}
      data-promotion-url={banner?.fields?.promotionURL?.value?.href}
    >
      <div className={img()}>
        <Link aria-label="image" href={decodeUrlQuery(banner?.fields?.link?.value?.href || '#')}>
          <ImageHelper
            field={banner?.fields?.desktopImage}
            className={deskImg()}
            priority={index === 0}
          />
          <ImageHelper
            field={banner?.fields?.mobileImage}
            className={mobImg()}
            priority={index === 0}
          />
        </Link>
      </div>
    </div>
  );

  const filteredSlide = fields?.selectCarouselItems?.filter(
    (slide) => slide?.fields?.HideDefaultContent?.value === false
  );

  const slideToRender = isEE ? fields?.selectCarouselItems : filteredSlide;

  const filteredBanner = isEE
    ? slideToRender?.map(withPersonalization(renderBanner))
    : slideToRender
        ?.map(withPersonalization(renderBanner))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.filter((x: any) => x?.props?.name);

  const banners = applyPersonalization ? filteredBanner : slideToRender?.map(renderBanner);

  return (
    <div
      className={base({ className: params?.Style ?? '' })}
      data-component="components/HeroCarousel"
    >
      {!isEE && !isDevEditor ? (
        <SplideSlider
          setVisibleIndex={setVisibleIndex}
          isAutoPlay={fields?.isAutoPlay?.value || true}
          options={{
            arrows: isArrow,
            type: isArrow ? 'loop' : '',
            interval: Number(fields?.autoPlayDuration?.value) || 3000,
            autoplay: isArrow ? fields?.isAutoPlay?.value || true : false,
            classes: {
              prev: 'arrow-prev splide-button splide__arrow--prev your-class-prev',
              next: 'arrow-next splide-button splide__arrow--next your-class-next',
            },
          }}
        >
          {banners}
        </SplideSlider>
      ) : (
        <>
          {params?.FieldNames === 'Default' ? (
            <SplideSlider
              setVisibleIndex={setVisibleIndex}
              isAutoPlay={fields?.isAutoPlay?.value || true}
              options={{
                arrows: isArrow,
                type: isArrow ? 'loop' : '',
                interval: Number(fields?.autoPlayDuration?.value) || 3000,
                autoplay: isArrow ? fields?.isAutoPlay?.value || true : false,
                classes: {
                  prev: 'arrow-prev splide-button splide__arrow--prev your-class-prev',
                  next: 'arrow-next splide-button splide__arrow--next your-class-next',
                },
              }}
            >
              {banners}
            </SplideSlider>
          ) : (
            <div className="ee_Herocarousel">{banners}</div>
          )}
        </>
      )}
    </div>
  );
};

export const Default: React.FC<HeroCarouselProps> = withPersonalization((props) => (
  <ReusableHeroCarousel {...props} />
));

export const ImagewithPersonalization: React.FC<HeroCarouselProps> = (props) => (
  <ReusableHeroCarousel {...props} applyPersonalization={true} />
);
