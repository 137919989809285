//global
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

//local
import { newNavigationTailwindVariant } from 'tailwindVariants/components/NewnavigationTailwindVariant';
import { TargetItem } from 'lib/types/header';
import MobileSubNav from './MobilesubNav';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { ComponentRendering, Field, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import BackText from 'src/helpers/HeaderR2/BackText';

//types
export type isOpen = {
  [key: string]: boolean;
};
export type MobileNavProps = {
  setIsHamburger: (val: boolean) => void;
  item: TargetItem;
  height: number;
  isActive: string;
  backText: Field<string>;
  parentIndex?: number; //for subnav
  rendering: ComponentRendering;
};

export default function MobileNav({
  item,
  backText,
  height,
  setIsHamburger,
  rendering,
  parentIndex,
}: MobileNavProps) {
  const {
    flexGrid,
    ulWrapper,
    navigationLink,
    wFull,
    iconWrapper,
    fillBlack,
    iconXSmall,
    mobileNavOpen,
    menuTitle,
    mobNavigation,
    mobNavTitle,
    justifyBetween,
    mobNavLinkWrapper,
    mobNavMarketingTile,
  } = newNavigationTailwindVariant();
  const hasChildren = item?.childrens?.results && item?.childrens?.results.length > 0;

  const [isOpen, setIsOpen] = useState<isOpen>({});
  const [activeIndex, setActiveIndex] = useState<number>();

  //show the current sub nav
  const toggleItem = (itemName: string) => {
    setIsOpen({
      ...isOpen,
      [itemName]: !isOpen[itemName],
    });
    if (itemName === '') {
      setIsOpen({});
    }
  };

  //handling sub navigation scroll
  useEffect(() => {
    const subNav = document?.getElementById('mobileNav');
    setTimeout(() => {
      subNav ? (subNav.scrollTop = 0) : '';
    }, 300);
  }, [isOpen]);

  function hasMoreThanOneTrueValue(obj: isOpen) {
    let trueCount = 0;
    for (const key in obj) {
      if (obj[key] === true) {
        trueCount++;
        if (trueCount > 1) return true;
      }
    }
    return false;
  }
  return (
    <ul
      className={ulWrapper({ className: 'flex-col px-mob-global-spacing-spacing-4' })}
      key={item?.name}
    >
      {!item?.isBrand?.jsonValue?.value && (
        <li
          className={flexGrid()}
          onClick={() => hasChildren && toggleItem(item?.link?.jsonValue?.value?.text || '')}
        >
          {hasChildren ? (
            <span className={menuTitle()}>{item?.link?.jsonValue?.value?.text}</span>
          ) : (
            item?.link?.jsonValue?.value?.href &&
            item?.link?.jsonValue?.value?.href?.length > 0 && (
              <LinkHelper
                field={item?.link?.jsonValue}
                className={clsx(navigationLink(), wFull(), menuTitle())}
                editable={false}
              />
            )
          )}
        </li>
      )}

      {!item?.isBrand?.jsonValue?.value &&
        hasChildren &&
        item?.childrens?.results?.flatMap((flchildren, fcindex) => {
          const hasColumn = flchildren?.name?.toLowerCase()?.startsWith('col');
          const hasflChildren =
            flchildren?.childrens?.results?.length && flchildren?.childrens?.results?.length > 0;

          return hasColumn && !item?.isService?.jsonValue?.value
            ? flchildren?.childrens?.results?.flatMap((tlchildren, tlchindex) => (
                <div
                  key={tlchildren?.name}
                  className={flexGrid()}
                  onClick={() =>
                    hasflChildren && toggleItem(tlchildren?.link?.jsonValue?.value?.text || '')
                  }
                >
                  {tlchildren?.childrens?.results && tlchildren?.childrens?.results?.length > 0 ? (
                    <div key={tlchindex} className={mobNavigation()}>
                      <div
                        data-wrapper={'link-and-data-wrapper'}
                        className={mobNavLinkWrapper()}
                        onClick={() => toggleItem(tlchildren?.link?.jsonValue?.value?.text || '')}
                      >
                        <span className={mobNavTitle()} id={tlchildren?.name}>
                          {tlchildren?.link?.jsonValue?.value?.text || tlchildren?.name}
                        </span>
                        <IconHelper
                          icon={'chevron-right'}
                          className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
                        />
                      </div>
                      <li
                        style={{ height: `calc(100vh - ${height}px)` }}
                        className={mobileNavOpen({
                          className:
                            isOpen[tlchildren?.link?.jsonValue?.value?.text || ''] &&
                            '!translate-x-0 !w-full !block ',
                        })}
                      >
                        <BackText
                          backText={backText}
                          toggleItem={() => toggleItem(tlchildren?.name as string)}
                          link={tlchildren?.link}
                        />

                        <MobileSubNav
                          height={height}
                          subNav={tlchildren}
                          isOpen={isOpen}
                          key={tlchildren?.name}
                          setIsHamburger={setIsHamburger}
                          toggleItem={toggleItem}
                          backText={backText}
                          isShop={true}
                          parentIndex={parentIndex}
                          firstLevel={true}
                          rendering={rendering}
                        />
                      </li>
                    </div>
                  ) : (
                    tlchildren?.link?.jsonValue?.value?.href &&
                    tlchildren?.link?.jsonValue?.value?.href?.length > 0 && (
                      <div key={tlchindex} className={mobNavigation()}>
                        <LinkHelper
                          field={tlchildren?.link?.jsonValue}
                          className={clsx(mobNavTitle())}
                          editable={false}
                          onClick={() => {
                            toggleItem(''), setIsHamburger(false);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              ))
            : !item?.isService?.jsonValue?.value && (
                <li key={fcindex} className={mobNavigation()}>
                  <div
                    data-wrapper={'link-and-data-wrapper'}
                    className={mobNavLinkWrapper()}
                    onClick={() => {
                      toggleItem(flchildren?.name || ''), setActiveIndex(fcindex);
                    }}
                  >
                    <span className={mobNavTitle()}>
                      {flchildren?.link?.jsonValue?.value?.text || flchildren?.name}
                    </span>
                    <IconHelper
                      icon={'chevron-right'}
                      className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
                    />
                  </div>
                  <li
                    style={{ height: `calc(100vh - ${height}px)` }}
                    className={clsx(
                      mobileNavOpen({
                        className:
                          isOpen[flchildren?.name || ''] && '!translate-x-0 !w-full !block',
                      }),
                      { '!overflow-y-hidden': hasMoreThanOneTrueValue(isOpen) }
                    )}
                  >
                    <BackText
                      backText={backText}
                      toggleItem={() => toggleItem(flchildren?.name as string)}
                      link={flchildren?.link}
                    />
                    {/**Mapping children i.e dog -> Food, Dog -> Treats etc ....*/}
                    {flchildren?.childrens?.results?.map((slchildren) => {
                      return (
                        <MobileSubNav
                          height={height}
                          subNav={slchildren}
                          isOpen={isOpen}
                          key={slchildren?.name}
                          setIsHamburger={setIsHamburger}
                          toggleItem={toggleItem}
                          backText={backText}
                          isShop={true}
                          firstLevel={true}
                        />
                      );
                    })}
                    {item?.isShop && (
                      <React.Fragment>
                        {rendering?.placeholders?.[`popular-categories-shop-${activeIndex}`] &&
                          rendering?.placeholders?.[`popular-categories-shop-${activeIndex}`]
                            ?.length > 0 && (
                            <div data-component="popular-categories">
                              <Placeholder
                                name={`popular-categories-shop-${activeIndex}`}
                                rendering={rendering}
                              />
                            </div>
                          )}
                        {rendering?.placeholders?.[`primary-nav-shop-${activeIndex}`] &&
                          rendering?.placeholders?.[`primary-nav-shop-${activeIndex}`]?.length >
                            0 && (
                            <div
                              data-component="mobile-shops-marketing-tile"
                              className={mobNavMarketingTile()}
                            >
                              <Placeholder
                                name={`primary-nav-shop-${activeIndex}`}
                                rendering={rendering}
                              />
                            </div>
                          )}
                      </React.Fragment>
                    )}
                  </li>
                </li>
              );
        })}

      {/** For Brand menu*/}
      {item?.isBrand?.jsonValue?.value && (
        <div className={justifyBetween()}>
          <div className={flexGrid()} onClick={() => toggleItem(item?.name as string)}>
            <p className={menuTitle()}>{item?.link?.jsonValue?.value?.text}</p>
            <IconHelper
              icon={'chevron-right'}
              className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
              onClick={() => toggleItem(item?.name as string)}
            />
          </div>
          <div
            style={{ height: `calc(100vh - ${height}px)` }}
            className={mobileNavOpen({
              className: isOpen[item?.name || ''] && '!translate-x-0 !w-full !block',
            })}
          >
            <BackText
              backText={backText}
              toggleItem={() => {
                toggleItem(item?.name as string);
              }}
              link={item?.link}
            />

            <MobileSubNav
              height={height}
              subNav={item}
              isOpen={isOpen}
              key={item?.name}
              setIsHamburger={setIsHamburger}
              toggleItem={toggleItem}
              backText={backText}
              isShop={true}
              firstLevel={true}
            />
          </div>
        </div>
      )}

      {/** For Brand menu*/}
      {item?.isService?.jsonValue?.value &&
        item?.childrens?.results?.[0]?.childrens?.results?.[0]?.childrens?.results?.map(
          (service, index) =>
            service?.link?.jsonValue?.value?.href && (
              <li className={mobNavigation()} key={index}>
                <div data-wrapper={'link-and-data-wrapper'} className={mobNavLinkWrapper()}>
                  <LinkHelper
                    onClick={() => {
                      toggleItem(''), setIsHamburger(false);
                    }}
                    field={service?.link?.jsonValue}
                    className={clsx(mobNavTitle({ className: 'hover:no-underline' }))}
                    editable={false}
                  />
                </div>
              </li>
            )
        )}
    </ul>
  );
}
