import React, { Dispatch, SetStateAction } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { LineItemWithXp } from 'src/redux/xp';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import LineItemCard from './LineItemCard';
import { PurchaseDetailsFields } from 'components/PurchaseDetails/PurchaseDetails';

export interface PurchaseLineItemsProps extends PurchaseDetailsFields {
  lineItems: LineItemWithXp[];
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setInventoryForAllLineItems: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: number;
        }[]
      | undefined
    >
  >;
  lineItemInventory:
    | {
        [key: string]: number;
      }[]
    | undefined;
  setLineItemInventory: Dispatch<SetStateAction<{ [key: string]: number }[] | undefined>>;
}
const PurchaseLineItems = ({
  lineItems,
  fields,
  setIsModalOpen,
  setLineItemInventory,
  setInventoryForAllLineItems,
}: PurchaseLineItemsProps) => {
  const { PurchaseLineItemsWrapper, totalItems, boldLabels, regularLabels, OrderLineItemsWrapper } =
    purchaseDetailsVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });

  return (
    <div
      className={PurchaseLineItemsWrapper()}
      data-component={'/helpers/purchaseDetails/PurchaseLineItems.tsx'}
    >
      <RichText
        tag="span"
        field={fields?.historicalPRiceNote}
        className={'block ml-5 lg:ml-0 lg:pt-4'}
      />
      <div className={totalItems()}>
        {/**Total lineItem count adding quantity of all lineItems */}
        <span className={boldLabels()}>
          {lineItems?.reduce((acc, item) => acc + (item?.Quantity || 0), 0)}
        </span>
        <Text tag="span" className={regularLabels()} field={fields?.itemsText} />
      </div>
      {/**Line item card */}
      <div className={OrderLineItemsWrapper()}>
        {lineItems?.map((item: LineItemWithXp, index) => (
          <LineItemCard
            setLineItemInventory={setLineItemInventory}
            setInventoryForAllLineItems={setInventoryForAllLineItems}
            key={index}
            item={item}
            index={index}
            lineItemsLength={lineItems?.length}
            fields={fields}
            setIsModalOpen={setIsModalOpen}
          />
        ))}
      </div>
    </div>
  );
};

export default PurchaseLineItems;

9173571429;
