import { tv } from 'tailwind-variants';
export const productVariantsTailwindVariant = tv(
  {
    slots: {
      variantLabel: '',
      base: 'flex flex-col gap-[25px] xl-for-pdp:max-w-[424px]',
      variantsWrapper: 'flex gap-2 flex-wrap',
      variantContainer:
        'font-primary !no-underline hover:!no-underline flex justify-center items-center flex-col leading-body-large-regular font-body-large-regular text-color-text-dark text-body-large-regular rounded text-left',
      priceText:
        'text-color-scale-7-mid-dark font-primary leading-body-small-regular font-body-small-regular text-body-small-regular',
      promoTag:
        'w-fit max-w-[343.5px] border-color-accent-red bg-[#f16e6f] border rounded-l rounded-r-[35px] font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-black',
      title:
        'font-primary leading-body-large-bold font-body-large-bold text-body-large-bold text-color-text-dark ',
    },
    variants: {
      size: {
        mobile: {
          base: 'mt-0',
          promoTag: 'py-mob-global-spacing-spacing-2 px-mob-global-spacing-spacing-4',
          title: 'mb-mob-global-spacing-spacing-1 ',
        },
        desktop: {
          variantContainer: 'w-fit ',
          base: 'mt-[25px] max-w-[424px]',
          promoTag: 'py-desk-global-spacing-spacing-2 px-desk-global-spacing-spacing-4',
          title: 'lg:mb-desk-global-spacing-spacing-1',
          variantsWrapper: 'text-left flex-wrap',
        },
        largeDesktop: {
          base: 'min-w-[350px]',
        },
      },
      isSelected: {
        true: {
          variantContainer:
            'border-2 !border-color-brand-primary-1-base bg-[#b9e5c9] py-[7px] px-[23px]',
        },
        false: {
          variantContainer:
            'border border-color-scale-7-mid-dark py-mob-global-spacing-spacing-2 px-mob-global-spacing-spacing-5 lg:py-desk-global-spacing-spacing-2 lg:px-desk-global-spacing-spacing-5',
        },
      },
      isOutOfStock: {
        true: {
          variantContainer:
            '!border border-dashed border-color-scale-5-mid !py-mob-global-spacing-spacing-2 !px-mob-global-spacing-spacing-5 lg:!py-desk-global-spacing-spacing-2 lg:!px-desk-global-spacing-spacing-5',
          variantLabel: 'text-color-scale-7-mid-dark',
        },
        false: {
          variantContainer: 'border-solid border-color-scale-7-mid-dark ',
        },
      },
      isSelectedOOS: {
        true: {
          variantContainer: [
            '!border-2 !border-dashed !border-color-brand-primary-1-base !bg-color-background-light',
          ],
        },
        false: {
          variantContainer: [''],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
