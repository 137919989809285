//global
import React, { useEffect, useState } from 'react';
import {
  Field as sitecoreField,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { apiRequest } from 'src/utils/apiWrapper';
import { useCookies } from 'react-cookie';
import { Tokens } from 'ordercloud-javascript-sdk';
import { useTheme } from 'lib/context/ThemeContext';
import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { accountUnlockFormTailwindVariant } from 'tailwindVariants/components/accountUnlockFormTailwindVariant';
import { ErrorCodes, getErrorKeyFromErrorCodes } from 'src/helpers/Constants';
import { loginAPI } from 'src/utils/nextApiConfig';

//type
export type AccountUnlockFormProps = ComponentProps & {
  fields?: {
    data?: {
      data?: {
        title: sitecoreField<string>;
        consentMessage: sitecoreField<string>;
        shortDescription: sitecoreField<string>;
        submitButtonText: sitecoreField<string>;
        successMessage: sitecoreField<string>;
        failureMessage: sitecoreField<string>;
        cancelButtonUrl: {
          jsonValue: LinkField;
        };
        cancelButtonText: sitecoreField<string>;
        successRedirectUrl: {
          jsonValue: LinkField;
        };
        formFields: {
          targetItems: FormField[];
        };
      };
    };
  };
};
type FormField = {
  id: sitecoreField<string>;
  name: sitecoreField<string>;
  label: sitecoreField<string>;
  placeholdertext: sitecoreField<string>;
  helptext: sitecoreField<string>;
  required: sitecoreField<string>;
  requiredvalidationmessage: sitecoreField<string>;
  validationpattern: sitecoreField<string>;
  validationerrormessage: sitecoreField<string>;
  characterlimit: sitecoreField<number | null>;
  disabled: sitecoreField<string>;
  fieldtype: sitecoreField<string>;
  checkboxvalues?: radioList[];
};
interface radioList {
  name?: string;
  value?: string;
}

const getFieldData = (
  formFields: {
    targetItems: FormField[];
  },
  fieldName: string
) => {
  return formFields?.targetItems?.find((item: FormField) => item?.name?.value === fieldName)?.label;
};
//main component
const AccountUnlockForm: React.FC<AccountUnlockFormProps> = ({ fields, params }) => {
  const { base, successBase, title, headingWrapper, button, description } =
    accountUnlockFormTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const [successResponse, setSuccessResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailFromLinkExpiredScenario, setEmailFromLinkExpiredScenario] = useState<false | string>(
    false
  );
  const [showAccountUnlockFormForLinkExpiredCase, setShowAccountUnlockFormForLinkExpiredCase] =
    useState(false);
  const [isMobileFromLinkExpiredScenario, setIsMobileFromLinkExpiredScenario] = useState(false);
  const [cookies] = useCookies(['ordercloud.access-token']);
  const { themeNameUpper } = useTheme();
  const router = useRouter();
  let token: string;
  if (cookies['ordercloud.access-token']) {
    token = cookies['ordercloud.access-token'];
  } else {
    token = Tokens.GetAccessToken() ?? '';
  }

  const renderFormFields = (formFields: { targetItems: FormField[] }) => {
    const { radioButtonWrapper, radioButtonsWrapper, radioField, radioIcon } =
      accountUnlockFormTailwindVariant({
        size: { initial: 'mobile', lg: 'desktop' },
      });
    const radioButtonData = formFields?.targetItems?.find(
      (item: FormField) => item?.id?.value === 'SendLinkCheckbox'
    )?.checkboxvalues?.values as radioList[] | undefined;

    return (
      <div role="group" aria-labelledby="my-radio-group" className={radioButtonsWrapper()}>
        {radioButtonData?.map((radioButton: radioList, index: number) => {
          if (
            radioButton?.name === 'Text' &&
            router?.query?.ismobile?.toString()?.toLowerCase() !== 'true' &&
            isMobileFromLinkExpiredScenario !== true
          ) {
            return <></>;
          }
          return (
            <>
              <label key={index} className={radioButtonWrapper()}>
                <Field
                  aria-label="SendLinkCheckbox"
                  type="radio"
                  class={radioField()}
                  name="SendLinkCheckbox"
                  value={radioButton?.name}
                />
                {radioButton?.name}
                <div className={radioIcon()}></div>
              </label>
            </>
          );
        })}
      </div>
    );
  };

  const onSendLinkClick = async (e: React.MouseEvent<HTMLButtonElement>, selectedMode: string) => {
    e.stopPropagation();
    e.preventDefault();
    if (router?.query?.un || emailFromLinkExpiredScenario) {
      try {
        const email = router?.query?.un ?? emailFromLinkExpiredScenario;
        const response = await apiRequest(loginAPI?.lockeduserrequest, {
          method: 'POST',
          headers: {
            Authorization: token,
            site: themeNameUpper,
            requesturl: window.location.origin,
          },
          data: {
            Email: decodeURIComponent(email?.toString()),
            SMS: selectedMode === 'Text',
          },
        });
        if (response) {
          setSuccessResponse(true);
          const loginForms = document.querySelectorAll('.loginForm');
          loginForms.forEach((form: HTMLElement) => {
            form.style.display = 'none';
          });
          document.querySelectorAll('.accountUnlockHeading').forEach((element) => {
            (element as HTMLElement).style.display = 'none'; // Set display to block
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const unlockUser = async () => {
    setIsLoading(true);
    try {
      const dataToSend = router?.query;
      delete dataToSend?.path;
      const response: {
        Status: number;
        Email?: string;
        IsMobile?: boolean;
      } = await apiRequest(loginAPI?.unlockuser, {
        method: 'POST',
        headers: {
          Authorization: token,
          site: themeNameUpper,
          requesturl: window.location.origin,
          dataToSend: new URLSearchParams(dataToSend as { [key: string]: string }).toString(),
        },
      });
      if (response) {
        showThisHeadingWithDescription(getErrorKeyFromErrorCodes(response?.Status));
        if (response?.Status == ErrorCodes?.LinkExpired) {
          setShowAccountUnlockFormForLinkExpiredCase(true);
          if (response?.Email) {
            setEmailFromLinkExpiredScenario(response?.Email);
          }
          if (response?.IsMobile) {
            setIsMobileFromLinkExpiredScenario(response?.IsMobile);
          }
        }
        if (response?.Status == ErrorCodes?.Success) {
          const loginForms = document.querySelectorAll('.loginForm');
          loginForms.forEach((form: HTMLElement) => {
            form.style.display = 'block';
          });
        } else {
          const loginForms = document.querySelectorAll('.loginForm');
          loginForms.forEach((form: HTMLElement) => {
            form.style.display = 'none';
          });
        }
      }
    } catch (error) {
      console.error(error);
      showThisHeadingWithDescription('Fail');
    } finally {
      setIsLoading(false);
    }
  };

  const showThisHeadingWithDescription = (className?: string) => {
    if (className) {
      const headings = document?.querySelectorAll(`.${className}`);
      headings.forEach((heading: HTMLElement) => {
        heading.style.display = 'block';
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
      if (router.isReady) {
        if (router?.query?.e) {
          unlockUser();
        } else {
          setIsLoading(false);
        }
      }
    }, 100);
    return () => {
      const loginForms = document.querySelectorAll('.loginForm');
      loginForms.forEach((form: HTMLElement) => {
        form.style.display = 'none';
      });
      clearTimeout(timer);
    };
  }, [router.isReady]);

  useEffect(() => {
    // Function to hide login forms if `un` query exists
    const hideLoginForms = () => {
      if (router?.query?.un) {
        document.querySelectorAll('.accountUnlockHeading').forEach((element) => {
          (element as HTMLElement).style.display = 'block'; // Set display to block
        });
      }
    };
    hideLoginForms();
    const handleRouteChange = () => hideLoginForms();
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  if (isLoading || !router.isReady) return <AccountSkeleton />;
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={`AccountUnlockForm ${params?.Styles} ${
        showAccountUnlockFormForLinkExpiredCase || router?.query?.un ? '!block' : ''
      }`}
    >
      {successResponse ? (
        <div className={successBase()}>
          <RichText className="rte" tag="p" field={fields?.data?.data?.successMessage} />
        </div>
      ) : (
        <>
          {(router?.query?.un || showAccountUnlockFormForLinkExpiredCase) && (
            <div className={base()}>
              <div>
                <div className={headingWrapper()}>
                  {fields?.data?.data?.formFields && (
                    <Text
                      tag="p"
                      className={title()}
                      field={getFieldData(fields?.data?.data?.formFields, 'SendLinkCheckbox')}
                    />
                  )}
                  <div>
                    {fields?.data?.data?.formFields && (
                      <Formik
                        initialValues={{
                          SendLinkCheckbox: 'Email',
                        }}
                        onSubmit={async () => {
                          await new Promise((r) => setTimeout(r, 500));
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            {fields?.data?.data?.formFields &&
                              renderFormFields(fields?.data?.data?.formFields)}
                            <button
                              type="submit"
                              className={button()}
                              onClick={(e) => onSendLinkClick(e, values.SendLinkCheckbox)}
                            >
                              {fields?.data?.data?.submitButtonText?.value}
                            </button>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className={headingWrapper()}>
                  {fields?.data?.data?.formFields && (
                    <Text
                      tag="p"
                      className={title()}
                      field={getFieldData(fields?.data?.data?.formFields, 'Registration1Text')}
                    />
                  )}
                  {fields?.data?.data?.formFields && (
                    <RichText
                      tag="p"
                      className={description()}
                      field={getFieldData(fields?.data?.data?.formFields, 'Registration2Text')}
                    />
                  )}
                </div>
                <LinkHelper
                  className={button()}
                  field={{
                    value: {
                      ...fields?.data?.data?.cancelButtonUrl?.jsonValue?.value,
                      text: fields?.data?.data?.cancelButtonText?.value,
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<AccountUnlockFormProps>(AccountUnlockForm);

const AccountSkeleton = () => {
  return (
    <div className="container text-center py-4 px-4 my-10">
      <div className="max-w-[954px] mx-auto space-y-4">
        {/* Heading Skeleton */}
        <div className="animate-pulse">
          <div className="h-10 bg-gray-300 rounded w-3/4 mx-auto mb-4"></div>

          {/* Description Skeleton */}
          <div className="h-6 bg-gray-200 rounded w-1/2 mx-auto mb-6"></div>
        </div>

        {/* Account Unlock Form Skeleton */}
        <div className="bg-white shadow-md rounded-lg p-6 animate-pulse">
          {/* Send Link Section */}
          <div className="mb-6">
            <div className="h-6 bg-gray-300 rounded w-1/3 mb-4"></div>
            <div className="flex items-center space-x-4">
              <div className="h-6 bg-gray-200 rounded w-1/4"></div>
            </div>
            <div className="mt-4 h-12 bg-gray-300 rounded w-full lg:w-1/4"></div>
          </div>

          {/* Create Account Section */}
          <div className="mt-28">
            <div className="h-6 bg-gray-300 rounded w-1/3 mb-4"></div>
            <div className="h-6 bg-gray-200 rounded w-2/3 mb-4"></div>
            <div className="mt-4 h-12 bg-gray-300 rounded w-full lg:w-1/3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
