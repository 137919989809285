import IconHelper from 'src/helpers/commonComponents/IconHelper';
import searchResultsTailwind from '../SearchResultsTailwind';
import { ProductSearchResultModel } from '../types';
import useDictionary from 'src/hooks/useDictionary';
import { BuyerProductWithXp } from 'src/redux/xp';

export function ProductAutoship({
  product,
}: {
  product: ProductSearchResultModel | BuyerProductWithXp;
}) {
  const dictionary = useDictionary();

  const { autoship } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  if (product.xp?.Autoship) {
    return (
      <div className={autoship()}>
        <IconHelper icon={'autoship'} />
        <span className="text-body-medium-bold font-body-medium-bold leading-body-medium-bold">
          {dictionary.getDictionaryValue('AutoshipSaveMorePLP', 'Save more with Autoship')}
        </span>
      </div>
    );
  }
  return <div className={'autoship min-h-[24px]'}></div>;
}
