import { tv } from 'tailwind-variants';

export const productDetailTailwindVariant = tv(
  {
    slots: {
      base: 'flex flex-col gap-[25px] lg-for-pdp:min-w-[350px] lg-for-pdp:max-w-[350px] xl-for-pdp:min-w-[424px] xl-for-pdp:max-w-[424px]',
      productName: 'font-primary text-color-text-dark',
      bazaarVoiceWrapper: 'mt-4',
      brandName:
        'font-primary leading-body-large-bold font-body-large-bold text-body-large-bold underline text-color-accent-blue hover:text-color-text-dark focus:text-color-text-dark',
      brandSection:
        'flex font-primary text-color-text-dark font-body-large-regular text-body-large-regular leading-body-large-regular',
      stockStatus:
        'inline-block border-2 w-fit rounded-full font-primary text-color-text-dark font-body-large-regular text-body-large-regular leading-body-large-regular',
    },
    variants: {
      size: {
        mobile: {
          productName:
            'font-heading-mob-large-bold text-heading-mob-large-bold leading-heading-mob-large-bold ',
          brandSection: 'mt-mob-global-spacing-spacing-1 gap-mob-global-spacing-spacing-05',
          stockStatus: 'py-mob-global-spacing-spacing-1 px-mob-global-spacing-spacing-4 ',
        },
        desktop: {
          base: 'min-w-[424px] max-w-[424px]',
          productName:
            'font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
          brandSection: 'mt-desk-global-spacing-spacing-1gap-desk-global-spacing-spacing-05',
          stockStatus: 'py-desk-global-spacing-spacing-1 px-desk-global-spacing-spacing-4 ',
        },
        largeDesktop: {
          base: 'min-w-[350px] max-w-[350px]',
        },
      },
      availability: {
        InStock: { stockStatus: 'border-color-global-in-stock' },
        LowStock: { stockStatus: 'border-color-global-low-stock' },
        OutOfStock: { stockStatus: 'border-color-global-outof-stock' },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
