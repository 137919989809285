import { tv } from 'tailwind-variants';

export const mobileBackToMenuTailwindVariant = tv(
  {
    slots: {
      base: ['bg-black', 'text-white', 'p-desk-component-button-condensed-padding-y'],
      text: ['font-body-small-regular', 'text-color-text-white'],
      sideNavItems: ['gap-desk-component-button-full-space-between'],
      sideNavItem: [
        'w-full',
        'hover:bg-color-scale-2-light',
        'hover:no-underline',
        'inline-block',
        'after:hidden',
        'cursor-pointer',
      ],
      sideNavItemIcon: [''],
      navLink: [
        'flex',
        'items-center',
        'gap-1',
        'cursor-pointer',
        'hover:no-underline',
        'hover:text-color-brand-primary-1-base',
      ],
      backToMenuIcon: [
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        '[&>svg>*]:fill-color-text-brand-1',
        'pt-[1px]',
      ],
      closeMenuIcon: [
        '[&>svg]:h-6',
        '[&>svg]:w-6',
        '[&>svg>*>*]:fill-color-text-brand-1',
        'pt-[2px]',
      ],
    },
    variants: {
      device: {
        mobile: {
          sideNavItems: ['border-b', 'border-color-border-mid'],
          sideNavItem: [
            'flex',
            'items-center',
            'justify-between',
            'text-color-text-dark',
            'capitalize',
            'text-heading-mob-small-bold',
            'font-heading-mob-small-bold',
            'leading-heading-mob-large-bold',
            'text-[#565a5c]',
            'py-mob-global-spacing-spacing-2',
            'px-mob-global-grid-margin',
          ],
          sideNavItemIcon: ['flex', 'items-center', 'gap-2', 'text-color-brand-primary-1-base'],
          base: ['block'],
        },
        desktop: {
          base: ['hidden'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
