import { tv } from 'tailwind-variants';

export const frequentlyBroughtProductVariants = tv(
  {
    slots: {
      mainWrapper: ['bg-color-background-light'],
      base: ['frequentlyBroughtProduct', 'flex', 'flex-col'],
      container: ['container mx-auto'],
      heading: ['border-b-[1px]', 'border-color-border-mid', 'pb-desk-global-spacing-spacing-2'],
      titleAndProductWrapper: ['flex', 'flex-col', 'gap-[10px]', 'w-full'],
      componentWrapper: ['flex', 'gap-desk-global-spacing-spacing-7'],
      productWrapper: ['flex'],
      productTile: ['max-w-[256px]', 'min-w-[256px]', 'h-full', 'flex', 'flex-col'],
      detailWrapper: ['flex', 'flex-col', 'justify-between'],
      selectAndDetailsWrapper: [
        'selectAndDetailsWrapper',
        'flex',
        'gap-desk-global-spacing-spacing-4',
        'flex-start',
        'grow',
      ],
      totalAndCTAWrapper: ['flex', 'flex-col', 'gap-desk-global-spacing-spacing-4'],
      checkBox: ['relative'],
      inputCheck: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border-[2px]',
        'appearance-none',
        'bg-color-background-white',
        'checked:border-[0px]',
        'checked:bg-color-brand-primary-1-base',
        'peer rounded-[4px]',
        'border-color-border-mid"',
      ],
      checkmarkIcon: [
        '[&>svg>*>*]:fill-color-text-white',
        'left-0',
        'absolute',
        '[&>svg]:w-[24px]',
        '[&>svg]:h-[24px]',
        'invisible',
        'rotate-[-12deg]',
        'peer-checked:visible',
      ],
      productName: [
        'text-color-text-dark',
        'text-left',
        'cursor-pointer',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      productPriceText: ['!text-heading-desk-medium-bold !font-heading-desk-medium-bold'],
      plusIcon: ['mt-[84px] mx-[14px]'],
      totalPriceText: [
        'text-heading-desk-small-regular',
        'font-heading-desk-small-regular',
        'leading-heading-desk-small-regular',
        'mr-desk-global-spacing-spacing-2',
      ],
      listPrice: [''],
      addToCartBtn: [
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        'py-desk-component-button-full-padding-y',
        'px-desk-component-button-full-padding-x',
        'bg-color-brand-primary-1-base',
        'text-color-text-white',
        'text-center',
        'w-fit',
        'whitespace-nowrap',
        'min-w-[233px]',
      ],
      productAndCtaWrapper: ['productAndCtaWrapper', 'flex'],
      productImage: ['fbImage', 'w-auto', 'h-full', 'max-h-[156px]', 'mix-blend-darken'],
      productTopWrapper: ['flex', 'justify-between'],
      flagIcon: ['w-[27px]', 'h-[18px]'],
      imageWrapper: [
        'px-desk-global-spacing-spacing-4',
        'h-[208px]',
        'flex',
        'justify-center',
        'items-center',
      ],
    },
    compoundSlots: [
      {
        slots: ['addToCartBtn', 'listPrice'],
        class: [
          'text-heading-desk-large-bold',
          'font-heading-desk-large-bold',
          'leading-heading-desk-large-bold',
        ],
      },
    ],
    variants: {
      size: {
        mobile: {
          base: [
            'pl-mob-padding-micro-x',
            'py-mob-global-spacing-spacing-7',
            'gap-mob-margin-base-bottom',
          ],
          container: ['p-0'],
          sitecoreProductRoot: ['w-[211px]', 'p-mob-global-spacing-spacing-2', 'flex', 'flex-col'],
          sitecoreProductName: ['py-mob-global-spacing-spacing-1'],
          heading: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          productAndCtaWrapper: ['flex-col', 'gap-mob-global-spacing-spacing-7'],
          productWrapper: ['overflow-y-scroll', 'w-full'],
          totalAndCTAWrapper: ['items-center'],
        },
        desktop: {
          base: ['px-desk-padding-micro-x', 'gap-desk-margin-base-bottom'],
          heading: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          container: ['px-4'],
          sitecoreProductName: ['py-desk-global-spacing-spacing-1', 'h-[106px]'],
          productBottomWrapper: ['gap-desk-space-between-tight-vertical'],
          productAndCtaWrapper: ['flex-row', 'items-center'],
          productWrapper: ['overflow-y-visible', 'w-fit'],
          totalAndCTAWrapper: ['items-start'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
