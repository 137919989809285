import { tv } from 'tailwind-variants';

export const productDetailDeliveryMethodTailwindVariant = tv(
  {
    slots: {
      base: 'lg-for-pdp:min-w-[350px] lg-for-pdp:max-w-[350px] xl-for-pdp:min-w-[432px] xl-for-pdp:max-w-[432px]',
      changeAddressText:
        'font-body-large-bold leading-body-large-bold text-body-large-bold underline',
      deliveryAvailabilityMessage:
        'text-center font-primary font-body-large-regular text-body-large-regular leading-body-large-regular flex justify-center gap-mob-global-spacing-spacing-1 lg:gap-desk-global-spacing-spacing-1 flex flex-wrap',
      deliveryInstruction:
        'font-primary font-body-large-regular text-body-large-regular leading-body-large-regular text-color-scale-7-mid-dark',
      deliveryInstructionWrapper:
        'flex items-center gap-mob-global-spacing-spacing-5 lg:gap-desk-global-spacing-spacing-5',
      deliveryInstructionsWrapper:
        'px-mob-global-spacing-spacing-5 lg:px-desk-global-spacing-spacing-5 flex flex-col gap-mob-global-spacing-spacing-2 lg:gap-desk-global-spacing-spacing-2',
      deliveryTitle:
        'text-center font-primary text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
      titleWrapper: 'justify-center flex',
      strikePrice:
        'line-through font-heading-desk-small-bold leading-heading-desk-small-bold text-heading-desk-small-bold',
      price: 'font-primary  text-color-accent-red mb-desk-global-spacing-spacing-1',
      containerWrapper: 'flex flex-col ',
      deliveryDetailWrapper:
        'relative items-center flex lg-for-pdp:px-desk-global-spacing-spacing-4 xl-for-pdp:px-desk-global-spacing-spacing-5',
      deliveryMessage:
        'text-color-scale-7-mid-dark text-center mt-mob-global-spacing-spacing-1 lg:mt-desk-global-spacing-spacing-1 font-primary text-body-small-regular leading-body-small-regular font-body-small-regular',
      deliveryAddressWrapper: [
        'py-mob-global-spacing-spacing-2 lg:py-desk-global-spacing-spacing-2',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      priceLine:
        'font-primary text-heading-desk-small-regular leading-heading-desk-small-regular font-heading-desk-small-regular text-color-scale-7-mid-dark',
      priceWrapper:
        'flex w-[80px] items-center flex-col font-primary text-heading-desk-small-bold leading-heading-desk-small-bold font-heading-desk-small-bold text-color-text-dark',
      deliveryMethodWrapper:
        'bg-color-background-light w-full rounded shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]',
      title:
        'font-primary leading-body-large-bold font-body-large-bold text-body-large-bold text-color-text-dark',
    },
    variants: {
      size: {
        mobile: {
          base: 'w-full',
          deliveryDetailWrapper:
            'gap-mob-global-spacing-spacing-6 px-mob-global-spacing-spacing-5 py-mob-global-spacing-spacing-3 xl-for-pdp:px-desk-global-spacing-spacing-7',
          containerWrapper: 'gap-mob-global-spacing-spacing-4',
          title: 'mb-mob-global-spacing-spacing-2',
          price:
            'text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold font-heading-mob-xLarge-bold',
        },
        desktop: {
          deliveryDetailWrapper:
            '!px-desk-global-spacing-spacing-5 py-desk-global-spacing-spacing-2 gap-mob-global-spacing-spacing-6',
          base: 'min-w-[432px] max-w-[432px]',
          title: 'mb-desk-global-spacing-spacing-2',
          containerWrapper: 'gap-desk-global-spacing-spacing-4',
          price: 'text-desk-large-bold leading-desk-large-bold font-desk-large-bold',
        },
      },
      availability: {
        InStock: { base: 'border-color-global-in-stock' },
        LowStock: { base: 'border-color-global-low-stock' },
        OutOfStock: { base: 'border-color-global-outof-stock' },
      },
      isSelected: {
        true: {
          deliveryDetailWrapper: '',
          deliveryMethodWrapper: 'border-color-brand-primary-1-base border-4 ',
          titleWrapper:
            'bg-color-brand-primary-1-base  w-full  py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-2 lg:px-desk-global-spacing-spacing-3 lg:py-desk-global-spacing-spacing-2',
          deliveryTitle: 'text-color-text-white',
        },
        false: {
          deliveryDetailWrapper: '',
          deliveryMethodWrapper: 'border-color-scale-3-light-mid border-4',
          titleWrapper:
            'bg-color-scale-3-light-mid w-full py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-2 lg:px-desk-global-spacing-spacing-3 lg:py-desk-global-spacing-spacing-2',
          deliveryTitle: 'text-color-text-black',
        },
      },
      priceType: {
        list: {
          price: ['text-color-text-black'],
        },
        ppc: {
          price: ['text-color-accent-red'],
        },
        imap: {
          price: [
            'text-color-accent-red !text-heading-mob-large-bold !leading-heading-mob-large-bold !font-heading-mob-large-bold',
          ],
        },
        autoship: {
          price: ['text-color-brand-autoship'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
