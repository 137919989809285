import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import ocConfig from './ocConfig';
import ocErrors from './ocErrors';
import ocAuth from './ocAuth';
import ocUser from './ocUser';
import ocCategoryCache from './ocCategoryCache';
import ocCategoryList from './ocCategoryList';
import ocProductCache from './ocProductCache';
import ocProductList from './ocProductList';
import ocProductDetail from './ocProductDetail';
import ocCurrentOrder from './ocCurrentOrder';
import ocAddressBook from './ocAddressBook';
import ocProductSpecsCache from './ocProductSpecsCache';
import storeReducer from './storeDetailsSlice';
import upcomingAppointments from './Grooming/appointments';
import pastAppointments from './Grooming/past-appointments';
import getDogs from './Grooming/user-dogs';
import wishlist from './wishlist';
import brands from './brands';
import myPetReducer from './myPetsSlice';
import { createStateSyncMiddleware } from 'redux-state-sync';
import otherSettingSlice from './otherSettingSlice';
import ocPastOrder from './ocPastOrders/index';
const store = configureStore({
  reducer: {
    ocConfig,
    ocErrors,
    ocAuth,
    ocUser,
    ocAddressBook,
    ocCategoryCache,
    ocCategoryList,
    ocProductCache,
    ocProductList,
    ocProductDetail,
    ocCurrentOrder,
    ocProductSpecsCache,
    myPetReducer,
    storeReducer,
    upcomingAppointments,
    pastAppointments,
    getDogs,
    wishlist,
    otherSettingSlice,
    ocPastOrder,
    brands,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware();
    // Add redux-state-sync middleware
    middlewares.push(createStateSyncMiddleware());
    return middlewares;
  },
});

export type OcRootState = ReturnType<typeof store.getState>;
export type LookupRootState = ReturnType<typeof store.getState>;
export type OcDispatch = typeof store.dispatch;
export type OcThunkApi = {
  dispatch: OcDispatch;
  state: OcRootState;
};

export const useOcDispatch = (): ThunkDispatch<OcRootState, null, AnyAction> =>
  useDispatch<OcDispatch>();
export const useOcSelector: TypedUseSelectorHook<OcRootState> = useSelector;

export default store;
