import { PageController } from '@sitecore-search/react';
import { Cookies } from 'react-cookie';

type StoreEntityProps = string | Array<string>;

const setStoreEntity = (updatedStoreEntity: StoreEntityProps) => {
  if (updatedStoreEntity) {
    const entityIds = Array.isArray(updatedStoreEntity) ? updatedStoreEntity : [updatedStoreEntity];
    PageController.getContext().setEntityIds('store', entityIds);
  }
};

export default setStoreEntity;

type ProductEntityProps = {
  productEntity?: { [key: string]: string[] };
  isDefaultContext?: boolean;
};

export const setProductEntity = ({ productEntity }: ProductEntityProps) => {
  if (productEntity) {
    PageController.getContext().setIds(productEntity);
  }
  // else if (isDefaultContext) {
  //   PageController.getContext().setUserUuid(getDomainId());
  // }
};

const generateUUID = (domainId: string) => {
  const uuidx = 'xx-xx-4x-1p-'; // pattern of prefix to user id
  let uuid = uuidx.replace(/[x]/g, () => digit2string((Math.random() * 36) | 0));
  for (let i = 0; i < 5; i++) {
    uuid += ('0000' + ((Math.random() * 1679615) | 0).toString(36)).slice(-4);
  }
  let value = domainId;
  value += '-' + uuid + '-' + Date.now();
  setCookie(value, 2);
  return value;
};

export const getDomainId = () => {
  const uuid = getCookie('uuid');
  if (uuid) {
    return uuid;
  }
  const domainID = process.env.NEXT_PUBLIC_PSP_SD_ID || '';
  return generateUUID(domainID);
};

/**
 * @description: Sets a cookie on the browser. Refer to https://www.w3schools.com/js/js_cookies.asp
 * @param {string} cname: Cookie name
 * @param {string} cvalue: Cookie value
 * @param {number} exdays: Expiry date in days
 */
function setCookie(cvalue: string, exdays: number, cname = 'uuid') {
  const cookies = new Cookies();
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  cookies?.set(cname, cvalue, { expires: new Date(d.toUTCString()) });
}
/**
 * @description: Gets a cookie already in browser. Returns empty if not exists.
 * Refer to https://www.w3schools.com/js/js_cookies.asp
 * @param cname
 * @returns {string}
 */
function getCookie(cname: string) {
  const cookies = new Cookies();
  const cookie = cookies.get(cname);
  if (cookie) {
    return cookie;
  }
  return undefined;
}
/**
 * @description: Converts a digit to the string that belongs to.
 * @param {number} i: Number to convert
 * @returns {string}
 */
function digit2string(i: number) {
  // 0-9 -> 0-9; 10-35 > a-z; 36-61 -> A-Z
  return String.fromCharCode(i < 10 ? i + 48 : i < 36 ? i + 87 : i + 29);
}
