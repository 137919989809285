import { tv } from 'tailwind-variants';

export const recommendationProductVariant = tv(
  {
    slots: {
      base: ['flex', 'flex-col', 'bg-color-background-white', 'overflow-hidden'],
      carouselWrapper: [
        'carouselWrapper',
        'flex',
        'flex-col',
        'gap-desk-margin-base-bottom',
        '[&>*>*>button]:border-color-border-brand',
        '[&>*>*>button>svg]:fill-color-brand-primary-1-base',
        '[&>*>*>button]:border-[2px]',
        '[&>*>*>button]:bg-white',
        '[&>*>*>button]:hidden',
        'lg:[&>*>*>button]:flex',
        '[&>*>*>button]:border-solid',
        'lg:[&>*>*>button]:w-10',
        'lg:[&>*>*>button]:h-10',
        '[&>*>*>button>svg]:h-4',
        '[&>*>*>button>svg]:w-4',
        '[&>*>*>button>svg]:w-4',
        'md:[&_.slider-prev]:left-[88%]',
        'lg:[&_.slider-prev]:left-[0px]',
        'lg:[&_.slider-next]:right-[0px]',
        '[&>*>*>button>svg]:h-3',
        '[&_.splide_li]:!w-fit',
        'lg:[&_.splide_li]:!w-auto',
      ],
      heading: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      container: ['productRecommendation', 'container mx-auto'],
      plpWrapper: ['bg-color-scale-2-light'],
      sitecoreProductRoot: ['productTile', 'justify-between', 'overflow-hidden'],
      productImageAndTitleWrapper: ['flex', 'flex-col'],
      resultsWrapper: [
        'grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4',
        'flex flex-row flex-wrap',
        'gap-[24px]',
      ],
      productInfoWrapper: ['productInfoWrapper', 'flex', 'flex-col', 'justify-end', 'h-full'],
      sitecoreProductName: [
        'text-color-text-dark',
        'text-left',
        'cursor-pointer',
        'inline-block whitespace-normal',
        'overflow-hidden overflow-ellipsis',
      ],
      moreOptionLink: [
        '!border-0',
        '!text-body-large-regula',
        '!font-body-large-regular',
        '!leading-body-large-regular',
        '!text-color-text-dark',
        'opacity-50',
        'hover:!bg-transparent',
        '!text-left',
        '!py-0 !min-h-[0px]',
        'group',
      ],
      productLabels: ['flex', 'justify-center'],
      productButtonCTA: [
        'py-mob-component-button-condensed-padding-y',
        'px-mob-component-button-condensed-padding-x',
        'rounded-global-radius-small',
        'border-[2px]',
        'bg-blue-300',
        'text-center',
      ],
      submitBtn: [
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        'font-heading-desk-medium-bold',
        'text-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-center',
        'py-desk-component-button-full-padding-y',
        'w-full',
      ],
      listPrice: ['w-full', 'flex', 'flex-row', 'justify-between'],
      autoship: [
        'text-color-brand-autoship',
        'text-center',
        'flex',
        'items-center',
        'justify-center',
        'gap-[4px]',
      ],

      productBottomWrapper: ['flex', 'flex-col', 'w-full', 'items-center'],
      productImage: ['w-auto', 'h-full', 'prImage'],
      productTopWrapper: ['flex', 'justify-between'],
      flagIcon: ['w-[27px]', 'h-[18px]'],
      sitecoreImage: ['relative', 'h-[185px]'],
      sitecoreImageWrapper: [
        'my-auto',
        'relative',
        'h-[156px]',
        'flex',
        'justify-center',
        'items-center',
        'overflow-hidden',
      ],
      productStockPriceInfo: [
        'productStockPriceInfo',
        'flex',
        'flex-col',
        'gap-global-spacing-spacing-1',
        'h-full',
      ],
      addToFavoritesWrapper: 'flex max-w-[148px] font-primary text-left',
      addToFavoriteIcon: [
        'inline-flex',
        '[&>svg>*>*]:fill-color-brand-primary-1-base',
        '[&>svg]:h-[20px]',
        '[&>svg]:w-[20px]',
      ],
      tooltipContainer: 'relative w-full h-full p-2 pb-3 lg:p-4',
      topToolTipDescription:
        'font-primary text-body-small-bold font-body-small-bold leading-body-small-bold gap-4 flex flex-col',
      topTooltipArrowFirstDiv:
        'absolute left-1/2 top-full border-[12px] border-solid border-l-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-x-1/2',
      topTooltipArrowSecondDiv:
        'border-[12px] absolute left-1/2 -translate-x-1/2 -bottom-[21px] border-solid border-l-transparent border-r-transparent border-b-transparent border-t-color-background-light',
      topTooltipWrapper:
        'absolute left-1/2 -translate-x-1/2 bottom-[calc(100%+10px)] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      offerLabel: [
        'absolute',
        'top-[60%]',
        'left-0',
        'w-[140px]',
        'text-color-text-white',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
        'py-desk-global-spacing-spacing-1',
        'px-desk-global-spacing-spacing-2',
        'rounded-e-desk-global-radius-x-large',
        'rounded-s-desk-global-radius-small',
        'bg-[#D1362E]',
      ],
    },
    variants: {
      size: {
        mobile: {
          base: ['pl-mob-padding-micro-x', 'py-mob-padding-tight-y', 'gap-mob-margin-base-bottom'],
          container: ['p-0'],
          sitecoreResultsWrapper: ['flex flex-col', 'py-mob-padding-tight-y'],
          sitecoreProductRoot: [
            'sitecoreProductRoot',
            'max-w-[211px]',
            'p-mob-global-spacing-spacing-2',
            'flex',
            'flex-col',
          ],
          sitecoreProductName: ['py-mob-global-spacing-spacing-1'],
          productBottomWrapper: ['gap-mob-space-between-tight-vertical'],
          facetPaginationBlock: ['gap-mob-space-between-tight-vertical'],
          topTooltipWrapper: 'rounded-mob-global-radius-medium',
        },
        desktop: {
          base: [
            'px-desk-padding-micro-x',
            'py-desk-padding-tight-y',
            'gap-desk-margin-base-bottom',
          ],
          heading: ['max-w-max'],
          container: ['px-4'],
          sitecoreProductRoot: [''],
          sitecoreResultsWrapper: ['flex-row', 'justify-between', 'py-desk-padding-tight-y'],
          sitecoreProductName: ['py-desk-global-spacing-spacing-1', 'h-[106px]'],
          productBottomWrapper: ['gap-desk-space-between-tight-vertical'],
          sitecoreLeftSection: [
            'min-w-fit',
            'block',
            'w-full',
            // 'lg:w-[12%]',
            'lg:w-[20%]',
            'lg:max-w-[276px]',
          ],
          filterButton: ['hidden'],
          iconSearch: ['right-[8px]'],
          searchTitle: [
            'font-heading-desk-xLarge-bold',
            'text-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          queryAndFilter: ['flex-row', 'w-full', 'justify-between', 'items-center'],
          querySummary: ['w-fit'],
          sortSelectParent: ['!w-[250px]', '[&>*>div>div]:w-[250px]'],
          facetItemLabel: ['max-w-[203px]'],
          sitecoreMarketingTile: ['w-[220px]', '[&_img]:h-full'],
          facetPaginationBlock: ['gap-desk-space-between-tight-vertical'],
          topTooltipWrapper: 'rounded-desk-global-radius-medium',
        },
      },
      outlineCta: {
        true: {
          submitBtn: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
        false: {
          submitBtn: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
