import { tv } from 'tailwind-variants';

const addToCartWarningModalVariant = tv(
  {
    slots: {
      errorPopupWrapper: ['flex', 'flex-col', 'items-start'],
      errorPopupSpacing: [],
      errorPopupHeading: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      errorPopupDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      errorPopupButtonWrapper: ['flex'],
    },
    variants: {
      size: {
        mobile: {
          errorPopupWrapper: ['gap-mob-space-between-tight-vertical'],
          errorPopupSpacing: ['py-mob-padding-tight-y', 'px-mob-padding-base-x'],
          errorPopupButtonWrapper: ['mt-mob-margin-base-top'],
        },
        desktop: {
          errorPopupWrapper: ['gap-desk-space-between-tight-vertical'],
          errorPopupSpacing: ['py-desk-padding-tight-y', 'px-desk-padding-base-x'],
          errorPopupButtonWrapper: ['mt-desk-margin-base-top'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

export default addToCartWarningModalVariant;
