/* eslint-disable */
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { bVRatingSummaryTailwindVariant } from 'tailwindVariants/components/bVRatingSummaryTailwindVariant';

const BazaarVoiceReview = () => {
  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  const { base } = bVRatingSummaryTailwindVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  return (
    <>
      {productData?.Xp?.UPC ? (
        <div
          id="pReview"
          className={`container bazaar-voice-pdp px-desk-global-spacing-spacing-4 py-desk-global-spacing-spacing-5 lg:px-desk-global-spacing-spacing-10 lg:py-desk-global-spacing-spacing-8`}
        >
          <div
            data-bv-show="reviews"
            data-bv-product-id={productData?.Xp?.UPC}
            className={base()}
          />
        </div>
      ) : null}
    </>
  );
};
export default BazaarVoiceReview;
