import { Dispatch, SetStateAction } from 'react';
import ButtonHelper from '../commonComponents/Button';
import ModalWrapper from '../commonComponents/ModalWrapper';
import addToCartWarningModalVariant from 'tailwindVariants/components/addToCartWarninigModalTailwindVariant';
import { Field, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

type AddToCartWarningModalProps = {
  isModalOpen: boolean;
  isPartiallyAdded: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsReorderAll?: Dispatch<SetStateAction<boolean>>;
  setIsPartiallyAdded?: Dispatch<SetStateAction<boolean>>;
  modalTitle: RichTextField;
  modalDescription: RichTextField;
  modalCTA: Field<string>;
};

const AddToCartWarningModal: React.FC<AddToCartWarningModalProps> = ({
  setIsModalOpen,
  isModalOpen,
  modalTitle,
  modalDescription,
  setIsReorderAll,
  isPartiallyAdded,
  setIsPartiallyAdded,
}) => {
  const {
    errorPopupSpacing,
    errorPopupWrapper,
    errorPopupHeading,
    errorPopupDescription,
    errorPopupButtonWrapper,
  } = addToCartWarningModalVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  function handleClosePopup() {
    setIsModalOpen(false);
    setIsReorderAll && setIsReorderAll(false);
    if (isPartiallyAdded || headerContextData?.isPartiallyAdded) {
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        isReorderAll: false,
      });
    }
    setIsPartiallyAdded && setIsPartiallyAdded(false);
  }
  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleClosePopup}
      showModal={isModalOpen}
      customPopup={true}
      additionalClassForPopUpModal={'items-baseline'}
      popupSpacing={errorPopupSpacing()}
      popupWidth={'max-w-[995px]'}
    >
      <div className={errorPopupWrapper()}>
        <RichText className={errorPopupHeading()} field={modalTitle} />

        <RichText className={errorPopupDescription()} field={modalDescription} />
        <div className={errorPopupButtonWrapper()}>
          <ButtonHelper
            isLinkField={true}
            variant="primary"
            size="default"
            field={{ value: { href: '/', text: 'Ok' } }}
            onClickHandler={(e) => {
              e?.preventDefault();
              handleClosePopup();
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddToCartWarningModal;
